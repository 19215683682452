import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Box,
  Divider,
  MenuItem,
  Select,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Container,
  Drawer,
} from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import {
  linksInformationIndexData,
  linksTransactionsIndexData,
} from "../standard_categories/dummyData";
import { Tab, Tabs } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import ShowSharingLinks from "./sharing_links_sidebar";
import LinkShowDetails from "./sharing_show";
import InfoIcon from "@material-ui/icons/Info";

const Index = () => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [submitting, setSubmitting] = useState<any>(false);
  const [storeColumns, setStoreColumns] = useState<any>();
  const [startDate, setStartDate] = useState<any>(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState<any>(moment());
  const [showSharingLinks, setShowSharingLinks] = useState(false);
  const [sharingLinkData, setSharingLinkData] = useState<any>();
  const [countDetails, setCountDetails] = useState<any>("");
  const _tableRef = React.createRef<any>();
  const _ShowLinkDetails = useRef<any>();
  const [stage, setStage] = useState<string>("links_information");
  const [value, setValue] = React.useState(0);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleClear = () => {
    handleMonthChange({ target: { value: "" } });
    handleYearChange({ target: { value: "" } });
    _tableRef.current.onQueryChange();
  };

  const handleSearch = async () => {
    if (month && year) {
      try {
        _tableRef.current.onQueryChange();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      alert("Please select both month and year");
    }
  };

  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 3 }, (_, i) => currentYear + (i - 1));

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setStage("links_information");
    } else if (newValue === 1) {
      setStage("links_transactions");
    } else if (newValue === 2) {
      setStage("report_shared_links");
    }
    _tableRef.current.onQueryChange();
  };
  const toggleShowSharingLinks = () => {
    setShowSharingLinks(!showSharingLinks);
  };

  const columnsInfo = [
    {
      title: "Brand",
      field: "name",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Created At",
      field: "created_at",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Created By",
      field: "user_name",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Phone Number",
      field: "phone_number",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Product Price",
      field: "price",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Profit per purchase",
      field: "item_profit",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    // {
    //   title: "Page",
    //   field: "page",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
    // {
    //   title: "Tab",
    //   field: "tab",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
    // {
    //   title: "Data",
    //   field: "data",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
    // {
    //   title: "Type",
    //   field: "creator_type",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
  ];
  const columnsTrans = [
    {
      title: "Type",
      field: "txn_type",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Unit",
      field: "txn_unit",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Amount",
      field: "amount",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Owner",
      field: "owner",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Owner phone number",
      field: "owner_phone_number",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Receiver phone number",
      field: "receiver_phone_number",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Category",
      field: "category",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    // {
    //   title: "Tab",
    //   field: "tab",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
    // {
    //   title: "Data",
    //   field: "data",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
    // {
    //   title: "Type",
    //   field: "creator_type",
    //   filtering: false,
    //   sorting: false,
    //   editable: "never",
    // },
  ];

  const columnsReport = [
    {
      title: "No of clicked links per month",
      field: "no_of_clicked_links_per_month",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "No of shared linked shared & purchased per month",
      field: "no_of_shared_linked_shared_and_purchased_per_month",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "No of shared links per month",
      field: "no_of_shared_links_per_month",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Total amount spend per month on shared links",
      field: "total_amount_spend_per_month_on_shared_links",
      filtering: false,
      sorting: false,
      editable: "never",
    },
  ];

  useEffect(() => {
    if (stage === "links_information") {
      setStoreColumns(columnsInfo);
    } else if (stage === "links_transactions") {
      setStoreColumns(columnsTrans);
    } else if (stage === "report_shared_links") {
      setStoreColumns(columnsReport);
    }
  }, [stage]);

  const formatEndDate = moment(endDate).format("DD-MM-YYYY");
  const formatStartDate = moment(startDate).format("DD-MM-YYYY");

  const LinkInfo = `/short_links.json?locale=en`;
  const LinkTrans = `/short_links/link_sharing_transactions.json?locale=en&month=${month}&year=${year}`;
  const LinkReports = `/short_links/shared_link_report.json?locale=en&start_date=${formatStartDate}&end_date=${formatEndDate}`;

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      <LinkShowDetails ref={_ShowLinkDetails} />
      <div id="pure-scrollable-tab" style={{ padding: "1rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label="Links Information"
            {...a11yProps(0)}
            style={{ margin: "0rem .5rem" }}
          />
          <Tab
            label="Links Transaction"
            {...a11yProps(1)}
            style={{ margin: "0rem .5rem" }}
          />
          <Tab
            label="Report Shared Link"
            {...a11yProps(2)}
            style={{ margin: "0rem .5rem" }}
          />
        </Tabs>
      </div>
      {stage == "report_shared_links" && (
        <Box sx={{ display: "flex", margin: "2rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "20%" }}>
            <Typography>From date</Typography>

            <Box style={{ minWidth: "40%", marginLeft: "15%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  name="start_date"
                  //   label="From date"
                  onChange={(ev) => {
                    setStartDate(ev), _tableRef.current.onQueryChange();
                  }}
                  format="dd/MM/yyyy"
                  value={startDate}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          <Box sx={{ display: "flex", alignItems: "center", width: "20%" }}>
            <Typography>To date</Typography>

            <Box style={{ minWidth: "40%", marginLeft: "17%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  name="end_date"
                  //   label="To date"
                  onChange={(ev) => {
                    setEndDate(ev), _tableRef.current.onQueryChange();
                  }}
                  format="dd/MM/yyyy"
                  value={endDate}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
        </Box>
      )}
      {stage == "links_transactions" && (
        <Container
          maxWidth="none"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 10, // Increased gap for more space between elements
              mt: 2,
              mb: 1,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <FormControl style={{ width: 200 }}>
              <InputLabel>Month</InputLabel>
              <Select value={month} label="Month" onChange={handleMonthChange}>
                {months.map((m, index) => (
                  <MenuItem key={index} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: 150 }}>
              <InputLabel>Year</InputLabel>
              <Select value={year} label="Year" onChange={handleYearChange}>
                {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", gap: 10, marginLeft: "3rem" }}>
              {" "}
              {/* Additional Box for button grouping */}
              <Button
                variant="contained"
                onClick={handleSearch}
                style={{
                  width: 120,
                  backgroundColor: "#ec9a53",
                  color: "#ffff",
                  "&:hover": {
                    backgroundColor: "#d88b48", // Slightly darker shade for hover state
                  },
                }}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                style={{
                  width: 120,
                  borderColor: "#ec9a53",
                  color: "#ec9a53",
                  "&:hover": {
                    borderColor: "#d88b48",
                    color: "#d88b48",
                  },
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
          <Box>{countDetails}</Box>
        </Container>
      )}
      <div style={{ padding: "1rem" }}>
        <Table
          title={
            stage == "links_information"
              ? `Sharing Links`
              : month && year
              ? `Sharing links Transactions ${year}/${month}`
              : `All Sharing links Transactions`
          }
          options={{
            toolbar: true,
            sorting: true,
            search: stage == "links_information" ? true : false,
            filtering: false,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
          }}
          // cellEditable={{
          //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          //     return new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         handleUpdates(rowData, oldValue, newValue, columnDef),
          //           resolve();
          //       }, 500);
          //     });
          //   },
          // }}
          columns={
            stage === "links_information"
              ? columnsInfo
              : stage === "links_transactions"
              ? columnsTrans
              : stage === "report_shared_links"
              ? columnsReport
              : undefined
          }
          tableRef={_tableRef}
          actions={[
            stage === "links_information"
              ? {
                  icon: () => <InfoIcon />,
                  onClick: toggleShowSharingLinks,
                  isFreeAction: true,
                  tooltip: "Show sharing links count",
                }
              : "",
            stage === "links_information"
              ? {
                  icon: () => <pureIcons.BiShow />,
                  onClick: (event, rowData) => {
                    _ShowLinkDetails.current.handleOpen(rowData.id);
                  },
                  tooltip: "Show",
                }
              : "",
          ]}
          data={(query: any) =>
            new Promise((resolve) => {
              var filters: any;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url: any =
                stage === "links_information"
                  ? LinkInfo
                  : stage === "links_transactions"
                  ? LinkTrans
                  : stage === "report_shared_links"
                  ? LinkReports
                  : undefined; // default case
              url += "&per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  setOrdersData(result?.short_links);
                  resolve({
                    data:
                      stage === "links_information"
                        ? result.short_links
                        : stage === "links_transactions"
                        ? result.transactions
                        : stage === "report_shared_links"
                        ? [result]
                        : undefined, // default case
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  setCountDetails(result.count_details);
                  setSharingLinkData(result);
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                });
            })
          }
        />
      </div>
      <Drawer
        anchor="right"
        open={showSharingLinks}
        onClose={toggleShowSharingLinks}
      >
        <ShowSharingLinks
          toggleShowSharingLinks={toggleShowSharingLinks}
          sharingLinkData={sharingLinkData}
        />
      </Drawer>
    </div>
  );
};

export default Index;
