import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import { Backdrop, Button } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";

const PaymentsComp = forwardRef((props, ref) => {
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [working, setWorking] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [ordersId, setOrdersId] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [muiTableKey, setMuiTableKey] = useState<any>(0);

  const _tableRef = React.createRef<any>();
  const _createcardRef = React.createRef<any>();
  const _showpaymentsRef = React.createRef<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(id, name) {
      setOpen(true);
      setOrdersId(id);
      getData(id);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Wallet type",
      field: "wallet_type",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Amount in currency",
      field: "amount",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Amount in Kidus",
      field: "kidus_amount",
      editable: "never",
      filtering: false,
      sorting: false,
    },

    {
      title: "Status",
      field: "status",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Wallet number",
      field: "wallet_number",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Created at",
      field: "created_at",
      editable: "never",
      filtering: false,
      sorting: true,
    },
    {
      title: "Updated at",
      field: "updated_at",
      editable: "never",
      filtering: false,
      sorting: true,
    },
  ];

  const getData = (id) => {
    const url = `/users/${id}/payments_index?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setData(result.payments),
          setPage(result.page - 1),
          setTotalCount(result.total);
      });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Table
          title={`Payments`}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            exportButton: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            maxBodyHeight: "60vh",
          }}
          columns={columns}
          tableRef={_tableRef}
          data={data}
        />
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(PaymentsComp);
