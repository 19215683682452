import React from 'react';
import Table from '../../shared/tables/material_table';
import {BsChatDots} from 'react-icons/bs';
import {AiOutlineShoppingCart} from 'react-icons/ai';
import TrafficIcon from '@material-ui/icons/Traffic';
import {FiRefreshCw} from 'react-icons/fi'
import PurchaseForm from './_purchase_form';
import {ImInfo} from "react-icons/im";
import {Badge, List, ListItem, ListItemText} from '@material-ui/core';
import ShowOrder from '../_show'
import AutoControlPanel from './_auto_control_panel'
import { MdOutlineSummarize } from "react-icons/md";
import OrdersSummary from "./_orders_summary"
export default class Received extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			loading: true,
			total: null,
		};
		this._tableRef = React.createRef();
		this._PurchaseFormRef = React.createRef();
		this._ShowOrderRef = React.createRef();
		this._AutoControlPanelRef = React.createRef();
		this._OrdersSummaryRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

	render() {
		const columns = [
			{title: 'Order Number',	field: 'order_number', sorting: false, editable: 'never', render: rowData => (<Badge badgeContent={rowData.new_customer ? 'new' : null} color='secondary'>{rowData.order_number}</Badge>)},
			{title: 'Vendor(s)', field: 'vendors', cellStyle: {whiteSpace: 'normal', wordWrap: 'break-word', width: '30%'}},
			{title: 'Order time', field: 'created_at'},
			{title: 'Shopper ID',	field: 'shopper_id', sorting: false},
			{title: 'Shopper Phone', field: 'shopper_phone_number', sorting: false},
			{title: 'Shopper Kidus', field: 'shopper_kidus', sorting: false},
		]

		const detailsColumns = [
			{title: 'Vendor', field: 'vendor', sorting: false},
			{title: 'Items qty', field: 'products_count', sorting: false},
			{title: 'Value', field: 'order_total', sorting: false},
		]

		return (
			<div className='bg-white rounded'>
				<PurchaseForm ref={this._PurchaseFormRef} reloadData={this.reloadData}/>
				<ShowOrder ref={this._ShowOrderRef}/>
				<AutoControlPanel ref={this._AutoControlPanelRef}/>
				<OrdersSummary ref={this._OrdersSummaryRef}/>
				<Table
					title={'Received Orders'}
					options={{filtering: false, debounceInterval: 1000}}
					localization={{toolbar: {searchPlaceholder: 'Order or vendor'}}}
					columns={columns}
					tableRef={this._tableRef}
					actions={
						[{
							icon: () => <TrafficIcon/>,
							onClick: () => {
								this._AutoControlPanelRef.current.handleOpen()
							},
							isFreeAction: true,
							tooltip: 'Auto control'
						},
							{
								icon: () => <FiRefreshCw/>,
								onClick: () => {
									this.reloadData()
								},
								isFreeAction: true,
								tooltip: 'Refresh'
							},
							,
							{
								icon: () => <MdOutlineSummarize/>,
								onClick: () => this._OrdersSummaryRef.current.handleOpen(),
								isFreeAction: true,
								tooltip: 'Orders Summary'
							},
							rowData => ({
								icon: () => <ImInfo/>,
								tooltip: <span>Show order details</span>,
								onClick: () => this._ShowOrderRef.current.handleOpen(rowData.id, rowData.order_number),
								position: 'row'
							})
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({field: col.column.field, value: col.value}))
							}
							let url = `/orders/received.json?automated=${this.props.automated}&`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.orders.flat(),
										page: result.page - 1,
										totalCount: result.total,
									});
									window.breadcrumb.addBreadcrumb(result.breadcrumb);
								})
						})
					}
					detailPanel={parentRowData => {
						return (
							<Table
								options={{filtering: false, toolbar: false, paging: false, pageSize: parentRowData.vendors_count}}
								columns={detailsColumns}
								data={query =>
									new Promise((resolve, reject) => {
										let url = `/orders/${parentRowData.id}/details.json`
										fetch(url)
											.then(response => response.json())
											.then((result) => {
												resolve({
													data: result
												})
											})
									})
								}
								actions={
									[rowData => ({
										icon: () => <AiOutlineShoppingCart/>,
										tooltip: <span>Shop {rowData.vendor}</span>,
										onClick: (event, rowData) => {
											this._PurchaseFormRef.current.handleOpen(rowData.id, rowData.vendor, rowData.order_number, rowData.in_the_cart, rowData.not_supported)
										},
										position: 'row',
										disabled: !parentRowData.ready_to_purchase
									}),
										rowData => ({
											icon: () => <BsChatDots/>,
											tooltip: rowData.notes.length !== 0 && <List dense={true}>
												{rowData.notes.map(note =>
													<ListItem key={note}>
														<ListItemText primary={note}/>
													</ListItem>,
												)}
											</List>,
											position: 'row',
											disabled: rowData.notes.length === 0
										})
									]}
							/>
						)
					}}
				/>
			</div>
		);
	}
}
