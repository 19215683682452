import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from '../shared/pure_icons';
import { Backdrop } from '@material-ui/core';
import CreateExpense from './_create_expense'
import Modal from '../shared/modal/material_dialog'



class Expenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      user_balance: '',
      errors: {},
    };
    this._tableRef = React.createRef();
    this._inputFileRef = React.createRef();
    this._createExpenseRef = React.createRef();
  }
  reloadData = () => {
    this._tableRef.current.onQueryChange();
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }
  render() {
    const columns = [
      { title: 'User Name', field: 'user_id', editable: 'never' },
      { title: 'Region', field: 'region', editable: 'never' },
      { title: 'Expense Type', field: 'expense_type', editable: 'never' },
      { title: 'Process', field: 'process', editable: 'never' },
      { title: 'reverse', field: 'reverse', editable: 'never' },
      { title: 'Amount', field: 'amount' },
      { title: 'Currency', field: 'currency', editable: 'never' },
      { title: 'Note', field: 'note', editable: 'never' },
      { title: 'Date', field: 'created_at', editable: 'never' }
    ]
    const body =
      <>
        <div className='bg-white rounded'>
          <Backdrop open={this.state.submitting} />
          <CreateExpense ref={this._createExpenseRef} reloadData={this.reloadData} />
          <Table
            title={`My Expenses `}
            options={{
              toolbar: true,
              sorting: true,
              search: false,
              exportButton: true,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
            }
            }
            columns={columns}
            tableRef={this._tableRef}
            actions={
              [
                // {
                //   icon: () => <pureIcons.AiOutlinePlus />,
                //   onClick: () => {
                //     this._createExpenseRef.current.handleOpen()
                //   },
                //   isFreeAction: true,
                //   tooltip: 'Add New Expense'
                // },
                {
                  icon: () => <pureIcons.FiRefreshCw />,
                  onClick: () => { this.reloadData() },
                  isFreeAction: true,
                  tooltip: 'Refresh'
                },
              ]}
            data={query =>
              new Promise((resolve, reject) => {
                var filters
                if (query.filters.length > 0) {
                  filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
                }
                let url = `/expense.json?`
                url += 'per_page=' + query.pageSize
                url += '&page=' + (query.page + 1)
                if (query.filters.length > 0) {
                  url += '&filters=' + JSON.stringify(filters)
                }
                if (query.orderBy) {
                  url += '&orderBy=' + query.orderBy.field
                  url += '&orderDirection=' + (query.orderDirection)
                }
                fetch(url)
                  .then(response => response.json())
                  .then((result) => {
                    resolve({
                      data: result.expenses,
                      page: result.page - 1,
                      totalCount: result.total,
                    });
                    this.setState({ user_balance: result.user_balance })
                    window.breadcrumb.addBreadcrumb(result.breadcrumb);
                  })
              })
            }
          />
        </div>
      </>
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'>
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(Expenses)
