import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from "../../shared/pure_icons";
import RangePicker from "react-range-picker";
import { Backdrop } from '@material-ui/core';

class CanceledItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      working: false,
      date: null,
      date2: null,
      muiTableKey: 0,
    };
    this._tableRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const onDateChanges = (date, date2) => {
      if (!date2) return;
      this.setState({ date: date, date2: date2 })
      this._tableRef.current.onQueryChange();
    };

    const clear = () => {
      if (this.state.date && this.state.date2) {
        this.setState({ date: null, date2: null })
        this._tableRef.current.onQueryChange();
      }
    };
    const placeholder = ({ startDate, endDate }) => {
      if (!this.state.date) {
        return <button type="button" className="btn btn-link">Select date range</button>
      }
      return (
        <div>
          <div>From - {this.state.date.toLocaleDateString("es-GT")}</div>
          {this.state.date2 && (
            <div>To - {this.state.date2.toLocaleDateString("es-GT")}</div>
          )}
        </div>
      );
    };

    const footer = ({ startDate, endDate, today, close }) => {
      return <div className='row pt-2'><button type="button" className="btn btn btn-light btn-sm" onClick={clear}>Clear</button></div>
    }

    const columns = [
      { title: 'Orders', field: 'order_number', filtering: false, sorting: false },
      { title: 'Product Name', field: 'product_name', filtering: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 300 }, sorting: false },
      { title: 'Quantity', field: 'quantity', filtering: false, cellStyle: { width: '2%' }, sorting: false },
      { title: 'Price', field: 'product_price', filtering: false, cellStyle: { width: '10%' }, sorting: false },
      { title: 'Canceled By', field: 'canceled_by', filtering: false, sorting: false },
      { title: 'Cancel Reason', field: 'admin_cancel_reason', filtering: false, sorting: false },
      { title: 'Date', field: 'cancel_date', filterComponent: () => <RangePicker onDateSelected={onDateChanges} rangeTillEndOfDay={true} placeholderText='Select date' dateFormat='DDDD-MMMM-YYYY' placeholder={placeholder} footer={footer} /> },
    ]
    const detailsColumns = [
      { title: 'Refund Stage', field: 'refund_stage', filtering: false, sorting: false },
      { title: 'Refund Type', field: 'refund_type', filtering: false, sorting: false },
      { title: 'Created BY', field: 'refund_creator', filtering: false, sorting: false },
      { title: 'Refund Amount', field: 'refund_amount', filtering: false, cellStyle: { width: '10%' }, sorting: false },
      { title: 'Is Refunded', field: 'is_refunded', filtering: false, cellStyle: { width: '10%' }, sorting: false },
      { title: 'Refund Date', field: 'refund_date', filtering: false },
    ]

    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title={'Canceled Items'}
          key={this.state.muiTableKey}
          options={{ debounceInterval: 1000, actionsColumnIndex: -1 }}
          columns={columns}
          tableRef={this._tableRef}
          localization={{ toolbar: { searchPlaceholder: 'Search By Order Number' } }}
          actions={
            [
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => { this.reloadData() },
                isFreeAction: true,
                tooltip: 'Refresh'
              },
            ]
          }
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/order_item_stages/canceled_items.json?date_start=${this.state.date}&date_end=${this.state.date2}&`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              if (query.search) {
                url += '&search=' + (query.search)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.items,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }

          detailPanel={[rowData => ({
            tooltip: "Show Refund Details",
            disabled: !rowData.refunded,
            render: (rowData) => {
              return (
                <Table
                  options={{
                    filtering: false, sorting: false, search: false, toolbar: false, paging: false, pageSize: rowData.items_count
                  }}
                  columns={detailsColumns}
                  data={() =>
                    new Promise((resolve, reject) => {
                      let url = `/order_item_stages/${rowData.id}/canceled_items_details.json?`
                      fetch(url)
                        .then(response => response.json())
                        .then((result, rowData) => {
                          resolve({
                            data: result.cash_refunds
                          })
                        })
                    })
                  }
                />
              )
            }
          })]}
        />
      </div>
    );
  }
}
export default withSnackbar(CanceledItems)
