import React from 'react';
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, TextField, InputLabel, Select, MenuItem, FormControlLabel, Checkbox,
	FormLabel, FormHelperText, FormGroup, FormControl} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack';
class ThirdPartyPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			file_path: '',
			year: '',
			months: [],
			email: '',
			page: 1,
			errors: {},
		}
	};

	handleOpen = () => {
		this.setState({open: true, page: 1, year: '', months: [], email: ''});
	};

	handleClose = () => {
		if (this.state.working) return;
		this.setState({open: false});
	};

	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('email' in fieldValues) {
			const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			temp.email = regex.test(fieldValues.email) ? '' : 'Invalid format';
		}
		if ('months' in fieldValues) {
			temp.months = fieldValues.months.length ? '' : 'Required';
		}

		this.setState({errors: {...temp}})

		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleInputChange = e => {
		this.setState({email: e.target.value})
		this.validate({email: e.target.value});
	}

	handleCreateReport = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({working: true, file_path: null});
		$.ajax({
			url: `/reports/total_of_vendors_by_month`,
			type: 'POST',
			data: {year: this.state.year, months: this.state.months, email: this.state.email},
			dataType: 'json',
			success: function (response) {
				self.setState({working: false, page: 2});
			},
			error: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	handleMonthsChange = e => {
		const _months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		var array = this.state.months;
		
		const {name, checked} = e.target;
		if (checked) {
			this.setState({months: array.concat(_months.indexOf(name) + 1)});
			this.validate({months: [_months.indexOf(name) + 1]});
		} else {
			var itemsToDelete = array.filter(word => word === _months.indexOf(name) + 1);
			let newArray = array.filter(x => !itemsToDelete.includes(x));
			this.setState({months: newArray});
			this.validate({months: newArray});
		}
	}
	render() {
		const {classes} = this.props;
		const currentYear = (new Date()).getFullYear();
		const currentMonth = new Date().getMonth() + 1;
		const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
		const _months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		const body = 
			<Card className={classes.root}>
				
					{this.state.page === 1 ? 
						<div className="container pb-5">
							<div className="row pt-2">
								<div className="col-2">
									<InputLabel id="simple-select-label">Year</InputLabel>
									<Select
										labelId="simple-select-label"
										name="simple-select"
										value={this.state.year}
										onChange={(event)=> this.setState({year: event.target.value})}
									>
										{range(currentYear, 2021, -1).map(year => <MenuItem value={year}>{year}</MenuItem>)}
									</Select>
								</div>
									<div className="col-10">
										<fieldset className={`alert ${this.state.errors.months ? 'alert-danger' : ''}`}>
											<FormControl error={this.state.errors.months} component="fieldset">
												<FormLabel component="label" >Month</FormLabel>
												{this.state.errors.months &&
													<FormHelperText error={true}>{this.state.errors.months}</FormHelperText>
												}
												<FormGroup>
												<div className="row">
													<div className="col-3">
														{['January', 'February', 'March', 'April'].map(m=><FormControlLabel
															hidden={currentYear === this.state.year && (_months.indexOf(m) >= currentMonth) }
															control={
																<Checkbox
																	checked={this.state.months.indexOf(_months.indexOf(m) + 1) !== -1}
																	onChange={this.handleMonthsChange}
																	disabled={!this.state.year}
																	name={m}
																	color="primary"
																/>
															}
															label={m}
														/>)}
													</div>
													<div className="col-3">
														{['May', 'June', 'July', 'August'].map(m=><FormControlLabel
															hidden={currentYear === this.state.year && (_months.indexOf(m) >= currentMonth) }
															control={
																<Checkbox
																	checked={this.state.months.indexOf(_months.indexOf(m) + 1) !== -1}
																	onChange={this.handleMonthsChange}
																	disabled={!this.state.year}
																	name={m}
																	color="primary"
																/>
															}
															label={m}
														/>)}
													</div>
													<div className="col-3">
														{['September', 'October', 'November', 'December'].map(m=><FormControlLabel
															hidden={currentYear === this.state.year && (_months.indexOf(m) >= currentMonth) }
															control={
																<Checkbox
																	checked={this.state.months.indexOf(_months.indexOf(m) + 1) !== -1}
																	onChange={this.handleMonthsChange}
																	name={m}
																	disabled={!this.state.year}
																	color="primary"
																/>
															}
															label={m}
														/>)}
													</div>
												</div>
												</FormGroup>
											</FormControl>
										</fieldset>
								</div>
								
							</div>
							<div className="row pt-3">	
								<div className="col">
									<TextField
										name='email'
										label='Email'
										fullWidth
										onChange={this.handleInputChange}
										value={this.state.email || ''}
										{...(this.state.errors.email && {error: true, helperText: this.state.errors.email})}
									/>
								</div>
							</div>
						</div>
					: 
						<div className="container pb-5">
							<div className="row pt-2">
								<p>A link will be send to your email when the report is ready</p>
							</div>
						</div>
					} 
				
			</Card>
	
		const actions = (
			<Button
				variant='contained'
				type='submit'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				disabled={this.state.working}
				hidden={this.state.page === 2}
				onClick={this.handleCreateReport}
			>
				{this.state.working ? 'Working...' : 'Submit'}
			</Button>
		);

		return (
			<Modal
				isOpen={this.state.open}
				title='Total vendor by month'
				handleClose={this.handleClose}
				minHeight='40vh'
				maxHeight='50%'
				maxWidth='sm'
				actions={actions}>
				{body}
			</Modal>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default withSnackbar(withStyles(useStyles)(ThirdPartyPayment))
