
import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from '../shared/pure_icons';
import { Backdrop, Button } from '@material-ui/core';
import $ from "jquery";


class StagingBusinessProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      muiTableKey: 0,
      total: null,
    };
    this._tableRef = React.createRef();
    this._vendorFormRef = React.createRef();

  }


  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  handleApproveBusiness = (rowData) => {
    let self = this;
    let values = this.state.values;
    let payload = {
      id: rowData.staging_user_id
    }
    $.ajax({
      url: '/staging_business_profiles/approve_business',
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully Created!', { variant: 'success' })
        self.reloadData()
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })

      },
    });
  }


  render() {
    const columns = [
      { title: 'ID', field: 'id', },
      { title: 'Info ID', field: 'staging_user_id', },
      { title: 'Business Name', field: 'business_name', },
      { title: 'User Name', field: 'name', },
      { title: 'Service Number', field: 'service_number' },
      { title: 'Phone Number', field: 'phone_number' },
      { title: 'Industry', field: 'industry', },
      { title: 'Sales ID', field: 'sales_id', },
      { title: 'Latitude/Longitude', field: 'longitude_latitude', },
      { title: 'Action Type', field: 'action_type', },
      { title: 'Created At', field: 'created_at', },
      // { title: 'Address', field: 'address', }, TODO:

    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title='Staging Business Profiles'
          options={{
            toolbar: true,
            filtering: false,
            sorting: false,
            debounceInterval: 1000,
            actionsColumnIndex: -1
          }
          }
          columns={columns}
          tableRef={this._tableRef}
          actions={
            [
              {
                icon: () => <pureIcons.MdOutlineAddBusiness />,
                tooltip: <span>Approve Business</span>,
                onClick: (event, rowData) => { window.confirm(`Are you sure you want to approve { ${rowData.name} } ?`) && this.handleApproveBusiness(rowData) },
                position: 'row'

              },
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => { this.reloadData() },
                isFreeAction: true,
                tooltip: 'Refresh'
              },

            ]}

          data={query =>
            new Promise((resolve) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/staging_business_profiles.json?`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              if (query.search) {
                url += '&search=' + (query.search)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.profiles,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  this.setState({ profiles: result.profiles })
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(StagingBusinessProfiles)
