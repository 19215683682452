import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import DatePicker from "../shared/date_picker";
import { useSnackbar, withSnackbar } from "notistack";
import moment from "moment";

const transaction_report = forwardRef((props: any, ref) => {
  const [working, setWorking] = useState<any>(false);
  const [open, setOpen] = useState<any>(false);
  const [date_start, setdate_start] = useState<any>(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  });
  const [date_end, setdate_end] = useState<any>(new Date());
  const [selectedOptionList, setSelectedOptionList] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [dropdownError, setDropdownError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    if (working) return;
    setOpen(false);
  };

  const handleCreateReport = (event) => {
    validateDropdown(selectedOption);
    setWorking(true);
    window.open(
      `/reports/generate_transactions_report?start_date=${moment(
        date_start
      ).format("DD-MM-YYYY")}&end_date=${moment(date_end).format(
        "DD-MM-YYYY"
      )}&transactions_type=${selectedOption}`
    );
    setWorking(false);
  };

  const selectedSDateValue = (date) => {
    setdate_start(date);
  };

  const selectedEDateValue = (date) => {
    setdate_end(date);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    validateDropdown(event.target.value);
  };
  const validateDropdown = (value) => {
    if (!value) {
      setDropdownError("Please select a type");
    } else {
      setDropdownError("");
    }
  };

  const getInitialData = () => {
    setIsLoading(true);
    $.ajax({
      url: `/reports/transactions_report`,
      dataType: "json",
      success: function (response) {
        setIsLoading(false);
        setSelectedOptionList(response.transactions_types);
        if (response.transactions_types.length > 0) {
          setSelectedOption(response.transactions_types[0]);
        }
      },
      error: function (response) {
        setIsLoading(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const body = (
    <Card>
      <div className="container pb-5">
        <div className="row pt-4">
          <div className="col">
            <DatePicker
              disableFuture={true}
              disablePast={false}
              name="date_start"
              label="From Date"
              disabled={working}
              selectedDateValue={selectedSDateValue}
              value={date_start}
            />
          </div>
          <div className="col">
            <DatePicker
              disableFuture={true}
              disablePast={false}
              name="date_end"
              label="To Date"
              disabled={working}
              selectedDateValue={selectedEDateValue}
              value={date_end}
            />
          </div>
          <div className="col">
            <FormControl fullWidth error={!!dropdownError}>
              <InputLabel>Type</InputLabel>
              <Select
                value={selectedOption}
                label="Select option"
                onChange={handleDropdownChange}
                disabled={working || isLoading}
              >
                {isLoading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : selectedOptionList.length === 0 ? (
                  <MenuItem disabled>No options available</MenuItem>
                ) : (
                  selectedOptionList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                )}
              </Select>
              {dropdownError && (
                <FormHelperText>{dropdownError}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
      </div>
    </Card>
  );

  const actions = (
    <Button
      variant="contained"
      type="submit"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      disabled={working}
      onClick={handleCreateReport}
    >
      {working ? "Working..." : "Submit"}
    </Button>
  );

  return (
    <Modal
      isOpen={open}
      title="Transactions Report"
      handleClose={handleClose}
      minHeight="30vh"
      maxHeight="30%"
      maxWidth="md"
      actions={actions}
    >
      {body}
    </Modal>
  );
});

export default transaction_report;
