import React from 'react'
import Table from '../shared/tables/material_table'
import { withSnackbar } from 'notistack'
import { Backdrop } from '@material-ui/core'
import { Link, Card, TextField, Typography, CardMedia } from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import { ImInfo } from 'react-icons/im'
import $ from 'jquery'

class ReviewHistory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      working: false,
      errors: {}
    }
    this._tableRef = React.createRef()
    this._createCityRef = React.createRef()
    this._reviewRef = React.createRef()
  }
  reloadData = () => {
    this._tableRef.current.onQueryChange()
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  reloadData = e => {
    this._tableRef.current.onQueryChange()
  }
  render () {
    const columns = [
      { title: 'Reviewer', field: 'reviewer', filtering: false },
      { title: 'Group Id', field: 'group_id', filtering: false },
      { title: 'Order Number', field: 'order_number', filtering: false },
      {
        title: 'Type',
        field: 'type',
        lookup: { archived: 'Archived', active: 'Active' }
      },
      { title: 'Created At', field: 'created_at', filtering: false },
      { title: 'id', field: 'id', hidden: true }
    ]
    const body = (
      <>
        <div className='bg-white rounded'>
          <Backdrop open={this.state.submitting} />
          <ReviewDetails ref={this._reviewRef} cart_id={this.props.cart_id} />

          <Table
            title={this.props.name + '  cart reviews'}
            options={{
              toolbar: true,
              sorting: false,
              search: false,
              exportButton: false,
              filtering: true,
              debounceInterval: 500,
              actionsColumnIndex: -1,
              pageSize: 12,
              pageSizeOptions: [12]
            }}
            columns={columns}
            tableRef={this._tableRef}
            actions={[
              rowData => ({
                icon: () => <ImInfo />,
                tooltip: <span>Show review details</span>,
                onClick: () => this._reviewRef.current.handleOpen(rowData.id),
                position: 'row'
              })
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                var filters
                if (query.filters.length > 0) {
                  filters = query.filters.map(col => ({
                    field: col.column.field,
                    value: col.value
                  }))
                }
                let url = '/carts/' + this.props.cart_id + '/cart_reviews.json?'
                url += 'per_page=' + query.pageSize
                url += '&page=' + (query.page + 1)
                if (query.filters.length > 0) {
                  url += '&filters=' + JSON.stringify(filters)
                }
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.reviews,
                      page: result.page - 1,
                      totalCount: result.total
                    })
                    window.breadcrumb.addBreadcrumb(result.breadcrumb)
                  })
              })
            }
          />
        </div>
      </>
    )
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'
      >
        {body}
      </Modal>
    )
  }
}

export default withSnackbar(ReviewHistory)
class ReviewDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      working: false,
      errors: {},
      values: this.initialValues()
    }
  }
  initialValues = () => {
    return {
      adjusment: '',
      old_weight: '',
      old_weight_liquid: '',
      old_category_weight: '',
      old_dimension: '',
      new_dimension: '',
      new_category_weight: '',
      review_weight: '',
      review_weight_liquid: '',
      review_dimension: '',
      weight_liquid: '',
      item_id: '',
      url: '',
      date: '',
      group: '',
      note: '',
      new_weight_liquid: '',
      new_weight: '',
      new_min_qty: '',
      new_max_qty: '',
      old_max_qty: '',
      old_min_qty: '',
      old_qty: '',
      new_qty: '',
      image: '',
      name: ''
    }
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  handleOpen = id => {
    this.setState({
      open: true,
      id: id,
      values: this.initialValues()
    })
    setTimeout(() => {
      this.handleValues()
    }, 50)
  }

  handleValues = () => {
    let self = this
    let data = { review_id: this.state.id }
    $.ajax({
      url: '/carts/' + this.props.cart_id + '/review_details',
      type: 'GET',
      dataType: 'json',
      data: data,
      success: function (response) {
        self.setState({
          values: {
            adjusment: response.adjusment,
            old_weight: response.old_weight,
            old_weight_liquid: response.old_weight_liquid,
            old_category_weight: response.old_category_weight,
            old_dimension: response.old_dimension,
            new_dimension: response.new_dimension,
            new_category_weight: response.new_category_weight,
            review_weight: response.new_review_weight,
            review_weight_liquid: response.review_weight_liquid,
            review_dimension: response.new_review_dimension,
            item_id: response.item_id,
            url: response.url,
            date: response.created_at,
            group: response.group_id,
            note: response.note,
            new_weight_liquid: response.new_weight_liquid,
            new_weight: response.new_weight,
            new_min_qty: response.new_min_qty,
            new_max_qty: response.new_max_qty,
            old_max_qty: response.old_max_qty,
            old_min_qty: response.old_min_qty,
            old_qty: response.old_qty,
            new_qty: response.new_qty,
            image: response.image,
            name: response.name
          }
        })
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }
  render () {
    const body = (
      <>
        <Card style={{ minHeight: '90vh' }}>
          <Backdrop open={this.state.working} />
          <div
            className='row'
            style={{
              display: 'flex',
              padding: 5,
              margin: 5,
              wordBreak: 'break-all'
            }}
          >
            <div
              className='col'
              style={{ width: 230, height: 350, objectFit: 'contain' }}
            >
              <CardMedia>
                <img
                  src={this.state.values.image}
                  alt={this.state.values.image}
                  style={{
                    height: '350px',
                    width: '230px',
                    objectFit: 'contain'
                  }}
                />
              </CardMedia>
            </div>
            <div
              className='col'
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Typography component='h9' variant='h9'>
                <Link href={this.state.values.url} target='_blank'>
                  {this.state.values.name}
                </Link>
              </Typography>

              <div className='row'>
                {this.state.values.review_weight != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='review_weight'
                    value={this.state.values.review_weight}
                    label='Review weight'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.group != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='group'
                    value={this.state.values.group}
                    label='Group no.'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.adjusment != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='adjustment'
                    value={this.state.values.adjusment}
                    label='Adjustment cost'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_max_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_max_qty'
                    value={this.state.values.old_max_qty}
                    label='Old max qty'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_max_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_max_qty'
                    value={this.state.values.new_max_qty}
                    label='New max qty'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.review_weight_liquid != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='review_weight_liquid'
                    value={this.state.values.review_weight_liquid}
                    label='Review liquid'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_min_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_min_qty'
                    value={this.state.values.old_min_qty}
                    label='Old min qty'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_min_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_min_qty'
                    value={this.state.values.new_min_qty}
                    label='New min qty'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_weight != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_weight'
                    value={this.state.values.old_weight}
                    label='Old weight'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_weight != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_weight'
                    value={this.state.values.new_weight}
                    label='New weight'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_category_weight != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_category_weight'
                    value={this.state.values.old_category_weight}
                    label='Old category weight'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_category_weight != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_category_weight'
                    value={this.state.values.new_category_weight}
                    label='New category weight  '
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_weight_liquid != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_weight_liquid'
                    value={this.state.values.old_weight_liquid}
                    label='Old weight liquid'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_weight_liquid != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_weight_liquid'
                    value={this.state.values.new_weight_liquid}
                    label='New weight liquid'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_dimension != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_dimension'
                    value={this.state.values.old_dimension}
                    label='Old dimension'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_dimension != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_dimension'
                    value={this.state.values.new_dimension}
                    label='New dimension'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.old_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='old_qty'
                    value={this.state.values.old_qty}
                    label='Old QTY'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.new_qty != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='new_qty'
                    value={this.state.values.new_qty}
                    label='New QTY'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.review_dimension != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='review_dimension'
                    value={this.state.values.review_dimension}
                    label='Review dimension'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.date != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='date'
                    value={this.state.values.date}
                    label='Created at'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
                {this.state.values.note != null && (
                  <TextField
                    fullWidth
                    margin='normal'
                    name='note'
                    value={this.state.values.note}
                    label='Note'
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Card>
      </>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title='Review Details'
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='75%'
        maxWidth='sm'
      >
        {body}
      </Modal>
    )
  }
}
