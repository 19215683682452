import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import App from '../app'
import { SnackbarProvider } from 'notistack';

export default class Session extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          stopOnIdle={false}
          eventsThrottle={1000 * 60 * 5}
          onAction={this.onAction}
          timeout={1000 * 60 * 30}
        />
        {<SnackbarProvider maxSnack={3}>
          <App/>
        </SnackbarProvider>}
      </>
    )
  }

  onAction = (e) => {
    this.checkUserIfActive();
  }

  onIdle = (e) => {
    this.checkUserIfActive();
  }

  checkUserIfActive = () => {
    fetch(`/users/idle?location=${window.location.href}`, )
    .then(response => {
        if(response.status === 401) {
          location.href = '/';
          history.pushState(null, null, location.href);
          window.onpopstate = function(event) {
            history.go(1);
          };
        }
        else this.idleTimer.reset();
      }
    ).catch(function(error) {
      location.href = '/';
      history.pushState(null, null, location.href);
      window.onpopstate = function(event) {
        history.go(1);
      };
    });
  }
}
