import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import { useSnackbar } from "notistack";

const SharingLinkSidebar = ({ toggleShowSharingLinks, sharingLinkData }) => {
  const { enqueueSnackbar } = useSnackbar();
  console.log(sharingLinkData);
  return (
    <div
      style={{
        width: 300,
        padding: "16px",
        overflowY: "auto",
        backgroundColor: "#f0f2f8",
        height: 2000,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" gutterBottom>
          Links Count
        </Typography>
        <Button onClick={toggleShowSharingLinks}>
          <CloseIcon />
        </Button>
      </div>
      <List>
        <React.Fragment>
          <Card style={{ marginBottom: "8px", color: "#476f6c" }}>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>Total no of links</div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.total_links}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>
                    Unique number of users who generated links
                  </div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.uniq_link_creator_users}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>Total no of clicks</div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.total_clicks}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>
                    Unique number of users clicking on links
                  </div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.uniq_clicking_users}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>
                    Total no of purchases from links
                  </div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.uniq_clicking_users}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>
                    Unique number of users making purchases
                  </div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.uniq_purchases_users}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>Total profits</div>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#476f6c", fontSize: "1.15rem" }}
                    >
                      {sharingLinkData.total_kidus}
                    </Typography>
                    <hr />
                  </React.Fragment>
                }
              />
            </ListItem>
          </Card>
          <Divider />
        </React.Fragment>
      </List>
    </div>
  );
};

export default SharingLinkSidebar;
