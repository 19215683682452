import { IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCopy } from "react-icons/ai";

const CopyTextComponent = (props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the tooltip message after 2 seconds
  };

  return (
    <>
      <CopyToClipboard text={props.textValue} onCopy={handleCopy}>
        <Tooltip title={copied ? "Copied!" : "Copy to clipboard"} arrow>
          <IconButton style={{ padding: "0" }}>
            <AiOutlineCopy />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

export default CopyTextComponent;
