import React from "react";
import Table from "../shared/tables/material_table";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Button,
  Box,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import CreateIndustry from "../operations/_create_industry";
import Cropper from "react-easy-crop";
import Backdrop from "../shared/backdrop";
import $ from "jquery";

const useStyles = (theme) => ({
  App: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cropContainer: {
    position: "absolute",
    top: "30%",
    left: 0,
    right: 0,
    // bottom: "80px",
    width: "50.5%",
    height: "39.6%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  controls: {
    position: "absolute",
    bottom: "-20px",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    height: "40px",
    display: "flex",
    alignItems: "end",
  },

  slider: {
    padding: "22px 0px",
  },

  zoomRange: {
    webkitAppearance: "none",
    mozAppearance: "none",
    height: "2px",
    borderTop: "5px solid #fff",
    borderBottom: "5px solid #fff",
    background: "#3f51b5",
    width: "100%",
  },

  // zoom-range::-moz-range-thumb {
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   border: 1px solid #3f51b5;
  //   background: #3f51b5;
  //   border-radius: 50%;
  //   width: 12px;
  //   height: 12px;
  //   transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // }

  // .zoom-range::-webkit-slider-thumb {
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   border: 1px solid #3f51b5;
  //   background: #3f51b5;
  //   border-radius: 50%;
  //   width: 12px;
  //   height: 12px;
  //   transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // }

  // .controls:hover input[type='range']::-webkit-slider-thumb {
  //   box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  //   border-radius: 50%;
  // }

  // .controls:hover input[type='range']::-moz-range-thumb {
  //   box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  // }
});

class Industries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      errors: {},
      imageModule: false,
      src: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      fileFormatData: null,
      operation: "",
      imageId: null,
      openDelete: false,
    };
    this._tableRef = React.createRef();
    this._createindustryRef = React.createRef();
    this._fileFormat = React.createRef(null);
    this._croppedParams = React.createRef({
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    });
    this._imageId = React.createRef(null);
    this._deleteId = React.createRef(null);
  }

  handleCropChange = (croppedAreaPixels) => {
    this._croppedParams.current = {
      x: croppedAreaPixels.x.toString(),
      y: croppedAreaPixels.y.toString(),
      w: croppedAreaPixels.width.toString(),
      h: croppedAreaPixels.height.toString(),
    };
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.handleCropChange(croppedAreaPixels);
  };
  // on selecting file we set load the image on to cropper
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ working: true });

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
        this.setState({ working: false });
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        fileFormatData: e.target.files[0],
      });
    }
  };

  getData = () => {
    let url = `/industries.json`;
    // url += "per_page=" + query.pageSize;
    // url += "&page=" + (query.page + 1);
    // if (query.filters.length > 0) {
    //   url += "&filters=" + JSON.stringify(filters);
    // }
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          data: result.industries,
        });
      });
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.getData();
    this.setState({
      operation: "Add",
    });
  };

  handleCancel = () => {
    this.setState({
      openDelete: false,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getFileFormat = async (imageSrc) => {
    const response = await fetch(imageSrc);
    const blob = await response.blob();

    const file = new File([blob], "image", {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    this.setState({ fileFormatData: file });
  };

  handleOpenImage = (data) => {
    this.setState({ imageModule: true });
    if (data.image_available === true) {
      this.setState({
        operation: "Remove",
      });
      let self = this;
      self.setState({ dataId: data.id });
      self.setState({ working: true });
      $.ajax({
        url: `/industries/${data.id}/add_industry_image?locale=en`,
        type: "GET",
        dataType: "json",
        success: (response) => {
          self.setState({ working: false });
          if (response?.images_url) {
            self.setState({
              src: response?.images_url.url,
              imageId: response?.image_id,
            });
            this.getFileFormat(response?.images_url.url);
          } else {
            self.setState({ src: null, fileFormatData: null });
          }
        },
        error: (response) => {
          {
            // self.props.enqueueSnackbar(response.responseJSON.error, {
            //   variant: "error",
            // });
            self.setState({ working: false });
            self.setState({ src: null, fileFormatData: null });
          }
        },
      });
    } else {
      this.setState({ dataId: data.id });
      this.setState({
        operation: "Add",
      });
    }
  };

  handleCloseImage = () => {
    this.setState({
      imageModule: false,
      src: null,
      fileFormatData: null,
      imageId: null,
    });
    this.setState({
      operation: "",
    });
  };
  reloadData = (e) => {
    // this._tableRef.current.onQueryChange();
    this.getData();
  };

  deleteIndustries = (dataId) => {
    let self = this;
    self.setState({ working: true });
    $.ajax({
      url: `/industries/${dataId}.json?locale=en`,
      type: "DELETE",
      dataType: "json",
      success: function (response) {
        if (response.success) {
          self.props.enqueueSnackbar(response.msg, {
            variant: "success",
          });
          self.reloadData();
        } else {
          self.props.enqueueSnackbar(
            response.msg || response.error || response.response,
            {
              variant: "error",
            }
          );
        }
        self.setState({ working: false });
        self.setState({
          openDelete: false,
        });
      },
      error: function (response) {
        {
          self.props.enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          self.setState({ working: false });
        }
      },
    });
    // let formData = new FormData();
    // formData.append("locale", "en");
    // formData.append("id", data.id);
    // let url = `/industries/${data.id}.json?locale=en`;
    // fetch(url, { method: "DELETE" })
    //   .then((response) => response.json())
    //   .then((result) => {
    //     this.reloadData();
    //   });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      this.setState({ working: true });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        this.setState({ working: true });
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleSubmit = (inputOperation) => {
    let self = this;
    self.setState({ working: true });

    const payload = {
      locale: "en",
      industry_id: this.state.dataId,
      industry: {
        industry_image_attributes: {
          image: this.state.image,
          _destroy: false,
        },
      },
      id: this.state.dataId,
    };

    const formData = new FormData();

    // Object.entries(payload).forEach(([key, value]) => {
    //   if (typeof value === "object" && value !== null) {
    //     Object.entries(value).forEach(([nestedKey, nestedValue]) => {
    //       formData.append(`${key}[${nestedKey}]`, nestedValue);
    //     });
    //   } else {
    //     formData.append(key, value);
    //   }
    // });

    formData.append("locale", "en");
    formData.append("industry_id", this.state.dataId);
    if (inputOperation === "Submit") {
      formData.append(
        "industry[industry_image_attributes][image]",
        this.state.fileFormatData
      );
    } else {
      formData.append(
        "industry[industry_image_attributes][id]",
        this.state.imageId
      );
    }
    formData.append(
      "industry[industry_image_attributes[_destroy]",
      inputOperation === "Remove" ? "1" : false
    );
    formData.append("id", this.state.dataId);

    $.ajax({
      url: `/industries/${this.state.dataId}/industry_image_update`,
      type: "PATCH",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        self.props.enqueueSnackbar(response.msg, {
          variant: "success",
        });
        self.setState({ working: false });
        self.handleCloseImage();
        self.reloadData();
      },
      error: function (response) {
        {
          self.props.enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          self.setState({ working: false });
        }
      },
    });
  };

  cropClick = () => {
    this.setState({
      operation: "Crop",
    });
  };

  handleCrop = () => {
    const payload = {
      industry_image: {
        crop_x: this._croppedParams.current.x,
        crop_y: this._croppedParams.current.y,
        crop_w: this._croppedParams.current.w,
        crop_h: this._croppedParams.current.h,
      },
      locale: "en",
    };

    this.setState({ working: true });

    $.ajax({
      url: `/industry_images/${this._imageId.current}`,
      type: "PATCH",
      dataType: "json",
      data: payload,
      success: (response) => {
        if (response.success) {
          this.props.enqueueSnackbar(response.response || response.msg, {
            variant: "success",
          });
          this.reloadData();
        } else {
          this.props.enqueueSnackbar(response.response || response.msg, {
            variant: "error",
          });
        }

        this.handleCloseImage();
        this.setState({
          working: false,
        });
      },
      error: (response) => {
        {
          this.props.enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          this.setState({ working: false });
        }
      },
    });
  };

  render() {
    const { classes } = this.props;

    const columns = [
      { title: "Name", field: "name_en" },
      { title: "Priority", field: "priority" },
      { title: "Daily deposit loan", field: "daily_deposit_loan" },
      { title: "Daily earnings loan", field: "daily_earnings_loan" },
      { title: "Max deposit loan", field: "max_deposit_loan" },
      { title: "Max earnings loan", field: "max_earnings_loan" },
      { title: "Min kidus", field: "min_kidus" },
      { title: "Pure fee", field: "pure_fee" },
    ];
    const body = (
      <>
        <div className="bg-white rounded">
          <CreateIndustry
            ref={this._createindustryRef}
            reloadData={this.reloadData}
          />
          <Table
            title={`Industries`}
            options={{
              toolbar: true,
              sorting: false,
              search: true,
              exportButton: false,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
              focus: true,
              emptyRowsWhenPaging: false,
              maxBodyHeight: "50vh",
            }}
            columns={columns}
            tableRef={this._tableRef}
            actions={[
              {
                icon: () => <pureIcons.AiOutlinePlus />,
                onClick: () => {
                  this._createindustryRef.current.handleOpen();
                },
                isFreeAction: true,
                tooltip: "Add New Industry",
              },
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => {
                  this.reloadData();
                },
                isFreeAction: true,
                tooltip: "Refresh",
              },
              (rowData) => ({
                icon: () => <pureIcons.Edit />,
                tooltip: <span>Edit Industry</span>,
                onClick: () =>
                  this._createindustryRef.current.handleOpen(rowData),
                position: "row",
              }),
              (rowData) => ({
                icon: () => <pureIcons.Delete />,
                tooltip: rowData.delete_flag ? (
                  <span>Delete Industry</span>
                ) : (
                  rowData.delete_msg
                ),
                onClick: () => {
                  this.setState({
                    openDelete: true,
                  });
                  this._deleteId.current = rowData.id;
                },
                position: "row",
                disabled: !rowData.delete_flag,
              }),
              (rowData) => {
                return {
                  icon: () =>
                    rowData.image_available === false ? (
                      <Badge badgeContent="+">
                        <pureIcons.ImageIcon />
                      </Badge>
                    ) : (
                      <Badge badgeContent="-">
                        <pureIcons.ImageIcon />
                      </Badge>
                    ),
                  tooltip:
                    rowData.image_available === false
                      ? "Add image"
                      : "Remove image",
                  onClick: () => {
                    this.handleOpenImage(rowData);
                    this._imageId.current = rowData.image_id;
                  },
                  position: "row",
                };
              },
            ]}
            data={this.state.data}
            // data={(query) =>
            //   new Promise((resolve, reject) => {
            //     var filters;
            //     if (query.filters.length > 0) {
            //       filters = query.filters.map((col) => ({
            //         field: col.column.field,
            //         value: col.value,
            //       }));
            //     }
            //     let url = `/industries.json?`;
            //     url += "per_page=" + query.pageSize;
            //     url += "&page=" + (query.page + 1);
            //     if (query.filters.length > 0) {
            //       url += "&filters=" + JSON.stringify(filters);
            //     }
            //     fetch(url)
            //       .then((response) => response.json())
            //       .then((result) => {
            //         resolve({
            //           data: result.industries,
            //           page: result.page - 1,
            //           totalCount: result.total,
            //         });
            //       });
            //   })
            // }
          />
        </div>
      </>
    );

    const body1 = (
      <>
        <Backdrop open={this.state.working} />
        <div>
          {this.state.operation === "Add" && (
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={this.onSelectFile}
            />
          )}
        </div>

        {this.state.src && this.state.operation === "Crop" && (
          <>
            <div className={classes.cropContainer}>
              <Cropper
                image={this.state.src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={4 / 3}
                onCropChange={(e) =>
                  this.setState({
                    crop: e,
                  })
                }
                onCropComplete={this.onCropComplete}
                onZoomChange={(e) =>
                  this.setState({
                    zoom: e,
                  })
                }
              />

              <div className={classes.controls}>
                <input
                  type="range"
                  value={this.state.zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    this.setState({ zoom: e.target.value });
                  }}
                  className={classes.zoomRange}
                />
              </div>
            </div>
          </>
        )}

        {this.state.src && this.state.operation !== "Crop" && (
          <div className={classes.cropContainer}>
            <img
              src={this.state.src}
              style={{ width: "100%", height: "100%" }}
              onLoadStart={() => this.setState({ working: true })}
              onLoad={() => {
                this.setState({ working: false });
              }}
            />
          </div>
        )}
      </>
    );

    const actionSubmit = (
      <Box
        style={{
          display: "flex",
          justifyContent:
            this.state.operation === "Remove" ? "space-between" : "end",
          width: "100%",
        }}
      >
        <div>
          {this.state.src && this.state.operation === "Remove" && (
            <Button
              style={{
                margin: "1rem",
                background: "#f6b67f",
                color: "white",
                textTransform: "none",
              }}
              onClick={this.cropClick}
            >
              Crop Image
            </Button>
          )}
          {this.state.src && this.state.operation === "Remove" && (
            <Button
              style={{
                margin: "1rem",
                background: "#f6b67f",
                color: "white",
                textTransform: "none",
              }}
              onClick={() => this.handleSubmit("Remove")}
            >
              Remove Image
            </Button>
          )}
        </div>

        {this.state.operation === "Crop" && (
          <Button
            style={{
              margin: "1rem",
              float: "right",
              background: "#f6b67f",
              color: "white",
              textTransform: "none",
            }}
            onClick={() => this.handleCrop()}
          >
            Crop
          </Button>
        )}

        {this.state.operation === "Add" && (
          <Button
            style={{
              margin: "1rem",
              float: "right",
              background: "#f6b67f",
              color: "white",
              textTransform: "none",
            }}
            onClick={() => this.handleSubmit("Submit")}
          >
            Submit
          </Button>
        )}
      </Box>
    );
    return (
      <>
        <Modal
          isOpen={this.state.open}
          handleClose={this.handleClose}
          minHeight="70vh"
          maxHeight="90%"
          maxWidth="lg"
        >
          {body}
        </Modal>
        <Modal
          isOpen={this.state.imageModule}
          handleClose={this.handleCloseImage}
          minHeight="70vh"
          maxHeight="90%"
          maxWidth="sm"
          actions={actionSubmit}
        >
          {body1}
        </Modal>

        <Dialog open={this.state.openDelete} onClose={this.handleCancel}>
          <DialogTitle>Delete Item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.deleteIndustries(this._deleteId.current)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withSnackbar(withStyles(useStyles)(Industries));
