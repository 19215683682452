
import React from 'react';
import Table from '../shared/tables/material_table';
import {withSnackbar} from 'notistack';
import {Badge} from '@material-ui/core';
import pureIcons from "../shared/pure_icons";
import Backdrop from "../shared/backdrop";
import EditUser from "./_edit_user"

class SystemUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			muiTableKey: 0,
			total: null,
		};
		this._tableRef = React.createRef();
		this._editUserRef = React.createRef();

	}


	reloadData = () => {
		// this.setState({muiTableKey: this.state.muiTableKey + 1})
		this._tableRef.current.onQueryChange();
	}
	
	render() {
		const columns = [
			{title: 'Name',	field: 'name', render: rowData => (rowData.admin ? <Badge badgeContent='ADMIN' color='secondary'>{rowData.name}</Badge> : rowData.name)},
			{title: 'Phone Number', field: 'phone_number', cellStyle: {width: '3%'}},
			{title: 'Roles', field: 'user_roles_pretty', cellStyle: {whiteSpace: 'normal', wordWrap: 'break-word', width: '50%'}},
			{title: 'Last Active', field: 'last_seen_at'}
		]
		return (
			<div className='bg-white rounded'>
				<Backdrop open={this.state.submitting}/>
				<EditUser ref={this._editUserRef} reloadData={this.reloadData} user={this.state.user}list={this.state.user_roles_list}/>

				<Table
					title='System Users'
					key={this.state.muiTableKey}
					options={{
						toolbar: true,
            filtering: false,
            sorting: false,
						debounceInterval: 1000,
						actionsColumnIndex: -1
					}
					}
					columns={columns}
					tableRef={this._tableRef}
					actions={
						[
							{
								icon: () => <pureIcons.AiOutlinePlus />,
								onClick: (event , rowData) => this._editUserRef.current.handleOpen(rowData, this.state.user_roles_list),

								isFreeAction: true,
								tooltip: 'Add New User'
							  },
							{
								icon: () => <pureIcons.Edit/>,
								tooltip: <span>Edit</span>,
								onClick: (event , rowData) => this._editUserRef.current.handleOpen(rowData, this.state.user_roles_list),
								position: 'row'
							},
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({field: col.column.field, value: col.value}))
							}
							let url = `/users.json?`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
							.then(response => response.json())
							.then((result) => {
								resolve({
									data: result.users,
									page: result.page - 1,
									totalCount: result.total,
								});
								this.setState({user_roles_list: result.user_roles_list})
								window.breadcrumb.addBreadcrumb(result.breadcrumb);
							})
						})
					}
				/>
			</div>
		);
	}
}

export default withSnackbar(SystemUsers)
