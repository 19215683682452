import React from 'react';
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, FormControlLabel, Switch} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import {withSnackbar} from 'notistack';

class AutoControlPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			brands: [],
			open: false,
			loading: false,
			submitting: false
		}
	};

	handleOpen = () => {
		this.setState({open: true});
		this.getVendors()
	};

	handleClose = () => {
		this.setState({open: false});
	};

	getVendors = () => {
		this.setState({loading: true});
		fetch('/orders/auto_vendors_status.json?tracking=true')
		.then(response => response.json())
		.then(result => {
			this.setState({brands: result.brands, loading: false});
		})
	};

	render() {
		const IOSSwitch = withStyles((theme) => ({
			root: {
				width: 42,
				height: 26,
				padding: 0,
				margin: theme.spacing(1),
			},
			switchBase: {
				padding: 1,
				'&$checked': {
					transform: 'translateX(16px)',
					color: theme.palette.common.white,
					'& + $track': {
						backgroundColor: '#52d869',
						opacity: 1,
						border: 'none',
					},
				},
				'&$focusVisible $thumb': {
					color: '#52d869',
					border: '6px solid #fff',
				},
			},
			thumb: {
				width: 24,
				height: 24,
			},
			track: {
				borderRadius: 26 / 2,
				border: `1px solid ${theme.palette.grey[400]}`,
				backgroundColor: theme.palette.grey[50],
				opacity: 1,
				transition: theme.transitions.create(['background-color', 'border']),
			},
			checked: {},
			focusVisible: {},
		}))(({classes, ...props}) => {
			return (
				<Switch
					focusVisibleClassName={classes.focusVisible}
					disableRipple
					classes={{
						root: classes.root,
						switchBase: classes.switchBase,
						thumb: classes.thumb,
						track: classes.track,
						checked: classes.checked,
					}}
					{...props}
				/>
			);
		});

		const handleChange = (event) => {
			this.setState({brands: this.state.brands.map(el => (el.name === event.target.name ? {...el, active: event.target.checked} : el)), submitting: true});

			let self = this;
			$.ajax({
				url: `/orders/update_auto_vendor_status?tracking=true`,
				type: 'POST',
				data: {new: JSON.stringify({name: event.target.name, active: event.target.checked})},
				dataType: 'json',
				success: function (response) {
					self.setState({submitting: false});
				},
				error: function (response) {
					self.setState({submitting: false});
					self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
				},
			});
		};

		const body =
			<div className="container">
				<div className="row">
					{this.state.brands.map(brand =>
						<div className="col-6" key={brand.name}>
							<FormControlLabel
								control={<IOSSwitch defaultChecked={brand.active} onChange={handleChange} name={brand.name} disabled={this.state.submitting}/>}
								label={brand.name}
							/>
						</div>
					)}
				</div>
			</div>

		return (
			<>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title='Tracking Automation Control'
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='60%'
					maxWidth='sm'>
					{this.state.loading ? <CircularProgress/> : body}
				</Modal>
			</>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default (withSnackbar)(withStyles(useStyles)(AutoControlPanel))
