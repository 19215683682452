import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import { Backdrop } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import CreateCity from "../operations/_create_city";

class DiscountCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      errors: {},
    };
    this._tableRef = React.createRef();
  }
  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  handleOpen = (id, name) => {
    this.setState({ open: true, id: id, name: name });
    let url = `/users/${id}/discount_codes.json?`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ data: result.codes });
        // data: result.codes,
        // page: result.page - 1,
        // totalCount: result.total,
        window.breadcrumb.addBreadcrumb(result.breadcrumb);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  };
  render() {
    const columns = [
      { title: "Discount code", field: "name" },
      { title: "Discount value", field: "value" },
      { title: "Discounts", field: "discount" },
      { title: "Order number(s)", field: "orders" },
      { title: "Order group number", field: "group_number" },
      { title: "Cart review coupon?", field: "review" },
      { title: "Created At", field: "created_at", sorting: true },
      { title: "Updated At", field: "updated_at", sorting: true },
    ];
    const body = (
      <>
        <div className="bg-white rounded">
          <Backdrop open={this.state.submitting} />
          <CreateCity ref={this._createCityRef} reloadData={this.reloadData} />
          <Table
            title={`DiscountCodes`}
            options={{
              toolbar: true,
              sorting: true,
              search: true,
              exportButton: false,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
              pageSize: 12,
              pageSizeOptions: [12],
            }}
            columns={columns}
            tableRef={this._tableRef}
            actions={[]}
            // data={(query) =>
            //   new Promise((resolve, reject) => {
            //     var filters;
            //     if (query.filters.length > 0) {
            //       filters = query.filters.map((col) => ({
            //         field: col.column.field,
            //         value: col.value,
            //       }));
            //     }
            //     let url = `/users/${this.state.id}/discount_codes.json?`;
            //     url += "per_page=" + query.pageSize;
            //     url += "&page=" + (query.page + 1);
            //     if (query.filters.length > 0) {
            //       url += "&filters=" + JSON.stringify(filters);
            //     }
            //     if (query.search) {
            //       url += "&search=" + query.search;
            //     }
            //     fetch(url)
            //       .then((response) => response.json())
            //       .then((result) => {
            //         resolve({
            //           data: result.codes,
            //           page: result.page - 1,
            //           totalCount: result.total,
            //         });
            //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
            //       });
            //   })
            data={this.state.data}
          />
        </div>
      </>
    );
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="90%"
        maxWidth="lg"
      >
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(DiscountCodes);
