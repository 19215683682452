import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSnackbar, withSnackbar } from "notistack";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import Backdrop from "../../shared/backdrop";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";

const PhoneCardEdit = forwardRef(({ callback }: any, ref) => {
  const [data, setData] = useState<any>({});
  const [factorData, setFactorData] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(rowData) {
      setOpen(true);
      setData(rowData);
      setFactorData(rowData.factor);
    },
  }));

  const clearData = () => {
    setFactorData("");
    setData([]);
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const saveData = () => {
    setSubmitting(true);
    const payload = {
      digital_brand: {
        factor: factorData,
        id: data.digital_brand_id,
      },
    };
    $.ajax({
      url: `/products/update_brand`,
      type: "POST",
      dataType: "json",
      data: payload,
      success: function (response) {
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        setSubmitting(false);
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ height: "95%" }}>
          <CardHeader title={`Update ${data.brand_name} brand`}></CardHeader>
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="number"
                margin="normal"
                label="Factor"
                style={{ width: "40%" }}
                value={factorData}
                onChange={(e: any) => {
                  setFactorData(e.target.value);
                }}
              ></TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="md"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(PhoneCardEdit);
