import React, { forwardRef, useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import { useLocation, useHistory } from "react-router-dom";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { standardCategoryIndexData } from "./dummyData";
import EditSubCategoryOne from "./editSubcategoriesOne";
import { getSubCategories } from "../../routes/routes";

const SubCategoriesOne = () => {
  const location = useLocation();
  const history = useHistory();
  const id = location.pathname.replace(/[^0-9]/g, "") || 0;
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const _tableRef = useRef<any>();
  const operation = useRef<string>("Add");
  const editData = useRef<any>(null);
  const editRef = useRef<any>(null);

  const { enqueueSnackbar } = useSnackbar();

  const onDeletedCategory = (id) => {
    // let url = `/parts_sub_category_ones/${id}?locale=en`;
    // fetch(url, { method: "DELETE" })
    //   .then((response) => response.json())
    //   .then((result) => {});
    $.ajax({
      url: `/parts_sub_category_ones/${id}?locale=en`,
      type: "DELETE",
      dataType: "json",
      success: function (response: any) {
        // handleClose();
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        // callback();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const getData = () => {
    const url = `/parts_sub_categories/${id}.json?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setTableData(result.parts_sub_category_ones);
        window.breadcrumb.addBreadcrumb(result.breadcrumb);
      });
  };

  const reload = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "ID",
      field: "id",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 100,
      },
    },
    {
      title: "Name",
      field: "name_en",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Weight",
      field: "weight",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Over size",
      field: "over_size",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Prohibited",
      field: "prohibited",
      sorting: false,
      searchable: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Age sensitive?",
      field: "age_sensitive",
      sorting: false,
      searchable: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      {open && (
        <EditSubCategoryOne
          parts_sub_category_id={id}
          ref={editRef}
          data={editData}
          open={open}
          operation={operation}
          setOpen={setOpen}
          callback={reload}
        />
      )}
      <Table
        title={
          <Box display="flex">
            <IconButton
              size="small"
              color="primary"
              onClick={() => history.goBack()}
            >
              <pureIcons.BackArrowIcon />
            </IconButton>

            <Typography variant="h6">
              &nbsp; Standard sub categories one
            </Typography>
          </Box>
        }
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          emptyRowsWhenPaging: false,
          searchFieldStyle: {
            width: 280,
          },
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search sub category one" },
        }}
        columns={columns}
        tableRef={_tableRef}
        data={tableData}
        // data={(query) =>
        //   new Promise((resolve, reject) => {
        //     let url = `/parts_sub_categories/${id}.json?locale=en`;
        //     fetch(url)
        //       .then((response) => response.json())
        //       .then((result) => {
        //         resolve({
        //           data: result.parts_sub_category_ones,
        //           page: result.page - 1,
        //           totalCount: result.total,
        //         });
        //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
        //       });
        //   })
        // }
        // data={standardCategoryIndexData}
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.CategoriesIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              operation.current = "Add";
              setOpen(true);
            },
            tooltip: "Add new sub category one",
            isFreeAction: true,
          },
          (rowData: any) => {
            return {
              icon: () => <pureIcons.Edit />,
              onClick: () => {
                setOpen(true);
                editData.current = rowData;
                operation.current = "Edit";
              },
              tooltip: "Edit",
            };
          },
          (rowData: any) => {
            return rowData.delete
              ? {
                  icon: () => <pureIcons.Delete />,
                  onClick: () => {
                    onDeletedCategory(rowData.id);
                  },
                  tooltip: "Delete",
                }
              : null;
          },
        ]}
      />
    </div>
  );
};
export default SubCategoriesOne;
