
import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from '../shared/pure_icons';
import { Backdrop, Button } from '@material-ui/core';

import $ from "jquery";


class BusinessProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      muiTableKey: 0,
      total: null,
      active: '',
    };
    this._tableRef = React.createRef();
    this._vendorFormRef = React.createRef();

  }

  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }


  handlePendingChange = (rowData) => {
    let self = this;
    const data = { id: rowData.id }
    $.ajax({
      url: `/business_profiles/update_business_to_pending`,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully updated!', { variant: 'success' })
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
        self.setState({ working: false });
      },
    });
  }

  handleApproveChange = (rowData) => {
    let self = this;
    const data = { id: rowData.id }
    $.ajax({
      url: `/business_profiles/update_business_to_approved`,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully updated!', { variant: 'success' })
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
        self.setState({ working: false });
      },
    });
  }




  render() {

    const PendingIcon = () => (
      <button style={{ fontWeight: 'bold', color: "red", border: "none", backgroundColor: "inherit", fontSize: "12px", cursor: "pointer", display: "inline-block" }}>Pending</button>
    );
    const ApproveIcon = () => (
      <button style={{ fontWeight: 'bold', color: "green", border: "none", backgroundColor: "inherit", fontSize: "12px", cursor: "pointer", display: "inline-block" }}>Approve</button>
    );

    const columns = [
      { title: 'Name', field: 'business_name', },
      // { title: 'Sales ID', field: 'sales_identifier', },
      { title: 'Address', field: 'address' },
      { title: 'Contact Name', field: 'contact_name', },
      { title: 'Service Number', field: 'service_number' },
      { title: 'Kidu Balance', field: 'kidu_balance', },
      { title: 'Verify Code', field: 'verify_code', },
      { title: 'Email', field: 'email', },
      { title: 'No. Of Empolyees', field: 'number_of_employess', },
      { title: 'Business Status', field: 'business_status', },
      { title: 'Current Status', field: 'current_status', },
      { title: 'Reason', field: 'reason', },
      { title: 'Created At', field: 'created_at', },
    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title='Business Profiles'
          options={{
            toolbar: true,
            filtering: false,
            sorting: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1
          }
          }
          columns={columns}
          tableRef={this._tableRef}

          actions={
            [
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => { this.reloadData() },
                isFreeAction: true,
                tooltip: 'Refresh'
              },
              {
                icon: PendingIcon,
                onClick: (event, rowData) => { window.confirm(`Are you sure you want to Change Business Status ?`) && this.handlePendingChange(rowData) },
                position: 'row'
              },
              {
                icon: ApproveIcon,
                onClick: (event, rowData) => { window.confirm(`Are you sure you want to Change Business Status ?`) && this.handleApproveChange(rowData) },
                position: 'row'
              },

            ]}

          data={query =>
            new Promise((resolve) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/business_profiles.json?`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              if (query.search) {
                url += '&search=' + (query.search)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.business_profiles,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  this.setState({ business_profiles: result.business_profiles })
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(BusinessProfiles)
