import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardMedia, CardContent, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 10
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
	media: {
		height: 200,
	},
}));

export default function Media() {
	const classes = useStyles();

	return (
		<Card className={classes.root} >
			<CardMedia className={classes.cover}>
				<Skeleton animation='wave' variant='rect' className={classes.media} mt={5}/>
			</CardMedia>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Skeleton animation='wave' width='100%' height={60} pt={0} mt={0}/>
					<Grid container>
						<Grid item xs={6}>
							<Skeleton animation='wave' height={20}  width='70%'/>
							<Skeleton animation='wave' height={20}  width='70%'/>
							<Skeleton animation='wave' height={20}  width='70%'/>
							<Skeleton animation='wave' height={20}  width='70%'/>
							<Skeleton animation='wave' height={20}  width='70%'/>
							<Skeleton animation='wave' height={20}  width='70%'/>
						</Grid>
						<Grid item xs={6}>
							<Skeleton animation='wave' height={20} width='60%'/>
							<Skeleton animation='wave' height={20} width='60%'/>
							<Skeleton animation='wave' height={20} width='60%'/>
						</Grid>
					</Grid>
				</CardContent>
			</div>
		</Card>
	);
}
