import React from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";
import {ImBarcode} from "react-icons/im";

class ShowBarcode extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
      <div className='d-none d-print-block'>
        <div className="text-center align-middle"><Barcode value={this.props.code} height={30} width={1.25}/></div>
      </div>
		);
	}
}
export default  class PrintBarcode extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <ImBarcode />}
          content={() => this.componentRef}
        />
        <ShowBarcode ref={el => (this.componentRef = el)} code={this.props.code}/>
      </div>
    );
  }
}
