import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from '../shared/modal/material_dialog'
import Table from '../shared/tables/material_table';
import pureIcons from '../shared/pure_icons';
import { Backdrop } from '@material-ui/core';
import $ from "jquery";


class CashRefunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      refunded: '',
      errors: {},
    };
    this._tableRef = React.createRef();

  }
  handleUpdateRefundStatus = (data) => {
    let self = this;
    const payload = { refunded: !data.refunded }
    $.ajax({
      url: `/cash_refunds/${data.id}/update_status`,
      type: 'PUT',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.reloadData();
      },
      error: function (response) {

        self.setState({ working: false });
      },
    });
  }

  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const canceledColumns = [
      { title: 'Shopper Number', field: 'shopper_number', sorting: false, filtering: false },
      { title: 'Order Number', field: 'order_number', sorting: false, filtering: false },
      { title: 'Refund Amount', field: 'amount', },
      { title: 'Refunded By', field: 'user_name', sorting: false, filtering: false },
      { title: 'Refunded', field: 'refunded', sorting: false, lookup: { true: 'Yes', false: 'No' } },
      { title: 'Refund Date', field: 'refund_date', sorting: false, filtering: false },
    ]
    const rejectedColumns = [
      { title: 'Card Details', field: 'card_meta', sorting: false, filtering: false },
      { title: 'Refund Type', field: 'refund_type', },
      { title: 'Refunded By', field: 'user_name', sorting: false, filtering: false },
      { title: 'Amount', field: 'amount', sorting: false, filtering: false },
      { title: 'Refunded', field: 'refunded', sorting: false, lookup: { true: 'Yes', false: 'No' } },
      { title: 'Refund Date', field: 'refund_date', sorting: false, filtering: false },
    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title={`Cash Refunds`}
          options={{
            toolbar: true,
            sorting: true,
            search: false,
            exportButton: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10],
          }
          }
          columns={this.props.refund_type === 'canceled' ? canceledColumns : rejectedColumns}
          tableRef={this._tableRef}
          actions={
            [
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => { this.reloadData() },
                isFreeAction: true,
                tooltip: 'Refresh'
              },
              {
                icon: () => <pureIcons.RiRefund2Fill />,
                tooltip: <span>Change Refund Status</span>,
                onClick: (event, rowData) => { window.confirm(`Are you sure you want to change refund status ?`) && this.handleUpdateRefundStatus(rowData) },
                position: 'row'
              }
            ]}
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/cash_refunds.json?refund_type=${this.props.refund_type}&`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.cash_refunds.flat(),
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    )
  }
}


function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default class ScrollableTabsButtonAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      open: false
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {

    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'>
        {
          <div id='pure-scrollable-tab'>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              <Tab label='canceled' {...a11yProps(0)} />
              <Tab label='rejected' {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={this.state.value} index={0}>
              <CashRefunds refund_type={"canceled"} />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <CashRefunds refund_type={"rejected"} />
            </TabPanel>
          </div>
        }
      </Modal>
    );
  }
}


