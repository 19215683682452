import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import Backdrop from "../shared/backdrop";
import { FiTrendingUp } from "react-icons/fi";

const ReferralComp = forwardRef((props, ref) => {
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [working, setWorking] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [ordersId, setOrdersId] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [muiTableKey, setMuiTableKey] = useState<any>(0);

  const _tableRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState<any>(moment());

  useImperativeHandle(ref, () => ({
    handleOpen(id, name) {
      setOpen(true);
      setOrdersId(id);
    },
  }));

  const clearData = () => {
    setStartDate(moment().subtract(1, "days"));
    setEndDate(moment());
    setData([]);
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const columns = [
    {
      title: "User id",
      field: "user_id",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Phone number",
      field: "phone_number",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Full name",
      field: "full_name",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Created at",
      field: "created_at",
      editable: "never",
      filtering: false,
      sorting: true,
    },
  ];

  const getData = () => {
    const formatEndDate = moment(endDate).format("DD-MM-YYYY");
    const formatStartDate = moment(startDate).format("DD-MM-YYYY");
    setSubmitting(FiTrendingUp);
    const url = `/customer_profiles/${ordersId}/signed_through_referrals.json?start_date=${formatStartDate}&end_date=${formatEndDate}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setSubmitting(false);
        setData(result.signed_users),
          setPage(result.page - 1),
          setTotalCount(result.total);
      });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        {/* <Card style={{ margin: "20px", height: "95%" }}> */}
        <CardHeader title="Referrals"></CardHeader>

        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
              <Typography>From date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "15%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="start_date"
                    //   label="From date"
                    onChange={(ev) => setStartDate(ev)}
                    format="dd/MM/yyyy"
                    value={startDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {/* placeholder={placeholder} footer={footer}  */}
            </Box>

            {/* <Divider style={{ margin: "20px 0px" }} /> */}

            <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
              <Typography>To date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "17%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="end_date"
                    //   label="To date"
                    onChange={(ev) => setEndDate(ev)}
                    format="dd/MM/yyyy"
                    value={endDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </Box>
          <Divider style={{ margin: "10px 0px" }} />

          <CardActions style={{ justifyContent: "end", padding: "0px" }}>
            <Button
              type="button"
              style={{
                backgroundColor: "#ec9a53",
                color: "#ebeef0",
                textTransform: "none",
              }}
              onClick={() => getData()}
            >
              View
            </Button>
          </CardActions>
        </CardContent>
        {/* </Card> */}
        <Table
          title={``}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            exportButton: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
          tableRef={_tableRef}
          data={data}
        />
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="50vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(ReferralComp);
