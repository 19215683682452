import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, MenuItem, TextField} from '@material-ui/core';
import InputMask from "react-input-mask";
import Modal from '../../shared/modal/material_dialog'
import $ from 'jquery';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import Alert from '@material-ui/lab/Alert';

class CreateList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			width: null,
			height: null,
			depth: null,
			weight:null,
			shipping_method: '',
			shipping_methods: [],
			vol_weight: 0,
			errors: []
		}
	}

	handleClose = () => {
		this.setState({open: false});
	};

	handleOpen = (products) => {
		this.setState({products: products, open: true, units: this.props.units, shipping_methods: this.props.shipping_methods, width: null, height: null, depth: null, weight:null, vol_weight: 0, shipping_method: ''})
		if (this.props.shipping_methods.length === 1) {
			this.setState({shipping_method: this.props.shipping_methods[0]})
		}
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({working: true});
		const payload = {products: this.state.products, width: this.state.width, height: this.state.height, depth: this.state.depth, weight: this.state.weight, shipping_method: this.state.shipping_method}
		$.ajax({
			url: `/order_item_stages/create_packing_list`,
			type: 'POST',
			data: payload,
			dataType: 'json',
			success: function (response) {
				self.setState({working: false, open: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
				self.props.callBackCreateShipment();
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
				self.setState({working: false});
			},
		});
	}

	handleInputChange = e => {
		const {name, value} = e.target;
		this.setState({[name]: value});
		this.validate({[name]: value});
	}

	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		if ('width' in fieldValues) {
			temp.width = fieldValues.width ? '' : 'Required';
		}
		if ('height' in fieldValues) {
			temp.height = fieldValues.height ? '' : 'Required';
		}
		if ('depth' in fieldValues) {
			temp.depth = fieldValues.depth ? '' : 'Required';
		}
		if ('weight' in fieldValues) {
			temp.weight = fieldValues.weight ? '' : 'Required';
		}
		if ('shipping_method' in fieldValues) {
			temp.shipping_method = fieldValues.shipping_method ? '' : 'Required';
		}
		const width = fieldValues.width || this.state.width
		const height = fieldValues.height || this.state.height
		const depth = fieldValues.depth || this.state.depth
		const weight = fieldValues.weight || this.state.weight
		if (width && height && depth & weight) {
			let vol_weight
			if (this.props.units[1] === 'in'){
				vol_weight = ((width * height * depth) / 139).toFixed(2);
			} else {
				vol_weight = ((width * height * depth) / 6000).toFixed(2);
			}
			this.setState({vol_weight: vol_weight})
		}

		this.setState({errors: {...temp}})
		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	render () {
		const {classes} = this.props;
		const  actions = (
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='submit'
				className={classes.button}
				onClick={this.handleSubmit}
			>
				Submit
			</Button>)

const body = <Card className={classes.root} style={{minHeight: '30vh'}}>
				<div className="container pb-5">
					{this.state.weight < (this.state.vol_weight - (this.state.vol_weight * 0.1)) && <Alert severity='error'>{`The volumetric weight for this shipment is ${this.state.vol_weight}${this.props.units[0]} do you wish to continue?`}</Alert>}
					<p>You have selected {this.state.products?.length} item{this.state.products?.length > 1 ? 's' : ''} to create new shipment</p>
					<div className="row">
						<div className="col">
							<p>Dimensions:</p>
						</div>
						<div className="col">
							<InputMask
								mask="999"
								value={this.state.width}
								maskChar=" "
								onChange={this.handleInputChange}
							>
								{() => <TextField name='width' label={`Width (${this.props.units[1]})`} className={classes.textField} {...(this.state.errors.width && {error: true, helperText: this.state.errors.width})}/>}
							</InputMask>
						</div>
						<div className="col">
							<InputMask
								mask="999"
								value={this.state.height}
								maskChar=" "
								onChange={this.handleInputChange}
							>
								{() => <TextField name='height' label={`Height (${this.props.units[1]})`} className={classes.textField} {...(this.state.errors.height && {error: true, helperText: this.state.errors.height})}/>}
							</InputMask>
						</div>
						<div className="col">
							<InputMask
								mask="999"
								value={this.state.depth}
								maskChar=" "
								onChange={this.handleInputChange}
							>
								{() => <TextField name='depth' label={`Depth (${this.props.units[1]})`} className={classes.textField} {...(this.state.errors.depth && {error: true, helperText: this.state.errors.depth})}/>}
							</InputMask>
						</div>
					</div>	
					<div className="row pt-3">
						<div className="col-3">
							<p>Weight:</p>
						</div>
						<div className="col-9">
							<InputMask
								mask="999"
								value={this.state.weight}
								maskChar=" "
								onChange={this.handleInputChange}
							>
								{() => <TextField name='weight' label={this.props.units[0]} className={classes.textField} {...(this.state.errors.weight && {error: true, helperText: this.state.errors.weight})}/>}
							</InputMask>
						</div>
					</div>
					{this.props.shipping_methods?.length > 1 &&
						<div className="row pt-3">
							<div className="col-3">
							</div>
							<div className="col-9">
								<TextField
									label='Shipping Method'
									select
									fullWidth
									value={this.state.shipping_method || ''}
									{...(this.state.errors.shipping_method && {error: true, helperText: this.state.errors.shipping_method})}
									onChange={(event) => this.setState({shipping_method: event.target.value})}
								>
									{this.state.shipping_methods.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</div>
						</div>
					}
				</div>
			</Card>

		return (
			<>
				<Backdrop open={this.state.working}/>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title="New Shipment"
					handleClose={this.handleClose}
					maxHeight='400%'
					maxWidth='sm'
					actions={actions}>
					{body}
				</Modal>
			</>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	textField: {
		margin: theme.spacing(1),
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
})

export default withSnackbar(withStyles(useStyles)(CreateList))
