import React from 'react';
import {Paper} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import Backdrop from '../../shared/backdrop'

export default class OrdersSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			working: false,
			orders_count: '',
			orders_total: '',
			regions: []
		}
	};
	
	handleClose = () => {
		this.setState({open: false});
	};

	handleOpen = () => {
		this.setState({open: true});
		this.getData();
	};

	getData = () => {
		this.setState({working: true});
		const url = `/orders/summary.json?`
		fetch(url)
		.then(response => {if (!response.ok) {
			throw Error(response.statusText);
		}
			return response.json()
		})
		.then(result => {
			this.setState({orders_count: result.orders_count, orders_total: result.orders_total, regions: result.regions, working: false});
		})
		.catch(error => {
			this.setState({working: false});
		});
	};
	render() {
		const body = <Paper>
			<div className="accordion accordion-flush" id="accordionFlush">
				<div className="accordion-item">
					<h2 className="accordion-header" id={`flush-headingAll`}>
						<button className="accordion-button collapsed" type="button" >
							<div className="container">
								<div className='row'>
									<div className='col'>All Regions</div>
									<div className='col'>count: {this.state.orders_count}</div>
									<div className='col'>subtotal: {this.state.orders_total}</div>
								</div>
							</div>
						</button>
					</h2>
				</div>
				{this.state.regions.map(r=>
					<div className="accordion-item">
						<h2 className="accordion-header" id={`flush-heading${r.region}`}>
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${r.region}`} aria-expanded="false" aria-controls={`flush-collapse${r.region}`}>
								<div className="container">
									<div className='row'>
										<div className='col'>{r.region}</div>
										<div className='col'>count: {r.region_orders_count}</div>
										<div className='col'>subtotal: {r.region_orders_total}</div>
										<div className='col'>shipping: {r.region_shipping}</div>
										<div className='col'>customs: {r.region_customs}</div>
									</div>
								</div>
							</button>
						</h2>
						<div id={`flush-collapse${r.region}`} className="accordion-collapse collapse" aria-labelledby={`flush-collapse${r.region}`} data-bs-parent="#accordionFlush">
							<table className="table table-striped">
								<thead>
									<tr>
										<th scope="col">Vendor</th>
										<th scope="col">count</th>
										<th scope="col">subtotal</th>
										<th scope="col">shipping</th>
										<th scope="col">customs</th>
									</tr>
								</thead>
								<tbody>
									{r.vendors.map(v=>
										<tr key={v.name}>
											<th scope="row">{v.name}</th>
											<td>{v.orders_count}</td>
											<td>{v.orders_total}</td>
											<td>{v.shipping}</td>
											<td>{v.customs}</td>
										</tr>
										)}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		</Paper>
	
		return (
			<>
				<Backdrop open={this.state.working}/>
				<Modal
					isOpen={this.state.open}
					title='Orders Summary'
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='75%'
					maxWidth='md'
					actions={<></>}>
					{body}
				</Modal>
			</>	
		)
	}
}
