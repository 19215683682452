import React from 'react';
import $ from 'jquery'
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import Backdrop from "../shared/backdrop";
import { withSnackbar } from 'notistack';

class CreateExpense extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: '',
      values: this.initialValues()
    }
  };

  initialValues = () => {
    return {
      reverse: false,
      region: '',
      expense_type: '',
      amount: '',
      currency: '',
      note: '',
      user_id: '',
      process: '',
      kidus: '',
      debtor_id: '',
      user_balance: ''
    }
  }

  handleOpen = () => {
    this.setState({ open: true, values: this.initialValues() });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload = {
      expenses: { expense_type: values.expense_type, kidus: values.kidus, amount: values.amount, reverse: values.reverse, process: values.process, debtor_id: values.debtor_id, region: values.region, currency: values.currency, note: values.note, user_id: values.user_id }
    }
    $.ajax({
      url: '/expense',
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully updated!', { variant: 'success' })
        self.handleClose()
      },
      error: function (response) {
        self.setState({ working: false, errors: { serverErrors: response.responseJSON.error } });
      },
    });
  }

  GetBalance = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload = {
      expenses: { user_balance: values.user_balance }
    }
    $.ajax({
      url: '/expense',
      type: 'GET',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully updated!', { variant: 'success' })
      },
      error: function (response) {
        self.setState({ working: false, errors: { serverErrors: response.responseJSON.error } });
      },
    });
  }


  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } })
    this.validate({ [name]: value });
  }


  handleCheckBoxChange = e => {
    this.setState({ values: { ...this.state.values, reverse: e.target.checked } });
  }

  handleInputNumberChange = e => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  }

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = '';
    if ('amount' in fieldValues) {
      temp.amount = fieldValues.amount ? '' : 'Required';
    }
    if ('expense_type' in fieldValues) {
      temp.expense_type = fieldValues.expense_type ? '' : 'Required';
    }
    if ('region' in fieldValues) {
      temp.region = fieldValues.region ? '' : 'Required';
    }
    if ('process' in fieldValues) {
      temp.process = fieldValues.process ? '' : 'Required';
    }
    if ('currency' in fieldValues) {
      temp.currency = fieldValues.currency ? '' : 'Required';
    }
    if ('debtor_id' in fieldValues) {
      temp.debtor_id = fieldValues.debtor_id ? '' : 'Required';
    }
    this.setState({ errors: { ...temp } })
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }


  render() {
    const { classes } = this.props;
    const body =
      <>
        {this.state.errors.serverErrors &&
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        }
        <Card className={classes.root} style={{ minHeight: '75vh' }}>
          <Backdrop open={this.state.working} />
          <div className="container">
            <TextField
              name="region"
              select
              fullWidth
              margin="normal"
              label="Region"
              value={this.state.values.region}
              onChange={this.handleInputChange}
              {...(this.state.errors.region && { error: true, helperText: this.state.errors.region })}
              SelectProps={{ native: true }}
            >
              {['', 'us', 'ae', 'tr', 'cn', 'sa', 'qa'].map((option) => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </TextField>
            <TextField
              name="currency"
              select
              fullWidth
              margin="normal"
              label="Currency"
              value={this.state.values.currency}
              onChange={this.handleInputChange}
              {...(this.state.errors.currency && { error: true, helperText: this.state.errors.currency })}
              SelectProps={{ native: true }}
            >
              {['', 'dollar', 'dinar'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <TextField
              name="expense_type"
              select
              fullWidth
              margin="normal"
              label="Expense Type"
              value={this.state.values.expense_type}
              onChange={this.handleInputChange}
              {...(this.state.errors.expense_type && { error: true, helperText: this.state.errors.expense_type })}
              SelectProps={{ native: true }}
            >
              {['', 'warehouse_rent', 'warehouse_costs', 'shipping', 'delivery_fee', 'custom', 'commission', 'cash_refund', 'shipment_delivery', 'sold_from_warehouse', 'moe_purposes', 'deposit_commission', 'suppliers_kidu_redeem', 'salaries', 'legal_matters', 'transfer_loan_fee', 'transfer_commission', 'deposit_kidu', 'get_money_from_moe', 'transfer', 'other', 'visa_card_refund', 'transfer_to_us', 'transfer_to_tr', 'transfer_to_ae'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <TextField
              name="debtor_id"
              hidden={this.state.values.expense_type != 'transfer'}
              select
              fullWidth
              margin="normal"
              label="Debtor"
              value={this.state.values.debtor_id}
              onChange={this.handleInputChange}
              {...(this.state.errors.debtor_id && { error: true, helperText: this.state.errors.debtor_id })}
            >

            </TextField>
            <TextField
              fullWidth
              margin="normal"
              name='amount'
              value={this.state.values.amount}
              label='Amount'
              {...(this.state.errors.amount && { error: true, helperText: this.state.errors.amount })}
              onChange={this.handleInputNumberChange}
            />
            <TextField
              name="process"
              select
              fullWidth
              margin="normal"
              label="Process"
              value={this.state.values.process}
              onChange={this.handleInputChange}
              {...(this.state.errors.process && { error: true, helperText: this.state.errors.process })}
              SelectProps={{ native: true }}
            >
              {['', 'credit', 'debit'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              margin="normal"
              name='note'
              value={this.state.values.note}
              label='Note'
              onChange={this.handleInputChange}
            />
          </div>
        </Card >
      </>

    const actions = (
      <>
        <Button
          type='button'
          variant='contained'
          onClick={() => this.setState({ values: this.initialValues(), errors: {} })}
        >
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          {this.state.working ? 'Working...' : 'Submit'}
        </Button>
      </>

    );

    return (
      <Modal
        isOpen={this.state.open}
        title={this.state.values.user_balance}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='70%'
        maxWidth='sm'
        actions={actions}>
        {body}
      </Modal>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(CreateExpense))
