import React from 'react';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import pureIcons from "../shared/pure_icons";
import { green, red } from '@material-ui/core/colors';

export default class OrderStatusAlert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			order_status: {},
			basket_number: null
		}
	};

	handleOpen = (order_id, basket_number) => {
		this.setState({open: true, basket_number: basket_number});
		this.getOrderStatus(order_id, basket_number)
	};

	handleClose = () => {
		this.props.reloadData();
		this.setState({open: false});
	};

	getOrderStatus = (order_id, basket_number) => {
		this.setState({working: true});
		const url = `/orders/${order_id}/order_status.json`
		fetch(url)
			.then(response => response.json())
			.then(result => {
				this.setState({order_status: result.order_status, working: false});
			})
	};

	render() {
		const order_status = this.state.order_status;
		const body = <div className='row'>
			<Typography variant='subtitle1' color='textSecondary'>
				Customer: <b>{order_status?.full_name} - {order_status?.phone_number}</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Address: <b>{order_status?.address}</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Basket #: <b>{this.state.basket_number}</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Delivery Preference: <b>{order_status?.home_delivery ? 'Home Delivery' : 'Pickup'}</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Order Status: <b>{order_status?.completed ? <>Completed <pureIcons.CheckCircleIcon style={{ color: green[500] }}/></> : <>Not Completed <pureIcons.BlockIcon style={{ color: red[500] }}/></>}</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Items QTY: <b>{order_status?.number_of_items} total items ({order_status?.total_items})</b>
			</Typography>
			<Typography variant='subtitle1' color='textSecondary'>
				Orders Number: <b>{order_status?.order_number}</b>
			</Typography>
			{order_status?.parent_order &&
				<Typography variant='subtitle1' color='textSecondary'>
					Related Orders: <b>{order_status?.parent_order} - {order_status?.other_orders}</b>
				</Typography>
			}
		</div>

		const actions = (
			<Button onClick={this.handleClose} variant='contained' style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}} startIcon={<pureIcons.ThumbUpIcon/>}>
				Okay
			</Button>
		);

		return (
			<Modal
				disableBackdropClick
				isOpen={this.state.open}
				title={`Order Status ${this.state?.order_status?.order_number}`}
				handleClose={this.handleClose}
				minHeight='40vh'
				maxHeight='60%'
				maxWidth='sm'
				actions={actions}>
				{this.state.working ? <CircularProgress/> : body}
			</Modal>
		);
	}
}
