import React from "react";
import {
  MenuItem,
  MenuList,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import { GrDocumentCsv } from "react-icons/gr";
import _third_party_payment from "./_third_party_payment";
import _total_of_vendors_by_month from "./_total_of_vendors_by_month";
import _orders from "./_orders";
import _local_orders from "./_local_orders";
import Expenses from "./_expenses";
import TransactionReport from "./transaction_report";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
    };
    this._third_party_paymentRef = React.createRef();
    this._total_of_vendors_by_monthRef = React.createRef();
    this._ordersRef = React.createRef();
    this._local_ordersRef = React.createRef();
    this._expensesRef = React.createRef();
    this._transactionReportRef = React.createRef();
  }

  render() {
    return (
      <div className="bg-white rounded">
        <_third_party_payment ref={this._third_party_paymentRef} />
        <_total_of_vendors_by_month ref={this._total_of_vendors_by_monthRef} />
        <_orders ref={this._ordersRef} />
        <_local_orders ref={this._local_ordersRef} />
        <Expenses ref={this._expensesRef} />
        <TransactionReport ref={this._transactionReportRef} />
        <MenuList>
          <MenuItem onClick={() => this._ordersRef.current.handleOpen()}>
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Orders</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => this._third_party_paymentRef.current.handleOpen()}
          >
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Third Party Payments</Typography>
          </MenuItem>
          <MenuItem
            onClick={() =>
              this._total_of_vendors_by_monthRef.current.handleOpen()
            }
          >
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Total of Vendors by Month</Typography>
          </MenuItem>
          <MenuItem onClick={() => this._local_ordersRef.current.handleOpen()}>
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Local Orders</Typography>
          </MenuItem>
          <MenuItem onClick={() => this._expensesRef.current.handleOpen()}>
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Expenses</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => this._transactionReportRef.current.handleOpen()}
          >
            <ListItemIcon>
              <GrDocumentCsv />
            </ListItemIcon>
            <Typography variant="inherit">Transactions Report</Typography>
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}

export default Reports;
