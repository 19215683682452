import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSnackbar, withSnackbar } from "notistack";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import Backdrop from "../../shared/backdrop";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";

const ShowPhoneCardEdit = forwardRef(({ callback }: any, ref) => {
  const [data, setData] = useState<any>({});
  const [digitalProData, setDigitalProData] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [productId, setProductId] = useState<any>("");
  const [digitalBrandId, setDigitalBrandId] = useState<any>("");
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(rowData, id) {
      setOpen(true);
      setData(rowData);
      setDigitalBrandId(id);
      setDigitalProData({
        name_en: rowData.name_en,
        name_ar: rowData.name_ar,
        name_ku: rowData.name_ku,
        description_en: rowData.description_en,
        description_ar: rowData.description_ar,
        description_ku: rowData.description_ku,
        factor: rowData.factor,
      });
      setProductId(rowData.product_id);
    },
  }));

  const clearData = () => {
    setDigitalProData({});
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const onChangeDigiData = (e) => {
    setDigitalProData({ ...digitalProData, [e.target.name]: e.target.value });
  };

  const saveData = () => {
    setSubmitting(true);
    const payload = {
      product: {
        ...digitalProData,
      },
      id: productId,
      digital_brand_id: digitalBrandId,
    };
    $.ajax({
      url: `/products/${productId}/update_name`,
      type: "PUT",
      dataType: "json",
      data: payload,
      success: function (response) {
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        setSubmitting(false);
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ height: "95%" }}>
          {/* <CardHeader title="New Kidus serial numbers"></CardHeader> */}
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Name en"
                name="name_en"
                style={{ width: "45%" }}
                value={digitalProData.name_en}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Name ar"
                name="name_ar"
                style={{ width: "45%" }}
                value={digitalProData.name_ar}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Name ku"
                name="name_ku"
                style={{ width: "45%" }}
                value={digitalProData.name_ku}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Description en"
                name="description_en"
                style={{ width: "45%" }}
                value={digitalProData.description_en}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Description ar"
                name="description_ar"
                style={{ width: "45%" }}
                value={digitalProData.description_ar}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Description ku"
                name="description_ku"
                style={{ width: "45%" }}
                value={digitalProData.description_ku}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                margin="normal"
                label="Factor"
                name="factor"
                style={{ width: "45%" }}
                value={digitalProData.factor}
                onChange={(e: any) => {
                  onChangeDigiData(e);
                }}
              ></TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="md"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(ShowPhoneCardEdit);
