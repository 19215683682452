import React from 'react';
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles';
import {Button, Card,TextField} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import DatePicker from '../shared/date_picker';
import { withSnackbar } from 'notistack';
class ThirdPartyPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			file_path: '',
			date_start: new Date(),
			date_end: new Date(),
			email: '',
			page: 1,
			errors: {},
		}
	};

	handleOpen = () => {
		this.setState({open: true, page: 1, date_start: new Date(), date_end: new Date(), email: ''});
	};

	handleClose = () => {
		if (this.state.working) return;
		this.setState({open: false});
	};

	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('date_start' in fieldValues) {
			temp.date_start = fieldValues.date_start === null ? '' : isNaN(fieldValues.date_start.getTime()) ? 'Invalid Date' : '';
		}
		if ('date_end' in fieldValues) {
			temp.date_end = fieldValues.date_end === null ? '' : isNaN(fieldValues.date_end.getTime()) ? 'Invalid Date' : '';
		}
		if ('email' in fieldValues) {
			const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			temp.email = regex.test(fieldValues.email) ? '' : 'Invalid format';
		}
		this.setState({errors: {...temp}})

		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleInputChange = e => {
		this.setState({email: e.target.value})
		this.validate({email: e.target.value});
	}

	handleCreateReport = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({working: true, file_path: null});
		$.ajax({
			url: `/reports/third_party_payment`,
			type: 'POST',
			data: {date_start: this.state.date_start, date_end: this.state.date_end, email: this.state.email},
			dataType: 'json',
			success: function (response) {
				self.setState({working: false, page: 2});
			},
			error: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	selectedSDateValue = (date) => {
		this.setState({date_start: date, file_path: null})
		this.validate({date_start: date});
	};

	selectedEDateValue = (date) => {
		this.setState({date_end: date, file_path: null})
		this.validate({date_end: date});
	};
	render() {
		const {classes} = this.props;
		const body = 
			<Card className={classes.root}>
				
					{this.state.page === 1 ? 
						<div className="container pb-5">
							<div className="row pt-2">
								<div className="col">
									<DatePicker
										disableFuture={true}
										disablePast={false}
										className={classes.textField}
										name='date_start'
										label='From Date'
										disabled={this.state.working}
										selectedDateValue={this.selectedSDateValue}
										value={this.state.date_start}
										{...(this.state.errors.date_start && {error: true, helperText: this.state.errors.date_start})}
									/>
								</div>
								<div className="col">
									<DatePicker
										disableFuture={true}
										disablePast={false}
										className={classes.textField}
										name='date_end'
										label='To Date'
										disabled={this.state.working}
										selectedDateValue={this.selectedEDateValue}
										value={this.state.date_end}
										{...(this.state.errors.date_end && {error: true, helperText: this.state.errors.date_end})}
									/>
								</div>
							</div>
							<div className="row pt-3">	
								<div className="col">
									<TextField
										name='email'
										label='Email'
										fullWidth
										onChange={this.handleInputChange}
										value={this.state.email || ''}
										{...(this.state.errors.email && {error: true, helperText: this.state.errors.email})}
									/>
								</div>
							</div>
						</div>
					: 
						<div className="container pb-5">
							<div className="row pt-2">
								<p>A link will be send to your email when the report is ready</p>
							</div>
						</div>
					} 
				
			</Card>
	
		const actions = (
			<Button
				variant='contained'
				type='submit'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				disabled={this.state.working}
				hidden={this.state.page === 2}
				onClick={this.handleCreateReport}
			>
				{this.state.working ? 'Working...' : 'Submit'}
			</Button>
		);

		return (
			<Modal
				isOpen={this.state.open}
				title='Third Party Payments'
				handleClose={this.handleClose}
				minHeight='30vh'
				maxHeight='30%'
				maxWidth='sm'
				actions={actions}>
				{body}
			</Modal>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default withSnackbar(withStyles(useStyles)(ThirdPartyPayment))
