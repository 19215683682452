import React from 'react';
import Table from '../shared/tables/material_table';
import {withSnackbar} from 'notistack';
import {FiRefreshCw} from "react-icons/fi";
import $ from "jquery";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";

class Invoices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			total: null,
      errors: {},
		};
		this._tableRef = React.createRef();
    this._inputFileRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}
	
  handleUpdate = (invoice_id, amount) => {
    let self = this;
		$.ajax({
			url: `/invoices/${invoice_id}`,
			type: 'PATCH',
			data: {amount: amount},
			dataType: 'json',
			success: function(response) {
				self.props.enqueueSnackbar('successfully updated!',{ variant: 'success' })
				self.reloadData();
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error,{ variant: 'error' })
				self.setState({working: false});
			},
		});
  }
  
  render() {
		const columns = [
			{title: 'invoice No.', field: 'invoice_number', editable: 'never'},
			{title: 'Vendor', field: 'vendor', editable: 'never'},
			{title: 'Order Number', field: 'order_number', editable: 'never'},
			{title: 'Amount', field: 'amount'},
			{title: 'Items total', field: 'items_total', editable: 'never'},
			{title: 'Date', field: 'created_at', editable: 'never'},
			{title: 'Last update', field: 'updated_at', editable: 'never'}
		]
    
    const onButtonClick = () => {

      this._inputFileRef.current.click();
    };
		return (
			<div className='bg-white rounded'>
        <input type='file' id='file' name='file' accept=".csv" ref={this._inputFileRef} style={{display: 'none'}} onChange={this.handleBulkUpdate}/>
				<Backdrop open={this.state.working}/>
				<Table
					title='invoices'
					key={this.state.muiTableKey}
					options={{
						toolbar: true,
            sorting: false, filtering: false,
						debounceInterval: 500,
						actionsColumnIndex: -1,
					}
					}
					columns={columns}
					tableRef={this._tableRef}
					cellEditable={{
						onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
							return new Promise((resolve, reject) => {
								setTimeout(() => {
									if ((rowData.subtotal + (rowData.subtotal * 0.025)) < newValue || (rowData.subtotal - (rowData.subtotal * 0.1)) > newValue)
										window.confirm(`Invoice is different than subtotal (${rowData.subtotal.toFixed(2)})`) && this.handleUpdate(rowData.id, newValue);
									else
										this.handleUpdate(rowData.id, newValue);
									resolve();
								}, 500)
							});
						}
					}}
					actions={
						[
							{
								icon: () => <FiRefreshCw/>,
								onClick: () => {this.reloadData()},
								isFreeAction: true,
								tooltip: 'Refresh'
							},
              {
								icon: () => <pureIcons.Edit/>,
								onClick: () => {onButtonClick()},
								isFreeAction: true,
								tooltip: 'Bulk update'
							}
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({field: col.column.field, value: col.value}))
							}
							let url = `/invoices.json?`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
							.then(response => response.json())
							.then((result) => {
								resolve({
									data: result.invoices,
									page: result.page - 1,
									totalCount: result.total,
								});
								window.breadcrumb.addBreadcrumb(result.breadcrumb);
							})
						})
					}
				/>
			</div>
		);
	}
}

export default withSnackbar(Invoices)
