import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Card, CardContent, CardMedia, CircularProgress, IconButton, Typography} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Modal from '../shared/modal/material_dialog'
import Select from '../shared/autocomplete'
import $ from 'jquery';
import {useSnackbar} from 'notistack';
import Backdrop from "../shared/backdrop";

export default function CancelForm({product, callBackDeleteItem, qty, stage, order_item_stage_id}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [working, setWorking] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const [itemPriceOnly, setItemPriceOnly] = React.useState(false);
	const [cancelReason, setCancelReason] = React.useState('');
	const [cancelReason2, setCancelReason2] = React.useState('');
	const {enqueueSnackbar} = useSnackbar();

	const handleSelectCallBack = (data) => {
		setCancelReason(data.value)
		validate(data.value);
	};

	const handleSelectCallBack2 = (data) => {
		setCancelReason2(data.value)
		validate2(data.value);
	};

	const validate = (fieldValues = cancelReason) => {
		let temp = {...errors};
		temp.cancelReason = fieldValues ? '' : 'Required';
		setErrors({...temp})
		if (fieldValues === cancelReason)
			return Object.values(temp).every(x => x === '')
	};

	const validate2 = (fieldValues = cancelReason2) => {
		let temp = {...errors};
		temp.cancelReason2 = fieldValues ? '' : 'Required';
		setErrors({...temp})
		if (fieldValues === cancelReason2)
			return Object.values(temp).every(x => x === '')
	};

	const handleCheckBoxChange = (e) => {
		setItemPriceOnly(!itemPriceOnly);

	}

	const body = <Card className={classes.root}>
		<CardMedia className={classes.cover}>
			<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}}/>
		</CardMedia>
		<div className={classes.details}>
			<CardContent className={classes.content}>
				<Typography component='h5' variant='h5'>
					{product.name}
				</Typography>
				<Typography variant='subtitle1' color='textSecondary' pb={5}>
					QTY: {qty}
				</Typography>
				<Typography variant='subtitle1' color='textSecondary' pb={5}>
					Price: {product.price}
				</Typography>
				<Select
					name='cancel_reason1'
					label='Admin cancellation reason'
					url='/codes/dropdown_options.json?code_category_name=Admin_cancellation_reasons'
					style={{width: 500}}
					error={errors.cancelReason}
					callBack={handleSelectCallBack}
				/>
				<br/>
				<Select
					name='cancel_reason2'
					label='Customer cancellation reason'
					url='/codes/dropdown_options.json?code_category_name=Cancellation_reasons&customer_codes=true'
					style={{width: 500}}
					error={errors.cancelReason2}
					callBack={handleSelectCallBack2}
				/>
			</CardContent>
		</div>
	</Card>

	const handleClose = () => {
		if (working) return;
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validate() || !validate2()) return;
		setWorking(true);
		const data = {
			cancel_reason: cancelReason,
			qty: qty,
			item_price_only: itemPriceOnly,
			subtotal: (product.price * qty),
			order_item_stage_id: order_item_stage_id,
			customer_cancel_reason: cancelReason2
		}
		$.ajax({
			url: `/order_items/${product.order_item_id}/cancel`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function (response) {
				setWorking(false);
				enqueueSnackbar('successfully cancelled!', {variant: 'success'})
				callBackDeleteItem();
				handleClose();
			},
			error: function (response) {
				setWorking(false);
				enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	const actions = (
		working ?
			<CircularProgress className={classes.button}/> :
			<>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="home_delivery" name='home_delivery' checked={itemPriceOnly} onClick={()=> setItemPriceOnly(!itemPriceOnly)}/>
					<label className="form-check-label" htmlFor="home_delivery">Item price only</label>
				</div>
				<div style={{flex: '1 0 0'}} />
				<Button
					variant='contained'
					style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
					type='submit'
					className={classes.button}
					disabled={working}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</>
	);

	return (
		<>
			<IconButton aria-label='delete' onClick={handleOpen}>
				<DeleteForeverIcon style={{color: '#f50057', fontSize: 30}}/>
			</IconButton>
			<Backdrop open={working}/>
			<Modal
				disableBackdropClick
				isOpen={open}
				title={`Cancel Item in stage: ${stage}`}
				handleClose={handleClose}
				maxHeight='60%'
				maxWidth='md'
				actions={actions}>
				{body}
			</Modal>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));
