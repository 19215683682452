import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import { useSnackbar } from "notistack";

const SharingLinkSidebar = ({ toggleShowPhoneCardSlider, balanceData }) => {
  return (
    <div
      style={{
        width: 300,
        padding: "16px",
        overflowY: "auto",
        backgroundColor: "#f0f2f8",
        height: 2000,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" gutterBottom>
          Balance Message
        </Typography>
        <Button onClick={toggleShowPhoneCardSlider}>
          <CloseIcon />
        </Button>
      </div>
      <List>
        <React.Fragment>
          <Card style={{ marginBottom: "8px", color: "#476f6c" }}>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ fontSize: "1.25rem" }}>{balanceData}</div>
                }
              />
            </ListItem>
          </Card>
          <Divider />
        </React.Fragment>
      </List>
    </div>
  );
};

export default SharingLinkSidebar;
