"@material-ui/core";

import React, { useEffect, useRef, useState } from "react";
import {
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import KidusTransferTable from "./kidus_transfer_table";

const KidusTransferLocation = () => {
  const [selectProvince, setSelectProvince] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [numberInput, setNumberInput] = useState("");
  const [numberError, setNumberError] = useState("");
  const [provinceList, setProvinceList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);

  const _KidusTransfer = useRef<any>("");

  const getProvince = () => {
    fetch("/users/kidu_transferor_province.json")
      .then((response) => response.json())
      .then((data) => {
        setProvinceList(data.kidu_transferor_provinces);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const getCity = (id) => {
    fetch(`/users/kidu_transferor_city.json?province_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setCityList(data.kidu_transferor_cities);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getProvince();
  }, []);

  const handleFirstSelectChange = (event) => {
    setSelectProvince(event.target.value);
    getCity(event.target.value);
    setSelectCity("");
  };

  const handleSecondSelectChange = (event) => {
    setSelectCity(event.target.value);
  };

  const handleNumberInputChange = (event) => {
    const value = event.target.value;
    // Only allow positive integers
    if (value === "" || /^[1-9]\d*$/.test(value)) {
      setNumberInput(value);
      validateNumberInput(value);
    }
  };

  const validateNumberInput = (value) => {
    if (value === "") {
      setNumberError("");
    } else {
      const numValue = Number(value);

      if (isNaN(numValue) || !Number.isInteger(numValue) || numValue <= 0) {
        setNumberError("Please enter a positive integer");
      } else {
        setNumberError("");
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectProvince && selectCity && !numberError && numberInput !== "") {
      _KidusTransfer.current.handleOpen(selectCity, numberInput);
    } else {
      console.log("Form is not valid");
    }
  };

  const clearData = () => {
    // setSelectProvince("");
    // setSelectCity("");
    // setNumberInput("");
  };

  return (
    <>
      <KidusTransferTable ref={_KidusTransfer} callback={clearData} />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ marginTop: "1rem" }}
          sx={{ textAlign: "center" }}
        >
          Kidus transferor province & city
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "100%",
            "& > *": { mb: 2, width: "100%" },
          }}
        >
          <FormControl fullWidth style={{ margin: "1.7rem 0rem" }}>
            <InputLabel>Select province</InputLabel>
            <Select
              value={selectProvince}
              label="Select province"
              onChange={handleFirstSelectChange}
            >
              {provinceList.map((opt) => {
                return (
                  <MenuItem value={opt[1]} key={opt[1]}>
                    {opt[0]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {selectProvince && (
            <>
              <FormControl fullWidth style={{ margin: "1.7rem 0rem" }}>
                <InputLabel>Select city</InputLabel>
                <Select
                  value={selectCity}
                  label="Select city"
                  onChange={handleSecondSelectChange}
                >
                  {cityList.map((opt) => {
                    return (
                      <MenuItem value={opt[1]} key={opt[1]}>
                        {opt[0]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                label="Kidus amount"
                type="text" // Changed from "number" to "text"
                value={numberInput}
                onChange={handleNumberInputChange}
                error={!!numberError}
                helperText={numberError}
                inputProps={{ inputMode: "numeric", pattern: "[1-9][0-9]*" }}
                style={{ margin: "1.7rem 0rem" }}
              />
            </>
          )}
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor:
                !selectProvince ||
                !selectCity ||
                !!numberError ||
                numberInput === ""
                  ? "#d1d1d1"
                  : "#f6b67f",
              color: "#ffffff",
              margin: "1.7rem 0rem",
            }}
            disabled={
              !selectProvince ||
              !selectCity ||
              !!numberError ||
              numberInput === ""
            }
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default KidusTransferLocation;
