import React from "react";
import Modal from "../shared/modal/material_dialog";
import NoteForm from "../notes/_note_form";
import Tab from "./_customized_tabs";
import { Typography, Button } from "@material-ui/core";
import AsyncSearchBar from "../shared/react_async_autocomplete";
import $ from "jquery";

export default class ShowOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      order_number: "",
      order_id: "",
      notes_count: 0,
      maxWidth: "sm",
      new_customer: false,
      order: { vendors: [] },
    };
  }

  handleOpen = (order_id = null, order_number = null) => {
    this.setState({
      open: true,
      order_id: order_id,
      order_number: order_number,
      maxWidth: order_id ? "xl" : "sm",
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  reset = () => {
    this.setState({ order_id: null, order_number: null, maxWidth: "sm" });
  };

  setNewCustomer = (value) => {
    this.setState({ new_customer: value });
    let order_id = this.state.order_id;
    let self = this;
    $.ajax({
      url: `/orders/${order_id}`,
      type: "GET",
      dataType: "json",
      success: function (response) {
        self.setState({
          customer_id: response.customer_id,
          customer_number: response.customer_number,
          customer_name: response.customer_name,
          order_summary: response.order_summary,
          payment_method: response.payment_method,
          delivery_method: response.delivery_method,
          shopper_address: response.shopper_address,
          card_info: response.card_info
        });
      },
    });
  };

  handleSelectChange = (object) => {
    this.setState({
      order_id: object.value[0],
      order_number: object.value[1],
      maxWidth: "xl",
    });
  };
  render() {
    const body = this.state.order_id ? (
      <Tab order_id={this.state.order_id} callBack={this.setNewCustomer} />
    ) : (
      <AsyncSearchBar
        setCallBack={this.handleSelectChange}
        noOptionsMessage="No order founded"
        placeholder=" "
        url="/orders.json"
      />
    );
    const orderTitle = (
      <>
        <p>
          {this.state.order_number}
          <NoteForm
            order_id={this.state.order_id}
            key={this.state.order_number}
            notes_count={this.state.order.notes_count}
            newNoteChild={() =>
              this.setState({ notes_count: this.state.notes_count + 1 })
            }
          />
          <button
            class="btn btn-link"
            type="button"
            hidden={!this.state.order_id}
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            variant="contained"
          >
            order info
          </button>
        </p>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <div className="row">
              <div className="col-3">
                <small>
                  Customer Id: <b>{this.state.customer_id}</b>
                </small>
              </div>
              <div className="col-3">
                <small>
                  Customer Number: <b>{this.state.customer_number}</b>
                </small>
              </div>
              {this.state.order_summary?.map(
                (o) =>
                  o.value !== 0 && (
                    <div className="col-3">
                      <small>
                        {o.name}: <b>{o.value}</b>
                      </small>
                    </div>
                  )
              )}
              <div className="col-3">
                <small>
                  Payment method: <b>{this.state.payment_method}</b>
                </small>
              </div>
              <div className="col-3">
                <small>
                  Delivery method: <b>{this.state.delivery_method}</b>
                </small>
              </div>
              {this.state.shopper_address && (
                <div className="col-3">
                  <small>
                    Shopper Address: <b>{this.state.shopper_address}</b>
                  </small>
                </div>
              )}
              {this.state.card_info && (
                <div className="col-3">
                  <small>
                    Credit Card(Country, Last 4, Exp Month, Exp Year, First 6):
                    <b> {this.state.card_info}</b>
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );

    const actions = (
      <Button
        variant="contained"
        hidden={!this.state.order_id}
        type="submit"
        onClick={() => this.reset()}
      >
        Clear
      </Button>
    );

    return (
      <Modal
        disableBackdropClick={false}
        isOpen={this.state.open}
        title={this.state.order_id ? orderTitle : "Search by order number"}
        handleClose={this.handleClose}
        minHeight="30%"
        maxWidth={this.state.maxWidth}
        maxHeight="85%"
        actions={actions}
      >
        <div style={{ paddingTop: "2px" }}>{body}</div>
      </Modal>
    );
  }
}
