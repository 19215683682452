import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import { Badge, Button } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ShowPhoneCardEdit from "./_show_edit";
import $ from "jquery";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Backdrop from "../../shared/backdrop";

const Index = forwardRef((props, ref) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [submitting, setSubmitting] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const _tableRef = React.createRef<any>();
  const _ShowPhoneCardEditRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [digitalBrandId, setDigitalBrandId] = useState<any>("");
  const [brandName, setBrandName] = useState<any>("");

  useImperativeHandle(ref, () => ({
    handleOpen(id, brand_name) {
      setOpen(true);
      getData(id);
      setDigitalBrandId(id);
      setBrandName(brand_name);
    },
  }));

  const getData = useCallback((id) => {
    setSubmitting(true);
    let url = `/products/digital_products.json?digital_brand_id=${id}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setSubmitting(false);
        setOrdersData(result.digital_products.flat());
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOrdersData([]);
  };
  const onChangeAvalability = (available, id) => {
    $.ajax({
      url: `/products/update_availability?available=${!available}&product_id=${id}`,
      type: "GET",
      dataType: "json",
      success: function (response) {
        if (response?.success) {
          enqueueSnackbar(response.response, {
            variant: "success",
          });
          getData(digitalBrandId);
        } else {
          enqueueSnackbar(response.response, {
            variant: "error",
          });
        }
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      title: "",
      field: "image_tag",
      sorting: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              src={row.image_tag}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "ID",
      field: "product_id",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Rmt product id",
      field: "remote_product_id",
      filtering: false,
      editable: "never",
      sorting: false,
    },

    {
      title: "Product name",
      field: "product_name",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "10%" },
      sorting: false,
    },
    {
      title: "LC price",
      field: "local_currency_price",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Price",
      field: "price",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Available to user?",
      field: "availability",
      editable: "never",
      filtering: false,
      sorting: false,
      render: (row) => (
        <>{capitalizeFirstLetter(row.availability.toString())}</>
      ),
    },
  ];

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      <ShowPhoneCardEdit ref={_ShowPhoneCardEditRef} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title=""
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
      >
        <Table
          title={`Digital products belong to ${brandName}`}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
          tableRef={_tableRef}
          actions={[
            //   {
            //     icon: () => <pureIcons.AiOutlinePlus />,
            //     onClick: () => {
            //       _addNewKidusRef.current.handleOpen();
            //     },
            //     isFreeAction: true,
            //     tooltip: "Add New Kidus Serial",
            //   },
            //   {
            //     icon: () => <pureIcons.FiRefreshCw />,
            //     onClick: () => {
            //       reloadData();
            //     },
            //     isFreeAction: true,
            //     tooltip: "Refresh",
            //   },
            (rowData) => ({
              icon: () => (
                <>
                  {rowData.availability ? (
                    <NotInterestedIcon style={{ color: "red" }} />
                  ) : (
                    <AssignmentTurnedInIcon style={{ color: "green" }} />
                  )}
                </>
              ),
              tooltip: (
                <span>
                  {rowData.availability ? "Unavailable" : "Available"}
                </span>
              ),
              onClick: () => {
                onChangeAvalability(rowData.availability, rowData.product_id);
              },
              position: "row",
            }),

            (rowData) => ({
              icon: () => <pureIcons.Edit />,
              tooltip: <span>Edit</span>,
              onClick: () => {
                _ShowPhoneCardEditRef.current.handleOpen(
                  rowData,
                  digitalBrandId
                );
              },
              position: "row",
            }),
          ]}
          data={ordersData}
        />
      </Modal>
    </div>
  );
});

export default withSnackbar(Index);
