import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import { Backdrop } from "@material-ui/core";
import AddNewExchangeRate from "./_add_new_exchange_rate";
import Modal from "../shared/modal/material_dialog";
import $ from "jquery";

class ExchangeRates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      user_balance: "",
      errors: {},
    };
    this._tableRef = React.createRef();
    this._inputFileRef = React.createRef();
    this._addNewExchangeRateRef = React.createRef();
  }

  handleOpen = () => {
    this.setState({ open: true });
    // this.getData();
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    fetch("/exchange_rate.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          tableData: data.exchange_rates,
          last_rates: data.last_rates,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  componentDidMount() {
    // Fetch data from an API or any other data source
    fetch("/exchange_rate.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          tableData: data.exchange_rates,
          last_rates: data.last_rates,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  render() {
    const columns = [
      { title: "Currency Value", field: "currency_value", editable: "never" },
      { title: "Currency Type", field: "currency_type", editable: "never" },
      { title: "Modified By", field: "user_name", editable: "never" },
      { title: "Created At", field: "created_at", editable: "never" },
    ];
    const body = (
      <>
        <div className="bg-white rounded">
          <Backdrop open={this.state.submitting} />
          <AddNewExchangeRate
            ref={this._addNewExchangeRateRef}
            reloadData={this.reloadData}
          />
          <div
            style={{
              padding: "1rem",
              borderBottom: "10px solid rgb(239, 242, 248)",
            }}
          >
            <span>Current currency to Kidu: {this.state.last_rates}</span>
          </div>
          <Table
            title={"Exchange Rates"}
            options={{
              toolbar: true,
              sorting: true,
              search: true,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,
              maxBodyHeight: "50vh",
            }}
            columns={columns}
            tableRef={this._tableRef}
            actions={[
              {
                icon: () => <pureIcons.AiOutlinePlus />,
                onClick: () => {
                  this._addNewExchangeRateRef.current.handleOpen();
                },
                isFreeAction: true,
                tooltip: "Add New Exchange Rate",
              },
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => this.reloadData(),
                isFreeAction: true,
                tooltip: "Refresh",
              },
            ]}
            data={this.state.tableData}
            // data={this.state.data}
            // data={(query) =>
            //   new Promise((resolve, reject) => {
            //     var filters;
            //     if (query.filters.length > 0) {
            //       filters = query.filters.map((col) => ({
            //         field: col.column.field,
            //         value: col.value,
            //       }));
            //     }
            //     let url = `/exchange_rate.json?`;
            //     url += "per_page=" + query.pageSize;
            //     url += "&page=" + (query.page + 1);
            //     if (query.filters.length > 0) {
            //       url += "&filters=" + JSON.stringify(filters);
            //     }
            //     if (query.orderBy) {
            //       url += "&orderBy=" + query.orderBy.field;
            //       url += "&orderDirection=" + query.orderDirection;
            //     }
            //     fetch(url)
            //       .then((response) => response.json())
            //       .then((result) => {
            //         this.setState({
            //           last_rates: result.last_rates,
            //         });
            //         resolve({
            //           data: result.exchange_rates,
            //           page: result.page - 1,
            //           totalCount: result.total,
            //         });
            //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
            //       });
            //   })
            // }
          />
        </div>
      </>
    );
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="90%"
        maxWidth="md"
      >
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(ExchangeRates);
