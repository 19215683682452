import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FiShoppingCart,
  FiChevronsLeft,
  FiChevronsRight,
  FiSearch,
  FiPackage,
} from "react-icons/fi";
import {
  FaClipboardList,
  FaShippingFast,
  FaWarehouse,
  FaFileInvoiceDollar,
  FaBusinessTime,
  FaRegThumbsUp,
  FaCubes,
  FaProductHunt,
} from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import {
  GiBoxUnpacking,
  GiStorkDelivery,
  GiCommercialAirplane,
  GiCardPickup,
  GiCancel,
  GiExpense,
} from "react-icons/gi";
import { BsHandThumbsUp, BsBookshelf } from "react-icons/bs";
import { IoBarChartSharp } from "react-icons/io5";
import {
  GrDocumentCsv,
  GrSettingsOption,
  GrUserSettings,
  GrHostMaintenance,
  GrBusinessService,
} from "react-icons/gr";
import { IoMdBusiness } from "react-icons/io";
import { ImTree } from "react-icons/im";
import ShowUser from "../users/_show_user";
import FindItem from "../marketplace_products/_index";
import ShowOrder from "../orders/_show";
import ShowBusiness from "../business_profiles/_show_business";
import { TbCategory, TbCategoryFilled, TbTruckDelivery } from "react-icons/tb";
import { TiCancelOutline } from "react-icons/ti";
import { BiSolidCoupon } from "react-icons/bi";
import { MdCategory, MdOutlineLocalLibrary } from "react-icons/md";
import { RiLinksFill } from "react-icons/ri";
import { CiLocationArrow1 } from "react-icons/ci";
import { HiDocumentPlus } from "react-icons/hi2";
import { LuFileDigit } from "react-icons/lu";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: localStorage.getItem("collapsed") === "true",
      sidebar: [],
    };
    this._ShowUserRef = React.createRef();
    this._FindItemRef = React.createRef();
    this._ShowOrder = React.createRef();
    this._ShowBusiness = React.createRef();
  }

  componentDidMount() {
    const { page_id } = this.props;
    let url = `/sidebar_content.json?`;
    if (page_id) url = url + `page_id=${page_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          sidebar: result.sidebar,
          loading: false,
        });
      });
  }

  handleToggleSidebar = () => {
    this.setState({ collapsed: !this.state.collapsed });

    document
      .getElementById("content")
      .setAttribute("collapsed", !this.state.collapsed);
    localStorage.setItem("collapsed", !this.state.collapsed);
  };

  render() {
    const KeysToComponentMap = {
      FiShoppingCart: FiShoppingCart,
      FaClipboardList: FaClipboardList,
      FiSearch: FiSearch,
      AiOutlineFileSearch: AiOutlineFileSearch,
      GiBoxUnpacking: GiBoxUnpacking,
      FaShippingFast: FaShippingFast,
      FaWarehouse: FaWarehouse,
      BsHandThumbsUp: BsHandThumbsUp,
      BsBookshelf: BsBookshelf,
      FiPackage: FiPackage,
      IoBarChartSharp: IoBarChartSharp,
      GrDocumentCsv: GrDocumentCsv,
      ImTree: ImTree,
      GrSettingsOption: GrSettingsOption,
      GrUserSettings: GrUserSettings,
      FaFileInvoiceDollar: FaFileInvoiceDollar,
      FaBusinessTime: FaBusinessTime,
      GiStorkDelivery: GiStorkDelivery,
      FaRegThumbsUp: FaRegThumbsUp,
      GiCommercialAirplane: GiCommercialAirplane,
      GiCardPickup: GiCardPickup,
      GrHostMaintenance: GrHostMaintenance,
      IoMdBusiness: IoMdBusiness,
      FaCubes: FaCubes,
      FaProductHunt: FaProductHunt,
      TbCategory: TbCategory,
      TbCategoryFilled: TbCategoryFilled,
      TiCancelOutline: TiCancelOutline,
      TbTruckDelivery: TbTruckDelivery,
      BiSolidCoupon: BiSolidCoupon,
      GiCancel: GiCancel,
      MdCategory: MdCategory,
      GiExpense: GiExpense,
      HiDocumentPlus: HiDocumentPlus,
      MdOutlineLocalLibrary: MdOutlineLocalLibrary,
      LuFileDigit: LuFileDigit,
      RiLinksFill: RiLinksFill,
      CiLocationArrow1: CiLocationArrow1,
    };

    const arrow = (
      <IconButton
        size="small"
        component="span"
        onClick={this.handleToggleSidebar}
      >
        {this.state.collapsed ? <FiChevronsRight /> : <FiChevronsLeft />}
      </IconButton>
    );

    const menu_items = this.state.sidebar.map((m) =>
      m.menu ? (
        <MenuItem
          icon={React.createElement(KeysToComponentMap[m.menu.icon])}
          key={m.menu.name}
        >
          {m.menu.name} <Link to={m.menu.link} />{" "}
        </MenuItem>
      ) : (
        <SubMenu
          title={m.sub_menu.name}
          icon={React.createElement(KeysToComponentMap[m.sub_menu.icon])}
          key={m.sub_menu.name}
          defaultOpen={m.sub_menu.open}
        >
          {m.sub_menu.sub_menu_items.map((mm) =>
            mm.sub_menu ? (
              <SubMenu
                title={mm.sub_menu.name}
                prefix={React.createElement(
                  KeysToComponentMap[mm.sub_menu.icon]
                )}
                key={mm.sub_menu.name}
                defaultOpen={mm.sub_menu.open}
              >
                {mm.sub_menu.sub_menu_items.map((mmm) => (
                  <MenuItem
                    prefix={React.createElement(KeysToComponentMap[mmm.icon])}
                    key={mmm.name}
                  >
                    {mmm.name} <Link to={mmm.link} />
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem
                prefix={React.createElement(KeysToComponentMap[mm.icon])}
                key={mm.name}
              >
                {mm.name} <Link to={mm.link} />
              </MenuItem>
            )
          )}
          {m.sub_menu &&
            m.sub_menu.name === "Business" && ( // Add this condition to check if the current submenu is "Business"
              <MenuItem
                prefix={<FiSearch />}
                key="Find Business"
                onClick={() => this._ShowBusiness.current.handleOpen()}
              >
                Find Business
              </MenuItem>
            )}
        </SubMenu>
      )
    );

    return (
      <ProSidebar collapsed={this.state.collapsed} breakPoint="lg">
        <ShowUser ref={this._ShowUserRef} />
        <FindItem ref={this._FindItemRef} />
        <ShowOrder ref={this._ShowOrder} />
        <ShowBusiness ref={this._ShowBusiness} />

        <SidebarHeader style={{ textAlign: "right" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <div className="block ">{arrow}</div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            <SubMenu
              title="Search"
              icon={<FiSearch />}
              key="Search"
              defaultOpen={false}
            >
              <MenuItem
                prefix={<FiSearch />}
                key="Find Order"
                onClick={() => this._ShowOrder.current.handleOpen()}
              >
                Find Order
              </MenuItem>
              <MenuItem
                prefix={<FiSearch />}
                key="Find Item"
                onClick={() => this._FindItemRef.current.handleOpen()}
              >
                Find Item
              </MenuItem>
              <MenuItem
                prefix={<FiSearch />}
                key="Find User"
                onClick={() => this._ShowUserRef.current.handleOpen()}
              >
                Find User
              </MenuItem>
            </SubMenu>
            {menu_items}

            {/* <MenuItem prefix={<FiSearch />} key="Find Business" onClick={() => this._ShowBusiness.current.handleOpen()}>Find Business</MenuItem> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter />
      </ProSidebar>
    );
  }
}
