import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useSnackbar } from "notistack";
import { shortLinkPageData } from "../standard_categories/dummyData";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const Index = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState<any>(moment());

  const viewClick = () => {
    const formatEndDate = endDate.format("MM-DD-YYYY");
    const formatStartDate = startDate.format("MM-DD-YYYY");

    $.ajax({
      url: `/customer_profiles/`,
      type: "POST",
      data: {
        startDate: formatStartDate,
        endDate: formatEndDate,
      },
      dataType: "json",
      success: function (response) {
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <Card style={{ margin: "20px", height: "95%" }}>
        <CardHeader title="Referrals for Test"></CardHeader>

        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ position: "absolute" }}>From date</Typography>

            <Box style={{ minWidth: "40%", marginLeft: "20%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  name="start_date"
                  //   label="From date"
                  onChange={(ev) => setStartDate(ev)}
                  format="MM/dd/yyyy"
                  value={startDate}
                  // {...(this.state.errors.eta && {
                  //   error: true,
                  //   helperText: this.state.errors.eta,
                  // })}
                />
              </MuiPickersUtilsProvider>
            </Box>
            {/* placeholder={placeholder} footer={footer}  */}
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ position: "absolute" }}>To date</Typography>

            <Box style={{ minWidth: "40%", marginLeft: "20%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  name="end_date"
                  //   label="To date"
                  onChange={(ev) => setEndDate(ev)}
                  format="MM/dd/yyyy"
                  value={endDate}
                  // {...(this.state.errors.eta && {
                  //   error: true,
                  //   helperText: this.state.errors.eta,
                  // })}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          <CardActions style={{ justifyContent: "end" }}>
            <Button
              type="button"
              style={{
                backgroundColor: "#ec9a53",
                color: "#ebeef0",
                textTransform: "none",
              }}
              onClick={() => viewClick()}
            >
              View
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      {/* <Backdrop open={working} style={{ zIndex: 0 }} /> */}
    </>
  );
};

export default Index;
