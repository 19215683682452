import React, { useEffect, useState } from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import { Backdrop, Button } from "@material-ui/core";
import EditPhases from "./_edit_phases";

const Index = (props) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<any>(false);
  const [muiTableKey, setMuiTableKey] = useState<any>(0);

  const _tableRef = React.createRef<any>();
  const _editPhasesRef = React.createRef<any>();
  const _showpaymentsRef = React.createRef<any>();

  const reloadData = () => {
    getData();
  };

  const handleUpdates = (data, oldValue, newValue, columnDef) => {
    const payload = { active: !data.active };
    $.ajax({
      url: `/marketplace_vendor/${data.id}/update_status`,
      type: "PUT",
      data: payload,
      dataType: "json",
      success: function (response) {
        props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        reloadData();
      },
      error: function (response) {
        props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const getData = () => {
    let url = `/shipping_carriers.json?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setOrdersData(result.shipping_carriers.flat()),
          window.breadcrumb.addBreadcrumb(result.breadcrumb);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Region",
      field: "region",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Carrier",
      field: "carrier",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Shipping Option",
      field: "shipping_option",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "2%" },
      sorting: false,
    },
    {
      title: "Hub Source",
      field: "hub_source",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "10%" },
      sorting: false,
    },
    {
      title: "Over Size",
      field: "over_size",
      editable: "never",
      filtering: false,
      sorting: false,
    },
  ];

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      {/* <CreateCard ref={this._createcardRef} reloadData={this.reloadData} />
      <ShowPayments ref={this._showpaymentsRef} reloadData={this.reloadData} /> */}
      <EditPhases ref={_editPhasesRef} />
      <Table
        title={`Shipping Carrires`}
        options={{
          toolbar: true,
          sorting: true,
          search: true,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                handleUpdates(rowData, oldValue, newValue, columnDef),
                  resolve();
              }, 500);
            });
          },
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          // {
          //   icon: () => <pureIcons.AiOutlinePlus />,
          //   onClick: () => {
          //     _createcardRef.current.handleOpen();
          //   },
          //   isFreeAction: true,
          //   tooltip: "Add New Credit",
          // },
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          (rowData) => ({
            icon: () => (
              <Button style={{ background: "#f6b67f", color: "white" }}>
                Phases
              </Button>
            ),
            tooltip: <span>Phases</span>,
            onClick: () => {
              _editPhasesRef.current.handleOpen(rowData.id, rowData.digits);
            },
            position: "row",
          }),
        ]}
        data={ordersData}
        // data={(query) =>
        //   new Promise((resolve, reject) => {
        //     var filters;
        //     if (query.filters.length > 0) {
        //       filters = query.filters.map((col) => ({
        //         field: col.column.field,
        //         value: col.value,
        //       }));
        //     }
        //     let url = `/prepaid_card_accesses.json?&status=blacklist&`;
        //     url += "per_page=" + query.pageSize;
        //     url += "&page=" + (query.page + 1);
        //     if (query.filters.length > 0) {
        //       url += "&filters=" + JSON.stringify(filters);
        //     }
        //     if (query.search) {
        //       url += "&search=" + query.search;
        //     }
        //     fetch(url)
        //       .then((response) => response.json())
        //       .then((result) => {
        //         resolve({
        //           data: result.cards.flat(),
        //           page: result.page - 1,
        //           totalCount: result.total,
        //         });
        //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
        //       });
        //   })
        // }
      />
    </div>
  );
};

export default withSnackbar(Index);
