import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Backdrop from "../shared/backdrop";
import Modal from "../shared/modal/material_dialog";

const AddBusinessCat = forwardRef(({ callback }: any, ref) => {
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [businessProfileId, setBusinessProfileOrdersId] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [discountRate, setDiscount] = useState<any>(0.05);
  const [submitting, setSubmitting] = useState(false);

  const [ErSelectSupplier, setErSelectSupplier] = useState<any>(false);
  const [ErSupplierCategory, setErSupplierCategory] = useState<any>(false);
  const [ErDiscountRate, setErDiscountRate] = useState<any>(false);

  const _tableRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(id, name) {
      setOpen(true);
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response1 = await fetch(
        "/business_category_discounts/business_profile_select?locale=en"
      );
      const data1 = await response1.json();
      console.log(data1);
      setData(data1.options);
      setCatData(data1.part_categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const clearData = () => {
    setBusinessProfileOrdersId("");
    setCategory("");
    setDiscount(0.05);

    setErSelectSupplier(false);
    setErDiscountRate(false);
    setErSupplierCategory(false);
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const saveData = () => {
    if (!businessProfileId || !category || !discountRate) {
      if (!businessProfileId) {
        setErSelectSupplier(true);
      }

      if (!category) {
        setErSupplierCategory(true);
      }

      if (!discountRate) {
        setErDiscountRate(true);
      }
      return;
    }

    let formData = new FormData();
    formData.append(
      "business_category_discount[business_profile_id]",
      businessProfileId
    );
    formData.append("business_category_discount[parts_category_id]", category);
    formData.append("business_category_discount[discount_rate]", discountRate);
    const url = `/business_category_discounts.json?locale=en`;

    setSubmitting(true);
    fetch(url, { method: "POST", body: formData })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(result.msg, {
            variant: "success",
          });
          handleClose();

          callback();

          setSubmitting(false);
        } else {
          enqueueSnackbar(result.error, {
            variant: "error",
          });
          setSubmitting(false);
          handleClose();
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.responseJSON.error, {
          variant: "error",
        });
        setSubmitting(false);
        handleClose();
      });
  };

  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };
  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="Add Supplier"></CardHeader>
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="   Select Supplier"
                select
                fullWidth
                margin="normal"
                label="Select supplier"
                style={{ width: "20%" }}
                value={businessProfileId}
                onChange={(e: any) => {
                  setBusinessProfileOrdersId(e.target.value);
                  setErSelectSupplier(false);
                }}
                helperText={
                  ErSelectSupplier && helperText("Please select supplier")
                }
                FormHelperTextProps={{ color: "red" }}
              >
                {data.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.phone_number}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Supplier Category"
                style={{ width: "20%" }}
                value={category}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setCategory(e.target.value);
                  setErSupplierCategory(false);
                }}
                helperText={
                  ErSupplierCategory &&
                  helperText("Please select supplier category")
                }
                FormHelperTextProps={{ color: "red" }}
              >
                {catData.map((option: any) => (
                  <MenuItem key={option.category_id} value={option.category_id}>
                    {option.category_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Discount Rate"
                type="number"
                margin="normal"
                label="Discount Rate"
                style={{ width: "20%" }}
                value={discountRate}
                onChange={(e: any) => {
                  setDiscount(e.target.value);
                  setErDiscountRate(false);
                }}
                helperText={
                  ErDiscountRate && helperText("Please enter discount rate")
                }
                FormHelperTextProps={{ color: "red" }}
              ></TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(AddBusinessCat);
