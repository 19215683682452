import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Typography,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import Table from "../shared/tables/material_table";
import Modal from "../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import AsyncSearchBar from "../shared/react_async_autocomplete";
import BackdropReusable from "../shared/backdrop";
import $ from "jquery";
import UpdateUserStatus from "./_update_user_status";
import AddExternalItem from "./_add_external_item";
import ShowCart from "./_show_cart";
import ReviewHistory from "./_cart_review_history";
import UpdateUserBalance from "./_update_user_balance";
import DiscountCodes from "./_discount_codes";
import pureIcons from "../shared/pure_icons";
import ShowOrder from "../orders/_show";
import Payments from "./_payments";
import Referral from "./_referral";

class ShowUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      errors: {},
      open: false,
      orders: [],
      maxWidth: "sm",
      transactionData: [],
      loading: false,
      openAccordion: null,
    };
    this._addExternalItemRef = React.createRef();
    this._updateUserStatusRef = React.createRef();
    this._updateUserBalanceRef = React.createRef();
    this._showCartRef = React.createRef();
    this._ShowOrderRef = React.createRef();
    this._reviewHistoryRef = React.createRef();
    this._discountCodesRef = React.createRef();
    this._table2Ref = React.createRef();
    this._tableRef = React.createRef();
    this._paymentsRef = React.createRef();
    this._referralRef = React.createRef();
  }

  getTransactionData() {
    let url = `/users/${this.state.id}/transactions.json`;
    if (this.state.id) {
      this.setState({ working: true });
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          this.setState({ working: false });
          this.setState({
            transactionData: data?.transactions,
          });
        });
    }
  }

  toggleAccordion = (id) => {
    this.setState((prevState) => ({
      openAccordion: prevState.openAccordion === id ? null : id,
    }));
    if (id == "one") {
      this._tableRef.current.onQueryChange();
    }
    if (id == "two" && this.state.transactionData.length == 0) {
      this.getTransactionData();
    }
  };

  resetFrom = () => {
    this.setState({
      id: "",
      name: "",
      phone_number: "",
      kidus: "",
      gender: "",
      type: "",
      active: "",
      delivery: "",
      hold: "",
      address: "",
      deactivate_reason: "",
      business_name: "",
      orders: [],
      transactions: [],
      maxWidth: "sm",
      page_controls: {},
      openAccordion: null,
      transactionData: [],
    });
  };
  handleOpen = () => {
    this.resetFrom();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangeDelivery = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    this.validate({ [e.target.name]: e.target.checked });
    let self = this;
    e.preventDefault();
    this.setState({ working: true });
    $.ajax({
      url: "/users/" + this.state.id,
      type: "PUT",
      data: { [e.target.name]: e.target.checked },
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.handleSelectChange();
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  clearFavorite = () => {
    let self = this;
    let data = { user_id: this.state.id };
    this.setState({ working: true });
    $.ajax({
      url: "/carts/clear_favorite",
      type: "DELETE",
      dataType: "json",
      data: data,
      success: function (response) {
        self.props.enqueueSnackbar("successfully deleted!", {
          variant: "success",
        });
        self.setState({ working: false });
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("active" in fieldValues) {
      temp.active = fieldValues.active ? "" : "Required";
    }
    if ("hold" in fieldValues) {
      temp.hold = fieldValues.hold ? "" : "Required";
    }
    if ("delivery" in fieldValues) {
      temp.delivery = fieldValues.delivery ? "" : "Required";
    }
    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state.values)
      return Object.values(temp).every((x) => x === "");
  };

  handleSelectChange = (object) => {
    const id = object ? object.value : this.state.id;
    this.setState({ id: id, working: true, maxWidth: "xl" });
    let self = this;
    $.ajax({
      url: "/users/" + id,
      type: "GET",
      dataType: "json",
      success: function (response) {
        self.setState({
          id: id,
          name: response.name,
          cart_id: response.cart_id,
          phone_number: response.phone_number,
          kidus: response.kidus,
          gender: response.gender,
          type: response.type,
          address: response.address,
          delivery: response.delivery,
          reason: response.reason,
          hold: response.hold,
          business_name: response.business_name,
          active: response.active,
          orders: response.orders,
          working: false,
          page_controls: response.page_controls,
        });
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  render() {
    const { classes } = this.props;
    const columns = [
      { title: "Order Number", field: "order_number" },
      { title: "Region", field: "order_region" },
      { title: "Subtotal", field: "sub_total" },
      { title: "Total", field: "total" },
      { title: "Balance", field: "shopper_balance" },
      { title: "Created at", field: "created_at" },
    ];
    const columns1 = [
      {
        title: "Process",
        field: "process",
        sorting: false,
        lookup: { Credit: "Credit", Debit: "Debit" },
      },
      { title: "Unit", field: "unit", sorting: false, filtering: false },
      {
        title: "Category",
        field: "category",
        sorting: false,
        filtering: false,
      },
      { title: "Amount", field: "amount", sorting: false, filtering: false },
      { title: "Balance", field: "balance", sorting: false, filtering: false },
      {
        title: "Time",
        field: "created_sortable",
        // defaultSort: "desc",
        filtering: false,
        render: (rowData) => <span>{rowData.created_at}</span>,
      },
      { title: "Notes", field: "note", sorting: false, filtering: false },
      {
        title: "Description",
        field: "description_short",
        sorting: false,
        filtering: false,
        render: (rowData) => (
          <Tooltip title={rowData.description}>
            <span>{rowData.description_short}</span>
          </Tooltip>
        ),
      },
      {
        title: "Transfer Kidus",
        field: "transfer_kidus_desc",
        sorting: false,
        filtering: false,
        cellStyle: {
          maxWidth: 200,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        render: (rowData) => (
          <Tooltip title={rowData.transfer_kidus_desc}>
            <span>{rowData.transfer_kidus_desc}</span>
          </Tooltip>
        ),
      },
    ];
    const body = (
      <>
        <UpdateUserStatus
          ref={this._updateUserStatusRef}
          id={this.state.id}
          active={this.state.active}
          hold={this.state.hold}
          name={this.state.name}
          callback={this.handleSelectChange}
        />
        <ReviewHistory
          ref={this._reviewHistoryRef}
          id={this.state.id}
          name={this.state.name}
          cart_id={this.state.cart_id}
        />
        <AddExternalItem ref={this._addExternalItemRef} />
        <ShowCart ref={this._showCartRef} />
        <UpdateUserBalance
          ref={this._updateUserBalanceRef}
          id={this.state.id}
          kidus={this.state.kidu}
          name={this.state.name}
          callback={this.handleSelectChange}
        />
        <DiscountCodes ref={this._discountCodesRef} />
        <Payments ref={this._paymentsRef} />
        <Referral ref={this._referralRef} />
        <BackdropReusable open={this.state.working} />
        <UpdateUserBalance
          ref={this._updateUserBalanceRef}
          id={this.state.id}
          kidus={this.state.kidu}
          name={this.state.name}
          callback={this.handleSelectChange}
        />
        {!this.state.id && (
          <AsyncSearchBar
            setCallBack={this.handleSelectChange}
            noOptionsMessage="No user found"
            placeholder=" "
            url="/users/find.json"
          />
        )}
        <Card className={classes.root} hidden={!this.state.id}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <div className="row">
                <div className="col-4">
                  <Typography variant="subtitle1" color="textSecondary">
                    ID: <b>{this.state.id}</b>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Phone Number: <b>{this.state.phone_number}</b>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Kidus: <b>{this.state.kidus}</b>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Type: <b>{this.state.type}</b>
                  </Typography>
                  {this.state.gender && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Gender: <b>{this.state.gender}</b>
                    </Typography>
                  )}
                  {this.state.business_name && (
                    <Typography variant="subtitle1" color="textSecondary">
                      business_name: <b>{this.state.business_name}</b>
                    </Typography>
                  )}
                  <Typography variant="subtitle1" color="textSecondary">
                    Address: <b>{this.state.address}</b>
                  </Typography>
                  {!this.state.active === true && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Active: <b>{this.state.active ? "true" : "false"}</b>
                    </Typography>
                  )}
                  {!this.state.hold === false && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Hold: <b>{this.state.hold ? "true" : "false"}</b>
                    </Typography>
                  )}
                  {this.state.reason && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Deactive/Hold Reason: <b>{this.state.reason}</b>
                    </Typography>
                  )}
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col d-grid gap-2">
                      <button
                        type="button"
                        disabled={!this.state.page_controls?.add_external_item}
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._addExternalItemRef.current.handleOpen(
                            this.state.id,
                            this.state.name
                          )
                        }
                      >
                        Add External Item
                      </button>
                      <button
                        type="button"
                        disabled={!this.state.page_controls?.update_kidus}
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._updateUserBalanceRef.current.handleOpen(
                            this.state.id,
                            this.state.name,
                            this.state.kidus
                          )
                        }
                      >
                        Update Kidus Balance
                      </button>
                    </div>
                    <div className="col d-grid gap-2">
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._updateUserStatusRef.current.handleOpen()
                        }
                      >
                        Update Active/Hold
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._showCartRef.current.handleOpen(
                            this.state.cart_id,
                            this.state.name
                          )
                        }
                      >
                        Show Cart
                      </button>
                    </div>
                    <div className="col d-grid gap-2">
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          window.confirm(
                            `Are you sure you want to clear all items in this favourite?`
                          ) && this.clearFavorite()
                        }
                      >
                        Clear Favorites
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._reviewHistoryRef.current.handleOpen()
                        }
                      >
                        Cart Review History
                      </button>
                    </div>
                    <div className="col d-grid gap-2">
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._discountCodesRef.current.handleOpen(
                            this.state.id,
                            this.state.name
                          )
                        }
                      >
                        Discount Codes
                      </button>
                      <button type="button" className="btn">
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                            htmlFor="home_delivery"
                          >
                            {this.state.delivery ? "Home Delivery" : "Pick Up"}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="home_delivery"
                            name="home_delivery"
                            checked={this.state?.delivery}
                            onClick={this.handleChangeDelivery}
                          />
                        </div>
                      </button>
                    </div>
                    <div className="col d-grid gap-2">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._paymentsRef.current.handleOpen(
                            this.state.id,
                            this.state.name
                          )
                        }
                      >
                        Payments
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() =>
                          this._referralRef.current.handleOpen(
                            this.state.id,
                            this.state.name
                          )
                        }
                      >
                        Referrals
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>

            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className={`accordion-button ${
                      this.state.openAccordion === "one" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => this.toggleAccordion("one")}
                    aria-expanded={this.state.openAccordion === "one"}
                    aria-controls="flush-collapseOne"
                  >
                    Orders
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className={`accordion-collapse collapse ${
                    this.state.openAccordion === "one" ? "show" : ""
                  }`}
                  aria-labelledby="flush-headingOne"
                >
                  <div className="accordion-body">
                    <Table
                      title=""
                      options={{
                        toolbar: true,
                        pageSize: 5,
                        pageSizeOptions: [5],
                        filtering: false,
                        sorting: true,
                        emptyRowsWhenPaging: false,
                      }}
                      columns={columns}
                      tableRef={this._tableRef}
                      actions={[
                        (rowData) => ({
                          icon: () => <pureIcons.ImInfo />,
                          tooltip: <span>Show order details</span>,
                          onClick: () =>
                            this._ShowOrderRef.current.handleOpen(
                              rowData.id,
                              rowData.order_number
                            ),
                          position: "row",
                        }),
                      ]}
                      data={(query) =>
                        new Promise((resolve, reject) => {
                          var filters;
                          if (query.filters.length > 0) {
                            filters = query.filters.map((col) => ({
                              field: col.column.field,
                              value: col.value,
                            }));
                          }
                          let url = `/users/${this.state.id}/orders.json?&`;
                          url += "per_page=" + query.pageSize;
                          url += "&page=" + (query.page + 1);
                          if (query.filters.length > 0) {
                            url += "&filters=" + JSON.stringify(filters);
                          }
                          if (query.orderBy) {
                            url += "&orderBy=" + query.orderBy.field;
                            url += "&orderDirection=" + query.orderDirection;
                          }
                          if (query.search) {
                            url += "&search=" + query.search;
                          }
                          fetch(url)
                            .then((response) => response.json())
                            .then((result) => {
                              resolve({
                                data: result.orders,
                                page: result.page - 1,
                                totalCount: result.total,
                              });
                            });
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className={`accordion-button ${
                      this.state.openAccordion === "two" ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => this.toggleAccordion("two")}
                    aria-expanded={this.state.openAccordion === "two"}
                    aria-controls="flush-collapseTwo"
                  >
                    Transactions
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className={`accordion-collapse collapse ${
                    this.state.openAccordion === "two" ? "show" : ""
                  }`}
                  aria-labelledby="flush-headingTwo"
                >
                  <div className="accordion-body">
                    {/* {!this.state.loading ? ( */}
                    <Table
                      title="Transactions"
                      options={{
                        toolbar: false,
                      }}
                      columns={columns1}
                      tableRef={this._table2Ref}
                      data={this.state.transactionData}
                    />
                    {/* ) : (
                      <Backdrop
                        open={this.state.loading}
                        style={{ zIndex: 0 }}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </>
    );

    const actions = (
      <Button
        variant="contained"
        hidden={!this.state.id}
        type="submit"
        onClick={this.resetFrom}
      >
        Clear
      </Button>
    );

    return (
      <>
        <ShowOrder ref={this._ShowOrderRef} />
        <Modal
          isOpen={this.state.open}
          title={
            this.state.id
              ? this.state.name + ` (${this.state.phone_number})`
              : "Search by phone number"
          }
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="90%"
          maxWidth={this.state.maxWidth}
          actions={actions}
        >
          <div style={{ paddingTop: "2px" }}>{body}</div>
        </Modal>
      </>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 20,
    margin: 20,
    wordBreak: "break-all",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
});

export default withSnackbar(withStyles(useStyles)(ShowUser));
