import React, { useEffect, useState } from 'react';
import { Card, TextField, InputLabel, Select, MenuItem, FormControl, makeStyles, IconButton } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const PrintExpenseSheet = ({
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    region,
    setRegion,
    dropdownData

}) => {

    return (
        <Card >
            <div className="container pb-5">
                <div className="row m-2 ">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker

                                disableFuture={false}
                                disablePast={false}
                                label="From date"
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                                animateYearScrolling
                                format="dd/MM/yyyy"
                                autoOk={true}
                                variant='inline'
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableFuture={false}
                                disablePast={false}
                                label="To Date"
                                value={toDate}
                                onChange={(date) => setToDate(date)}
                                animateYearScrolling
                                format="dd/MM/yyyy"
                                autoOk={true}
                                variant='inline'
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    {/* <div className="col">
                        <FormControl style={{ marginRight: "16px", width: "100px" }}>
                            <InputLabel id="region-label">Region</InputLabel>
                            <Select
                                fullWidth
                                labelId="region-label"
                                value={region}
                                onChange={(e: any) => { setRegion(e.target.value) }}
                            >
                                {dropdownData.map((option: any) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div> */}
                </div>
            </div>
        </Card>
    );
};

export default PrintExpenseSheet;