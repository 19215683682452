import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	Card,
	IconButton,
	CardContent,
	TextField,
	InputAdornment,
	Tooltip,
	Select,
	MenuItem,
	Checkbox,
	FormGroup,
	FormControlLabel
} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack';
import Backdrop from "../shared/backdrop";
import InputMask from "react-input-mask";
import DatePicker from "../shared/date_picker";
import PureIcons from "../shared/pure_icons";
import EditCustomizationPage from './_edit_customizations_page';
import $ from "jquery";



class EditProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			errors: {},
			open: false,
			showCustomizationForm: false,
			productCategories: [],
			wholesale: {
				active: false,
				customers: false
			},
			serverError: '',
			values: this.initialValues()
		}
		this.fileInputRef = React.createRef();
	};

	initialValues = () => {
		return {
			business_profile_id: this.props.business_profile_id,
			name_en: '',
			name_ar: '',
			description_en: '',
			description_ar: '',
			images: [],
			pureCategoryAndSubcategory: {
				category: '',
				subcategory: ''
			},
			customizations: [],
			wholesale: {
				active: false,
				customers: false
			}
		}
	}

	handleOpen = () => {
		this.setState({
			open: true,
			values: this.props.data ? this.state.values : this.initialValues()
		})
	}

	toggleCustomizationForm = () => {
		this.setState((prevState) => ({
			showCustomizationForm: !prevState.showCustomizationForm,
		}));
	};

	handleClose = () => {
		this.setState((prevState) => ({
			open: false,
			showCustomizationForm: false,
			values: {...prevState.values},
		}));
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (!this.validate()) return;
		const { values } = this.state;

		let data = new FormData();
		this.setState((prevState) => ({
			...prevState,
			working: true,
			showCustomizationForm: false
		}));

		Object.keys(values).forEach((fieldName) => {
			if (fieldName !== 'customizations' && fieldName !== 'images' && fieldName !== 'pureCategoryAndSubcategory' && fieldName !== 'wholesale') {
				data.append(fieldName, values[fieldName]);
			}
		});

		data.append('customizations', JSON.stringify(values.customizations));
		data.append('pureCategoryAndSubcategory', JSON.stringify(values.pureCategoryAndSubcategory));
		data.append('wholesale', JSON.stringify(values.wholesale));

		values.customizations.forEach((customization, customizationIndex) => {
			customization.images.forEach((imageFile, imageIndex) => {
				data.append(`__customizations_images[${customizationIndex}][images][${imageIndex}]`, imageFile);
			});
		})

		values.customizations.map((customization) => {
			customization.images.forEach((file) => {
			data.append('images[]', file);
			});
		})

		$.ajax({
			url: `/products/${this.props.data.remote_product_id}`,
			type: 'PUT',
			data: data,
			dataType: 'json',
			processData: false,
			contentType: false,
			success: (response) => {
				this.setState({
					working: false, open: false, values: {
						...this.state.values,
						images: [] // Set images to an empty array
					},
				});
				this.props.handleSelectChange();
				this.props.enqueueSnackbar('Successfully Updated!', { variant: 'success' });
			},
			error: (response) => {
				this.setState({ working: false });
				this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
			},
		});
	};

	handleCustTranslateParagraph = (Paragraph, index, type) => {
		const requestData = {
			value: Paragraph,
			translate_from: 'EN',
			translate_to: 'AR',
		};

		const { values } = this.state;

		if (type === "description") {
			$.ajax({
				url: '/products/translate',
				type: 'POST',
				data: requestData,
				dataType: 'json',
				success: (response) => {
					// Handle the response
					const translatedDescription = response.translated;
					this.setState((prevState) => ({
					values: {
						...prevState.values,
						customizations: prevState.values.customizations.map((customization, i) => {
						if (i === index) {
							return {
							...customization,
							description_ar: translatedDescription,
							};
						}
						return customization;
						}),
					},
					}));
				},
				error: (response) => {
					// Handle the error
					this.setState({ working: false });
					this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
				},
			});
		} else if (type === "name") {
			$.ajax({
				url: '/products/translate',
				type: 'POST',
				data: requestData,
				dataType: 'json',
				success: (response) => {
					// Handle the response
					const translatedDescription = response.translated;
					this.setState((prevState) => ({
					values: {
						...prevState.values,
						customizations: prevState.values.customizations.map((customization, i) => {
						if (i === index) {
							return {
							...customization,
							name_ar: translatedDescription,
							};
						}
						return customization;
						}),
					},
					}));
				},
				error: (response) => {
					// Handle the error
					this.setState({ working: false });
					this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
				},
			});
		}

	};

	handleTranslateParagraph = (Paragraph, type) => {
		const requestData = {
			value: Paragraph,
			translate_from: 'EN',
			translate_to: 'AR',
		};

		const { values } = this.state;

		if (type === "description") {
			$.ajax({
				url: '/products/translate',
				type: 'POST',
				data: requestData,
				dataType: 'json',
				success: (response) => {
					// Handle the response
					const translatedDescription = response.translated;

					this.setState((prevState) => ({
						values: {
							...prevState.values,
							description_ar: translatedDescription,
						},
						// working: false // TODO: make the action loading while clicking
					}));
				},
				error: (response) => {
					// Handle the error
					this.setState({ working: false });
					this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
				},
			});
		} else if (type === "name") {
			$.ajax({
				url: '/products/translate',
				type: 'POST',
				data: requestData,
				dataType: 'json',
				success: (response) => {
					// Handle the response
					const translatedDescription = response.translated;

					this.setState((prevState) => ({
						values: {
							...prevState.values,
							name_ar: translatedDescription,
						},
						// working: false // TODO: make the action loading while clicking
					}));
				},
				error: (response) => {
					// Handle the error
					this.setState({ working: false });
					this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
				},
			});
		}
	};


	handleGenerateDescription = (Paragraph) => {
		const requestData = {
			name: Paragraph
		};
		$.ajax({
			url: '/products/generate_description',
			type: 'POST',
			data: requestData,
			dataType: 'json',
			success: (response) => {
				// Handle the response
				const generatedDescription = response.description;
				this.setState((prevState) => ({
					values: {
						...prevState.values,
						description_en: generatedDescription,
					},
					// working: false // TODO: make the action loading while clicking

				}));
			},
			error: (response) => {
				// Handle the error
				this.setState({ working: false });
				this.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	};


	handleInputNumberChange = e => {
		const { name, value } = e.target;
		if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
			this.setState({ values: { ...this.state.values, [name]: value } });
		}
		this.validate({ [name]: value });
	}

	handleImageChange = (index, event) => {
		const files = Array.from(event.target.files); // Get the selected files
		if (files.length > 0) {
		  this.setState((prevState) => {
			const customizations = prevState.values.customizations.map((customization, i) => {
			  if (i === index) {
				const existingImageNames = customization.images.map(image => image.name); // Get existing image names
				const newFiles = files.filter(file => !existingImageNames.includes(file.name)); // Filter out files with existing names
				const newImageURLs = newFiles.map(file => URL.createObjectURL(file)); // Create preview URLs for the selected images
				return {
				  ...customization,
				  images: [...customization.images, ...newFiles], // Append new selected files to the existing images
				  showImage: [...customization.showImage, ...newImageURLs], // Append new image preview URLs to the existing ones
				};
			  }
			  return customization;
			});
			return {
			  values: {
				...prevState.values,
				customizations: customizations,
			  },
			};
		  });
		}
	};

	handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === 'category' || name === 'subcategory') {
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					pureCategoryAndSubcategory: {
						...prevState.values.pureCategoryAndSubcategory,
						[name]: value,
					},
				},
			}));
		} else {
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					[name]: value,
				},
			}));
		}
	};

	componentDidUpdate(prevProps) {
		if(this.props.data !== prevProps.data){
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					name_en: this.props.data.name_en,
					name_ar: this.props.data.name_ar,
					description_en: this.props.data.description_en,
					description_ar: this.props.data.description_ar,
					pureCategoryAndSubcategory: this.props.data.pureCategoryAndSubcategory,
					wholesale: {
						active: this.props.data.wholesale.active,
						customers: this.props.data.wholesale.customers
					},
					customizations: this.props.data.customizations.map((customization) => {
						return {
						...customization,
						images: customization.images,
						showImage: customization.images.map((image)=>{
							return image.src;
						}),
						};
					  }),
				},
			}));
			if(this.state.open === true && this.state.productCategories.length === 0) {
				this.callAPI();
			}
		}
	}

	callAPI = async () => {
		try {
			const response = await fetch('products/product_categories.json');
			const data = await response.json();
			this.setState({ productCategories: data.product_categories });
		} catch (error) {
			console.error('Error fetching product categories:', error);
		}
	}
	
	handleDeleteImage = (index, customizationIndex) => {
		const { values } = this.state;
		const newCustomizations = [...values.customizations];
		const customization = { ...newCustomizations[customizationIndex] };
		const newImages = [...customization.images];
		newImages.splice(index, 1); // Remove the selected image from the images array
		const newShowImage = [...customization.showImage];
		newShowImage.splice(index, 1); // Remove the corresponding showImage value
		customization.images = newImages;
		customization.showImage = newShowImage;
		newCustomizations[customizationIndex] = customization;
		
		this.setState({
		  values: {
			...values,
			customizations: newCustomizations,
		  },
		});

		this.fileInputRef.current.value = '';
	};

	validate = (fieldValues = this.state.values) => {
		let temp = { ...this.state.errors };
		temp.serverErrors = '';
		if ('name_en' in fieldValues) {
			temp.name_en = fieldValues.name_en ? '' : 'Required';
		}
		if ('name_ar' in fieldValues) {
			temp.name_ar = fieldValues.name_ar ? '' : 'Required';
		}
		if ('description_en' in fieldValues) {
			temp.description_en = fieldValues.description_en ? '' : 'Required';
		}
		if ('description_ar' in fieldValues) {
			temp.description_ar = fieldValues.description_ar ? '' : 'Required';
		}
		this.setState({ errors: { ...temp } })
		if (fieldValues === this.state.values)
			return Object.values(temp).every(x => x === '')
	};

	addCustomization = () => {
		this.setState((prevState) => ({
			values: {
				...prevState.values,
				customizations: [
					...prevState.values.customizations,
					{
						name_en: "",
						name_ar: "",
						description_en: "",
						description_ar: "",
						price: "",
						images: [],
						showImage: [],
						weight: "",
						dimensions: "",
						shipping: "",
						maxQuantity: "",
						weightFLOZ: "",
						eta: "",
						varaiations: [], // Initialize varaiations as an empty array
					},
				],
			},
		}));
	};

	removeCustomizationFields = (index) => {
		let newCustomizationValues = [...this.state.values.customizations];
		newCustomizationValues.splice(index, 1);
		this.setState({ values: { ...this.state.values, customizations: newCustomizationValues }, errors: {} });
		this.validate({ customizations: newCustomizationValues });
	};

	handleVaraiationChange = (index, event) => {
		const { name, value } = event.target;
		// Changing customization varaiation fields
		if (name === 'color'){
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					customizations: prevState.values.customizations.map(
					(customization, i) => {
						if (i === index) {
						return {
							...customization,
							varaiations: [
							{
								name: 'color',
								option: value,
							},
							customization.varaiations[1],
							],
						};
						}
						return customization;
					}
					),
				},
			}));
		} else if (name === 'size') {
			this.setState((prevState) => ({
				values: {
				  ...prevState.values,
				  customizations: prevState.values.customizations.map(
					(customization, i) => {
					  if (i === index) {
						return {
						  ...customization,
						  varaiations: [
							customization.varaiations[0],
							{
							  name: 'size',
							  option: value,
							},
						  ],
						};
					  }
					  return customization;
					}
				  ),
				},
			}));
		}
	};

	handleCustomizationsInputChange = (index, event) => {
		const { name, value } = event.target;

		if (index === -1) {
			// Changing product fields
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					customizations: {
						...prevState.values.customizations,
						[name]: value,
					},
				},
			}));
		} else {
			// Changing customization fields
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					customizations: prevState.values.customizations.map((customization, i) => {
						if (i === index) {
							return {
								...customization,
								[name]: value,
							};
						}
						return customization;
					}),
				},
			}));
		}
	};

	updateCustomizations = (newCustomizations) => {
		this.setState((prevState) => ({
			values: {
				...prevState.values,
				customizations: newCustomizations,
			},
		}));
	};

	handleCheckBoxChange = (event) => {
		const { name, checked } = event.target;

		if(name === 'customers') {
			if (checked){
				this.setState((prevState) => ({
					values: {
						...prevState.values,
						wholesale: {active: event.target.checked, customers: event.target.checked},
					},
				}));
			} else {
				this.setState((prevState) => ({
					values: {
						...prevState.values,
						wholesale: {active: true, customers: event.target.checked},
					},
				}));
			}
		} else {
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					wholesale: {active: event.target.checked, customers: false},
				},
			}));
		};
	};

	render() {
		const { classes } = this.props;
		const { values, showCustomizationForm, productCategories } = this.state;

		const imageStyle = {
			width: '150px',
			height: '150px',
			objectFit: 'scale-down',
		};


		const body =
			<>
				<Backdrop open={this.state.working} />
				{this.state.errors.serverErrors &&
					<div className="alert alert-danger p-1 text-center" role="alert">
						{this.state.errors.serverErrors}
					</div>
				}

				<Card className={classes.root}>
					<CardContent className={classes.content}>
						<div className="row pb-2">
							<div className="col">
								<TextField
									name="name_en"
									fullWidth
									label="Name (English)"
									value={this.state.values.name_en}
									onChange={this.handleInputChange}
									{...(this.state.errors.name_en && {
										error: true,
										helperText: this.state.errors.name_en,
									})}
								/>
							</div>
							<div className="col">
								<TextField
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Tooltip title="Translate Name">
													<IconButton
														onClick={() =>
															this.handleTranslateParagraph(
																this.state.values.name_en,
																"name"
															)
														}
														style={{ color: 'black', boxShadow: 'none' }} // Remove hover shadow
													>
														<PureIcons.HiTranslate />

													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
									name="name_ar"
									fullWidth
									label="Name (Arabic)"
									value={this.state.values.name_ar}
									onChange={this.handleInputChange}
									{...(this.state.errors.name_ar && {
										error: true,
										helperText: this.state.errors.name_ar,
									})}
								/>
							</div>
						</div>



						<div className="row pb-3 pt-3">
							<div className="col">
								<TextField
									name="description_en"
									fullWidth
									multiline
									placeholder="Enter English Description"
									value={this.state.values.description_en}
									onChange={this.handleInputChange}
									{...(this.state.errors.description_en && {
										error: true,
										helperText: this.state.errors.description_en,
									})}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Tooltip title="Generate Description">
													<IconButton
														onClick={() =>
															this.handleGenerateDescription(this.state.values.name_en)
														}
														style={{ color: 'black', boxShadow: 'none' }} // Remove hover shadow
													>
														<PureIcons.BsFillChatTextFill />

													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</div>

							<div className="col">
								<TextField
									name="description_ar"
									fullWidth
									multiline
									placeholder="Enter Arabic Description"
									value={this.state.values.description_ar}
									onChange={this.handleInputChange}
									{...(this.state.errors.description_ar && {
										error: true,
										helperText: this.state.errors.description_ar,
									})}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Tooltip title="Translate Description">
													<IconButton
														onClick={() =>
															this.handleTranslateParagraph(
																this.state.values.description_en,
																"description"
															)
														}
														style={{ color: 'black', boxShadow: 'none' }} // Remove hover shadow
													>
														<PureIcons.HiTranslate />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>

						<div className="row pb-2">
							<div className="col">
								<Select
									name="category"
									value={this.state.values.pureCategoryAndSubcategory.category}
									onChange={this.handleInputChange}
									fullWidth
									// label="Category"
									displayEmpty
									{...(this.state.errors.category && {
										error: true,
										helperText: this.state.errors.category,
									})}
								>
									<MenuItem value="" disabled>
										Category
									</MenuItem>
									{productCategories.map((category) => (
										<MenuItem key={category.category} value={category.category}>
											{category.category}
										</MenuItem>
									))}
								</Select>
							</div>

							<div className="col">
								<Select
									name="subcategory"
									value={this.state.values.pureCategoryAndSubcategory.subcategory}
									onChange={this.handleInputChange}
									fullWidth
									// label="Subcategory"
									displayEmpty
									{...(this.state.errors.subcategory && {
										error: true,
										helperText: this.state.errors.subcategory,
									})}
								>
									<MenuItem value="" disabled>
										Subcategory
									</MenuItem>
									{productCategories
										.find((item) => item.category === values.pureCategoryAndSubcategory.category)
										?.subcategories.map((subcategory) => (
											<MenuItem key={subcategory} value={subcategory}>
												{subcategory}
											</MenuItem>
										))}
								</Select>
							</div>
						</div>

						<div className="row pb-2">
							<div className="col" style={{marginTop: "10px"}}>
								<div style={{ width: "100%"}}>
			            Wholesale Available For
			          </div>
								<FormGroup>
									<div style={{ display: "flex"}}>
										<div>
										  <FormControlLabel control={<Checkbox name="active" checked={this.state.values.wholesale.active} onChange={this.handleCheckBoxChange}/>} label="Business"/>
										</div>
										<div>
										  <FormControlLabel control={<Checkbox name="customers" checked={this.state.values.wholesale.customers} onChange={this.handleCheckBoxChange}/>} label="Customer"/>
										</div>
									</div>
								</FormGroup>	
							</div>
						</div>

					</CardContent>
				</Card>

			</>

		const actions = (
			<>
				<Button
					type='button'
					variant='outlined'
					color='primary'
					onClick={this.toggleCustomizationForm}
					hidden={!values.business_profile_id}
				>
					{showCustomizationForm ? 'Hide Customizations' : 'Edit Customizations'}
				</Button>
				<div style={{ flex: '1 0 0' }} />
				<Button
					variant='contained'
					type='submit'
					style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
					disabled={this.state.working}
					onClick={this.handleSubmit}
				>
					Save
				</Button>
			</>
		);

		return (
			<Modal
				isOpen={this.state.open}
				title={`Edit product`}
				handleClose={this.handleClose}
				minHeight='40vh'
				maxHeight='80%'
				maxWidth='lg'
				actions={actions}
			>
				{showCustomizationForm ? (
					<EditCustomizationPage
						customizations={values.customizations}
						addCustomization={this.addCustomization}
						handleCustomizationsInputChange={this.handleCustomizationsInputChange}
						removeCustomizationFields={this.removeCustomizationFields}
						handleCustTranslateParagraph={this.handleCustTranslateParagraph}
						onCustomizationsUpdate={this.updateCustomizations}
						handleImageChange={this.handleImageChange}
						handleInputChange={this.handleInputChange}
						fileInputRef={this.fileInputRef}
						handleDeleteImage={this.handleDeleteImage}
						classes={classes}
						handleVaraiationChange={this.handleVaraiationChange}
						productType={this.props.data.product_type}
					/>
				) : (
					body
				)}
			</Modal>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		padding: 20,
		margin: 20,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},

	imageContainer: {
		marginTop: theme.spacing(2),
		display: 'flex', // Use flexbox layout
		flexWrap: 'wrap', // Wrap to the next line when images reach the end of the container
		gap: theme.spacing(2), // Add some gap between the images
	},
	imageItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative', // Add a relative position for the container
		flex: '0 0 150px', // Set the width of each image item to 150px (adjust as needed)
	},
	deleteButton: {
		position: 'absolute',
		bottom: '8px', // Adjust the position of the delete button
		left: '50%', // Center the button horizontally within the container
		transform: 'translateX(-50%)', // Center the button horizontally within the container
		backgroundColor: '#f44336',
		color: 'white',
		padding: theme.spacing(1),
		borderRadius: '50%',
	},

});

export default withSnackbar(withStyles(useStyles)(EditProduct))
