import React, { useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import { Badge, MenuItem, Select } from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { discountCodesIndex } from "../standard_categories/dummyData";

const Index = () => {
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);

  const _tableRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  //   const reloadData = (fullReload = false) => {
  //     if (fullReload) setMuiTableKey(muiTableKey + 1);
  //     _tableRef.current.onQueryChange();
  //   };

  //   const handleReviewStatus = (data: any) => {
  //     $.ajax({
  //       url: `/stages/${data.order_number}/new_stage_review`,
  //       type: "PUT",
  //       data: { invoice: data.invoice, stage: "purchased" },
  //       dataType: "json",
  //       success: function (response) {
  //         enqueueSnackbar("successfully updated!", {
  //           variant: "success",
  //         });
  //         reloadData();
  //       },
  //       error: function (response) {
  //         enqueueSnackbar(response.responseJSON.error, {
  //           variant: "error",
  //         });
  //         setWorking(false);
  //       },
  //     });
  //   };

  //   const handleUpdate = (
  //     oldValue: any,
  //     newValue: any,
  //     columnDef: any,
  //     order_number: any,
  //     source: any
  //   ) => {
  //     if (oldValue === newValue || newValue === "" || newValue === null) return;

  //     let url, data;
  //     switch (columnDef.field) {
  //       case "invoice":
  //         url = `/order_item_stages/update_stage_tracking.json?source=${source}`;
  //         data = {
  //           tracking: oldValue,
  //           new_tracking: newValue,
  //           stage: "purchased",
  //         };
  //         break;
  //       case "eta":
  //         url = `/order_item_stages/update_stage.json?source=${source}`;
  //         data = { tracking: oldValue, eta: newValue, stage: "purchased" };
  //         break;
  //       case "total":
  //         url = `/order_item_stages/update_invoice_total.json?source=${source}`;
  //         data = {
  //           tracking: oldValue,
  //           total: newValue,
  //           order_number: order_number,
  //         };
  //         break;
  //       default:
  //     }

  //     setWorking(true);
  //     $.ajax({
  //       url: url,
  //       type: "PATCH",
  //       data: data,
  //       dataType: "json",
  //       success: function (response) {
  //         setWorking(false);
  //         reloadData();
  //         enqueueSnackbar("successfully processed!", {
  //           variant: "success",
  //         });
  //       },
  //       error: function (response) {
  //         setWorking(false);
  //         enqueueSnackbar(response.responseJSON.error, {
  //           variant: "error",
  //         });
  //       },
  //     });
  //   };

  //   const handleReturnToPreviousStage = (rowData) => {
  //     setWorking(true);
  //     $.ajax({
  //       url: `/order_item_stages/return_to_previous_stage`,
  //       type: "POST",
  //       data: {
  //         invoice: rowData.raw_invoice,
  //         source: rowData.source,
  //         order_number: rowData.order_number,
  //         vendor: rowData.vendor,
  //       },
  //       dataType: "json",
  //       success: function (response) {
  //         setWorking(false);
  //         reloadData();
  //         enqueueSnackbar("successfully processed!", {
  //           variant: "success",
  //         });
  //       },
  //       error: function (response) {
  //         setWorking(false);
  //         enqueueSnackbar(response.responseJSON.error, {
  //           variant: "error",
  //         });
  //       },
  //     });
  //   };

  const columns = [
    {
      title: "Discount code",
      field: "discount_code",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Discount value",
      field: "discount_value",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Discounts",
      field: "discounts",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Order number(s)",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Order group number",
      field: "order_group_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Cart review coupon?",
      field: "cart_review_coupon",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Created at",
      field: "created_at",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Updated at",
      field: "updated_at",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];
  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      <Table
        title={"Shopper discounts code index for Peso Alani Test"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category discounts" },
        }}
        columns={columns}
        tableRef={_tableRef}
        // cellEditable={{
        //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
        //     return new Promise((resolve: any, reject) => {
        //       setTimeout(() => {
        //         if (
        //           columnDef.field === "invoice_total" &&
        //           (rowData.subtotal + rowData.subtotal * 0.025 < newValue ||
        //             rowData.subtotal - rowData.subtotal * 0.1 > newValue)
        //         )
        //           window.confirm(
        //             `Invoice is different than subtotal (${rowData.subtotal.toFixed(
        //               2
        //             )})`
        //           ) &&
        //             handleUpdate(
        //               rowData.raw_invoice,
        //               newValue,
        //               columnDef,
        //               rowData.order_number,
        //               rowData.source
        //             );
        //         else
        //           handleUpdate(
        //             rowData.raw_invoice,
        //             newValue,
        //             columnDef,
        //             rowData.order_number,
        //             rowData.source
        //           );
        //         resolve();
        //       }, 500);
        //     });
        //   },
        // }}
        // actions={[
        //   {
        //     icon: () => <pureIcons.Traffic />,
        //     onClick: () => {
        //       _AutoControlPanelRef.current.handleOpen();
        //     },
        //     isFreeAction: true,
        //     tooltip: "Auto control",
        //   },
        //   {
        //     icon: () => <pureIcons.FiRefreshCw />,
        //     onClick: () => {
        //       reloadData();
        //     },
        //     isFreeAction: true,
        //     tooltip: "Refresh",
        //   },
        //   (rowData) => ({
        //     icon: () => <pureIcons.FaShippingFast />,
        //     tooltip: <span>Ship {rowData.vendor}</span>,
        //     onClick: (event, rowData) => {
        //       _ShippingForm.current.handleOpen(
        //         rowData.raw_invoice,
        //         rowData.order_number,
        //         rowData.vendor,
        //         rowData.id,
        //         rowData.source,
        //         rowData.group_id,
        //         rowData.market_source,
        //         rowData.new_customer
        //       );
        //     },
        //     position: "row",
        //   }),

        //   (rowData) => ({
        //     icon: () => <pureIcons.MdPreview />,
        //     tooltip:
        //       rowData.is_reviewed === true ? (
        //         <span>Reviewed By: {rowData.reviewer}</span>
        //       ) : (
        //         <span>Mark as Reviewed</span>
        //       ),
        //     onClick: (event, rowData) => {
        //       window.confirm(
        //         `Are you sure you want to change status to be { REVIEWED } ?`
        //       ) && handleReviewStatus(rowData);
        //     },
        //     position: "row",
        //     disabled: rowData.is_reviewed === true,
        //   }),
        //   {
        //     icon: () => <AssignmentReturnIcon />,
        //     tooltip: "Return to previous stage",
        //     onClick: (event, rowData) => {
        //       window.confirm(
        //         `Are you sure you want to return ${rowData.invoice} to previous stage?`
        //       ) && handleReturnToPreviousStage(rowData);
        //     },
        //     position: "row",
        //   },
        // ]}
        // data={(query) =>
        //   new Promise((resolve, reject) => {
        //     let url = `/parts_categories`;
        //     fetch(url)
        //       .then((response) => response.json())
        //       .then((result) => {
        //         resolve({
        //           data: result.invoices,
        //           page: result.page - 1,
        //           totalCount: result.total,
        //         });
        //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
        //       });
        //   })
        // }
        data={discountCodesIndex}
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.DiscountIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              //   _AutoControlPanelRef.current.handleOpen();
            },
            tooltip: "Add new discount",
            isFreeAction: true,
          },
          //   {
          //     icon: () => <pureIcons.Edit />,
          //     onClick: () => {
          //       //   _AutoControlPanelRef.current.handleOpen();
          //     },
          //     tooltip: "Edit",
          //   },
        ]}
      />
    </div>
  );
};

export default Index;
