import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import Modal from "../shared/modal/material_dialog";

const EditCategory = (
  { data, open, setOpen, operation, callback }: any,
  ref: any
) => {
  const { enqueueSnackbar } = useSnackbar();

  const id = data.current?.id || "";
  const name_en = data.current?.name_en || "";
  const name_ar = data.current?.name_ar || "";
  const name_ku = data.current?.name_ku || "";
  const discount_rate = data.current?.discount_rate || "";
  const prohibited = data.current?.prohibited || false;
  const priority = data.current?.priority || "";
  const age_sensitive = data.current?.age_sensitive || false;
  const tax_fee = data.current?.tax_fee || "";
  const weight = data.current?.weight || "";

  const handleClose = () => {
    data.current = null;
    setOpen(false);
  };

  const [formSMSValue, setFormSMSValue] = React.useState<any>({
    name_ar: "",
    name_en: "",
    name_ku: "",
  });
  const [arTextEr, setArTextEr] = React.useState<any>("");
  const [enTextEr, setEnTextEr] = React.useState<any>("");
  const [kuTextEr, setKuTextEr] = React.useState<any>("");

  const [weights, setWeight] = useState<string>("");
  const [discount_rates, setDiscountRate] = useState<string>("");
  const [prioritys, setPriority] = useState<string>("");
  const [prohibiteds, setProhibited] = useState<boolean>(false);
  const [age_sensitives, setAgeSensitive] = useState<boolean>(false);
  const [tax_rates, setTaxRate] = useState<string>("");

  const onchangeFormValue = (event) => {
    setFormSMSValue({
      ...formSMSValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (open) {
      setFormSMSValue({
        name_ar: name_ar,
        name_en: name_en,
        name_ku: name_ku,
      });
      setWeight(weight);
      setDiscountRate(discount_rate);
      setPriority(priority);
      setProhibited(prohibited);
      setAgeSensitive(age_sensitive);
      setTaxRate(tax_fee !== "" ? (Number(tax_fee) / 100).toString() : "");
    }
  }, [open]);

  // useEffect(() => {
  //   let url = `/parts_categories/${id}.json?locale=en`;
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //       window.breadcrumb.addBreadcrumb(result.breadcrumb);
  //     });
  // }, []);

  const validate = () => {
    if (formSMSValue.name_ar) {
      setArTextEr("");
    } else {
      setArTextEr("Required arabic name");
    }
    if (formSMSValue.name_en) {
      setEnTextEr("");
    } else {
      setEnTextEr("Required english name");
    }
    if (formSMSValue.name_ku) {
      setKuTextEr("");
    } else {
      setKuTextEr("Required kurdish name");
    }
  };

  const addClick = () => {
    validate();
    if (formSMSValue.name_ar && formSMSValue.name_en && formSMSValue.name_ku) {
      $.ajax({
        url: `/parts_categories?locale=en`,
        type: "POST",
        data: {
          locale: "en",
          parts_category: {
            name_en: formSMSValue.name_en,
            name_ar: formSMSValue.name_ar,
            name_ku: formSMSValue.name_ku,
            weight: weights,
            priority: prioritys,
            discount_rate: discount_rates,
            tax_fee: tax_rates,
            prohibited: prohibiteds,
            age_sensitive: age_sensitives,
          },
        },
        dataType: "json",
        success: function (response: any) {
          handleClose();
          enqueueSnackbar("successfully added!", {
            variant: "success",
          });
          callback();
        },
        error: function (response) {
          console.log(response);
          enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
        },
      });
    }
  };

  const editClick = () => {
    validate();

    if (formSMSValue.name_ar && formSMSValue.name_en && formSMSValue.name_ku) {
      $.ajax({
        url: `/parts_categories/${id}.json?locale=en`,
        type: "PUT",
        data: {
          id: id,
          locale: "en",
          parts_category: {
            name_en: formSMSValue.name_en,
            name_ar: formSMSValue.name_ar,
            name_ku: formSMSValue.name_ku,
            weight: weights,
            priority: prioritys,
            discount_rate: discount_rates,
            tax_fee: tax_rates,
            prohibited: prohibiteds,
            age_sensitive: age_sensitives,
          },
        },
        dataType: "json",
        success: function (response: any) {
          console.log(response);
          if (response.success) {
            enqueueSnackbar(response.response, {
              variant: "success",
            });

            callback();
            handleClose();
          } else {
            enqueueSnackbar(response.error, {
              variant: "error",
            });
            handleClose();
          }
        },
        error: function (response) {
          console.log(response);
          enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
        },
      });
    }
  };

  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };

  return (
    <Modal
      title={`${
        operation.current === "Add"
          ? "New Standard Category"
          : "Update Standard Category"
      }`}
      handleClose={() => handleClose()}
      disableBackdropClick={false}
      isOpen={open}
      minHeight="30%"
      minWidth="60%"
      maxHeight="85%"
      fullWidth={false}
      actions={""}
    >
      <Card>
        <CardContent>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Name en</Typography>

            <TextField
              name="name_en"
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              value={formSMSValue.name_en}
              onChange={onchangeFormValue}
              placeholder="Name en"
              helperText={helperText(enTextEr)}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Name ar</Typography>

            <TextField
              name="name_ar"
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Name ar"
              value={formSMSValue.name_ar}
              onChange={onchangeFormValue}
              helperText={helperText(arTextEr)}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Name ku</Typography>

            <TextField
              name="name_ku"
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Name ku"
              value={formSMSValue.name_ku}
              onChange={onchangeFormValue}
              helperText={helperText(kuTextEr)}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Weight</Typography>

            <TextField
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Weight"
              value={weights}
              onChange={(ev) => setWeight(ev.target.value)}
              type="number"
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Priority</Typography>

            <TextField
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Priority"
              value={prioritys}
              onChange={(ev) => setPriority(ev.target.value)}
              type="number"
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Discount Rate</Typography>

            <TextField
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Discount Rate"
              value={discount_rates}
              onChange={(ev) => setDiscountRate(ev.target.value)}
              type="number"
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Tax Free Rate</Typography>

            <TextField
              style={{
                minWidth: "30%",
                marginRight: "42%",
                marginLeft: "auto",
              }}
              size="small"
              variant="outlined"
              placeholder="Tax Free Rate"
              value={tax_rates}
              onChange={(ev) => setTaxRate(ev.target.value)}
              type="number"
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Prohibited</Typography>

            <Checkbox
              style={{ marginRight: "68%", marginLeft: "auto" }}
              placeholder="Prohibited"
              checked={prohibiteds}
              onChange={(ev) => setProhibited(ev.target.checked)}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Age Sensitivity</Typography>

            <Checkbox
              style={{ marginRight: "68%", marginLeft: "auto" }}
              placeholder="Age Sensitivity"
              checked={age_sensitives}
              onChange={(ev) => setAgeSensitive(ev.target.checked)}
            />
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          <CardActions style={{ justifyContent: "end" }}>
            <Button
              type="button"
              style={{
                backgroundColor: "#ec9a53",
                color: "#ebeef0",
                textTransform: "none",
              }}
              onClick={() => {
                if (operation.current === "Add") {
                  addClick();
                } else if (operation.current === "Edit") {
                  editClick();
                }
              }}
            >
              Submit
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EditCategory;
