import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, TextField, CardContent, Grid, Typography} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack';
import Backdrop from "../shared/backdrop";
import Select from '../shared/autocomplete';
import $ from "jquery";
class UpdateCredential extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      page: 1,
      errors: {},
      values: this.initialValues()

    }
  };
  initialValues = () => {
		return {id: '', value: '', value_type: '', key: '', updated_at: '', current: ''}
	}
  handleOpen = () => {
    this.setState({
        open: true,
        errors: [],
        values: this.initialValues()
      }
    );
  };


  handleClose = () => {
    this.setState({open: false});
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    const id = values.id;
    const value = values.value
    const key = values.key
    const data={value: values.value,value_type: values.value_type,key: values.key};
    $.ajax({
      url: '/credentials/'+id,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.setState({working: false});
        self.props.enqueueSnackbar(`${key}`+' Successfully Updated With Value: '+`${value}`, {variant: 'success'})
        self.handleClose();
      },
      error: function (response) {
        {
          self.props.enqueueSnackbar(response.responseJSON.error,{ variant: 'error' })
          self.setState({working: false,
            updated_at: response.updated_at,
            value: response.value,
            key: response.key,});
        }
      },
    });
  }
  handleInputChange = e => {
    const {name, value} = e.target;
		if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
			this.setState({values: {...this.state.values,[name]: value}});
		}
		this.validate({[name]: value});
	}
  handleSelectCredentialChange = (object) => {
    this.setState({values: {...this.state.values, id: object ? object.value : null, key: object ? object.label : null}})
    this.validate({id: object ? object.value : null});
    let self = this
    const id = object.value
		$.ajax({
			url: '/credentials/'+id,
			type: 'GET',
			dataType: 'json',
			success: function (response) {
				self.setState({
					value_type: response.value_type,
					current: response.value,
					key: response.key,
          updated_at: response.updated_at,

				});
			},
		});
  }

  validate = (fieldValues = this.state.values) => {
    let temp = {...this.state.errors};
    temp.serverErrors = '';
    if ('id' in fieldValues) {
      temp.id = fieldValues.id ? '' : 'Required';
    }
    if ('value' in fieldValues) {
      temp.value = fieldValues.value ? '' : 'Required';
    }
    this.setState({errors: {...temp}})
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }

  render() {
    const {classes} = this.props;
    <Backdrop open={this.state.working}/>
    const body =
    <div className="container pb-5">
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Select
            name='id'
            fullWidth
            label='Search for credential by key'
            freeSolo={false}
            value={this.state.values}
            url='/credentials.json'
            callBack={this.handleSelectCredentialChange}
          />
          {this.state.values.id &&
            <TextField
              name='value'
              fullWidth
              value={this.state.values.value}
              label={'Value'}
              {...(this.state.errors.value && {error: true, helperText: this.state.errors.value})}
              onChange={this.handleInputChange}
            />
            }
            {this.state.values.id &&
            <div className="pt-4">
            <Grid container>
								<Grid item xs={10}>
									<Typography variant='subtitle1' color='textSecondary'>
										Credential Key: <b>{this.state.key}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
                  Credential Value: <b>{this.state.current}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Updated at: <b>{this.state.updated_at}</b>
									</Typography>
								</Grid></Grid>
                </div>
                }
        </CardContent>
      </Card>
      </div>
    const actions = (
        <Button
          variant='contained'
          type='submit'
          style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
          className={classes.button}
          disabled={this.state.working}
          onClick={this.handleSubmit}

        >
          Submit
        </Button>);
    return (
      <>
        <Backdrop open={this.state.working}/>
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title='Update Credentials'
          handleClose={this.handleClose}
          minHeight='30vh'
          maxHeight='65%'
          maxWidth='sm'
          actions={actions}>
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		padding: 20,
		margin: 20,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
});
export default (withSnackbar)(withStyles(useStyles)(UpdateCredential))


