import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, CardContent, Card } from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack'
import $ from 'jquery'
import Select from '../shared/autocomplete'
import Typography from '@material-ui/core/Typography'

class UpdateUserStatus extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      working: false,
      errors: {},
      open: false,
      data1: '',
      key: '',
      hidehold: '',
      hideactive: ''
    }
  }
  handleOpen = data1 => {
    const id = this.props.id
    this.setState({
      id: id,
      open: true,
      key: '',
      hidehold: '',
      hideactive: '',
      maxWidth: 'xl'
    })
    let self = this
    $.ajax({
      url: '/users/' + id,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        self.setState({
          name: response.name,
          hold: response.hold,
          active: response.active,
          working: false
        })
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSubmit = event => {
    let self = this
    const data = {
      deactivate_reason: this.state.reason,
      hold: this.state.hold,
      active: this.state.active
    }
    $.ajax({
      url: '/users/' + this.props.id,
      type: 'PUT',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.setState({ working: false })
        self.props.enqueueSnackbar('successfully processed!', {
          variant: 'success'
        })
        self.handleClose()
        self.props.callback()
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }
  handleChangeStatushold = e => {
    this.setState({
      [e.target.name]: e.target.checked,
      key: e.target?.checked || null,
      hideactive: e.target?.checked || null
    })
    this.validate({ [e.target.name]: e.target.checked })
  }
  handleChangeStatusactive = e => {
    this.setState({
      [e.target.name]: e.target.checked,
      key: !e.target?.checked || null,
      hidehold: !e.target?.checked || null
    })
    this.validate({ [e.target.name]: e.target.checked })
  }

  handleSelectreason = object => {
    this.setState({ reason: object ? object.value : null })
    this.validate({ reason: object ? object.value : null })
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors }
    temp.serverErrors = ''
    if ('reason' in fieldValues) {
      temp.reason = fieldValues.reason ? '' : 'Required'
    }
    if ('hold' in fieldValues) {
      temp.hold = fieldValues.hold ? '' : 'Required'
    }
    if ('active' in fieldValues) {
      temp.active = fieldValues.active ? '' : 'Required'
    }
    this.setState({ errors: { ...temp } })
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }
  render () {
    const { classes } = this.props

    const body = (
      <>
        {' '}
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component='h5' variant='h5'>
                {this.props.name}
              </Typography>
              <div className='row'>
                {' '}
                <div className='col'>
                  {!this.state.hidehold && (
                    <div className='form-check form-switch'>
                      <label className='form-check-label' htmlFor='hold'>
                        Hold{' '}
                      </label>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='hold'
                        name='hold'
                        checked={this.state.hold}
                        onClick={this.handleChangeStatushold}
                      />
                    </div>
                  )}
                </div>{' '}
                <div className='col'>
                  {!this.state.hideactive && (
                    <div className='form-check form-switch'>
                      <label className='form-check-label' htmlFor='active'>
                        active
                      </label>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='active'
                        name='active'
                        checked={this.state.active}
                        onClick={this.handleChangeStatusactive}
                      />
                    </div>
                  )}
                </div>
              </div>
              {this.state.key ? (
                <Select
                  name='reason'
                  label='Select Deactivate or Hold reason'
                  style={{ width: 400 }}
                  value={this.state.label}
                  url='/codes/dropdown_options.json?code_category_name=Deactivate_user'
                  callBack={this.handleSelectreason}
                />
              ) : null}
            </CardContent>{' '}
          </div>{' '}
        </Card>
      </>
    )
    const actions = (
      <>
        <Button
          variant='contained'
          style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
          type='submit'
          className={classes.button}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </>
    )
    return (
      <>
        <Modal
          isOpen={this.state.open}
          title='Deactivate/Hold User'
          handleClose={this.handleClose}
          minHeight='50vh'
          maxHeight='50%'
          maxWidth='sm'
          actions={actions}
        >
          {body}
        </Modal>
      </>
    )
  }
}

const useStyles = theme => ({
  content: {
    flex: '1 0 auto'
  },
  root: {
    display: 'flex',
    padding: 20,
    margin: 20,
    wordBreak: 'break-all'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
})

export default withSnackbar(withStyles(useStyles)(UpdateUserStatus))
