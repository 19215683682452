import React from 'react';
import $ from 'jquery'
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import Backdrop from "../shared/backdrop";
import { withSnackbar } from 'notistack';

class AddNewExchangeRate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: '',
      values: this.initialValues()
    }
  };

  initialValues = () => {
    return {
      currency_value: '',
      currency_type: '',
      user_id: '',
    }
  }

  handleOpen = () => {
    this.setState({ open: true, values: this.initialValues() });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload = {
      exchange_rates: { currency_value: values.currency_value, currency_type: values.currency_type, user_id: values.user_id }
    }
    $.ajax({
      url: '/exchange_rate',
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully Created!', { variant: 'success' })
        self.handleClose()
        self.props.reloadData()
      },
      error: function (response) {
        self.setState({ working: false, errors: { serverErrors: response.responseJSON.error } });
      },
    });
  }


  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } })
    this.validate({ [name]: value });
  }




  handleInputNumberChange = e => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  }

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = '';
    if ('currency_value' in fieldValues) {
      temp.currency_value = fieldValues.currency_value ? '' : 'Required';
    }
    if ('currency_type' in fieldValues) {
      temp.currency_type = fieldValues.currency_type ? '' : 'Required';
    }
    this.setState({ errors: { ...temp } })
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }


  render() {
    const { classes } = this.props;
    const body =
      <>
        {this.state.errors.serverErrors &&
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        }
        <Card className={classes.root} style={{ minHeight: '50' }}>
          <Backdrop open={this.state.working} />
          <div className="container">
            <TextField
              fullWidth
              margin="normal"
              name='currency_value'
              value={this.state.values.currency_value}
              label='Currency Value'
              {...(this.state.errors.currency_value && { error: true, helperText: this.state.errors.currency_value })}
              onChange={this.handleInputNumberChange}
            />
            <TextField
              name="currency_type"
              select
              fullWidth
              margin="normal"
              label="Currency Type"
              value={this.state.values.currency_type}
              onChange={this.handleInputChange}
              {...(this.state.errors.currency_type && { error: true, helperText: this.state.errors.currency_type })}
              SelectProps={{ native: true }}
            >
              {['', 'dollar', 'dinar', 'euro', 'lira', 'dirham', 'pound_sterling'].map((option) => (
                <option key={option} value={option}>
                  {option.toUpperCase()}
                </option>
              ))}
            </TextField>
          </div>
        </Card >
      </>

    const actions = (
      <>
        <Button
          type='button'
          variant='contained'
          onClick={() => this.setState({ values: this.initialValues(), errors: {} })}
        >
          Clear
        </Button>
        <Button
          variant='contained'
          type='submit'
          style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          {this.state.working ? 'Working...' : 'Submit'}
        </Button>
      </>

    );

    return (
      <Modal
        isOpen={this.state.open}
        title={this.state.values.user_balance}
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='40%'
        maxWidth='sm'
        actions={actions}>
        {body}
      </Modal>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(AddNewExchangeRate))
