import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Asynchronous({url, name, label, variant, callBack, style, error, freeSolo=false, helperText}) {
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = open && options.length === 0;

	React.useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			const response = await fetch(url);
			const responseJSON = await response.json();
			if (active) {
				setOptions(responseJSON.records.map((e) => ({value: e.id, label: e.label})));
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const handleOnChange = (e, newValue) => {
		callBack(newValue ? newValue : {});
	}

	return (
		<Autocomplete
			id={name}
			name={name}
			freeSolo={freeSolo}
			variant={variant}
			style={style}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			getOptionSelected={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.label}
			onChange={handleOnChange}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					{...(error && {error: true, helperText: error})}
					variant={variant}
					helperText={helperText}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
}
