import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AddNonStandard from "./_add_non_standard";
import EditNonStandard from "./_edit_non_standard";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import Modal from "../shared/modal/material_dialog";

const Index = () => {
  const [orders, setOrders] = useState<any>([]);
  // const [working, setWorking] = useState<boolean>(false);
  const [total, setTotal] = useState<any>(null);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [order_number, setOrderNumber] = useState<any>("");
  const [tableData, setTableData] = useState<any>([]);
  const [venderName, setVenderName] = useState<any>();
  const [standardCategory, setStandardCategory] = useState<any>();
  const [standardSubCategory, setStandardSubCategory] = useState<any>();
  const [standardSubCategoryOne, setStandardSubCategoryOne] = useState<any>();
  const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState<any>(moment());
  const [openNested, setOpenNested] = useState(false);

  const [open, setOpen] = useState<boolean>(false);
  const _deleteId = useRef<any>(null);

  const _tableRef = useRef<any>();
  const _ShippingForm = useRef<any>();
  const _AddNonStandardRef = useRef<any>();
  const _EditNonStandardRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const reload = () => {
    _tableRef.current.onQueryChange();
  };

  const getData = () => {
    // setWorking(true);
    const url = `/vendor_categories.json?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        // setWorking(false);
        setTableData(result.vendor_categories);
        setVenderName(result.vendor_names);
        setStandardCategory(result.standard_categories);
        setStandardSubCategory(result.standard_sub_categories);
        setStandardSubCategoryOne(result.standard_sub_category_ones);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onCallParent = () => {
    setIsRefresh(!isRefresh);
  };

  const onCloseNestedModal = () => {
    setOpenNested(false);
  };

  const printData = () => {
    const formatEndDate = moment(endDate).format("DD-MM-YYYY");
    const formatStartDate = moment(startDate).format("DD-MM-YYYY");
    window.open(
      `/vendor_categories/non_standard_categories_report?locale=en?locale=en&start_date=${formatStartDate}&end_date=${formatEndDate}&commit=View`,
      "_self"
    );
    onCloseNestedModal();
  };

  const handleConfirmDelete = () => {
    $.ajax({
      url: `/vendor_categories/${_deleteId.current}.json?locale=en`,
      type: "DELETE",
      dataType: "json",
      success: function (response: any) {
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        reload();
        handleCancel();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.msg, {
          variant: "error",
        });
        handleCancel();
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Non Standard Category",
      field: "cat_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Non Standard Sub Category",
      field: "sub_cat_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Non Standard Sub Category One",
      field: "sub_cat_one_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Standard Category",
      field: "parts_category",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Standard Sub Category",
      field: "parts_sub_category",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Standard Sub Category One",
      field: "parts_sub_category_one",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Vendor Name",
      field: "vendor_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Prohibited",
      field: "prohibited",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];
  return (
    <div className="bg-white rounded">
      {/* <Backdrop open={working} /> */}
      <AddNonStandard ref={_AddNonStandardRef} callback={reload} />
      <EditNonStandard ref={_EditNonStandardRef} reload={reload} />
      <Table
        title={"Non Standard Categories"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category" },
        }}
        columns={columns}
        tableRef={_tableRef}
        // data={tableData}
        data={(query) =>
          new Promise((resolve) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/vendor_categories.json?locale=en`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.vendor_categories,
                  page: result.page - 1,
                  totalCount: result.total,
                });
                // setWorking(false);
                // setTableData(result.vendor_categories);
                setVenderName(result.vendor_names);
                setStandardCategory(result.standard_categories);
                setStandardSubCategory(result.standard_sub_categories);
                setStandardSubCategoryOne(result.standard_sub_category_ones);
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              });
          })
        }
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.CategoriesIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              _AddNonStandardRef.current.handleOpen({
                venderName,
                standardCategory,
                standardSubCategory,
                standardSubCategoryOne,
              });
            },
            tooltip: "Add new category",
            isFreeAction: true,
          },
          {
            icon: () => <pureIcons.DownloadIcon />,
            onClick: () => setOpenNested(true),
            tooltip: "Download csv file",
            isFreeAction: true,
          },
          (rowData) => ({
            icon: () => <pureIcons.Edit />,
            onClick: () => {
              _EditNonStandardRef.current.handleOpen({
                venderName,
                standardCategory,
                standardSubCategory,
                standardSubCategoryOne,
                rowData,
              });
            },
            tooltip: "Edit",
          }),
          (rowData) => ({
            icon: () => <pureIcons.Delete />,
            onClick: () => {
              setOpen(true);
              _deleteId.current = rowData.id;
            },
            tooltip: "Delete",
          }),
        ]}
      />
      <Modal
        title={`Prohibited canceled items details`}
        handleClose={() => onCloseNestedModal()}
        disableBackdropClick={false}
        isOpen={openNested}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={""}
      >
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader></CardHeader>

          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>From date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "15%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="start_date"
                    //   label="From date"
                    onChange={(ev) => setStartDate(ev)}
                    format="dd/MM/yyyy"
                    value={startDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {/* placeholder={placeholder} footer={footer}  */}
            </Box>

            <Divider style={{ margin: "20px 0px" }} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>To date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "17%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="end_date"
                    //   label="To date"
                    onChange={(ev) => setEndDate(ev)}
                    format="dd/MM/yyyy"
                    value={endDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>

            <Divider style={{ margin: "20px 0px" }} />

            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => printData()}
              >
                Print Sheet
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Modal>

      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}>
          Delete Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
