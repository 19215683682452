import React from "react";
import Barcode from "react-barcode";


export default class OrderLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const data = this.props.data
		return (
			<div className='d-print-block'>
				<div className="container-fluid p-5">
					<div className="border border-1 border-dark rounded border-bottom-0">
						<div className='m-2'>
							<div className='row'>
								<h5 className='text-center'>PURE PLATFORM&trade;</h5>
							</div>
							<div className='row'>
								<div className='col'><b><u>Local Address</u></b></div>
								<div className='col-2'></div>
								<div className='col'><b><u>USA Address</u></b></div>
							</div>
							<div className='row'>
								<div className='col'>{data.baghdad_address_l1}</div>
								<div className='col-2'/>
								<div className='col'>{data.pure_address_l1}</div>
							</div>
							<div className='row'>
								<div className='col'>Phone: {data.baghdad_address_l2}</div>
								<div className='col-2'/>
								<div className='col'>{data.pure_address_l2}</div>
							</div>
						</div>
					</div>
					<div className="border border-1 border-dark rounded">
						<div className='m-2'>
							<div className='row'>
								<div className='col'><b><u>Customer Information</u></b></div>
								<div className='col'><b><u>Order ID(s)</u></b></div>
							</div>
							<div className='row'>
								<div className='col'>
									<p className='m-0 p-0'>{data.full_name}</p>
									<p className='m-0 p-0'>Phone: {data.delivery_phone_number}</p>
									<p className='m-0 p-0'>{data.address}</p>
								</div>
								<div className='col'>{data.completed?.map(c => <p className='m-0 p-0'>{c[0]}</p>)}</div>
							</div>
							<div className='row'>
								<div className='col'><b><u>Delivery</u></b></div>
								<div className='col'><b><u>Tracking</u></b></div>
							</div>
							<div className='row'>
								<div className='col'>{data.delivery_fees <= 0.0 ? 'Not Paid' : 'Paid ' + data.delivery_fees}</div>
								<div className='col'>{data.random}</div>
							</div>
							<div className='row'>
								<div className='col'>
									<span className="border border-dark h-75 d-inline-block w-100 p-3"></span>
								</div>
								<div className='col'>{data.order_value}</div>
							</div>
							<div className='row'>
								<div className='col'>
									<span className="border border-dark h-75 d-inline-block w-100 p-3"></span>
								</div>
								<div className='col'>
									<b className='text-center'>{data.delivery_note}</b>
								</div>
							</div>
							<div className='row'>
								<div className='col d-flex justify-content-end'>
									<Barcode value={data.first_order_number} height={25} width={2} fontSize={10}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
