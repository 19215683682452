export const columns = [
  {
    title: "Coupon Number",
    field: "coupon_number",
    filtering: false,
    sorting: true,
    editable: "never",
  },
  {
    title: "Discount Type",
    field: "discount_type",
    filtering: false,
    editable: "never",
    sorting: true,
  },
  {
    title: "Discount Value",
    field: "discount_value",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "Coupon Type",
    field: "coupon_type",
    editable: "never",
    filtering: false,
    sorting: true,
  },

  {
    title: "Reusable?",
    field: "reusable",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "Start Date",
    field: "start_date",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "End Date",
    field: "end_date",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "Created At",
    field: "created_at",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "Created By",
    field: "created_by",
    editable: "never",
    filtering: false,
    sorting: true,
  },
  {
    title: "Active?",
    field: "active",
    editable: "never",
    filtering: false,
    sorting: true,
  },
];

export const couponData = {
  couponName: "PesoAlani20",
  startDate: "2024/04/22",
  endDate: "2024/05/01",
  discountType: "percentage",
  discountValue: 0.2,
  couponType: "shipping",
  minimumTotalNeeded: 0.0,
  minimumTotalType: "",
  noOfTimesUsed: 1,
  isActive: true,
  isExpired: false,
  vendors: "All vendors",
};

export const convertCamelCaseToReadable = (input: string): string => {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
};

export const convertSnakeCaseToTitleCase = (input: string): string => {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
