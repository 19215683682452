import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  IconButton,
  CardContent,
  CardMedia,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import Backdrop from "../shared/backdrop";
import InputMask from "react-input-mask";
import DatePicker from "../shared/date_picker";
import PureIcons from "../shared/pure_icons";
import $ from "jquery";

class AddExternalItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      errors: {},
      open: false,
      brands: [],
      serverError: "",
      values: this.initialValues(),
    };
  }

  initialValues = (id) => {
    return {
      user_id: id,
      product_name: "",
      product_url: "",
      product_image: "",
      brand: "",
      price: "",
      qty: "",
      weight: "",
      item_weight: "",
      weight_unit: "",
      dimensions: "",
      liquid_weight: "",
      liquid_weight_unit: "",
      // region: "",
      country: "",
      shipping_to_hub: "",
      eta: null,
      customizations: [],
      length: "",
      width: "",
      height: "",
      unit: "",
    };
  };

  handleOpen = (id, name) => {
    this.setState({ open: true, values: this.initialValues(id), name: name });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    const { length, width, height, unit } = this.state.values;
    let combinedDimensions;
    if (length && width && height && unit) {
      combinedDimensions = `${length} x ${width} x ${height} ${unit}`;
    }
    let values = this.state.values;
    let obj = values.customizations.reduce(
      (o, c) => ({
        ...o,
        [c[`name_${values.customizations.indexOf(c)}`]]:
          c[`value_${values.customizations.indexOf(c)}`],
      }),
      {}
    );
    let newObj = {
      ...obj,
      quantity: values.qty.replace(/\s/g, ''),
      weight: values.weight ? `${values.weight} ${values.weight_unit}` : null,
      weight_item: values.item_weight
        ? `${values.item_weight} ${values.weight_unit}`
        : null,
      max_quantity: null,
      shipping_usa_cost: values.shipping_to_hub,
      delivery_us: values.eta,
      product_dimensions: combinedDimensions,
      shipping_to_hub: values.shipping_to_hub,
      weight_liquid: values.liquid_weight
        ? `${values.liquid_weight} ${values.liquid_weight_unit}`
        : null,
      hub_delivery_time: values.eta,
      external_url: values.product_url,
      // region: values.region,
      country: values.country,
    };
    let payload = {
      user_id: values.user_id,
      product_name: values.product_name,
      product_url: values.product_url,
      product_image: values.product_image,
      brand: values.brand,
      price: values.price,
      qty: values.qty,
      // region: values.region,
      country: values.country,
      customizations: newObj,
      shipping_to_hub: values.shipping_to_hub,
    };
    this.setState({ working: true });
    $.ajax({
      url: `/order_items`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false, open: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        self.setState({
          working: false,
          errors: { serverErrors: response.responseJSON.error },
        });
      },
    });
  };

  handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } });
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("product_name" in fieldValues) {
      temp.product_name = fieldValues.product_name ? "" : "Required";
    }
    if ("product_url" in fieldValues) {
      temp.product_url = fieldValues.product_url ? "" : "Required";
    }
    if ("product_image" in fieldValues) {
      temp.product_image = fieldValues.product_image ? "" : "Required";
    }
    if ("brand" in fieldValues) {
      temp.brand = fieldValues.brand ? "" : "Required";
    }
    if ("price" in fieldValues) {
      temp.price = fieldValues.price ? "" : "Required";
    }
    if ("qty" in fieldValues) {
      temp.qty = fieldValues.qty
        ? fieldValues.qty > 0
          ? ""
          : "Must be greater than 0"
        : "Required";
    }
    // if ("region" in fieldValues) {
    //   temp.region = fieldValues.region ? "" : "Required";
    // }
    if ("country" in fieldValues) {
      temp.country = fieldValues.country ? "" : "Required";
    }
    if ("weight_unit" in fieldValues) {
      temp.weight_unit = fieldValues.weight_unit
        ? ""
        : fieldValues.weight || fieldValues.item_weight
        ? "Required"
        : "";
    }
    if ("liquid_weight_unit" in fieldValues) {
      temp.liquid_weight_unit = fieldValues.liquid_weight_unit
        ? ""
        : fieldValues.liquid_weight
        ? "Required"
        : "";
    }
    if ("customizations" in fieldValues) {
      this.state.values.customizations.map((c, i) => {
        temp[`name_${i}`] = fieldValues.customizations[i][`name_${i}`]
          ? ""
          : "Required";
        temp[`value_${i}`] = fieldValues.customizations[i][`value_${i}`]
          ? ""
          : "Required";
      });
    }
    // if ("length" in fieldValues) {
    //   temp.length = fieldValues.length ? "" : "Required";
    // }
    // if ("width" in fieldValues) {
    //   temp.width = fieldValues.width ? "" : "Required";
    // }
    // if ("height" in fieldValues) {
    //   temp.height = fieldValues.height ? "" : "Required";
    // }
    // if ("unit" in fieldValues) {
    //   temp.unit = fieldValues.unit ? "" : "Required";
    // }

    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state.values)
      return Object.values(temp).every((x) => x === "");
  };

  addCustomizationFields = () => {
    this.setState({
      values: {
        ...this.state.values,
        customizations: [
          ...this.state.values.customizations,
          {
            [`name_${this.state.values.customizations.length}`]: "",
            [`value_${this.state.values.customizations.length}`]: "",
          },
        ],
      },
    });
  };

  removeCustomizationFields = (i) => {
    let newCustomizationValues = [...this.state.values.customizations];
    newCustomizationValues.splice(i, 1);
    this.setState({
      values: { ...this.state.values, customizations: newCustomizationValues },
      errors: {},
    });
    this.validate({ customizations: newCustomizationValues });
  };

  handleCustomizationsInputChange = (i, e) => {
    let newCustomizationValues = [...this.state.values.customizations];
    newCustomizationValues[i][e.target.name] = e.target.value.replace(/\s/g, '');
    this.setState({
      values: { ...this.state.values, [customizations]: newCustomizationValues},
    });
    this.validate({ customizations: newCustomizationValues });
  };
  render() {
    const { classes } = this.props;
    const body = (
      <>
        <Backdrop open={this.state.working} />
        {this.state.errors.serverErrors && (
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        )}
        <Card className={classes.root} hidden={!this.state.values.user_id}>
          <CardMedia className={classes.cover}>
            <img
              src={this.state.values.product_image}
              alt={this.state.values.product_name}
              style={{ height: "250px", width: "200px", objectFit: "contain" }}
            />
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <TextField
                name="product_name"
                fullWidth
                className="pb-2"
                multiline
                value={this.state.values.product_name}
                label="Product Name"
                {...(this.state.errors.product_name && {
                  error: true,
                  helperText: this.state.errors.product_name,
                })}
                onChange={this.handleInputChange}
              />
              <TextField
                name="product_url"
                fullWidth
                className="pb-2"
                multiline
                value={this.state.values.product_url}
                label="Product Link"
                {...(this.state.errors.product_url && {
                  error: true,
                  helperText: this.state.errors.product_url,
                })}
                onChange={this.handleInputChange}
              />
              <TextField
                name="product_image"
                fullWidth
                className="pb-2"
                multiline
                value={this.state.values.product_image}
                label="Product Image"
                {...(this.state.errors.product_image && {
                  error: true,
                  helperText: this.state.errors.product_image,
                })}
                onChange={this.handleInputChange}
              />
              <div className="row">
                <div className="col">
                  <TextField
                    name="brand"
                    fullWidth
                    value={this.state.values.brand}
                    label="Brand"
                    {...(this.state.errors.brand && {
                      error: true,
                      helperText: this.state.errors.brand,
                    })}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col">
                  <TextField
                    name="price"
                    fullWidth
                    value={this.state.values.price}
                    label="Price"
                    {...(this.state.errors.price && {
                      error: true,
                      helperText: this.state.errors.price,
                    })}
                    onChange={this.handleInputNumberChange}
                  />
                </div>
                <div className="col">
                  <InputMask
                    mask="9999"
                    value={this.state.values.qty}
                    maskChar=" "
                    onChange={this.handleInputChange}
                  >
                    {() => (
                      <TextField
                        name="qty"
                        label="Quantity"
                        {...(this.state.errors.qty && {
                          error: true,
                          helperText: this.state.errors.qty,
                        })}
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextField
                    name="weight"
                    fullWidth
                    value={this.state.values.weight}
                    label="Weight"
                    {...(this.state.errors.price && {
                      weight: true,
                      helperText: this.state.errors.weight,
                    })}
                    onChange={this.handleInputNumberChange}
                  />
                </div>
                <div className="col">
                  <TextField
                    name="item_weight"
                    fullWidth
                    value={this.state.values.item_weight}
                    label="Item Weight"
                    {...(this.state.errors.item_weight && {
                      error: true,
                      helperText: this.state.errors.item_weight,
                    })}
                    onChange={this.handleInputNumberChange}
                  />
                </div>
                <div className="col">
                  <TextField
                    name="weight_unit"
                    select
                    fullWidth
                    label="Weight Unit"
                    {...(this.state.errors.weight_unit && {
                      error: true,
                      helperText: this.state.errors.weight_unit,
                    })}
                    value={this.state.values.weight_unit}
                    onChange={this.handleInputChange}
                    SelectProps={{ native: true }}
                  >
                    {["", "lb", "oz", "kg", "gr"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {/* <TextField
										name='dimensions'
										fullWidth
										value={this.state.values.dimensions}
										label='Product Dimensions'
										helperText='example: 4x2x3'
										onChange={this.handleInputChange}
									/> */}
                  <Grid container spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Dimension Length"
                          name="length"
                          value={this.state.length}
                          onChange={this.handleInputChange}
                          fullWidth
                          {...(this.state.errors.length && {
                            error: true,
                            helperText: this.state.errors.length,
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Dimension Width"
                          name="width"
                          value={this.state.width}
                          onChange={this.handleInputChange}
                          fullWidth
                          {...(this.state.errors.width && {
                            error: true,
                            helperText: this.state.errors.width,
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Grid container spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Dimension Height"
                          name="height"
                          value={this.state.height}
                          onChange={this.handleInputChange}
                          fullWidth
                          {...(this.state.errors.height && {
                            error: true,
                            helperText: this.state.errors.height,
                          })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth error={!!this.state.errors.unit}>
                          <InputLabel id="unit-label">
                            Dimension Unit
                          </InputLabel>
                          <Select
                            labelId="unit-label"
                            name="unit"
                            value={this.state.unit}
                            onChange={this.handleInputChange}
                          >
                            <MenuItem value="inches">inches</MenuItem>
                            {/* Add other units as needed */}
                          </Select>
                          {this.state.errors.unit && (
                            <FormHelperText>
                              {this.state.errors.unit}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextField
                    name="liquid_weight"
                    fullWidth
                    value={this.state.values.liquid_weight}
                    label="Liquid weight"
                    {...(this.state.errors.liquid_weight && {
                      error: true,
                      helperText: this.state.errors.liquid_weight,
                    })}
                    onChange={this.handleInputNumberChange}
                  />
                </div>
                <div className="col">
                  <TextField
                    name="liquid_weight_unit"
                    select
                    fullWidth
                    label="Liquid Weight Unit"
                    value={this.state.values.liquid_weight_unit}
                    {...(this.state.errors.liquid_weight_unit && {
                      error: true,
                      helperText: this.state.errors.liquid_weight_unit,
                    })}
                    onChange={this.handleInputChange}
                    SelectProps={{ native: true }}
                  >
                    {["", "ml", "fl oz"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="row pb-3">
                {/*<div className="col">
                  <TextField
                    name="region"
                    select
                    fullWidth
                    label="Region"
                    value={this.state.values.region}
                    onChange={this.handleInputChange}
                    {...(this.state.errors.region && {
                      error: true,
                      helperText: this.state.errors.region,
                    })}
                    SelectProps={{ native: true }}
                  >
                    {["", "US", "GC", "TR", "CN"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </div>*/}
                <div className="col">
                  <TextField
                    name="country"
                    select
                    fullWidth
                    label="Country"
                    value={this.state.values.country}
                    onChange={this.handleInputChange}
                    {...(this.state.errors.country && {
                      error: true,
                      helperText: this.state.errors.country,
                    })}
                    SelectProps={{ native: true }}
                  >
                    {["", "US", "AE", "TR", "CN", "SA", "IQ", "QA"].map(
                      (option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      )
                    )}
                  </TextField>
                </div>
                <div className="col">
                  <TextField
                    name="shipping_to_hub"
                    fullWidth
                    value={this.state.values.shipping_to_hub}
                    label="Shipping to HUB"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col">
                  <DatePicker
                    disablePast={true}
                    disableFuture={false}
                    name="eta"
                    label="HUB ETA"
                    selectedDateValue={(date) =>
                      this.setState({
                        values: { ...this.state.values, eta: date },
                      })
                    }
                    value={this.state.values.eta}
                  />
                </div>
              </div>
              {this.state.values.customizations.length > 0 && (
                <span>
                  <strong>Customizations:</strong>
                </span>
              )}
              {this.state.values.customizations.map((c, i) => (
                <div className="row" key={i}>
                  <div className="col">
                    <TextField
                      name={"name_" + i}
                      fullWidth
                      {...(this.state.errors[`name_${i}`] && {
                        error: true,
                        helperText: this.state.errors[`name_${i}`],
                      })}
                      value={c.name}
                      label="Name"
                      onChange={(e) =>
                        this.handleCustomizationsInputChange(i, e)
                      }
                    />
                  </div>
                  <div className="col">
                    <TextField
                      name={"value_" + i}
                      {...(this.state.errors[`value_${i}`] && {
                        error: true,
                        helperText: this.state.errors[`value_${i}`],
                      })}
                      fullWidth
                      value={c.value}
                      label="Value"
                      onChange={(e) =>
                        this.handleCustomizationsInputChange(i, e)
                      }
                    />
                  </div>
                  <div className="col">
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      component="span"
                      className="mt-3"
                      onClick={() => this.removeCustomizationFields(i)}
                    >
                      <PureIcons.Delete style={{ color: "#f50057" }} />
                    </IconButton>
                  </div>
                </div>
              ))}
            </CardContent>
          </div>
        </Card>
      </>
    );

    const actions = (
      <>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          onClick={this.addCustomizationFields}
          hidden={!this.state.values.user_id}
        >
          add Customizations
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button
          type="button"
          variant="contained"
          onClick={() =>
            this.setState({
              values: this.initialValues(this.state.values.user_id),
              errors: {},
            })
          }
        >
          Clear
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </>
    );

    return (
      <Modal
        isOpen={this.state.open}
        title={`Add external item to ${this.state.name}'s cart`}
        handleClose={this.handleClose}
        minHeight="40vh"
        maxHeight="80%"
        maxWidth="md"
        actions={actions}
      >
        {body}
      </Modal>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 20,
    margin: 20,
    wordBreak: "break-all",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
    height: 250,
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(AddExternalItem));
