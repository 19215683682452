import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";

const AsyncSearchBar = ({ setCallBack, noOptionsMessage, placeholder, url}) => {
	//set default query terms
	const [query, setQuery] = useState("");
	const selectRef = useRef(null);

	//get animated components wrapper
	const animatedComponents = makeAnimated();

	// fetch filteres search results for dropdown
	const loadOptions = (query, callback) => {
		setTimeout(() => {
			if(query.length < 3) return callback([]);
			return fetch(`${url}?per_page=99999&search=${query}`)
			.then(response => response.json())
			.then(data => callback(data.records))
		}, 500);
	};

	useEffect(() => {
		selectRef.current.select.select.onInputBlur = () => {};
	}, []);

	const handleChange = (value, action) => {
		if (action === "input-change") {
			setQuery(value);
		}
  	};

	return (
		<AsyncSelect
			ref={selectRef}
			cacheOptions
			isClearable
			autoFocus
			components={animatedComponents}
			getOptionLabel={(e) => e.label}
			getOptionValue={(e) => e.value}
			loadOptions={loadOptions}
			noOptionsMessage={() => noOptionsMessage}
			placeholder={placeholder}
			onInputChange={(value, { action }) => handleChange(value, action)}
			onChange={(value) => setCallBack(value)}
			menuPlacement="auto"
			maxMenuHeight={250}
			inputValue={query}
			styles={{
				placeholder: (provided) => ({
					...provided,
					padding: '2px 8px !important',
					// pointerEvents: "none !important",
					// userSelect: "none !important",
					// MozUserSelect: "none !important",
					// WebkitUserSelect: "none !important",
					// msUserSelect: "none !important"
				}),
				input: (provided) => ({
					...provided,
					input: {
						width: '75vw !important',
						padding: '2px 8px !important',
						gridArea: '1 / 2 / 4 / 4 !important'
					}
				}),
			}}
		/>
	);
};

AsyncSearchBar.propTypes = {
	url: PropTypes.string.isRequired,
	noOptionsMessage: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	setCallBack: PropTypes.func.isRequired
}
export default AsyncSearchBar;
