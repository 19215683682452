import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card } from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import DatePicker from '../shared/date_picker';
import { withSnackbar } from 'notistack';

class WaybillsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      working: false,
      errors: {},
    }
  };

  handleOpen = () => {
    this.setState({ open: true, date_start: new Date(), date_end: new Date() });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  startDownload = () => {
    window.open(`/reports/waybills?date_start=${this.state.date_start}&date_end=${this.state.date_end}`, '_self');
    this.handleClose();
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = '';
    if ('date_start' in fieldValues) {
      temp.date_start = fieldValues.date_start === null ? '' : isNaN(fieldValues.date_start.getTime()) ? 'Invalid Date' : '';
    }
    if ('date_end' in fieldValues) {
      temp.date_end = fieldValues.date_end === null ? '' : isNaN(fieldValues.date_end.getTime()) ? 'Invalid Date' : '';
    }
    this.setState({ errors: { ...temp } })

    if (fieldValues === this.state)
      return Object.values(temp).every(x => x === '')
  };

  selectedSDateValue = (date) => {
    this.setState({ date_start: date, file_path: null })
    this.validate({ date_start: date });
  };

  selectedEDateValue = (date) => {
    this.setState({ date_end: date, file_path: null })
    this.validate({ date_end: date });
  };

  render() {
    const { classes } = this.props;
    const body =
      <Card className={classes.root}>
        <div className="container pb-5">
          <div className="row pt-2">
            <div className="col">
              <DatePicker
                disableFuture={true}
                disablePast={false}
                className={classes.textField}
                name='date_start'
                label='From Date'
                disabled={this.state.working}
                selectedDateValue={this.selectedSDateValue}
                value={this.state.date_start}
                {...(this.state.errors.date_start && { error: true, helperText: this.state.errors.date_start })}
              />
            </div>
            <div className="col">
              <DatePicker
                disableFuture={true}
                disablePast={false}
                className={classes.textField}
                name='date_end'
                label='To Date'
                disabled={this.state.working}
                selectedDateValue={this.selectedEDateValue}
                value={this.state.date_end}
                {...(this.state.errors.date_end && { error: true, helperText: this.state.errors.date_end })}
              />
            </div>
          </div>
        </div>
      </Card>

    const actions = (
      <Button
        variant='contained'
        type='submit'
        style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
        disabled={this.state.working}
        onClick={this.startDownload}
      >
        {this.state.working ? 'Working...' : 'Submit'}
      </Button>
    );

    return (
      <Modal
        isOpen={this.state.open}
        title='Export Waybills'
        handleClose={this.handleClose}
        minHeight='20vh'
        maxHeight='30%'
        maxWidth='sm'
        actions={actions}>
        {body}
      </Modal>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(WaybillsReport))
