import React, { forwardRef, useImperativeHandle, useState } from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, Select, TextField } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";
import { useSnackbar, withSnackbar } from "notistack";

const EditCity = forwardRef((props: any, ref) => {
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);
  const [editValues, setEditValues] = useState({
    latitude: "",
    longitude: "",
    name_ar: "",
    name_en: "",
    name_ku: "",
    province_id: null,
  });
  const [provinceValue, setProvinceValue] = useState<any>([]);
  const [tableId, setTableId] = useState<any>();
  const { classes } = props;
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(rowData, province) {
      setOpen(true);
      setProvinceValue(province);
      setEditValues({
        latitude: rowData.latitude,
        longitude: rowData.longitude,
        name_ar: rowData.name_ar,
        name_en: rowData.name_en,
        name_ku: rowData.name_ku,
        province_id: province.find((val) => val.label === rowData?.province)
          ?.id,
      });
      setTableId(rowData?.id);
    },
  }));

  const onSetEditCityValue = (e) => {
    setEditValues({ ...editValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setWorking(true);
    const payload = {
      cities: {
        ...editValues,
      },
      id: tableId,
    };
    $.ajax({
      url: `/cities/${tableId}?locale=en`,
      type: "PUT",
      data: payload,
      dataType: "json",
      success: function (response) {
        enqueueSnackbar(response?.response, {
          variant: "success",
        });
        setWorking(false);
        handleClose();
        props.reloadData();
      },
      error: function (response) {
        enqueueSnackbar(
          response?.responseJSON.error || response?.responseText,
          {
            variant: "error",
          }
        );
        setWorking(false);
      },
    });
  };

  const body = (
    <>
      {/* {this.state.errors.serverErrors && (
        <div className="alert alert-danger p-1 text-center" role="alert">
          {this.state.errors.serverErrors}
        </div>
      )} */}
      <Card className={classes?.root} style={{ minHeight: "65vh" }}>
        <Backdrop open={working} />
        <div className="container">
          <Select
            name="province_id"
            fullWidth
            label="Search for province"
            value={editValues?.province_id}
            onChange={onSetEditCityValue}
            // url="/cities.json"
            // {...(this.state.errors.province_id && {
            //   error: true,
            //   helperText: this.state.errors.province_id,
            // })}
            // callBack={this.handleSelectProvinceChange}
          >
            {provinceValue?.map((value) => {
              return (
                <MenuItem key={value.id} value={value.id}>
                  {value.label}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            name="name_en"
            fullWidth
            margin="normal"
            label="Name EN"
            value={editValues?.name_en}
            onChange={onSetEditCityValue}
            // value={this.state.values.name_en}
            // onChange={this.handleInputChange}
            // {...(this.state.errors.name_en && {
            //   error: true,
            //   helperText: this.state.errors.name_en,
            // })}
          ></TextField>
          <TextField
            name="name_ar"
            fullWidth
            margin="normal"
            label="Name AR"
            value={editValues?.name_ar}
            onChange={onSetEditCityValue}
            // value={this.state.values.name_ar}
            // onChange={this.handleInputChange}
            // {...(this.state.errors.name_ar && {
            //   error: true,
            //   helperText: this.state.errors.name_ar,
            // })}
          ></TextField>
          <TextField
            name="name_ku"
            fullWidth
            margin="normal"
            label="Name KU"
            value={editValues?.name_ku}
            onChange={onSetEditCityValue}
            // onChange={this.handleInputChange}
            // {...(this.state.errors.name_ku && {
            //   error: true,
            //   helperText: this.state.errors.name_ku,
            // })}
          ></TextField>
          <TextField
            name="latitude"
            fullWidth
            margin="normal"
            label="Latitude"
            value={editValues?.latitude}
            onChange={onSetEditCityValue}
            // value={this.state.values.latitude}
            // onChange={this.handleInputNumberChange}
            // {...(this.state.errors.latitude && {
            //   error: true,
            //   helperText: this.state.errors.latitude,
            // })}
          ></TextField>
          <TextField
            name="longitude"
            fullWidth
            margin="normal"
            label="Longitude"
            value={editValues?.longitude}
            onChange={onSetEditCityValue}
            // value={this.state.values.longitude}
            // onChange={this.handleInputNumberChange}
            // {...(this.state.errors.longitude && {
            //   error: true,
            //   helperText: this.state.errors.longitude,
            // })}
          ></TextField>
        </div>
      </Card>
    </>
  );

  const actions = (
    <>
      <Button
        type="button"
        variant="contained"
        // onClick={() =>
        //   this.setState({ values: this.initialValues(), errors: {} })
        // }
      >
        Clear
      </Button>
      <Button
        variant="contained"
        type="submit"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        disabled={working}
        onClick={handleSubmit}
      >
        {working ? "Working..." : "Submit"}
      </Button>
    </>
  );

  const handleClose = () => {
    setOpen(false);
    setEditValues({
      latitude: "",
      longitude: "",
      name_ar: "",
      name_en: "",
      name_ku: "",
      province_id: null,
    });
  };
  return (
    <Modal
      isOpen={open}
      title={`Edit City`}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="70%"
      maxWidth="sm"
      actions={actions}
    >
      {body}
    </Modal>
  );
});

export default EditCity;
