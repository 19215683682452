import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import $ from "jquery";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Backdrop from "../../shared/backdrop";
import Modal from "../../shared/modal/material_dialog";

const AddNewKidus = forwardRef((props: any, ref) => {
  const [open, setOpen] = useState(false);
  const [no_of_cards, setNo_of_cards] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [seller, setSeller] = useState<any>("");
  const [expire_date, setExpire_date] = useState<any>("");
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const clearData = () => {
    setNo_of_cards("");
    setAmount("");
    setExpire_date("");
    setSeller("");
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const convertToCSV = (csvHeader, csvData) => {
    const headerRow = csvHeader.join(",") + "\n";
    const rows = csvData
      .map((item) => csvHeader.map((header) => item[header]).join(","))
      .join("\n");
    return headerRow + rows;
  };

  const downloadCSV = (csvHeader, csvData) => {
    const today = new Date().toISOString().split("T")[0];
    const fileName = `Kidus serial numbers for ${today}.csv`;
    const csvContent = convertToCSV(csvHeader, csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar(
        "Kidus serial numbers file was downloaded to your computer",
        {
          variant: "success",
        }
      );
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const saveData = () => {
    setSubmitting(true);
    const date = new Date(expire_date);
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    const payload = {
      no_of_cards: no_of_cards,
      kidu_serial_number: {
        amount: amount,
        seller: seller,
        expire_date: formattedDate,
        creator_id: props?.creatorId,
      },
    };
    $.ajax({
      url: `/kidu_serial_numbers?locale=en`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response, {
          variant: "success",
        });
      },
      error: function (response) {
        setSubmitting(false);
        const textData = response.responseText;
        const lines = textData.trim().split("\n");
        const headerLine = lines[0];
        const extractedHeaders = headerLine.split(",");
        const processedData = lines.slice(1).map((line) => {
          const values = line.split(",");
          return extractedHeaders.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
          }, {});
        });

        // setData(processedData);
        downloadCSV(extractedHeaders, processedData);
      },
    });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ height: "95%" }}>
          <CardHeader title="New Kidus serial numbers"></CardHeader>
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="number"
                margin="normal"
                label="No. of cards"
                style={{ width: "40%" }}
                value={no_of_cards}
                onChange={(e: any) => {
                  setNo_of_cards(e.target.value);
                }}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Amount"
                style={{ width: "40%" }}
                value={amount}
                onChange={(e: any) => {
                  setAmount(e.target.value);
                }}
              >
                {props.numbersDropDown?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Seller"
                style={{ width: "40%" }}
                value={seller}
                onChange={(e: any) => {
                  setSeller(e.target.value);
                }}
              >
                {props.sellersDropDown?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="date"
                fullWidth
                margin="normal"
                // label="Expire date"
                style={{ width: "40%" }}
                value={expire_date}
                onChange={(e: any) => {
                  setExpire_date(e.target.value);
                }}
              ></TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="50vh"
      maxHeight="70%"
      maxWidth="md"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(AddNewKidus);
