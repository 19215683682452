import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, MenuItem, TextField} from '@material-ui/core';
import Select from '../../shared/autocomplete'
import Modal from '../../shared/modal/material_dialog'
import $ from 'jquery';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";

class AddToListForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			errors: []
		}
	}

	handleClose = () => {
		this.setState({open: false});
	};

	handleOpen = (data) => {
		this.setState({
				open: true,
				values: data,
				errors: [],
				tracking: null
			}
		);
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({submitting: true});
		$.ajax({
			url: `/order_item_stages/add_to_shipment`,
			type: 'POST',
			data: {data: this.state.values, tracking: this.state.tracking},
			dataType: 'json',
			success: function (response) {
				self.setState({submitting: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
				self.props.reloadData();
				self.handleClose();
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
				self.setState({submitting: false});
			},
		});
	}

	handleSelectCallBack = (data) => {
		this.setState({tracking: data.value})
		this.validate({data});
	};

	validate = (fieldValues = this.state.tracking) => {
		let temp = {...this.state.errors};
		temp.tracking = fieldValues ? '' : 'Required';
		this.setState({errors: {...temp}})
		if (fieldValues === this.state.tracking) return Object.values(temp).every(x => x === '')};

	render () {
		const {classes} = this.props;
		const  actions = (
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='submit'
				className={classes.button}
				onClick={this.handleSubmit}
			>
				Submit
			</Button>)

		const body = <Card className={classes.root} style={{minHeight: '30vh'}}>
			<div className="container pb-5">
				<p>You have selected {this.state.values?.length} box{this.state.values?.length > 1 ? 'es' : ''} to add to an existing shipment</p>
				<div className="row pt-2">
					<Select
						name='tracking'
						label='Tracking'
						url='/order_item_stages/in_transit_list.json'
						error={this.state.errors.tracking}
						callBack={this.handleSelectCallBack}
					/>
				</div>
				{this.props.shipping_methods?.length > 1 &&
					<div className="row pt-3">
						<div className="col">
							<TextField
								label='Shipping Method'
								select
								fullWidth
								value={this.state.shipping_method || ''}
								{...(this.state.errors.shipping_method && {error: true, helperText: this.state.errors.shipping_method})}
								onChange={(event) => this.setState({shipping_method: event.target.value})}
							>
								{this.state.shipping_methods?.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</div>
					</div>
				}
			</div>
		</Card>

		return (
			<>
				<Backdrop open={this.state.working}/>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title="Add to Shipment"
					handleClose={this.handleClose}
					maxHeight='400%'
					maxWidth='sm'
					actions={actions}>
					{body}
				</Modal>
			</>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
})

export default withSnackbar(withStyles(useStyles)(AddToListForm))
