import React from "react";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { FiPackage, FiRefreshCw } from "react-icons/fi";
import { ImInfo } from "react-icons/im";
import { BsChatDots } from "react-icons/bs";
import { GiBoxUnpacking } from "react-icons/gi";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  FaFileInvoice,
  FaShippingFast,
  FaExternalLinkAlt,
} from "react-icons/fa";
import PrintIcon from "@material-ui/icons/Print";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import BlockIcon from "@material-ui/icons/Block";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { AiOutlineCheck } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { AiOutlinePlus } from "react-icons/ai";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { RiRefund2Fill } from "react-icons/ri";
import { MdPreview } from "react-icons/md";
import { BiShow } from "react-icons/bi";
import {
  BsChevronRight,
  BsChevronLeft,
  BsFillChatTextFill,
} from "react-icons/bs";
import { GoPackage } from "react-icons/go";
import { MdOutlineSummarize } from "react-icons/md";
import { MdOutlineAddBusiness } from "react-icons/md";
import { HiTranslate } from "react-icons/hi";
import CategoryIcon from "@material-ui/icons/Category";
import { MdDownloadForOffline } from "react-icons/md";
import { RiProhibitedLine } from "react-icons/ri";
import { RiProductHuntLine } from "react-icons/ri";
import { MdOutlineDiscount } from "react-icons/md";
import ImageIcon from "@material-ui/icons/Image";
import CancelIcon from "@material-ui/icons/Cancel";

const pureIcons = {
  BsFillChatTextFill: forwardRef((props, ref) => (
    <BsFillChatTextFill {...props} ref={ref} />
  )),
  HiTranslate: forwardRef((props, ref) => <HiTranslate {...props} ref={ref} />),
  MdOutlineAddBusiness: forwardRef((props, ref) => (
    <MdOutlineAddBusiness {...props} ref={ref} />
  )),
  GoPackage: forwardRef((props, ref) => <GoPackage {...props} ref={ref} />),
  BiShow: forwardRef((props, ref) => <BiShow {...props} ref={ref} />),
  BsChevronLeft: forwardRef((props, ref) => (
    <BsChevronLeft {...props} ref={ref} />
  )),
  BsChevronRight: forwardRef((props, ref) => (
    <BsChevronRight {...props} ref={ref} />
  )),
  PowerSettingsNewIcon: forwardRef((props, ref) => (
    <PowerSettingsNewIcon {...props} ref={ref} />
  )),
  AiOutlinePlus: forwardRef((props, ref) => (
    <AiOutlinePlus {...props} ref={ref} />
  )),
  AiOutlineCheck: forwardRef((props, ref) => (
    <AiOutlineCheck {...props} ref={ref} />
  )),
  RxCross2: forwardRef((props, ref) => <RxCross2 {...props} ref={ref} />),
  FaExternalLinkAlt: forwardRef((props, ref) => (
    <FaExternalLinkAlt {...props} ref={ref} />
  )),
  FaShippingFast: forwardRef((props, ref) => (
    <FaShippingFast {...props} ref={ref} />
  )),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  NavigateBeforeIcon: forwardRef((props, ref) => (
    <NavigateBeforeIcon {...props} ref={ref} />
  )),
  NavigateNextIcon: forwardRef((props, ref) => (
    <NavigateNextIcon {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  LockIcon: forwardRef((props, ref) => <LockIcon {...props} ref={ref} />),
  LockOpenIcon: forwardRef((props, ref) => (
    <LockOpenIcon {...props} ref={ref} />
  )),
  FiPackage: forwardRef((props, ref) => <FiPackage {...props} ref={ref} />),
  FiRefreshCw: forwardRef((props, ref) => <FiRefreshCw {...props} ref={ref} />),
  ImInfo: forwardRef((props, ref) => <ImInfo {...props} ref={ref} />),
  BsChatDots: forwardRef((props, ref) => <BsChatDots {...props} ref={ref} />),
  GiBoxUnpacking: forwardRef((props, ref) => (
    <GiBoxUnpacking {...props} ref={ref} />
  )),
  CheckBoxIcon: forwardRef((props, ref) => (
    <CheckBoxIcon {...props} ref={ref} />
  )),
  CheckBoxOutlineBlankIcon: forwardRef((props, ref) => (
    <CheckBoxOutlineBlankIcon {...props} ref={ref} />
  )),
  FaFileInvoice: forwardRef((props, ref) => (
    <FaFileInvoice {...props} ref={ref} />
  )),
  PrintIcon: forwardRef((props, ref) => <PrintIcon {...props} ref={ref} />),
  ThumbUpIcon: forwardRef((props, ref) => <ThumbUpIcon {...props} ref={ref} />),
  CheckCircleIcon: forwardRef((props, ref) => (
    <CheckCircleIcon {...props} ref={ref} />
  )),
  BlockIcon: forwardRef((props, ref) => <BlockIcon {...props} ref={ref} />),
  RiRefund2Fill: forwardRef((props, ref) => (
    <RiRefund2Fill {...props} ref={ref} />
  )),
  RiRefund2Line: forwardRef((props, ref) => (
    <RiRefund2Line {...props} ref={ref} />
  )),
  MdPreview: forwardRef((props, ref) => <MdPreview {...props} ref={ref} />),
  MdOutlineSummarize: forwardRef((props, ref) => (
    <MdOutlineSummarize {...props} ref={ref} />
  )),
  CategoriesIcon: forwardRef((props, ref) => (
    <CategoryIcon {...props} ref={ref} />
  )),
  DownloadIcon: forwardRef((props, ref) => (
    <MdDownloadForOffline {...props} ref={ref} />
  )),
  ProhibitedIcon: forwardRef((props, ref) => (
    <RiProhibitedLine {...props} ref={ref} />
  )),
  ProductMetadata: forwardRef((props, ref) => (
    <RiProductHuntLine {...props} ref={ref} />
  )),
  DiscountIcon: forwardRef((props, ref) => (
    <MdOutlineDiscount {...props} ref={ref} />
  )),
  BackArrowIcon: forwardRef((props, ref) => <ArrowBack {...props} ref={ref} />),
  ImageIcon: forwardRef((props, ref) => <ImageIcon {...props} ref={ref} />),
  CancelIcon: forwardRef((props, ref) => <CancelIcon {...props} ref={ref} />),
};

export default pureIcons;
