import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import { Badge, MenuItem, Select } from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AddBusinessCat from "./add_business_cat";
import EditBusinessCat from "./edit_business_cat";

const Index = () => {
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [tableData, setTableData] = useState<any>();

  const _tableRef = useRef<any>();
  const _business_categories = useRef<any>([]);

  const _AutoControlPanelRef = useRef<any>();
  const _EditRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    const url = `/business_category_discounts.json?locale=en`;
    setWorking(true);
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setTableData(result.business_category_discounts);
        setWorking(false);
      });
  };

  const reload = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Supplier",
      field: "supplier",
      sorting: false,
    },
    {
      title: "Category",
      field: "category",
      sorting: false,
    },
    {
      title: "Discount Rate",
      field: "discount_rate",
      sorting: false,
    },
  ];
  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      <AddBusinessCat ref={_AutoControlPanelRef} callback={reload} />
      <EditBusinessCat
        business_categories={_business_categories || []}
        ref={_EditRef}
        callback={reload}
      />
      <Table
        title={"Supplier Category Discounts"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          searchFieldStyle: {
            width: 280,
          },
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category discounts" },
        }}
        columns={columns}
        tableRef={_tableRef}
        data={tableData}
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.DiscountIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              _AutoControlPanelRef.current.handleOpen();
            },
            tooltip: "Add new discount",
            isFreeAction: true,
          },
          (rowData) => ({
            icon: () => <pureIcons.Edit />,
            onClick: () => {
              _EditRef.current.handleOpen(rowData);
              _business_categories.current = rowData.business_categories;
            },
            tooltip: "Edit",
          }),
        ]}
      />
    </div>
  );
};

export default Index;
