import React, { useState } from 'react';
import { TextField, Button, IconButton, Card, CardContent, InputAdornment, Tooltip } from '@material-ui/core';
import PureIcons from '../shared/pure_icons';

const EditCustomizationPage = ({
  customizations,
  productType,
  addCustomization,
  handleCustomizationsInputChange,
  handleCustTranslateParagraph,
  removeCustomizationFields,
  handleImageChange,
  fileInputRef,
  handleDeleteImage,
  handleVaraiationChange,
  classes
}) => {
  const imageStyle = {
    width: '150px',
    height: '150px',
    objectFit: 'scale-down',
  };

  return (
    <div>
      {customizations.map((customization, index) => (
        <Card key={index} style={{ marginBottom: "1rem" }}>
          <CardContent>
            <div className="row pb-4">
              <div className="col">
                <TextField
                  name={`name_en`}
                  fullWidth
                  multiline
                  placeholder="Name (English)"
                  value={customization[`name_en`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Translate Name">
                          <IconButton
                            onClick={() =>
                              handleCustTranslateParagraph(customization["name_en"], index, "name")
                            }
                            style={{ color: "black", boxShadow: "none" }} // Remove hover shadow
                          >
                            <PureIcons.HiTranslate />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  name={`name_ar`}
                  fullWidth
                  multiline
                  placeholder="Name (Arabic)"
                  value={customization[`name_ar`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>
            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`description_en`}
                  fullWidth
                  multiline
                  placeholder="Description (English)"
                  value={customization[`description_en`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Translate Description">
                          <IconButton
                            onClick={() =>
                              handleCustTranslateParagraph(customization["description_en"], index, "description")
                            }
                            style={{ color: "black", boxShadow: "none" }} // Remove hover shadow
                          >
                            <PureIcons.HiTranslate />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  name={`description_ar`}
                  fullWidth
                  multiline
                  placeholder="Description (Arabic)"
                  value={customization[`description_ar`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`price`}
                  fullWidth
                  label="Price"
                  value={customization[`price`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`maxQuantity`}
                  fullWidth
                  label="Max Quantity"
                  value={customization[`maxQuantity`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  name={`eta`}
                  fullWidth
                  label="ETA"
                  value={customization[`eta`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`dimensions`}
                  fullWidth
                  label="Dimensions"
                  value={customization[`dimensions`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  name={`shipping`}
                  fullWidth
                  label="Shipping"
                  value={customization[`shipping`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`weight`}
                  fullWidth
                  label="Weight"
                  value={customization[`weight`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  name={`weightFLOZ`}
                  fullWidth
                  label="Weight Liquid"
                  value={customization[`weightFLOZ`]}
                  onChange={(e) => handleCustomizationsInputChange(index, e)}
                />
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <TextField
                  name={`color`}
                  fullWidth
                  label="Color"
                  value={customization[`varaiations`][0]?.option || ''}
                  onChange={(e) => handleVaraiationChange(index, e)}
                />
              </div>
              <div className="col">
                <TextField
                  name={`size`}
                  fullWidth
                  label="Size"
                  value={customization[`varaiations`][1]?.option || ''}
                  onChange={(e) => handleVaraiationChange(index, e)}
                />
              </div>
            </div>


            <div className="row pt-3 pb-3">
              <div className="col">
                <div style={{ width: "100%" }}>
                  <input
                    type="file"
                    name="images"
                    id={`single-photo-input-${index}`}
                    style={{ display: 'none' }}
                    className='hide'
                    accept="image/*"
                    multiple
                    onChange={(event) => {
                      handleImageChange(index, event)
                    }}
                    ref={fileInputRef}
                  />
                  <label htmlFor={`single-photo-input-${index}`} style={{ width: "100%" }}>
                    <Button
                      id={`image-btn-${index}`}
                      key={`image-btnkey-${index}`}
                      component="span"
                      fullWidth // Set fullWidth to true to make the button full width
                      style={{
                        backgroundColor: "#90C4BD",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      Upload Images
                    </Button>
                  </label>
                </div>

                <div className={classes.imageContainer}>
                  {customization.showImage.length > 0 &&
                    customization.showImage.map((preview, imgIndex) => (
                      <div key={imgIndex} className={classes.imageItem}>
                        <img
                          src={preview}
                          alt={`Product Image ${imgIndex + 1}`}
                          style={imageStyle}
                        />
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          component="span"
                          className={classes.deleteButton}
                          onClick={() => handleDeleteImage(imgIndex, index)} // Call the handleDeleteImage method on button click
                        >
                          <PureIcons.Delete />
                        </IconButton>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="row pb-2">
              <div className="col">
                <Button
                  onClick={() => removeCustomizationFields(index)}
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    color: "white",
                  }}
                >
                  Remove Customization
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}

      {(productType === 'variable' || ((productType !== 'variable') && customizations?.length !== 1) ) && (
        <div className="row pb-3">
          <div className="col">
            <Button
              onClick={addCustomization}
              style={{
                width: "100%",
                backgroundColor: "#90C4BD",
                color: "white",
              }}
            >
              Add Customization
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCustomizationPage;
