import React, { useEffect } from 'react';
import Table from '../../shared/tables/material_table';
import { BsChatDots } from 'react-icons/bs';
import { GiBoxUnpacking } from 'react-icons/gi'
import WarehouseProcessForm from './_wh_process_form';
import { List, ListItem, ListItemText, Badge } from '@material-ui/core';
import ShowOrder from "../_show";
import $ from "jquery";
import { withSnackbar } from 'notistack';
import Moment from 'moment'
import Backdrop from '../../shared/backdrop'
import { FiRefreshCw } from 'react-icons/fi'
import pureIcons from '../../shared/pure_icons'


class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			muiTableKey: 0,
			total: null,
		};
		this._tableRef = React.createRef();
		this._WarehouseProcessFormRef = React.createRef();
		this._ShowOrderRef = React.createRef();
	}

	reloadData = (fullReload = false) => {
		if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 })
		this._tableRef.current.onQueryChange();
	}

	handleUpdate = (tracking, oldValue, newValue, columnDef) => {
		let self = this;
		let url, data;
		if (oldValue === newValue || newValue === '' || newValue === null) return;
		switch (columnDef.field) {
			case 'tracking':
				url = `/order_item_stages/update_stage_tracking`
				data = { tracking: tracking, new_tracking: newValue, stage: 'vendor_shipped' }
				break;
			case 'eta':
				url = `/order_item_stages/update_stage`
				data = { tracking: tracking, eta: newValue, stage: 'vendor_shipped' }
				break;
			case 'group_id':
				url = `/order_item_stages/update_stage`
				data = { tracking: tracking, group_id: newValue, stage: 'vendor_shipped' }
				break;
			default:
		}
		this.setState({ working: true });
		$.ajax({
			url: url,
			type: 'PATCH',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}


	render() {
		const columns = [
			{ title: 'Tracking', field: 'tracking', sorting: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 400 }, filtering: false },
			{ title: 'Order Number', field: 'order_number', sorting: false, filtering: false, editable: 'never', render: rowData => (<Badge badgeContent={rowData.new_customer ? 'new' : null} color='secondary'>{rowData.order_number}</Badge>) },
			{ title: 'Invoice', field: 'invoice', sorting: false, editable: 'never', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 350 }, filtering: false },
			{ title: 'Vendor', field: 'vendor', filtering: false, editable: 'never', sorting: false },
			{
				title: 'ETA', field: 'eta', type: "date", dateSetting: { format: 'dd/MM/yyyy' }, render: rowData => {
					return rowData.flag ? <div style={{ color: "#FF0000" }}>{Moment(rowData.eta).format('DD/MM/YYYY')}</div> : <div>{Moment(rowData.eta).format('DD/MM/YYYY')}</div>
				}, filtering: false
			},
			{ title: 'Date', field: 'created_at', filtering: false, editable: 'never' },
			{ title: 'Filter', field: 'group_id', sorting: false, lookup: { ISSUE: 'ISSUE', DELIVERED: 'DELIVERED', RETURN: 'RETURN', EXPENSIVE: 'EXPENSIVE', NO_TRACKING: 'NO TRACKING', LOCAL_DIRECT: 'LOCAL_DIRECT', ___: '---' } },
		]
		return (
			<div className='bg-white rounded'>
				<ShowOrder ref={this._ShowOrderRef} />
				<Backdrop open={this.state.working} />
				<WarehouseProcessForm ref={this._WarehouseProcessFormRef} reloadData={this.reloadData} />
				<Table
					title={'Shipped From the Vendors'}
					key={this.state.muiTableKey}
					options={{ debounceInterval: 500, actionsColumnIndex: -1, focus: true }}
					columns={columns}
					tableRef={this._tableRef}
					cellEditable={{
						onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
							return new Promise((resolve, reject) => {
								setTimeout(() => {
									this.handleUpdate(rowData.raw_tracking, oldValue, newValue, columnDef);
									resolve();
								}, 500)
							});
						}
					}}
					actions={
						[{
							icon: () => <FiRefreshCw />,
							onClick: () => {
								this.reloadData()
							},
							isFreeAction: true,
							tooltip: 'Refresh'
						},
						rowData => ({
							icon: () => <GiBoxUnpacking />,
							tooltip: <span>Ship {rowData.vendor}</span>,
							onClick: (event, rowData) => {
								this._WarehouseProcessFormRef.current.handleOpen(rowData.raw_tracking, rowData.order_number, rowData.vendor, rowData.id, rowData.invoice);
							},
							position: 'row',
						}),
						rowData => ({
							icon: () => <BsChatDots />,
							tooltip: rowData.notes.length !== 0 && <List dense={true}>
								{rowData.notes.map(note =>
									<ListItem key={note}>
										<ListItemText primary={note} />
									</ListItem>,
								)}
							</List>,
							position: 'row',
							disabled: rowData.notes.length === 0
						})
						]}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
							}
							let url = `/order_item_stages/vendor_shipped.json?`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
								.then(response => response.json())
								.then((result) => {
									resolve({
										data: result.orders,
										page: result.page - 1,
										totalCount: result.total,
									});
									window.breadcrumb.addBreadcrumb(result.breadcrumb);
								})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(Index)
