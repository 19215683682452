import React, { useEffect, useState } from 'react';
import { Card, TextField, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useSnackbar } from 'notistack';
import $ from "jquery";


const EditCouponForm = ({
    editId,
    callUpdateApi,
    setCallUpdateApi,
    setEditDetails,
    reloadData,
    dropdownData
}) => {
    const [couponNumber, setCouponNumber] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState<any>('');
    const [maxDiscount, setMaxDiscount] = useState('');
    const [isCouponUsedMultipleTimes, setIsCouponUsedMultipleTimes] = useState(false);
    const [couponType, setCouponType] = useState('');
    const [isMinTotalRequired, setIsMinTotalRequired] = useState(false);
    const [minTotalType, setMinTotalType] = useState('');
    const [minTotalNeeded, setMinTotalNeeded] = useState('');
    const [isCoupnUseLimited, setIsCoupnUseLimited] = useState(false);
    const [noOfUses, setNoOfUses] = useState('');
    const [isPaymentGatewayCoupon, setIsPaymentGatewayCoupon] = useState(false);
    const [paymentGateway, setPaymentGateway] = useState('');
    const [isUserWithActivityCoupon, setIsUserWithActivityCoupon] = useState(false);
    const [userType, setUserType] = useState('');
    const [activity, setActivity] = useState('');
    const [isSelectAllByVendor, setIsSelectAllByVendor] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
    const [vendorsOptions, setVendorsOptions] = useState<any>([{
        id: "",
        name: 'All vendors'
    }]);
    const [isSelectByRegion, setIsSelectByRegion] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]);


    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const formArray = vendorsOptions.concat(dropdownData?.vendors_dropdown);
        setVendorsOptions(formArray);
    }, [dropdownData?.vendors_dropdown]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/coupons/${editId}.json?locale=en`);
                const jsonData = await response.json();
                setCouponNumber(jsonData.coupon_number);
                setStartDate(jsonData.start_date);
                setEndDate(jsonData.end_date);
                setDiscountType(jsonData.discount_type);
                setDiscountValue(jsonData.discount_value);
                setMaxDiscount(jsonData.max_discount_amount);
                setIsCouponUsedMultipleTimes(jsonData.reusable);
                setCouponType(jsonData.coupon_type);
                if (jsonData.min_total || jsonData.min_total_type) {
                    setIsMinTotalRequired(true);
                    setMinTotalType(jsonData.min_total_type);
                    setMinTotalNeeded(jsonData.min_total);
                }
                if (jsonData.number_of_use) {
                    setIsCoupnUseLimited(true);
                    setNoOfUses(jsonData.number_of_use);
                }
                if (jsonData.payment_gateway) {
                    setIsPaymentGatewayCoupon(true);
                    setPaymentGateway(jsonData.payment_gateway);
                }
                if (jsonData.user_type || jsonData.activity) {
                    setIsUserWithActivityCoupon(true);
                    setUserType(jsonData.user_type);
                    setActivity(jsonData.activity);
                }
                if (jsonData.vendors_by_region == "All vendors") {
                    setSelectedVendors([""]);
                }
                else {
                    setSelectedVendors(jsonData.vendor_ids);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [editId]);

    const getDropdownValue = (dropdown: Array<any>, value: any): any => {
        if (dropdown?.findIndex((item: any) => item == value) == -1) {
            return null;
        } else {
            return dropdown?.findIndex((item: any) => item == value)
        }
    }

    useEffect(() => {
        if (callUpdateApi) {
            const payload = {
                coupon: {
                    user_id: dropdownData?.user_id,
                    coupon_number: couponNumber,
                    start_date: startDate,
                    end_date: endDate,
                    discount_type: getDropdownValue(dropdownData?.discount_type_dropdown, discountType),
                    discount_value: discountValue,
                    max_discount_amount: maxDiscount,
                    reusable: isCouponUsedMultipleTimes,
                    coupon_type: getDropdownValue(dropdownData?.coupon_type_dropdown, couponType),
                    min_total_check: isMinTotalRequired ? 1 : 0,
                    min_total_type: isMinTotalRequired
                        ? getDropdownValue(dropdownData?.min_total_type_dropdown, minTotalType) : null,
                    min_total: isMinTotalRequired ? minTotalNeeded : null,
                    limited_use: isCoupnUseLimited ? 1 : 0,
                    number_of_use: isCoupnUseLimited ? noOfUses : null,
                    payment_gateway_check: isPaymentGatewayCoupon ? 1 : 0,
                    payment_gateway: isPaymentGatewayCoupon
                        ? getDropdownValue(dropdownData?.payment_gateway_type_dropdown, paymentGateway) : null,
                    user_type_check: isUserWithActivityCoupon ? 1 : 0,
                    user_type: isUserWithActivityCoupon
                        ? getDropdownValue(dropdownData?.user_type_dropdown, userType) : null,
                    activity: isUserWithActivityCoupon
                        ? getDropdownValue(dropdownData?.activity_type_dropdown, activity) : null,
                    // select_by_vendor: isSelectAllByVendor ? 1 : 0,
                    // vendor_ids: isSelectAllByVendor ? [] : vendorByRegion,
                    // select_by_region: isSelectByRegion ? 1 : 0,
                    // vendors_by_region: isSelectByRegion ? selectedRegions : 'All Vendors'
                    select_by_vendor: isSelectAllByVendor ? 1 : 0,
                    vendor_ids: selectedVendors,
                    select_by_region: isSelectByRegion ? 1 : 0,
                },
                vendor_by_region: selectedRegions,
                locale: "en",
                id: editId
            };

            $.ajax({
                url: `/coupons/${editId}`,
                type: "PUT",
                data: payload,
                dataType: "json",
                success: function (response: any) {
                    console.log('response', response);
                    enqueueSnackbar(response.msg, {
                        variant: "success",
                    });
                    reloadData();
                    setCallUpdateApi(false);
                    setEditDetails(false);
                },
                error: function (response) {
                    enqueueSnackbar(response.responseJSON.msg, {
                        variant: "error",
                    });
                    setCallUpdateApi(false);
                },
            });
        }
    }, [callUpdateApi])


    return (
        <Card style={{ minHeight: '90vh' }}>
            <div className="container pb-5">
                <div className="col">
                    <TextField
                        fullWidth
                        margin="normal"
                        name='couponNumber'
                        label='Coupon Number'
                        value={couponNumber}
                        onChange={(e: any) => { setCouponNumber(e.target.value) }}
                    // {...(this.state.errors.name && { error: true, helperText: this.state.errors.name })}
                    />
                </div>
                <div className='row mt-2'>
                    <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                disableFuture={true}
                                //disablePast={false}
                                label="Start Date"
                                value={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                animateYearScrolling
                                format="dd/MM/yyyy"
                                autoOk={true}
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                // disableFuture={true}
                                // disablePast={false}
                                fullWidth
                                label="End Date"
                                value={endDate}
                                onChange={(date: any) => setEndDate(date)}
                                animateYearScrolling
                                format="dd/MM/yyyy"
                                autoOk={true}
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="col mt-2">
                    <TextField
                        name="discountType"
                        select
                        fullWidth
                        margin="normal"
                        label="Discount Type"
                        value={discountType}
                        onChange={(e: any) => { setDiscountType(e.target.value) }}
                    >
                        {dropdownData?.discount_type_dropdown?.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                </div>
                <div className='row'>
                    <div className="col-5">
                        <TextField
                            fullWidth
                            margin="normal"
                            name='discountValue'
                            label='Discount Value'
                            value={discountValue}
                            onChange={(e: any) => { setDiscountValue(e.target.value) }}
                            type='number'
                        />
                    </div>
                    <div className="col-7">
                        <TextField
                            fullWidth
                            margin="normal"
                            name='maxDiscount'
                            label='Max Discount Amount (OPTIONAL)'
                            value={maxDiscount}
                            onChange={(e: any) => { setMaxDiscount(e.target.value) }}
                            type='number'
                        />
                    </div>
                </div>
                <div className="mt-3 form-check" >
                    <input className="form-check-input"
                        type="checkbox"
                        name="isCouponUsedMultipleTimes"
                        checked={isCouponUsedMultipleTimes}
                        onChange={(e: any) => { setIsCouponUsedMultipleTimes(e.target.checked); }}
                    />
                    <label className="form-check-label">
                        Coupon will be used multiple times by user?
                    </label>
                </div>
                <div className="col mt-1">
                    <TextField
                        name="couponType"
                        select
                        fullWidth
                        margin="normal"
                        label="Coupon Type"
                        value={couponType}
                        onChange={(e: any) => { setCouponType(e.target.value) }}
                    >
                        {dropdownData?.coupon_type_dropdown?.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                </div>
                <div className="mt-3 form-check" >
                    <input className="form-check-input"
                        type="checkbox"
                        name="isMinTotalRequired"
                        checked={isMinTotalRequired}
                        onChange={(e: any) => { setIsMinTotalRequired(e.target.checked); }}
                    />
                    <label className="form-check-label">
                        Require min total?
                    </label>
                </div>
                {isMinTotalRequired &&
                    <div className='row'>
                        <div className="col-6">
                            <TextField
                                fullWidth
                                name="minTotalType"
                                select
                                margin="normal"
                                label="Minimum Total Type"
                                value={minTotalType}
                                onChange={(e: any) => { setMinTotalType(e.target.value) }}
                            >
                                {dropdownData?.min_total_type_dropdown?.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-6">
                            <TextField
                                fullWidth
                                margin="normal"
                                name='minTotalNeeded'
                                label='Minimum Total Needed'
                                value={minTotalNeeded}
                                onChange={(e: any) => { setMinTotalNeeded(e.target.value) }}
                                type='number'
                            />
                        </div>
                    </div>
                }

                <div className="mt-3 form-check" >
                    <input className="form-check-input"
                        type="checkbox"
                        name="isCoupnUseLimited"
                        checked={isCoupnUseLimited}
                        onChange={(e: any) => { setIsCoupnUseLimited(e.target.checked); }}
                    />
                    <label className="form-check-label">
                        Limited use coupon?
                    </label>
                </div>
                {isCoupnUseLimited &&
                    <div className="col-6">
                        <TextField
                            fullWidth
                            margin="normal"
                            name='noOfUses'
                            label='Number of Uses'
                            value={noOfUses}
                            onChange={(e: any) => { setNoOfUses(e.target.value) }}
                            type='number'
                        />
                    </div>
                }

                <div className="mt-3 form-check" >
                    <input className="form-check-input"
                        type="checkbox"
                        name="isPaymentGatewayCoupon"
                        checked={isPaymentGatewayCoupon}
                        onChange={(e: any) => { setIsPaymentGatewayCoupon(e.target.checked); }}
                    />
                    <label className="form-check-label">
                        Payment gateway coupon?
                    </label>
                </div>
                {isPaymentGatewayCoupon &&
                    <div className="col-6">
                        <TextField
                            fullWidth
                            name="paymentGateway"
                            select
                            margin="normal"
                            label="Payment Gateway"
                            value={paymentGateway}
                            onChange={(e: any) => { setPaymentGateway(e.target.value) }}
                        >
                            {dropdownData?.payment_gateway_type_dropdown?.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </TextField>
                    </div>
                }
                <div className="mt-3 form-check" >
                    <input className="form-check-input"
                        type="checkbox"
                        name="isUserWithActivityCoupon"
                        checked={isUserWithActivityCoupon}
                        onChange={(e: any) => { setIsUserWithActivityCoupon(e.target.checked); }}
                    />
                    <label className="form-check-label">
                        User type with activity coupon?
                    </label>
                </div>
                {isUserWithActivityCoupon &&
                    <div className='row'>
                        <div className="col-6">
                            <TextField
                                fullWidth
                                name="userType"
                                select
                                margin="normal"
                                label="User Type"
                                value={userType}
                                onChange={(e: any) => { setUserType(e.target.value) }}
                            >
                                {dropdownData?.user_type_dropdown?.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-6">
                            <TextField
                                fullWidth
                                name="activity"
                                select
                                margin="normal"
                                label="Activity"
                                value={activity}
                                onChange={(e: any) => { setActivity(e.target.value) }}
                            >
                                {dropdownData?.activity_type_dropdown?.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                }
                <div className='mt-4' style={{ fontSize: '12px' }}>* Please select from 'Select by region' if coupon type is shipping</div>
                <div style={{ color: "red", fontSize: '12px' }}>* Please select 'Select all or by vendor' if no change will be made on vendors</div>
                <div >Vendors</div>
                {
                    !isSelectByRegion &&
                    <>
                        <div className="mt-2 form-check" >
                            <input className="form-check-input"
                                type="checkbox"
                                name="isSelectAllByVendor"
                                checked={isSelectAllByVendor}
                                onChange={(e: any) => { setIsSelectAllByVendor(e.target.checked); }}
                            />
                            <label className="form-check-label">
                                Select all or by vendor
                            </label>
                        </div>
                        {isSelectAllByVendor &&
                            <>
                                <div className="col-6">
                                    <Select
                                        fullWidth
                                        placeholder='Select Vendors'
                                        value={selectedVendors}
                                        onChange={(e) => {
                                            console.log('e', e.target.value);
                                            setSelectedVendors(e.target.value as string[])
                                        }}
                                        multiple
                                    >
                                        {vendorsOptions?.map((option: any) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </>
                        }
                    </>
                }

                {
                    !isSelectAllByVendor &&
                    <>
                        <div className="pt-2 form-check" >
                            <input className="form-check-input"
                                type="checkbox"
                                name="isSelectByRegion"
                                checked={isSelectByRegion}
                                onChange={(e: any) => { setIsSelectByRegion(e.target.checked); }}
                            />
                            <label className="form-check-label">
                                Select by region
                            </label>
                        </div>
                        {isSelectByRegion &&
                            <>
                                <div className="col-6">
                                    <Select
                                        fullWidth
                                        placeholder='Select Regions'
                                        value={selectedRegions}
                                        onChange={(e) => {
                                            console.log('e', e);
                                            setSelectedRegions(e.target.value as string[])
                                        }}
                                        multiple
                                    >
                                        {dropdownData?.region_by_dropdown?.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </>
                        }
                    </>
                }

            </div>
        </Card >
    );
};

export default EditCouponForm;
