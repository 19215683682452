import React from "react";

export default class CustomersList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='d-none d-print-block '>
        <div className="row m-2">
          <h6>{this.props.title}</h6>
          <p>{this.props.customers_count} customers in this shipment</p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Order #</th>
                <th scope="col">Item Name</th>
                <th scope="col" className="text-center">QTY</th>
                <th scope="col" className="text-center">Price</th>
                <th scope="col" className="text-center">Total</th>
              </tr>
            </thead>
            <tbody>
            {this.props.products.map(product =>
              <tr>
                <th scope="row">{product.order_number}</th>
                <td>{product.name_50}</td>
                <td>{product.qty}</td>
                <td>${product.price}</td>
                <td>${(product.qty * product.price).toFixed(2)}</td>
              </tr>
            )}
              <tr className="table-light">
                <td className="text-end fw-bolder" colSpan="4">Total shipment value</td>
                <td>${this.props.products.reduce((a, v) => a = a + (v.qty * v.price), 0).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
