import React from 'react'
import Table from '../shared/tables/material_table'
import {withSnackbar} from 'notistack'
import pureIcons from '../shared/pure_icons'
import {Backdrop} from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import ShowCart from './_show_cart'

class CartReviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      errors: {}
    }
    this._tableRef = React.createRef()
    this._showCartRef = React.createRef()
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  handleClose = () => {
    this.setState({open: false})
  }
  reloadData = e => {
    this._tableRef.current.onQueryChange()
  }

  render() {
    const columns = [
      {title: 'ID', field: 'id'},
      {title: 'Name', field: 'name'},
      {title: 'Phone Number', field: 'phone_number'},
      {title: 'Items', field: 'items'},
      {title: 'Status', field: 'status'},
      {title: 'Last update', field: 'updated_at'},

    ]
    const body = (
      <>
        <div className='bg-white rounded'>
          <ShowCart ref={this._showCartRef}/>
          <Backdrop open={this.state.submitting}/>
          <Table
            title={`Review Requests`}
            options={{
              toolbar: true,
              sorting: true,
              filtering: false,
              pageSize: 10,
              pageSizeOptions: [10],
              actionsColumnIndex: -1,

            }}
            localization={{toolbar: {searchPlaceholder: 'phone number'}}}
            columns={columns}
            tableRef={this._tableRef}
            actions={[
              rowData => ({
                icon: () => <pureIcons.ImInfo/>,
                tooltip: <span>Show User's Cart</span>,
                onClick: () => {
                  this._showCartRef.current.handleOpen(
                    rowData.cart_id,
                    rowData.name
                  )
                },
                position: 'row',
              })
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                var filters
                if (query.filters.length > 0) {
                  filters = query.filters.map(col => ({
                    field: col.column.field,
                    value: col.value
                  }))
                }
                let url = `/carts/review_requests.json?`
                url += 'per_page=' + query.pageSize
                url += '&page=' + (query.page + 1)
                if (query.filters.length > 0) {
                  url += '&filters=' + JSON.stringify(filters)
                }
                if (query.search) {
                  url += '&search=' + (query.search)
                }
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: result.requests,
                      page: result.page - 1,
                      totalCount: result.total
                    })
                  })
              })
            }
          />
        </div>
      </>
    )
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'
      >
        {body}
      </Modal>
    )
  }
}

export default withSnackbar(CartReviews)
