import React, { useEffect, useState } from "react";
import { useSnackbar, withSnackbar } from "notistack";
import Table from "../shared/tables/material_table";
import {
  Button,
  Drawer,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import PrintIcon from "@material-ui/icons/Print";
import InfoIcon from "@material-ui/icons/Info";
import PrintExpenseSheet from "./PrintExpenseSheet";
import $ from "jquery";
import ShowBalance from "./ShowBalance";
import pureIcons from "../shared/pure_icons";
import { addDays } from "date-fns";


const ExpensesIncurred = () => {
  const [showBalances, setShowBalances] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expensUsers, setExpenseUsers] = useState<any>({});
  const [dropdownData, setDropdownData] = useState<any>({});

  const _tableRef = React.createRef<any>();

  const today = new Date();
  const tomorrow = addDays(today, 1);

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(tomorrow);
  const [region, setRegion] = useState<string | null>("");
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    { title: "ID", field: "user_id" },
    { title: "Region", field: "region" },
    { title: "Expense type", field: "expense_type" },
    { title: "Process", field: "process" },
    { title: "Amount", field: "amount", type: "numeric" },
    { title: "Currency", field: "currency" },
    { title: "Created by", field: "created_by" },
    { title: "Date Created", field: "created_at", type: "date" },
    { title: "Debtor name", field: "debtor" },
    { title: "Reverese", field: "reverse" },
    { title: "Note", field: "note" },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const location = urlParams.get('location');
    console.log('location - ', location);
    setRegion(location);
  }, []);

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const toggleBalances = () => {
    setShowBalances(!showBalances);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const downloadSheet = () => {
    //window.open(`/expense/expense_report.json?utf8=✓&start_date=${fromDate}&end_date=${toDate}&region=${region}&commit=Print+Sheet`);
    if (region == 'global' || region == null) {
      enqueueSnackbar('Please select Location', {
        variant: "error",
      });
    } else {
      $.ajax({
        url: `/expense/expense_report.json?utf8=✓&start_date=${fromDate}&end_date=${toDate}&region=${region}&commit=Print+Sheet`,
        type: "GET",
        dataType: "csv",
        success: function (response: any) {
          const blob = new Blob([response.responseText], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'expense.csv';
          document.body.appendChild(a);
          a.click();
          enqueueSnackbar('Successfully Printed', {
            variant: "success",
          });
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);

        },
        error: function (response: any) {
          if (response.status === 200) {
            const blob = new Blob([response.responseText], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'expense.csv';
            document.body.appendChild(a);
            a.click();
            enqueueSnackbar('Successfully Printed', {
              variant: "success",
            });
            // setFromDate(null);
            // setToDate(null);
            // setRegion('');
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            enqueueSnackbar(JSON.parse(response.responseText).error, {
              variant: "error",
            });
          }
        },
      });
    }

    setShowModal(false);
  };

  return (
    <div className="bg-white rounded">
      <Table
        title="Expenses Incurred"
        options={{
          toolbar: true,
          filtering: false,
          sorting: true,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          {
            icon: () => <InfoIcon />,
            onClick: toggleBalances,
            isFreeAction: true,
            tooltip: "Show Balances",
          },
          // {
          //   icon: () => <PrintIcon />,
          //   onClick: openModal,
          //   isFreeAction: true,
          //   tooltip: "Export Expenses",
          // },
        ]}
        data={(query: any) =>
          new Promise((resolve) => {
            var filters: any;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/expense.json?locale=en`;
            url += "&per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setExpenseUsers(result?.expense_users);
                setDropdownData(result?.region_dropdown);
                resolve({
                  data: result.expenses,
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              });
          })
        }
      />
      <Drawer anchor="right" open={showBalances} onClose={toggleBalances}>
        <ShowBalance
          toggleBalances={toggleBalances}
          expensUsers={expensUsers}
        />
      </Drawer>
      <Modal
        isOpen={showModal}
        title="Export Expenses"
        handleClose={handleClose}
        minHeight="33vh"
        maxHeight="33%"
        maxWidth="sm"
        actions={
          <Button
            variant="contained"
            style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
            onClick={downloadSheet}
          >
            Print Sheet
          </Button>
        }
      >
        <PrintExpenseSheet
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          region={region}
          setRegion={setRegion}
          dropdownData={dropdownData}
        />
      </Modal>
    </div>
  );
};

export default withSnackbar(ExpensesIncurred);
