import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function TableSkeleton() {
	return (
		<div className='bg-white rounded'>
			<table className="table table-striped ">
				<tbody>
					<tr>
						<th scope="col"><Skeleton variant="rect" animation="wave"/></th>
						<th scope="col"><Skeleton variant="rect" animation="wave"/></th>
						<th scope="col"><Skeleton variant="rect" animation="wave"/></th>
						<th scope="col"><Skeleton variant="rect" animation="wave"/></th>
						<th scope="col"><Skeleton variant="rect" animation="wave"/></th>
					</tr>
					{[...Array(10)].map((e, i) =>
						<tr key={e}>
							<th><Skeleton variant="text" animation="wave"/></th>
							<td><Skeleton variant="text" animation="wave"/></td>
							<td><Skeleton variant="text" animation="wave"/></td>
							<td><Skeleton variant="text" animation="wave"/></td>
							<td><Skeleton variant="text" animation="wave"/></td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}
