import { withSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Backdrop from "../../shared/backdrop";
import { useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import Modal from "../../shared/modal/material_dialog";

const ProductShow = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [working, setWorking] = useState<boolean>(false);
  const [ordersData, setOrdersData] = useState<any>([]);
  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      getData(id);
    },
  }));

  const getData = (id) => {
    let url = `/prohibited_products/${id}.json`;
    setWorking(true);
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        if (result && !result.error) {
          setOrdersData(result);
        } else {
          // setOpen(false);
          setOrdersData([]);
          enqueueSnackbar(result.error, {
            variant: "error",
          });
        }

        setWorking(false);
      })
      .catch((err) => {
        setWorking(false);
        setOpen(false);
        setOrdersData(null);
        enqueueSnackbar(err.error, {
          variant: "error",
        });
      });
  };

  const clearData = () => {
    setOrdersData([]);
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const params = ordersData?.request_params;

  return (
    <div>
      <Modal
        title={`Product Statistics`}
        handleClose={() => onCloseModal()}
        disableBackdropClick={false}
        isOpen={open}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={""}
      >
        <>
          <Backdrop open={working} />
          {ordersData?.id && (
            <Card>
              <CardContent>
                <>
                  <Box
                    sx={{
                      display: "flex",
                      margin: "20px 0px",
                    }}
                  >
                    <div>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ minWidth: "150px" }}>
                              Name
                            </TableCell>
                            <TableCell>{ordersData?.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>{ordersData?.description}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>
                              <Link href={ordersData?.url}>
                                {ordersData?.url}
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Price</TableCell>
                            <TableCell>{ordersData?.price}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Brand</TableCell>
                            <TableCell>{ordersData?.brand}</TableCell>
                          </TableRow>

                          {Object.entries(params).map(([key, value]: any) => {
                            let finalValue = value;
                            if (key === "original_info") {
                              let val: any = "";
                              Object.entries(value).map(([key, value]: any) => {
                                val += `${[key]}: ${value},`;
                              });
                              finalValue = val.slice(0, -1);
                            } else if (
                              key === "prohibited" ||
                              key === "prohibited_by_refresh"
                            ) {
                              finalValue = value.toString();
                            }
                            return (
                              <TableRow>
                                <TableCell>{key}</TableCell>
                                {key === "product_screenshots" ? (
                                  <TableCell>
                                    {value?.map((val: any, i: any) => {
                                      return (
                                        <>
                                          <Link href={val}>{val}</Link>
                                          {i !== value?.length && <br />}
                                        </>
                                      );
                                    })}
                                  </TableCell>
                                ) : (
                                  <TableCell>{finalValue}</TableCell>
                                )}
                              </TableRow>
                            );
                          })}

                          <TableRow>
                            <TableCell>Shopper type</TableCell>
                            <TableCell>{ordersData?.shopper_type}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard category</TableCell>
                            <TableCell>{ordersData?.category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard sub category</TableCell>
                            <TableCell>{ordersData?.sub_category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard sub category one</TableCell>
                            <TableCell>
                              {ordersData?.sub_category_one}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User phone number</TableCell>
                            <TableCell>
                              {ordersData?.phone_number?.toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Number of times added</TableCell>
                            <TableCell>{ordersData?.count}</TableCell>
                          </TableRow>

                          {/* <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.description}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Size</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.size}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sub category</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.sub_category}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.category}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Metadata</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.metadata}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Width</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.width}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Color</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.color}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.region}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Style</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.style}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Additional information</TableCell>
                            <TableCell>
                              {
                                ordersData?.request_params
                                  ?.additional_information
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Quantity</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.quantity}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Weight</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.weight}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Product dimensions</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.product_dimensions}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Shipping to hub</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.shipping_to_hub}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Max quantity</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.max_quantity}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Min quantity</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.min_quantity}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Weight liquid</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.weight_liquid}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Hub delivery time</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.hub_delivery_time}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Product id</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.product_id}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Customization id</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.customization_id}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Original info</TableCell>
                            <TableCell>
                              Region: {originalInfo?.region}, Brand:{" "}
                              {originalInfo?.brand}, URL: {originalInfo?.url}{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>JS AI</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.js_ai}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Product screenshot</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.product_screenshots?.map(
                                (val) => (
                                  <Link href={val}>{val}</Link>
                                )
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Local business id</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.local_business_id}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Local business province id</TableCell>
                            <TableCell>
                              {
                                ordersData?.request_params
                                  ?.local_business_province_id
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.country}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Remote product id</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.remote_product_id}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Predict weight</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.predict_weight}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Predict dimensions</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.predict_dimensions}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Prohibited</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.prohibited?.toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Freight</TableCell>
                            <TableCell>
                              {ordersData?.request_params?.freight}
                            </TableCell>
                          </TableRow> */}
                        </TableBody>
                      </Table>
                    </div>
                    <div style={{ width: "30%", textAlign: "center" }}>
                      <img src={ordersData?.image} width="100%" />
                    </div>
                  </Box>
                </>

                {/* <CardActions style={{ justifyContent: "end" }}>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "#ec9a53",
                    color: "#ebeef0",
                    textTransform: "none",
                  }}
                  onClick={() => onCloseModal()}
                  // onClick={() => {
                  //   if (operation.current === "Add") {
                  //     addClick();
                  //   } else if (operation.current === "Edit") {
                  //     editClick();
                  //   }
                  // }}
                >
                  Back
                </Button>
              </CardActions> */}
              </CardContent>
            </Card>
          )}
        </>
      </Modal>
    </div>
  );
});

export default ProductShow;
