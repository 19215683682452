import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

const AddNewProduct = forwardRef((props: any, ref) => {
  const [data, setData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [ordersId, setOrdersId] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const _tableRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [formSMSValue, setFormSMSValue] = React.useState<any>({
    name_ar: "",
    name_en: "",
    name_ku: "",
  });
  const [arTextEr, setArTextEr] = React.useState<any>("");
  const [enTextEr, setEnTextEr] = React.useState<any>("");
  const [kdTextEr, setKdTextEr] = React.useState<any>("");

  useImperativeHandle(ref, () => ({
    handleOpen(id, name) {
      setOpen(true);
      setOrdersId(id);
    },
  }));

  const clearData = () => {
    setFormSMSValue({
      name_ar: "",
      name_en: "",
      name_ku: "",
    });
  };

  const onchangeFormValue = (event) => {
    setFormSMSValue({
      ...formSMSValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    if (formSMSValue.name_ar) {
      setArTextEr("");
    } else {
      setArTextEr("Required arabic name");
    }
    if (formSMSValue.name_en) {
      setEnTextEr("");
    } else {
      setEnTextEr("Required english name");
    }
    if (formSMSValue.name_ku) {
      setKdTextEr("");
    } else {
      setKdTextEr("Required kurdish name");
    }
  };

  const saveData = () => {
    validate();
    if (formSMSValue.name_ar && formSMSValue.name_en && formSMSValue.name_ku) {
      const payload = {
        name_en: formSMSValue.name_en,
        name_ar: formSMSValue.name_ar,
        name_ku: formSMSValue.name_ku,
      };
      let formData = new FormData();
      formData.append("metadata_product[name_en]", formSMSValue.name_en);
      formData.append("metadata_product[name_ar]", formSMSValue.name_ar);
      formData.append("metadata_product[name_ku]", formSMSValue.name_ku);
      //   formData.append("locale", "en");

      const url = `/metadata_products.json?locale=en`;
      fetch(url, { method: "POST", body: formData })
        .then((response) => response.json())
        .then((result) => {
          props.callback();
          clearData();
          handleClose();

          enqueueSnackbar(result.msg, {
            variant: "success",
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.responseJSON.error, {
            variant: "error",
          });
        });
    }
  };
  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };
  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="New Product Metadata"></CardHeader>
          <CardContent>
            <div className="ar-text">
              <div>
                <span>Name en : </span>

                <TextField
                  name="name_en"
                  variant="outlined"
                  size="small"
                  placeholder="Add English Name"
                  onChange={onchangeFormValue}
                  helperText={helperText(enTextEr)}
                  value={formSMSValue.name_en}
                />
              </div>
            </div>
            <div className="ar-text">
              <div>
                <span>Name ar : </span>
                <TextField
                  name="name_ar"
                  variant="outlined"
                  size="small"
                  placeholder="Add Arabic Name"
                  onChange={onchangeFormValue}
                  helperText={helperText(arTextEr)}
                  value={formSMSValue.name_ar}
                />
              </div>
            </div>
            <div className="ar-text">
              <div>
                <span>Name ku : </span>
                <TextField
                  value={formSMSValue.name_ku}
                  name="name_ku"
                  variant="outlined"
                  size="small"
                  placeholder="Add Kurdish Name"
                  onChange={onchangeFormValue}
                  helperText={helperText(kdTextEr)}
                />
              </div>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(AddNewProduct);
