import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  MenuList,
  Typography,
  CardContent,
  MenuItem,
  Badge
} from '@material-ui/core'
import Table from '../shared/tables/material_table'
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack'
import AsyncSearchBar from '../shared/react_async_autocomplete'
import Backdrop from '../shared/backdrop'
import $ from 'jquery'
import pureIcons from '../shared/pure_icons'
import ProcessBusinessOrder from './_process_business_orders'
import AddProduct from './_add_product'
import EditProduct from "../business_profiles/_edit_product";

class ShowBusiness extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      errors: {},
      open: false,
      orders: [],
      maxWidth: 'sm'
    }
    this._ProcessBusinessOrderRef = React.createRef();
    this._addProductRef = React.createRef();
    this._EditProductRef = React.createRef();
  }

  resetFrom = () => {
    this.setState({
      id: '',
      phone_number: '',
      kidus: '',
      address: '',
      business_name: '',
      orders: [],
      maxWidth: 'sm'
    })
  }
  handleOpen = () => {
    this.resetFrom()
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSelectChange = object => {
    const user_id = object ? object.user_id : this.state.id
    this.setState({ id: user_id, working: true, maxWidth: 'xl' })
    let self = this
    $.ajax({
      url: `/products/${user_id}/show_products`,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        self.setState({
          name: response.name,
          phone_number: response.phone_number,
          kidus: response.kidus,
          address: response.address,
          profile_id: response.profile_id,
          business_profile_id: response.business_profile_id,
          business_name: response.business_name,
          products: response.products,
          working: false,
        })
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }

  handleDelete = (rowData) => {
    let self = this;
      let data= {product_id: rowData.remote_product_id,pure_product_id: rowData.id};
      let product_id= rowData.remote_product_id;
    this.setState({ working: true });
    $.ajax({
      url: `/products/${product_id}/delete_product`,
      type: 'POST',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.setState({ working: false });
        self.handleSelectChange();
        self.props.enqueueSnackbar('Successfully Deleted!', { variant: 'success' });
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' });
      },
    });
  }
  render() {
    const { classes } = this.props
    const columns = [
      {
        title: '', field: 'image', sorting: false, cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '12%' }, filtering: false,
        render: (row) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <Badge badgeContent='DG' invisible={!row.dg} overlap="circular" color="error">
              <img style={{ height: '60px', width: '60px', objectFit: 'contain' }} alt={row.name} src={row.image}></img>
            </Badge>
          </div>
        )
      },
      { title: 'Product Name', field: 'name_en', sorting: false, cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      { title: 'Original QTY', field: 'original_qty', sorting: false, cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      { title: 'Max QTY', field: 'max_qty', cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      { title: 'Active', field: 'active', sorting: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200, width: '10%' } },
      { title: 'Created at', field: 'created_at', cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      { title: 'Updated at', field: 'updated_at', cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      { title: 'id', field: 'id', hidden: true },
      { title: 'remote_product_id', field: 'remote_product_id', hidden: true },
    ]
    const body = (
      <>
        <ProcessBusinessOrder ref={this._ProcessBusinessOrderRef} />
        <AddProduct ref={this._addProductRef} id={this.state.business_profile_id} handleSelectChange={this.handleSelectChange} />
        <EditProduct ref={this._EditProductRef} business_profile_id={this.state.business_profile_id} data={this.state.selectedRowData} reloadData={this.reloadData} handleSelectChange={this.handleSelectChange} />

        <Backdrop open={this.state.working} />
        {!this.state.id && (
          <AsyncSearchBar
            setCallBack={this.handleSelectChange}
            noOptionsMessage='No business found'
            placeholder='search business by phone number'
            url='/users/find_business.json'
          />
        )}
        <Card className={classes.root} hidden={!this.state.id}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <div className='row'>
                <div className='col-4'>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Id: <b>{this.state.id}</b>
                  </Typography>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Business ID: <b>{this.state.business_profile_id}</b>
                  </Typography>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Phone Number: <b>{this.state.phone_number}</b>
                  </Typography>
                  <Typography variant='subtitle1' color='textSecondary'>
                    Kidus: <b>{this.state.kidus}</b>
                  </Typography>
                  {this.state.business_name && (
                    <Typography variant='subtitle1' color='textSecondary'>
                      Business Name: <b>{this.state.business_name}</b>
                    </Typography>
                  )}

                </div>
                {/* <div className='col-8'>
                  <div className='row'>
                    <div className='col'>
                      <MenuList>
                        <MenuItem button onClick={() => this._addProductRef.current.handleOpen(this.state.business_profile_id)}>
                          <Typography variant='button'>
                            Add Product
                          </Typography>
                        </MenuItem>
                      </MenuList>
                    </div>
                  </div>
                </div> */}
              </div>
            </CardContent>
            <div className='row'>
              <div className='col'>
                <Table
                  title='Products'
                  options={{
                    toolbar: true,
                    search: false,
                    filtering: true,
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [5, 25, 100],
                    debounceInterval: 1000,
                  }}
                  columns={columns}
                  tableRef={this._tableRef}
                  data={this.state.products}
                  actions={[{
                      icon: pureIcons.Edit,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          selectedRowData: rowData,
                        }));
                        this._EditProductRef.current.handleOpen(rowData.id)},
                      position: 'row'
                    },
                    rowData => ({
                    icon: () => <pureIcons.Delete />,
                    tooltip: <span>Delete Product</span>,
                    onClick:  (event, rowData) => { window.confirm(`Are you sure you want to delete this product ?`) && this.handleDelete(rowData)},
                    position: 'row',
                    })]
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </>
    )

    const actions = (
      <Button
        variant='contained'
        hidden={!this.state.id}
        type='submit'
        onClick={this.resetFrom}
      >
        Clear
      </Button>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title={this.state.id ? this.state.name + ` (${this.state.phone_number})` : 'Search business by phone number'}
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='80%'
        maxWidth={this.state.maxWidth}
        actions={actions}
      >
        {body}
      </Modal>
    )
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    padding: 20,
    margin: 20,
    wordBreak: 'break-all'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 250,
    height: 250,
    objectFit: 'contain'
  }
})

export default withSnackbar(withStyles(useStyles)(ShowBusiness))
