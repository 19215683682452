import React, { useEffect, useState } from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import { Button } from "@material-ui/core";
import ProductShow from "./_product_show";
import ProductStatistic from "./_product_statistic";
import Backdrop from "../../shared/backdrop";

const Index = (props) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<any>(false);
  const [muiTableKey, setMuiTableKey] = useState<any>(0);

  const _tableRef = React.createRef<any>();
  const _showRef = React.createRef<any>();
  const _productStatisticRef = React.createRef<any>();

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const handleUpdates = (data, oldValue, newValue, columnDef) => {
    const payload = { active: !data.active };
    $.ajax({
      url: `/marketplace_vendor/${data.id}/update_status`,
      type: "PUT",
      data: payload,
      dataType: "json",
      success: function (response) {
        props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        reloadData();
      },
      error: function (response) {
        props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const columns = [
    {
      title: "Product Image",
      field: "image",
      filtering: false,
      sorting: false,
      editable: "never",
      render: (row) => <img src={row.image} width="150px" height="150px" />,
    },
    {
      title: "Product Name",
      field: "name",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Date",
      field: "created_at",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "2%" },
      sorting: false,
    },
    {
      title: "Vendor",
      field: "brand",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "10%" },
      sorting: false,
    },
  ];
  const productData = [
    {
      id: 1,
      image_link:
        "https://fastly.picsum.photos/id/1037/200/200.jpg?hmac=MMp-F1917NlyyHn_Bd0ZS6iR3v1-FLpGFkxYeQguinM",
      name: "New Antminer KS3 8.2Th/s Kaspa Kas Miner Most Profitable Bitmain KS3 3180W Crypto Miner Include APW12 Power Supply",
      date: "2022/12/30",
      vender: "	Pure Platform",
    },
    {
      id: 2,
      image_link:
        "https://fastly.picsum.photos/id/1037/200/200.jpg?hmac=MMp-F1917NlyyHn_Bd0ZS6iR3v1-FLpGFkxYeQguinM",
      name: "OEMGMINER Bitmain Antminer Dash Miner D9 Hashrate 1770G/s Power 2839W Bulid-in PSU Ready Stock",
      date: "2023/12/30",
      vender: "ebay",
    },
    {
      id: 3,
      image_link:
        "https://fastly.picsum.photos/id/1037/200/200.jpg?hmac=MMp-F1917NlyyHn_Bd0ZS6iR3v1-FLpGFkxYeQguinM",
      name: "New Antminer KS3 8.2Th/s Kaspa Kas Miner Most Profitable Bitmain KS3 3180W Crypto Miner Include APW12 Power Supply",
      date: "2024/12/30",
      vender: "Trendyol",
    },
    {
      id: 4,
      image_link:
        "https://fastly.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U",
      name: "New Antminer KS3 8.2Th/s Kaspa Kas Miner Most Profitable Bitmain KS3 3180W Crypto Miner Include APW12 Power Supply",
      date: "2023/10/30",
      vender: "amazon",
    },
    {
      id: 5,
      image_link:
        "https://fastly.picsum.photos/id/1037/200/200.jpg?hmac=MMp-F1917NlyyHn_Bd0ZS6iR3v1-FLpGFkxYeQguinM",
      name: "OEMGMINER Bitmain Antminer Dash Miner D9 Hashrate 1770G/s Power 2839W Bulid-in PSU Ready Stock",
      date: "2023/12/10",
      vender: "amazon",
    },
    {
      id: 6,
      image_link:
        "https://fastly.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U",
      name: "New Antminer KS3 8.2Th/s Kaspa Kas Miner Most Profitable Bitmain KS3 3180W Crypto Miner Include APW12 Power Supply",
      date: "2023/12/30",
      vender: "amazon",
    },
  ];

  useEffect(() => {
    _tableRef.current.onQueryChange();
  }, []);

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      {/* <CreateCard ref={this._createcardRef} reloadData={this.reloadData} />
      <ShowPayments ref={this._showpaymentsRef} reloadData={this.reloadData} /> */}
      <ProductStatistic ref={_productStatisticRef} />
      <ProductShow ref={_showRef} />
      <Table
        title={`Prohibited Products`}
        options={{
          toolbar: true,
          sorting: true,
          search: true,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                handleUpdates(rowData, oldValue, newValue, columnDef),
                  resolve();
              }, 500);
            });
          },
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          {
            icon: () => <pureIcons.Add />,
            onClick: () => {
              _productStatisticRef.current.handleOpen();
            },
            isFreeAction: true,
            tooltip: "Products Statistic",
          },
          (rowData) => ({
            icon: () => (
              <Button style={{ background: "#f6b67f", color: "white" }}>
                Show
              </Button>
            ),
            tooltip: <span>Show</span>,
            onClick: () => {
              _showRef.current.handleOpen(rowData.id, rowData.digits);
            },
            position: "row",
          }),
        ]}
        // data={ordersData}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/prohibited_products.json?locale=en`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.prohibited_products.flat(),
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              });
          })
        }
      />
    </div>
  );
};

export default withSnackbar(Index);
