import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from '../shared/modal/material_dialog'
import Table from '../shared/tables/material_table';
import pureIcons from '../shared/pure_icons';
import { Backdrop } from '@material-ui/core';
import $ from "jquery";
import CreateCard from '../operations/_create_card'
import ShowPayments from '../operations/_show_payments'


class CreditCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
      type: ''
    };
    this._tableRef = React.createRef();
    this._createcardRef = React.createRef()
    this._showpaymentsRef = React.createRef()


  }
  handleUpdates = (rowData, oldValue, newValue, columnDef) => {
		let self = this;
		let data;
    let id= rowData.id
		if (oldValue === newValue || newValue === '' || newValue === null) return;
		switch (columnDef.field) {
			case 'digits':
				data = {last4: newValue}
				break;
			case 'country':
				data = {country: newValue }
				break;
			case 'phone_number':
				data = {phone_number: newValue}
				break;
      case 'user_id':
				data = {user_id: newValue}
				break;
			default:
		}
		this.setState({ working: true });
		$.ajax({
			url: `/prepaid_card_accesses/${id}/edit`,
			type: 'PATCH',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  handleShowPayments = (rowData) => {
		let self = this;
    let id= rowData.id
		this.setState({ working: true });
		$.ajax({
			url: `/credit_cards/${id}/show_payments`,
			type: 'GET',
			data: id,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  handleDelete = (rowData) => {
		let self = this;
    let id= rowData.id
		this.setState({ working: true });
		$.ajax({
			url: `/prepaid_card_accesses/${id}`,
			type: 'DELETE',
			data: id,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const blackList = [
      { title: 'Last 4 Digits/Aqsati last6', field: 'digits', sorting: false, filtering: false, validate: rowData => rowData.digits.match(/^\d{0,4}$/) ? true : { isValid: false, helperText: 'Enter The Last 4 Digits/Aqsati last6' }, },
      { title: 'Country', field: 'country', sorting: false, filtering: false, validate: rowData => rowData.country.match(/^[A-Z]{0,2}$/) ?  true : { isValid: false, helperText: 'Enter Only 2 Capital Letters' } },
      { title: 'User Id', field: 'user_id', validate: rowData => rowData.user_id.match(/^\d{1,}$/) ?  true : { isValid: false, helperText: 'Enter Valid Id' } },
      { title: 'Phone number', field: 'phone_number', sorting: false, filtering: false , validate: rowData => rowData.phone_number.match(/^\d{1,}$/) ?  true : { isValid: false, helperText: 'Enter Valid Phone Number' } },
      { title: 'Created At', field: 'created_at', sorting: false,  filtering: false,editable:'never' },
      { title: 'Updated At', field: 'updated_at', sorting: false, filtering: false ,editable:'never'},
      { title: 'id', field: 'id',hidden: true},

    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <CreateCard
            ref={this._createcardRef}
            reloadData={this.reloadData}
          />
          <ShowPayments
            ref={this._showpaymentsRef}
            reloadData={this.reloadData}
          />
        <Table
          title={`Black List`}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10]

          }}
          cellEditable={{
						onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
							return new Promise((resolve, reject) => {
								setTimeout(() => {
                 this.handleUpdates(rowData, oldValue, newValue, columnDef),
									resolve();
								}, 500)
							});
						}
					}}
          columns={ blackList}
          tableRef={this._tableRef}
          actions={
            [
                {
                    icon: () => <pureIcons.AiOutlinePlus />,
                    onClick: () => {
                      this._createcardRef.current.handleOpen()
                    },
                    isFreeAction: true,
                    tooltip: 'Add New Credit'
                  },
                  {
                    icon: () => <pureIcons.FiRefreshCw />,
                    onClick: () => {
                      this.reloadData()
                    },
                    isFreeAction: true,
                    tooltip: 'Refresh'
                  },
                  rowData => ({
                    icon: () => <pureIcons.Delete />,
                    tooltip: <span>Delete Card</span>,
                    onClick:  (event, rowData) => { window.confirm(`Are you sure you want to delete this card ?`) && this.handleDelete(rowData)},
                    position: 'row',
                  }),
                  rowData => ({
                    icon: () => <pureIcons.ImInfo />,
                    tooltip: <span>Show Payments</span>,
                    onClick: () => {
                      this._showpaymentsRef.current.handleOpen(rowData.id,rowData.digits)
                    },
                     position: 'row',
                  })
            ]}
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/prepaid_card_accesses.json?&status=blacklist&`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.search) {
								url += '&search=' + (query.search)
							}
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.cards.flat(),
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    )
  }
}
class WhiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
      type:''
    };
    this._tableRef = React.createRef();
    this._createcardRef = React.createRef()
    this._showpaymentsRef = React.createRef()


  }
  handleUpdate = (rowData, oldValue, newValue, columnDef) => {
		let self = this;
		let data;
    let id= rowData.id
		if (oldValue === newValue || newValue === '' || newValue === null) return;
		switch (columnDef.field) {
      case 'digits':
				data = {last4: newValue}
				break;
			case 'country':
				data = {country: newValue }
				break;
			case 'phone_number':
				data = {phone_number: newValue}
				break;
      case 'user_id':
				data = {user_id: newValue}
				break;
			default:
		}
		this.setState({ working: true });
		$.ajax({
			url: `/prepaid_card_accesses/${id}/edit`,
			type: 'PATCH',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  handleDeleteWhite = (rowData) => {
		let self = this;
    let id= rowData.id
		this.setState({ working: true });
		$.ajax({
			url: `/prepaid_card_accesses/${id}`,
			type: 'DELETE',
			data: id,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  handleShowPayments = (rowData) => {
		let self = this;
    let id= rowData.id
		this.setState({ working: true });
		$.ajax({
			url: `/credit_cards/${id}/show_payments`,
			type: 'GET',
			data: id,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadData();
				self.props.enqueueSnackbar('successfully processed!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const whiteList = [
      { title: 'Last 4 Digits/Aqsati last6', field: 'digits', sorting: false, filtering: false, validate: rowData => rowData.digits.match(/^\d{0,4}$/) ? true : { isValid: false, helperText: 'Enter The Last 4 Digits/Aqsati last6' }, },
      { title: 'Country', field: 'country', sorting: false, filtering: false, validate: rowData => rowData.country.match(/^[A-Z]{0,2}$/) ?  true : { isValid: false, helperText: 'Enter Only 2 Capital Letters' } },
      { title: 'User Id', field: 'user_id', validate: rowData => rowData.user_id.match(/^\d{1,}$/) ?  true : { isValid: false, helperText: 'Enter Valid Id' } },
      { title: 'Phone number', field: 'phone_number', sorting: false, filtering: false , validate: rowData => rowData.phone_number.match(/^\d{1,}$/) ?  true : { isValid: false, helperText: 'Enter Valid Phone Number' } },
      { title: 'Created At', field: 'created_at', sorting: false,  filtering: false,editable:'never' },
      { title: 'Updated At', field: 'updated_at', sorting: false, filtering: false ,editable:'never'},
      { title: 'id', field: 'id',hidden: true},

    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <CreateCard
            ref={this._createcardRef}
            reloadData={this.reloadData}
          />
        <ShowPayments
            ref={this._showpaymentsRef}
            reloadData={this.reloadData}
          />
        <Table
          title={`White List`}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            exportButton: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10],
          }
          }
          cellEditable={{
						onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
							return new Promise((resolve, reject) => {
								setTimeout(() => {
                 this.handleUpdate(rowData, oldValue, newValue, columnDef),
									resolve();
								}, 500)
							});
						}
					}}
          columns={ whiteList}
          tableRef={this._tableRef}
          actions={
            [
                {
                    icon: () => <pureIcons.AiOutlinePlus />,
                    onClick: () => {
                      this._createcardRef.current.handleOpen()
                                        },
                    isFreeAction: true,
                    tooltip: 'Add New Credit'
                  },
                  {
                    icon: () => <pureIcons.FiRefreshCw />,
                    onClick: () => {
                      this.reloadData()
                    },
                    isFreeAction: true,
                    tooltip: 'Refresh'
                  },
                  rowData => ({
                    icon: () => <pureIcons.Delete />,
                    tooltip: <span>Delete Card</span>,
                    onClick:  (event, rowData) => { window.confirm(`Are you sure you want to delete this card ?`) && this.handleDeleteWhite(rowData)},
                    position: 'row',
                  }),
                  rowData => ({
                    icon: () => <pureIcons.ImInfo />,
                    tooltip: <span>Show Payments</span>,
                    onClick: () => {
                      this._showpaymentsRef.current.handleOpen(rowData.id,rowData.digits)
                    },
                     position: 'row',
                  })
            ]}
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/prepaid_card_accesses.json?&status=whitelist&`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.search) {
								url += '&search=' + (query.search)
							}
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.cards.flat(),
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    )
  }
}
class RejectedPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
      type:''
    };
    this._tableRef = React.createRef();
    this._createcardRef = React.createRef()


  }
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }

  render() {
    const payments = [
      { title: 'id', field: 'id', sorting: false, filtering: false},
      { title: 'Last 4 Digits/Aqsati last6', field: 'digits', sorting: false, filtering: false},
      { title: 'Amount', field: 'amount', sorting: false, filtering: false },
      { title: 'Phone number', field: 'phone_number', },
      { title: 'User type', field: 'user_type', sorting: false, filtering: false },
      { title: 'Card country', field: 'card_country', sorting: false,  filtering: false},
      { title: 'Payment method', field: 'payment_method', sorting: false, filtering: false},
      { title: 'Card in blacklist?', field: 'blacklist?', sorting: false, filtering: false },
      { title: 'Refunded?', field: 'refunded?', },
      { title: 'Created At', field: 'created_at', sorting: false,  filtering: false},
      { title: 'Rejected At', field: 'rejected_at', sorting: false, filtering: false},

    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.submitting} />
        <Table
          title={`Rejected Payments`}
          options={{
            toolbar: true,
            sorting: true,
            search: true,
            exportButton: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10],
          }
          }
          columns={ payments}
          tableRef={this._tableRef}
          actions={
            [

            ]}
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = `/prepaid_card_accesses/rejected_payments.json?`
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + JSON.stringify(filters)
              }
              if (query.search) {
								url += '&search=' + (query.search)
							}
              if (query.orderBy) {
                url += '&orderBy=' + query.orderBy.field
                url += '&orderDirection=' + (query.orderDirection)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.rejecteds.flat(),
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    )
  }
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default class CallCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      open: false
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {

    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'>
        {
          <div id='pure-scrollable-tab'>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              <Tab label='Black List' {...a11yProps(0)} />
              <Tab label='White List' {...a11yProps(1)} />
              <Tab label='Rejected Pyments' {...a11yProps(2)} />

            </Tabs>
            <TabPanel value={this.state.value} index={0}>
              <CreditCards columns={"blackList"}/>
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <WhiteList columns={"rejected"} />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <RejectedPayments columns={"payments"} />
            </TabPanel>
          </div>
        }
      </Modal>
    );
  }
}
