import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import { Backdrop, Button } from "@material-ui/core";
import VendorForm from "./_vendor_form";
import Bulb from "react-bulb";

import $ from "jquery";

class MarketplaceVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      muiTableKey: 0,
      total: null,
      active: "",
    };
    this._tableRef = React.createRef();
    this._vendorFormRef = React.createRef();
  }

  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  };

  handleBrandStatus = (data) => {
    let self = this;
    const payload = { active: !data.active };
    $.ajax({
      url: `/marketplace_vendor/${data.id}/update_status`,
      type: "PUT",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  render() {
    const columns = [
      { title: "Name", field: "name" },
      { title: "Region", field: "region" },
      { title: "Delivery Time (Days)", field: "hub_delivery_time" },
      {
        title: "Current Status",
        field: "active",
        lookup: {
          true: <Bulb size={10} color="green" />,
          false: <Bulb size={10} color="red" />,
        },
      },
    ];
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.submitting} />
        <VendorForm ref={this._vendorFormRef} reloadData={this.reloadData} />
        <Table
          title="Marketplace Vendors"
          options={{
            toolbar: true,
            filtering: false,
            sorting: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
          }}
          columns={columns}
          tableRef={this._tableRef}
          actions={[
            {
              icon: () => <pureIcons.Edit />,
              tooltip: <span>Edit</span>,
              onClick: (event, rowData) =>
                this._vendorFormRef.current.handleOpen(rowData),
              position: "row",
            },
            {
              icon: () => <pureIcons.FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              icon: () => <pureIcons.AiOutlinePlus />,
              onClick: () => {
                this._vendorFormRef.current.handleOpen();
              },
              isFreeAction: true,
              tooltip: "Add New Vendor",
            },

            {
              icon: () => <pureIcons.PowerSettingsNewIcon />,
              tooltip: <span>Change Status</span>,
              onClick: (event, rowData) => {
                window.confirm(
                  `Are you sure you want to mark { ${
                    rowData.name
                  } } as { ${!rowData.active} }?`
                ) && this.handleBrandStatus(rowData);
              },
              position: "row",
            },
          ]}
          data={(query) =>
            new Promise((resolve) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/marketplace_vendor.json?`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.vendors,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  // this.setState({ vendors: result.vendors })
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                });
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(MarketplaceVendors);
