import React, { useEffect, useMemo, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { standardCategoryIndexData } from "./dummyData";
import { useHistory } from "react-router-dom";
import EditCategory from "./editCategory";
import { getSubCategories } from "../../routes/routes";
// import SubCategories from "./subCategories";

const Index = () => {
  const history = useHistory();
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [subCategoriesModal, setSubCategoriesModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const _tableRef = useRef<any>();
  const operation = useRef<string>("Add");
  const editData = useRef<any>(null);
  const editRef = useRef<any>(null);
  const subCategoriesRef = useRef<any>(null);
  const subCategoriesDataRef = useRef<any>(null);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      title: "ID",
      field: "id",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 100,
      },
    },
    {
      title: "Name",
      field: "name_en",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Weight",
      field: "weight",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Discount rate",
      field: "discount_rate",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Priority",
      field: "priority",
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Prohibited",
      field: "prohibited",
      editable: "never",
      sorting: false,
      searchable: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Age sensitivity",
      field: "age_sensitive",
      editable: "never",
      sorting: false,
      searchable: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Tax rate",
      field: "tax_fee",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
      render: (params: any) => {
        const getPerc = params?.tax_fee ? "%" : null;
        return (
          <>
            {params.tax_fee}
            {getPerc}
          </>
        );
      },
    },
  ];
  const reload = () => {
    getData();
  };

  const onDeletedCategory = () => {
    // let formData = new FormData();
    // formData.append("id", id);
    // formData.append("locale", "en");
    // let url = `/parts_categories/${id}?locale=en`;
    // fetch(url, { method: "DELETE" })
    //   .then((response) => response.json())
    //   .then((result) => {});

    $.ajax({
      url: `/parts_categories/${deleteId}?locale=en`,
      type: "DELETE",
      dataType: "json",
      success: function (response: any) {
        handleCancel();
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        reload();
      },
      error: function (response) {
        console.log(response);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const getData = useMemo(() => {
    return () => {
      let url = `/parts_categories.json?locale=en`;
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setTableData(result.parts_categories);
          window.breadcrumb.addBreadcrumb(result.breadcrumb);
        });
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleCancel = () => {
    setDeleteOpen(false);
  };

  const onPrintSheet = () => {
    window.open(`/parts_categories/category_report`, "_self");
  };

  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      {open && (
        <EditCategory
          ref={editRef}
          data={editData}
          open={open}
          operation={operation}
          setOpen={setOpen}
          callback={reload}
        />
      )}

      {/* {subCategoriesModal && (
        <SubCategories
          ref={subCategoriesRef}
          data={subCategoriesDataRef}
          open={subCategoriesModal}
          setSubCategoriesModal={setSubCategoriesModal}
        />
      )} */}
      <Table
        title={"Standard Categories"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category" },
        }}
        columns={columns}
        tableRef={_tableRef}
        data={tableData}
        // data={(query) =>
        //   new Promise((resolve, reject) => {
        //     let url = `/parts_categories.json?locale=en`;
        //     fetch(url)
        //       .then((response) => response.json())
        //       .then((result) => {
        //         resolve({
        //           data: result.parts_categories,
        //           page: result.page - 1,
        //           totalCount: result.total,
        //         });
        //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
        //       });
        //   })
        // }
        // data={standardCategoryIndexData}
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.CategoriesIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              operation.current = "Add";
              setOpen(true);
            },
            tooltip: "Add new category",
            isFreeAction: true,
          },
          {
            icon: () => <pureIcons.PrintIcon />,
            onClick: () => {
              onPrintSheet();
            },
            tooltip: "Print sheet",
            isFreeAction: true,
          },

          (rowData: any) => {
            return {
              icon: () => <pureIcons.Edit />,
              onClick: () => {
                setOpen(true);
                editData.current = rowData;
                operation.current = "Edit";
              },
              tooltip: "Edit",
            };
          },
          (rowData: any) => {
            return {
              icon: () => <pureIcons.CategoriesIcon />,
              onClick: () => {
                history.push(getSubCategories(rowData.id));
              },
              tooltip: "Sub categories",
            };
          },
          (rowData: any) => {
            return rowData.delete
              ? {
                  icon: () => <pureIcons.Delete />,
                  onClick: () => {
                    setDeleteId(rowData.id);
                    setDeleteOpen(true);
                    // onDeletedCategory(rowData.id);
                  },
                  tooltip: "Delete",
                }
              : null;
          },
        ]}
      />

      <Dialog open={deleteOpen} onClose={handleCancel}>
        <DialogTitle style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}>
          Delete Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onDeletedCategory}
            style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
