import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, TextField } from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import Logo from "../../../assets/images/logo.png";
import Signature from "../../../assets/images/moe.png";
import Stamp from "../../../assets/images/stamp.png";
import ReactToPrint from "react-to-print";
import Select from "../shared/autocomplete";

class Fake extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			amount: null,
			errors: {},
			orders: [],
			total: null
		}
	};

	handleOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	validate = (fieldValues = this.state) => {
		let temp = { ...this.state.errors };
		temp.serverErrors = '';
		if ('amount' in fieldValues) {
			temp.amount = fieldValues.amount ? '' : 'Required';
		}

		this.setState({ errors: { ...temp } })
		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleGetReport = () => {
		if (!this.validate()) return;
		this.setState({ working: true });
		const url = `/reports/fake_invoice.json?amount=${this.state.amount}&bank_code=${this.state.bank_code}`
		fetch(url)
			.then(response => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json()
			})
			.then(result => {
				this.setState({ orders: result.orders, working: false, total: result.total, date: result.date, invoice: result.invoice, bank: result.bank,
					logo: result.logo, signature: result.signature, stamp: result.stamp});
			})
			.catch(error => {
				this.setState({ working: false });
			});
	}

	handleInputChange = e => {
		const { name, value } = e.target;
		if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
			this.setState({ [name]: value })
			this.validate({ [name]: value });
		}
	}

	handleSelectChange = object => {
		this.setState({ bank_code: object.value })
		this.validate({ bank_code: object.value });
	}

	validate = (fieldValues = this.state) => {
		let temp = { ...this.state.errors };
		temp.serverErrors = '';
		if ('amount' in fieldValues) {
			temp.amount = fieldValues.amount ? '' : 'Required';
		}
		if ('bank_code' in fieldValues) {
			temp.bank_code = fieldValues.bank_code ? '' : 'Required';
		}
		this.setState({ errors: { ...temp } })
		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	}

	render() {
		const { classes } = this.props;
		const body =
			<Card className={classes.root} style={{ minHeight: '30vh' }}>
				<div className="container pb-5">
					<div className="row pt-2">
						<div className="col-4">
							<TextField
								fullWidth
								autoFocus
								name='amount'
								value={this.state.amount}
								label='Amount'
								{...(this.state.errors.amount && { error: true, helperText: this.state.errors.amount })}
								onChange={this.handleInputChange}
							/>
						</div>
						<div className='col-8'>
							<Select
								name='bank_code'
								fullWidth
								label='Select bank'
								freeSolo={false}
								value={this.state.bank_code}
								url='/reports/fake_invoice.json'
								callBack={this.handleSelectChange}
								{...(this.state.errors.bank_code && { error: true, helperText: this.state.errors.bank_code })}
							/>
						</div>
					</div>
				</div>
			</Card>

		const actions = (
			<>
				{this.state.orders.length > 0 && <PrintInvoice total={this.state.total} orders={this.state.orders} date={this.state.date} invoice={this.state.invoice} bank={this.state.bank}
				logo={this.state.logo} signature={this.state.signature} stamp={this.state.stamp}/>}
				<Button
					variant='contained'
					hidden={!this.state.bank_code}
					style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
					disabled={this.state.working}
					onClick={this.handleGetReport}
				>
					{this.state.working ? 'Working...' : 'Generate'}
				</Button>
			</>
		);

		return (
			<Modal
				isOpen={this.state.open}
				title='Export Orders'
				handleClose={this.handleClose}
				minHeight='20vh'
				maxHeight='30%'
				maxWidth='sm'
				actions={actions}>
				{body}
			</Modal>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default withStyles(useStyles)(Fake)

class PrintInvoice extends React.Component {
	render() {
		document.title = 'Invoice'
		return (
			<div>
				<ReactToPrint
					trigger={() => <Button type='submit' variant="outlined" color="primary">
						print invoice
					</Button>}
					content={() => this.componentRef}
				/>
				<Invoice ref={el => (this.componentRef = el)} total={this.props.total} orders={this.props.orders} date={this.props.date} invoice={this.props.invoice} bank={this.props.bank}
								 logo={this.props.logo} signature={this.props.signature} stamp={this.props.stamp}/>
			</div>
		);
	}
}

class Invoice extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { total, orders, date, invoice, bank } = this.props
		return (
			<div className='d-none d-print-block'>
				<div className="container-fluid p-3">
					<div className="pure-invoice--header p-3">
						<table className="pdf-table">
							<tr>
								<td>
									<p className='p-0 m-0'><b className='p-0 m-0'>The exclusive platform for commercial and general trade</b></p>
									<p className='p-0 m-0'><b className='p-0 m-0'>Iraq-Baghdad-Albu Shujaa- Locality/907- Alley/33- House/47</b></p>
									<p className='p-0 m-0'>Account No. <b>16880</b></p>
									<p className='p-0 m-0'>Invoice No. <b>{invoice}</b></p>
									<p className='p-0 m-0'>Invoice Date: <b>{date}</b></p>
								</td>
								<td className="text-end">
									<img src={this.props.logo} className='logo' alt='logo'/>
								</td>
							</tr>
						</table>
					</div>
					<div className="pure-invoice--body">
						<table className="pdf-table border border-5 p-5" style={{ width: "100%" }}>
							<thead>
								<tr>
									<th className="text-center" >Purchase Order No.</th>
									<th className="text-center" >Invoice</th>
									<th className="text-center" >Product</th>
									<th className="text-center" >Category</th>
									<th className="text-center" >Payment Method</th>
									<th className="text-center" >Subtotal</th>
								</tr>
							</thead>
							<tbody>
								{orders.map(order => (
									<tr>
										<td className="text-center">{order.order_number}</td>
										<td className="text-center">{order.invoice_number}</td>
										<td className="text-center">{order.product_name}</td>
										<td className="text-center">{order.category}</td>
										<td className="text-center">{order.method}</td>
										<td className="text-center">${order.subtotal}</td>
									</tr>
								))}
								<tr>
									<td className="text-end fw-bolder" colSpan="5">Total Value and Currency:</td>
									<td className="text-center fw-bolder">${total}</td>
								</tr>
							</tbody>
						</table>
						<div className='row pt-3'>
							<div className='col-6'>
								<p className='p-0 m-0'>Bank: <b>{bank.name}</b></p>
								<p className='p-0 m-0'>Address: <b>{bank.address}</b></p>
								<p className='p-0 m-0'>Routing: <b>{bank.routing_no}</b></p>
								<p className='p-0 m-0'>SWIFT: <b>{bank.swift}</b></p>
								<p className='p-0 m-0'>Account No. <b>{bank.account}</b></p>
							</div>
							<div className='col-2'>
								<img src={this.props.stamp} className='stamp' alt='stamp'/>
							</div>
							<div className='col-3 text-end pe-2'>
								<ul className="list-unstyled">
									<img src={this.props.signature} className='signature' alt='signature'/>
									<li>Moe Alkhafaji</li>
									<li>President & CEO</li>
									<li>{date}</li>
								</ul>
								<br/>
								<b className='p-0 m-0'>Pure Platform Inc.</b>
								<p className='p-0 m-0'>700 W. Oakton Street</p>
								<p className='p-0 m-0'>Des Plaines, IL 60018</p>
								<p className='p-0 m-0'>+1 (630) 290-1113</p>
								<p className='p-0 m-0'>www.pureplatform.com</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

