import React from 'react';
import {IconButton, TableCell, TableBody, TableRow, Paper, Table, TableHead} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import InfoIcon from '@material-ui/icons/Info';

export default function StagePhase({phases}) {
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const body = <Paper>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Location</TableCell>
					<TableCell>Description</TableCell>
					<TableCell>Date</TableCell>
					<TableCell>ETA</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{phases.map(row => (
					<TableRow key={row.location}>
						<TableCell>{row.location}</TableCell>
						<TableCell>{row.description}</TableCell>
						<TableCell>{row.date}</TableCell>
						<TableCell>{row.eta}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</Paper>

	return (
		<>
			<IconButton aria-label='order-info' onClick={handleOpen}>
				<InfoIcon />
			</IconButton>
			<Modal
				disableBackdropClick={false}
				isOpen={open}
				title='Shipment update'
				handleClose={handleClose}
				minHeight='40vh'
				maxHeight='60%'
				maxWidth='lg'
				actions={<></>}>
				{body}
			</Modal>
		</>
	)
}
