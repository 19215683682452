import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import { Backdrop } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import CreateCity from "../operations/_create_city";
import EditCity from "./_edit_city";

class Cities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      user_balance: "",
      errors: {},
      provinceValue: [],
    };
    this._tableRef = React.createRef();
    this._createCityRef = React.createRef();
    this._editCityRef = React.createRef();
  }
  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  };
  render() {
    const columns = [
      { title: "Name", field: "name_en" },
      { title: "Province", field: "province" },
      { title: "latitude", field: "latitude" },
      { title: "longitude", field: "longitude" },
    ];
    const body = (
      <>
        <div className="bg-white rounded">
          <Backdrop open={this.state.submitting} />
          <CreateCity ref={this._createCityRef} reloadData={this.reloadData} />
          <EditCity ref={this._editCityRef} reloadData={this.reloadData} />
          <Table
            title={`Cities`}
            options={{
              toolbar: true,
              sorting: false,
              search: true,
              exportButton: false,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
              pageSize: 12,
              pageSizeOptions: [12],
            }}
            columns={columns}
            tableRef={this._tableRef}
            actions={[
              {
                icon: () => <pureIcons.AiOutlinePlus />,
                onClick: () => {
                  this._createCityRef.current.handleOpen();
                },
                isFreeAction: true,
                tooltip: "Add New City",
              },
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => {
                  this.reloadData();
                },
                isFreeAction: true,
                tooltip: "Refresh",
              },
              (rowData) => ({
                icon: () => <pureIcons.Edit />,
                onClick: () => {
                  this._editCityRef.current.handleOpen(
                    rowData,
                    this.state.provinceValue
                  );
                },
                tooltip: "Edit",
              }),
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                var filters;
                if (query.filters.length > 0) {
                  filters = query.filters.map((col) => ({
                    field: col.column.field,
                    value: col.value,
                  }));
                }
                let url = `/cities.json?`;
                url += "per_page=" + query.pageSize;
                url += "&page=" + (query.page + 1);
                if (query.filters.length > 0) {
                  url += "&filters=" + JSON.stringify(filters);
                }
                if (query.orderBy) {
                  url += "&orderBy=" + query.orderBy.field;
                  url += "&orderDirection=" + query.orderDirection;
                }
                if (query.search) {
                  url += "&search=" + query.search;
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.cities,
                      page: result.page - 1,
                      totalCount: result.total,
                    });
                    this.setState({ provinceValue: result?.records });
                    window.breadcrumb.addBreadcrumb(result.breadcrumb);
                  });
              })
            }
          />
        </div>
      </>
    );
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="90%"
        maxWidth="lg"
      >
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(Cities);
