import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Modal from "../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import $ from "jquery";
import pureIcons from "../shared/pure_icons";
import Table from "../shared/tables/material_table";
import { Badge, Button, Link } from "@material-ui/core";
import ShowOrder from "../orders/_show";
import Backdrop from "../shared/backdrop";

const ShowWayBills = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState<any>("");
  const [totalQuantity, setTotalQuantity] = useState<any>("");
  const [wayBillList, setWayBillList] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);

  const _ShowOrderRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(id, billNumber) {
      setOpen(true);
      getWayBill(id, billNumber);
    },
  }));

  const getWayBill = (id, billNumber) => {
    setSubmitting(true);
    $.ajax({
      url: `/waybills/${id}.json?waybill_number=${billNumber}`,
      type: "GET",
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        setSubmitting(false);
        setTotalQuantity(response.total_qty_items);
        setTrackingNumber(response.tracking_number);
        setWayBillList(response.order_item_stages);
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setTrackingNumber("");
    setTotalQuantity("");
    setWayBillList([]);
  };
  const columns = [
    {
      title: "",
      field: "image",
      sorting: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "product_name",
      sorting: false,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "30%",
      },
      //   render: (rowData) => (
      //     <Link href={rowData.url} target="_blank">
      //       {rowData.name_short}
      //     </Link>
      //   ),
    },
    {
      title: "Brand",
      field: "brand",
      sorting: false,
    },
    { title: "Status", field: "status", sorting: false },
    {
      title: "QTY",
      field: "qty",
      sorting: false,
    },
    {
      title: "Price",
      field: "price",
      sorting: false,
    },
    {
      title: "Order Number",
      field: "order_number",
      render: (rowData) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() =>
            _ShowOrderRef.current.handleOpen(
              rowData.order_id,
              rowData.order_number
            )
          }
        >
          {rowData.order_number}
        </Link>
      ),
    },
  ];

  return (
    <>
      <ShowOrder ref={_ShowOrderRef} />
      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            Waybill Number: {trackingNumber}
          </div>
        }
        handleClose={() => handleClose()}
        disableBackdropClick={false}
        isOpen={open}
        minHeight="40%"
        minWidth="90%"
        maxHeight="90%"
        fullWidth={false}
        actions={""}
      >
        <Backdrop open={submitting} />
        <div style={{ padding: "5px 0px" }}>
          Total Qty Of Items: {totalQuantity}
        </div>
        <Table
          title={`Shipping Carrires`}
          options={{
            toolbar: false,
            sorting: false,
            search: false,
            filtering: false,
            debounceInterval: 500,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
          }}
          columns={columns}
          //   tableRef={_tableRef}
          actions={""}
          data={wayBillList}
        />
      </Modal>
    </>
  );
});

export default ShowWayBills;
