import React from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, TextField } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";
import { withSnackbar } from "notistack";

class CreateIndustry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: "",
      values: this.initialValues(),
    };
  }

  initialValues = () => {
    return {
      name_en: "",
      name_ar: "",
      name_ku: "",
      priority: "",
      daily_deposit_loan: "",
      daily_earnings_loan: "",
      max_deposit_loan: "",
      max_earnings_loan: "",
      min_kidus: "",
      pure_fee: "",
    };
  };

  handleOpen = (industry) => {
    this.setState({
      open: true,
      values: this.initialValues(),
      industry: industry,
    });
  };

  clearErrorData = () => {
    this.setState({
      errors: {},
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.clearErrorData();
  };

  reloadData = () => {
    this.props.reloadData();
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let industry = this.state.industry;
    if (industry) {
      let payload = {
        name_en: industry.name_en,
        name_ar: industry.name_ar,
        name_ku: industry.name_ku,
        max_deposit_loan: industry.max_deposit_loan,
        max_earnings_loan: industry.max_earnings_loan,
        min_kidus: industry.min_kidus,
        pure_fee: industry.pure_fee,
        priority: industry.priority,
        daily_deposit_loan: industry.daily_deposit_loan,
        daily_earnings_loan: industry.daily_earnings_loan,
      };

      $.ajax({
        url: `/industries/${this.state.industry.id}`,
        type: "PUT",
        data: {
          industry: payload,
        },
        dataType: "json",
        success: function (response) {
          self.props.enqueueSnackbar("successfully Updated!", {
            variant: "success",
          });
          self.handleClose();
          self.reloadData();
        },
        error: function (response) {
          self.setState({
            working: false,
            errors: { serverErrors: response.responseJSON.error },
          });
        },
      });
    } else {
      let values = this.state.values;
      let payload = {
        name_en: values.name_en,
        name_ar: values.name_ar,
        name_ku: values.name_ku,
        max_deposit_loan: values.max_deposit_loan,
        max_earnings_loan: values.max_earnings_loan,
        min_kidus: values.min_kidus,
        pure_fee: values.pure_fee,
        priority: values.priority,
        daily_deposit_loan: values.daily_deposit_loan,
        daily_earnings_loan: values.daily_earnings_loan,
      };

      $.ajax({
        url: "/industries",
        type: "POST",
        data: {
          industry: payload,
        },
        dataType: "json",
        success: function (response) {
          self.props.enqueueSnackbar("successfully Created!", {
            variant: "success",
          });
          self.reloadData();
          self.handleClose();
        },
        error: function (response) {
          self.setState({
            working: false,
            errors: { serverErrors: response.responseJSON.error },
          });
        },
      });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (this.state.industry) {
      this.setState({ industry: { ...this.state.industry, [name]: value } });
    } else {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  };

  handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d+([.,]\d+)?$/)) {
      if (this.state.industry) {
        this.setState({ industry: { ...this.state.industry, [name]: value } });
      } else {
        this.setState({ values: { ...this.state.values, [name]: value } });
      }
    }
    this.validate({ [name]: value });
  };

  validate = (
    fieldValues = this.state.industry ? this.state.industry : this.state.values
  ) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("priority" in fieldValues) {
      temp.priority = fieldValues.priority.toString() ? "" : "Required";
    }
    if ("name_en" in fieldValues) {
      temp.name_en = fieldValues.name_en.toString() ? "" : "Required";
    }
    if ("name_ar" in fieldValues) {
      temp.name_ar = fieldValues.name_ar.toString() ? "" : "Required";
    }
    if ("name_ku" in fieldValues) {
      temp.name_ku = fieldValues.name_ku.toString() ? "" : "Required";
    }
    if ("daily_deposit_loan" in fieldValues) {
      temp.daily_deposit_loan = fieldValues.daily_deposit_loan.toString()
        ? ""
        : "Required";
    }
    if ("daily_earnings_loan" in fieldValues) {
      temp.daily_earnings_loan = fieldValues.daily_earnings_loan.toString()
        ? ""
        : "Required";
    }
    if ("max_deposit_loan" in fieldValues) {
      temp.max_deposit_loan = fieldValues.max_deposit_loan.toString()
        ? ""
        : "Required";
    }
    if ("max_earnings_loan" in fieldValues) {
      temp.max_earnings_loan = fieldValues.max_earnings_loan.toString()
        ? ""
        : "Required";
    }
    if ("min_kidus" in fieldValues) {
      temp.min_kidus = fieldValues.min_kidus.toString() ? "" : "Required";
    }
    if ("pure_fee" in fieldValues) {
      temp.pure_fee = fieldValues.pure_fee.toString() ? "" : "Required";
    }

    this.setState({ errors: { ...temp } });
    if (
      fieldValues === this.state.industry
        ? this.state.industry
        : this.state.values
    )
      return Object.values(temp).every((x) => x === "");
  };

  render() {
    console.log(this.state.errors);
    const { classes } = this.props;
    const body = (
      <>
        {this.state.errors.serverErrors && (
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        )}
        <Card className={classes.root} style={{ minHeight: "65vh" }}>
          <Backdrop open={this.state.working} />
          <div className="container">
            <TextField
              name="name_en"
              fullWidth
              margin="normal"
              label="Name(EN)"
              value={
                this.state.industry
                  ? this.state.industry.name_en
                  : this.state.values.name_en
              }
              onChange={this.handleInputChange}
              {...(this.state.errors.name_en && {
                error: true,
                helperText: this.state.errors.name_en,
              })}
            ></TextField>

            <TextField
              name="name_ar"
              fullWidth
              margin="normal"
              label="Name(AR)"
              value={
                this.state.industry
                  ? this.state.industry.name_ar
                  : this.state.values.name_ar
              }
              onChange={this.handleInputChange}
              {...(this.state.errors.name_ar && {
                error: true,
                helperText: this.state.errors.name_ar,
              })}
            ></TextField>

            <TextField
              name="name_ku"
              fullWidth
              margin="normal"
              label="Name(KU)"
              value={
                this.state.industry
                  ? this.state.industry.name_ku
                  : this.state.values.name_ku
              }
              onChange={this.handleInputChange}
              {...(this.state.errors.name_ku && {
                error: true,
                helperText: this.state.errors.name_ku,
              })}
            ></TextField>

            <TextField
              name="daily_deposit_loan"
              fullWidth
              margin="normal"
              label="daily_deposit_loan"
              value={
                this.state.industry
                  ? this.state.industry.daily_deposit_loan
                  : this.state.values.daily_deposit_loan
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.daily_deposit_loan && {
                error: true,
                helperText: this.state.errors.daily_deposit_loan,
              })}
            ></TextField>
            <TextField
              name="daily_earnings_loan"
              fullWidth
              margin="normal"
              label="daily_earnings_loan"
              value={
                this.state.industry
                  ? this.state.industry.daily_earnings_loan
                  : this.state.values.daily_earnings_loan
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.daily_earnings_loan && {
                error: true,
                helperText: this.state.errors.daily_earnings_loan,
              })}
            ></TextField>
            <TextField
              name="priority"
              fullWidth
              margin="normal"
              label="priority"
              value={
                this.state.industry
                  ? this.state.industry.priority
                  : this.state.values.priority
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.priority && {
                error: true,
                helperText: this.state.errors.priority,
              })}
            ></TextField>
            <TextField
              name="max_deposit_loan"
              fullWidth
              margin="normal"
              label="max_deposit_loan"
              value={
                this.state.industry
                  ? this.state.industry.max_deposit_loan
                  : this.state.values.max_deposit_loan
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.max_deposit_loan && {
                error: true,
                helperText: this.state.errors.max_deposit_loan,
              })}
            ></TextField>
            <TextField
              name="max_earnings_loan"
              fullWidth
              margin="normal"
              label="max_earnings_loan"
              value={
                this.state.industry
                  ? this.state.industry.max_earnings_loan
                  : this.state.values.max_earnings_loan
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.max_earnings_loan && {
                error: true,
                helperText: this.state.errors.max_earnings_loan,
              })}
            ></TextField>
            <TextField
              name="min_kidus"
              fullWidth
              margin="normal"
              label="min_kidus"
              value={
                this.state.industry
                  ? this.state.industry.min_kidus
                  : this.state.values.min_kidus
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.min_kidus && {
                error: true,
                helperText: this.state.errors.min_kidus,
              })}
            ></TextField>
            <TextField
              name="pure_fee"
              fullWidth
              margin="normal"
              label="pure_fee"
              value={
                this.state.industry
                  ? this.state.industry.pure_fee
                  : this.state.values.pure_fee
              }
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.pure_fee && {
                error: true,
                helperText: this.state.errors.pure_fee,
              })}
            ></TextField>
          </div>
        </Card>
      </>
    );

    const actions = (
      <>
        {!this.state.industry && (
          <Button
            type="button"
            variant="contained"
            onClick={() =>
              this.setState({ values: this.initialValues(), errors: {} })
            }
          >
            Clear
          </Button>
        )}
        <Button
          variant="contained"
          type="submit"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          {this.state.working ? "Working..." : "Submit"}
        </Button>
      </>
    );

    return (
      <Modal
        isOpen={this.state.open}
        title="Industry"
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="70%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(CreateIndustry));
