export const balanceData = [
    {
        name: 'Peso Alani Admin',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Hassan F. Mohsen Almaliki',
        dollarBalance: 21505.23,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Ammar Alkhafaji TR WH',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Peso Alani Admin',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Hassan F. Mohsen Almaliki',
        dollarBalance: 21505.23,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Ammar Alkhafaji TR WH',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Peso Alani Admin',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Hassan F. Mohsen Almaliki',
        dollarBalance: 21505.23,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
    {
        name: 'Ammar Alkhafaji TR WH',
        dollarBalance: 0.0,
        dinarBalance: 0.0,
        cashRefund: 0.0,
        visaCardRefund: 0.0,
    },
];

export const tableData = [
    {
        id: 193827,
        region: 'iraq',
        expenseType: 'deposit_kidu',
        process: 'credit',
        amount: 50,
        currency: 'dollar',
        createdBy: 'الحسن على حمود المطر',
        dateCreated: new Date('2024-01-03T11:12:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'
    },
    {
        id: 10639,
        region: 'iraq',
        expenseType: 'exchange',
        process: 'credit',
        amount: 16839913,
        currency: 'iqd',
        createdBy: 'Ahmad Foad ALobede',
        dateCreated: new Date('2024-01-03T10:31:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'
    },
    {
        id: 10639,
        region: 'iraq',
        expenseType: 'exchange',
        process: 'debit',
        amount: 12757.51,
        currency: 'dollar',
        createdBy: 'Ahmad Foad ALobede',
        dateCreated: new Date('2024-01-03T10:31:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'

    },
    {
        id: 357,
        region: 'iraq',
        expenseType: 'delivery_fee',
        process: 'debit',
        amount: 40000,
        currency: 'iqd',
        createdBy: 'Ali Sabah Abbas',
        dateCreated: new Date('2024-01-03T09:33:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'
    },
    {
        id: 357,
        region: 'iraq',
        expenseType: 'shipping',
        process: 'debit',
        amount: 210000,
        currency: 'iqd',
        createdBy: 'Ali Sabah Abbas',
        dateCreated: new Date('2024-01-03T09:31:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'

    },
    {
        id: 357,
        region: 'iraq',
        expenseType: 'delivery_fee',
        process: 'debit',
        amount: 5598500,
        currency: 'iqd',
        createdBy: 'Ali Sabah Abbas',
        dateCreated: new Date('2024-01-03T09:30:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'

    },
    {
        id: 357,
        region: 'iraq',
        expenseType: 'delivery_fee',
        process: 'debit',
        amount: 3380500,
        currency: 'iqd',
        createdBy: 'Ali Sabah Abbas',
        dateCreated: new Date('2024-01-03T09:29:00'),
        debtorName: null,
        reverse: false,
        note: 'Deposit kidus'

    },
];