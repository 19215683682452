import React from "react";
import {
  MenuItem,
  MenuList,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import pureIcons from "../shared/pure_icons";
import UpdateCredential from "./_update_credential";
import ReturnToStage from "./_move_item_to_previous_stage";
import Expenses from "./_show_expenses";
import Cities from "./_city";
import Industries from "./_industries";
import CallCards from "./_credit_cards";
import ExchangeRates from "./_exchange_rate";
import ScrollableTabsButtonAuto from "./_cash_refunds";
import Fake from "../reports/_fake_invoice";
import { GrDocumentCsv } from "react-icons/gr";
import CartReviews from "../users/_cart_reviews";
import NotificationComp from "./_notification";
import SendSMS from "./send_sms";
import CreatePPUrl from "./create_pp_url";
import UnShippable from "./_unshippable";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_controll: [],
    };
    this._update_credentialRef = React.createRef();
    this._showCityRef = React.createRef();
    this._update_credentialRef = React.createRef();
    this._returnToStageRef = React.createRef();
    this._showExpenseRef = React.createRef();
    this._showExchangeRatesRef = React.createRef();
    this._cashRefundsRef = React.createRef();
    this._fakeInvoiceRef = React.createRef();
    this._industriesRef = React.createRef();
    this._creditCardsRef = React.createRef();
    this._cartReviewsRef = React.createRef();
    this._notificationRef = React.createRef();
    this._sendSmsRef = React.createRef();
    this._createPPUrlRef = React.createRef();
    this._unshippedRef = React.createRef();
  }
  componentDidMount() {
    let url = `/orders/operations.json`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          page_controll: result.page_controll,
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="bg-white rounded">
        <ExchangeRates ref={this._showExchangeRatesRef} />
        <UpdateCredential ref={this._update_credentialRef} />
        <ReturnToStage ref={this._returnToStageRef} />
        <Expenses ref={this._showExpenseRef} />
        <Cities ref={this._showCityRef} />
        <ScrollableTabsButtonAuto ref={this._cashRefundsRef} />
        <UpdateCredential ref={this._update_credentialRef} />
        <ReturnToStage ref={this._returnToStageRef} />
        <Fake ref={this._fakeInvoiceRef} />
        <Industries ref={this._industriesRef} />
        <CallCards ref={this._creditCardsRef} />
        <CartReviews ref={this._cartReviewsRef} />
        <NotificationComp ref={this._notificationRef} />
        <SendSMS ref={this._sendSmsRef} />
        <CreatePPUrl ref={this._createPPUrlRef} />
        <UnShippable ref={this._unshippedRef} />

        <MenuList>
          {this.state.page_controll.cash_refunds === true && (
            <MenuItem onClick={() => this._cashRefundsRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Cash Refunds</Typography>
            </MenuItem>
          )}
          {this.state.page_controll.return_item === true && (
            <MenuItem
              onClick={() => this._returnToStageRef.current.handleOpen()}
            >
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">
                Return item to previous stage
              </Typography>
            </MenuItem>
          )}
          {this.state.page_controll.expenses === true && (
            <MenuItem onClick={() => this._showExpenseRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Expenses</Typography>
            </MenuItem>
          )}
          {this.state.page_controll.city === true && (
            <MenuItem onClick={() => this._showCityRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Show Cities</Typography>
            </MenuItem>
          )}

          <MenuItem onClick={() => this._cartReviewsRef.current.handleOpen()}>
            <ListItemIcon>
              <pureIcons.FaExternalLinkAlt />
            </ListItemIcon>
            <Typography variant="inherit">Cart Reviews</Typography>
          </MenuItem>

          {this.state.page_controll.exchange_rate === true && (
            <MenuItem
              onClick={() => this._showExchangeRatesRef.current.handleOpen()}
            >
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Exchange Rates</Typography>
            </MenuItem>
          )}
          {this.state.page_controll.fake_invoice === true && (
            <MenuItem onClick={() => this._fakeInvoiceRef.current.handleOpen()}>
              <ListItemIcon>
                <GrDocumentCsv />
              </ListItemIcon>
              <Typography variant="inherit">Fake Invoice</Typography>
            </MenuItem>
          )}
          {this.state.page_controll.update_credential === true && (
            <MenuItem
              onClick={() => this._update_credentialRef.current.handleOpen()}
            >
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Update Credential</Typography>
            </MenuItem>
          )}
          {this.state.page_controll.industries === true && (
            <MenuItem onClick={() => this._industriesRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Industries</Typography>
            </MenuItem>
          )}
          {
            <MenuItem onClick={() => this._creditCardsRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Credit Cards</Typography>
            </MenuItem>
          }
          {
            <MenuItem
              onClick={() => this._notificationRef.current.handleOpen()}
            >
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Notifications</Typography>
            </MenuItem>
          }
          {
            <MenuItem onClick={() => this._sendSmsRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Send SMS</Typography>
            </MenuItem>
          }
          {
            <MenuItem onClick={() => this._createPPUrlRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">Create PP Url</Typography>
            </MenuItem>
          }
          {
            <MenuItem onClick={() => this._unshippedRef.current.handleOpen()}>
              <ListItemIcon>
                <pureIcons.FaExternalLinkAlt />
              </ListItemIcon>
              <Typography variant="inherit">UnShippable Orders</Typography>
            </MenuItem>
          }
        </MenuList>
      </div>
    );
  }
}
