import React from "react";
import { Button, TextField, Box, IconButton } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ProductCard from "./_shipping_card";
import Typography from "@material-ui/core/Typography";
import DatePicker from "../../shared/date_picker";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import ShowOrder from "../_show";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import $ from "jquery";
import OrderInfo from "../received/_order_info";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";
import CopyTextComponent from "../../shared/copy_clipboard";
class ShippingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      products: [],
      order_number: "",
      orderSummary: [],
      vendor: "",
      order_id: "",
      shipped: [],
      eta: null,
      tracking: "",
      working: false,
    };
    this._ShowOrder = React.createRef();
  }

  handleOpen = (invoice, order_number, vendor, order_id) => {
    this.setState({
      open: true,
      order_number: order_number,
      invoice: invoice,
      vendor: vendor,
      order_id: order_id,
      errors: [],
      duplicate: false,
      eta: null,
    });
    this.getProducts(invoice);
  };

  handleClose = () => {
    this.setState({ open: false });
    const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
  };

  getProducts = (invoice) => {
    this.setState({ working: true });
    const url = `/order_item_stages/items_to_ship.json?invoice=${invoice}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        this.setState({
          products: result.products,
          working: false,
          shipped: [],
          tracking: "",
          eta: null,
          orderSummary: result.order_summary,
        });
      })
      .catch((error) => {
        this.setState({ working: false });
        this.props.enqueueSnackbar(
          "Invalid invoice or items has been moved to the next stage",
          { variant: "error" }
        );
        this.handleClose();
      });
  };

  handleAfterSubmit = () => {
    if (
      this.state.shipped.length === this.state.products.length &&
      this.state.shipped.reduce((a, v) => (a = a + v.qty), 0) ===
        this.state.products.reduce((a, v) => (a = a + v.qty), 0)
    ) {
      this.handleClose();
    } else {
      this.getProducts(this.state.invoice);
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("tracking" in fieldValues) {
      temp.tracking = fieldValues.tracking ? "" : "Required";
    }
    if ("eta" in fieldValues) {
      temp.eta =
        fieldValues.eta === null
          ? "Required"
          : isNaN(fieldValues.eta.getTime())
          ? "Invalid Date"
          : "";
    }
    if ("shipped" in fieldValues) {
      if (fieldValues.shipped.length > 0) temp.shipped = "";
      else {
        temp.shipped = "One or more items must be selected!";
        this.props.enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    this.setState({ errors: { ...temp } });

    if (fieldValues === this.state)
      return Object.values(temp).every((x) => x === "");
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    this.setState({ working: true });
    const { tracking, eta, shipped, duplicate } = this.state;
    const data = { shipped: shipped, tracking: tracking, eta: eta, duplicate };
    $.ajax({
      url: `/orders/${this.state.order_id}/ship`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false, duplicate: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.props.reloadData(true);
        self.handleAfterSubmit();
      },
      error: function (response) {
        if (response.responseJSON.error === "duplicate") {
          self.setState({ working: false, duplicate: true });
        } else {
          self.props.enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          self.setState({ working: false });
        }
      },
    });
  };

  setProductData = (data) => {
    if (data.selected) {
      let obj = this.state.shipped.find(
        (o) => o.order_item_stage_id === data.order_item_stage_id
      );
      if (obj) {
        let replace = this.state.shipped.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        replace.push(data);
        this.setState({ shipped: replace });
      } else {
        this.setState((prevState) => ({
          shipped: [...prevState.shipped, data],
        }));
      }
    } else {
      if (data.deleted) {
        let replace = this.state.products.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        if (this.state.products.length === 1) {
          this.handleClose();
          this.props.reloadData(true);
        } else {
          this.setState({ products: replace });
        }
      }
      let replace = this.state.shipped.filter(
        (o) => o.order_item_stage_id !== data.order_item_stage_id
      );
      this.setState({ shipped: replace });
    }
  };

  render() {
    const { classes } = this.props;
    const body = this.state.products.map((p) => (
      <ProductCard
        product={p}
        callBack={this.setProductData}
        key={p.order_item_stage_id}
        selected={this.state.shipped.find(
          (o) => o.order_item_stage_id === p.order_item_stage_id
        )}
      />
    ));

    const summary = (
      <div className="container">
        <div className="row ms-4">
          <div className="col">
            <Box pt={2} mt={1}>
              <Typography variant="button" color="textSecondary">
                Selected: {this.state.shipped.length}/
                {this.state.products.length}
              </Typography>
            </Box>
          </div>
          <div className="col">
            <TextField
              fullWidth
              name="tracking"
              value={this.state.tracking}
              label="Tracking number"
              {...(this.state.errors.tracking && {
                error: true,
                helperText: this.state.errors.tracking,
              })}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col">
            <DatePicker
              disablePast={true}
              disableFuture={false}
              name="eta"
              label="ETA"
              selectedDateValue={this.selectedDateValue}
              value={this.state.eta}
              {...(this.state.errors.eta && {
                error: true,
                helperText: this.state.errors.eta,
              })}
            />
          </div>
        </div>
      </div>
    );

    const actions = (
      <>
        {this.state.duplicate && (
          <Alert severity="error">
            Tracking number already exist in the system!
          </Alert>
        )}
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          className={classes.button}
          onClick={this.handleSubmit}
        >
          {this.state.duplicate ? "Submit duplicate" : "Submit"}
        </Button>
      </>
    );

    const orderTitle = (
      <>
        <Button
          style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
          onClick={() =>
            this._ShowOrder.current.handleOpen(
              this.state.order_id,
              this.state.order_number
            )
          }
        >
          {this.state.order_number}
        </Button>
        <IconButton
          onClick={() => navigator.clipboard.writeText(this.state.order_number)}
          aria-label="copy"
        >
          <CopyTextComponent textValue={this.state.order_number} />
        </IconButton>
        <OrderInfo orderSummary={this.state.orderSummary} />
        {`(${this.state.vendor} ${this.state.invoice})`}
      </>
    );
    return (
      <div>
        <Backdrop open={this.state.working} />
        <ShowOrder ref={this._ShowOrder} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={orderTitle}
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="85%"
          maxWidth="lg"
          summary={summary}
          actions={actions}
        >
          {this.state.working ? <ProductCardSkeleton /> : body}
        </Modal>
      </div>
    );
  }

  selectedDateValue = (date) => {
    this.setState({ eta: date });
    this.validate({ eta: date });
  };
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export default withSnackbar(withStyles(useStyles)(ShippingForm));
