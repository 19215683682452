import Rails from "@rails/ujs"
import { render } from 'react-dom';
import Session from './components/shared/session';
import React from 'react';

Rails.start()
document.addEventListener('DOMContentLoaded', () => {
  render(
    <Session />,
    document.getElementById('react-root')
  );
});

//the following code is required for server side component rendering (react_component)
let ReactRailsUJS = require('react_ujs');
import reactComponents from "./components/shared/**.jsx"

let componentsContext = {}
reactComponents.forEach((component) => {
  componentsContext[component.default.name] = component.default
})

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name]
}
