import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button,	Card,	CardContent, CardMedia, CircularProgress,	IconButton,	Typography} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import Modal from '../shared/modal/material_dialog'
import $ from 'jquery';
import { useSnackbar } from 'notistack';
import NumericInput from 'react-numeric-input';

export default function StageQtyForm({product, callBackStageQty, qty, stage, table=false}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [stageQty, setStageQty] = React.useState(qty);

	const handleQtyChange = (value) => {
		if (value > qty || value < 0 || value === null) return
		setStageQty(value);
	};

	const body = <Card className={classes.root}>
		<CardMedia	className={classes.cover}>
			<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
		</CardMedia>
		<div className={classes.details}>
			<CardContent className={classes.content}>
				<Typography component='h5' variant='h5' pb={5}>
					{product.name}
				</Typography>
				<br/>
				<Typography variant='subtitle1' color='textSecondary' >
					QTY: <NumericInput min={0} max={qty} value={stageQty} mobile size={4} onChange={handleQtyChange} name='qty'/> 
				</Typography>
			</CardContent>
		</div>
	</Card>

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setStageQty(qty);
		setOpen(true);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if(stageQty === qty) return;
		setSubmitting(true);
		const data = {qty: stageQty, order_item_stage_id: product.order_item_stage_id}
		$.ajax({
			url: `/order_items/${product.order_item_id}/update_stage_qty`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function(response) {
				setSubmitting(false);
				enqueueSnackbar('successfully updated!',{ variant: 'success' })
				callBackStageQty(stageQty, true);
				handleClose();
			},
			error: function (response) {
				setSubmitting(false);
				enqueueSnackbar(response.responseJSON.error,{ variant: 'error' })
			},
		});
	}

	const actions = (
		submitting ?
			<CircularProgress className={classes.button}/> :
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='submit'
				className={classes.button}
				disabled={submitting || stageQty === qty}
				onClick={handleSubmit}
			>
				Submit
			</Button>
	);

	return (
		<>
			{table ?  <UpdateIcon onClick={handleOpen}/> : 
			<IconButton aria-label='delete' onClick={handleOpen}>
				<UpdateIcon style={{fontSize: 30}}/>
			</IconButton>
			}
			
			<Modal
				disableBackdropClick
				isOpen={open}
				title={`Update stage qty in stage: ${stage}`}
				handleClose={handleClose}
				maxHeight='60%'
				maxWidth='md'
				actions={actions}>
				{body}
			</Modal>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));
