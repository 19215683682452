import React from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";
import { withSnackbar } from "notistack";
import Select from "../shared/autocomplete";

class CreateCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: "",
      values: this.initialValues(),
    };
  }

  initialValues = () => {
    return {
      name_en: "",
      name_ar: "",
      name_ku: "",
      province_id: "",
      latitude: "",
      longitude: "",
    };
  };

  handleOpen = () => {
    this.setState({ open: true, values: this.initialValues() });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload = {
      cities: {
        name_en: values.name_en,
        name_ar: values.name_ar,
        name_ku: values.name_ku,
        province_id: values.province_id,
        latitude: values.latitude,
        longitude: values.longitude,
      },
    };
    $.ajax({
      url: "/cities",
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.props.enqueueSnackbar("successfully Created!", {
          variant: "success",
        });
        self.handleClose();
      },
      error: function (response) {
        self.setState({
          working: false,
          errors: { serverErrors: response.responseJSON.error },
        });
      },
    });
  };

  handleSelectProvinceChange = (object) => {
    this.setState({
      values: {
        ...this.state.values,
        province_id: object ? object.value : null,
        id: object ? object.label : null,
      },
    });
    this.validate({ id: object ? object.value : null });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } });
    this.validate({ [name]: value });
  };

  handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,9})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("province_id" in fieldValues) {
      temp.province_id = fieldValues.province_id ? "" : "Required";
    }
    if ("name_en" in fieldValues) {
      temp.name_en = fieldValues.name_en ? "" : "Required";
    }
    if ("name_ar" in fieldValues) {
      temp.name_ar = fieldValues.name_ar ? "" : "Required";
    }
    if ("name_ku" in fieldValues) {
      temp.name_ku = fieldValues.name_ku ? "" : "Required";
    }
    if ("latitude" in fieldValues) {
      temp.latitude = fieldValues.latitude ? "" : "Required";
    }
    if ("longitude" in fieldValues) {
      temp.longitude = fieldValues.longitude ? "" : "Required";
    }
    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state.values)
      return Object.values(temp).every((x) => x === "");
  };

  render() {
    const { classes } = this.props;
    const body = (
      <>
        {this.state.errors.serverErrors && (
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        )}
        <Card className={classes.root} style={{ minHeight: "65vh" }}>
          <Backdrop open={this.state.working} />
          <div className="container">
            <Select
              name="province_id"
              fullWidth
              label="Search for province"
              freeSolo={false}
              value={this.state.values.province_id}
              url="/cities.json"
              {...(this.state.errors.province_id && {
                error: true,
                helperText: this.state.errors.province_id,
              })}
              callBack={this.handleSelectProvinceChange}
            />
            <TextField
              name="name_en"
              fullWidth
              margin="normal"
              label="Name EN"
              value={this.state.values.name_en}
              onChange={this.handleInputChange}
              {...(this.state.errors.name_en && {
                error: true,
                helperText: this.state.errors.name_en,
              })}
            ></TextField>
            <TextField
              name="name_ar"
              fullWidth
              margin="normal"
              label="Name AR"
              value={this.state.values.name_ar}
              onChange={this.handleInputChange}
              {...(this.state.errors.name_ar && {
                error: true,
                helperText: this.state.errors.name_ar,
              })}
            ></TextField>
            <TextField
              name="name_ku"
              fullWidth
              margin="normal"
              label="Name KU"
              value={this.state.values.name_ku}
              onChange={this.handleInputChange}
              {...(this.state.errors.name_ku && {
                error: true,
                helperText: this.state.errors.name_ku,
              })}
            ></TextField>
            <TextField
              name="latitude"
              fullWidth
              margin="normal"
              label="Latitude"
              value={this.state.values.latitude}
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.latitude && {
                error: true,
                helperText: this.state.errors.latitude,
              })}
            ></TextField>
            <TextField
              name="longitude"
              fullWidth
              margin="normal"
              label="Longitude"
              value={this.state.values.longitude}
              onChange={this.handleInputNumberChange}
              {...(this.state.errors.longitude && {
                error: true,
                helperText: this.state.errors.longitude,
              })}
            ></TextField>
          </div>
        </Card>
      </>
    );

    const actions = (
      <>
        <Button
          type="button"
          variant="contained"
          onClick={() =>
            this.setState({ values: this.initialValues(), errors: {} })
          }
        >
          Clear
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          disabled={this.state.working}
          onClick={this.handleSubmit}
        >
          {this.state.working ? "Working..." : "Submit"}
        </Button>
      </>
    );

    return (
      <Modal
        isOpen={this.state.open}
        title={this.state.values.user_balance}
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="70%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(CreateCity));
