import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '../shared/modal/material_dialog';
import $ from 'jquery'


const stages = ['Received', 'Purchased', 'Ready For Delivery', 'Out For Delivery'];

class ProcessBusinessOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      products: [],
      new_customer: false,
      stage_name: '',
      order_item_id: '',
      product_name: null,
      image: null,
      brand: '',
      price: '',
      parts_category_id: '',
      parts_sub_category_id: '',
      freight_type: '',
      shopper_type: '',
      shopper_name: '',
      redeem_code: '',
      user_id: ''
    };
    this._ShowOrder = React.createRef();
  }

  handleOpen = (order_id, order_number) => {
    this.setState({
      order_number: order_number,
      open: true,
    });
    this.getProducts(order_id);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getProducts = (order_id) => {
    this.setState({ working: true });
    const url = `/local_orders/${order_id}.json`;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => {
        this.setState({
          new_customer: result.new_customer,
          stage_name: result.stage_name,
          order_item_id: result.order_item_id,
          product_name: result.product_name,
          image: result.image,
          brand: result.brand,
          price: result.price,
          parts_category_id: result.parts_category_id,
          parts_sub_category_id: result.parts_sub_category_id,
          freight_type: result.freight_type,
          shopper_type: result.shopper_type,
          shopper_name: result.shopper_name,
          redeem_code: result.redeem_code,
          user_id: result.user_id,
          working: false,
        });
      })
      .catch(error => {
        this.setState({ working: false });
        this.props.enqueueSnackbar('Invalid Order Number', { variant: 'error' });
        this.handleClose();
      });
  };


  handleProcessOrder = (event) => {
    let self = this;
    event.preventDefault();
    let payload = {
      local_order: { order_item_id: this.state.order_item_id, stage_name: this.state.stage_name  }
    }
    $.ajax({
      url: '/local_orders/process_stages',
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('successfully Created!', { variant: 'success' })
        self.handleClose()
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
        self.setState({ submitting: false });
      },
    });
  }


  render() {
    const { classes } = this.props;

    return (
      <>
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={`Order Number: ${this.state.order_number}`}
          handleClose={this.handleClose}
          minHeight='40vh'
          maxHeight='85%'
          maxWidth='md'
          actions={
          [  <Button
              variant='contained'
              type='submit'
              style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
              disabled={this.state.working}
              onClick={this.handleProcessOrder}
            >
              Process Order
            </Button>
            ]
          }
        >
          <div className={classes.modalContent}>
            {/* Order Stage */}
            <Typography variant='h5'>Order Stage</Typography>
            <Paper elevation={3} className={classes.orderStage}>
              <List component="nav" className={classes.stagesList}>
                {stages.map((stage, index) => (
                  <ListItem
                    key={index}
                    button
                    selected={this.state.stage_name === stage.toLowerCase().replace(/\s+/g, '_')}
                    onClick={() => this.setState({ stage_name: stage.toLowerCase().replace(/\s+/g, '_') })}
                    className={classes.stageItem}
                  >
                    <ListItemText primary={stage} />
                  </ListItem>
                ))}
              </List>
            </Paper>

            {/* Product Details */}
            <Typography variant='h5'>Product Details</Typography>
            <Paper elevation={3} className={classes.productDetails}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Brand"
                    variant="outlined"
                    value={this.state.brand}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Price"
                    variant="outlined"
                    value={this.state.price}
                    fullWidth
                    disabled
                  />
                </Grid>
                {/* Add more product details here */}
              </Grid>
            </Paper>

            {/* Customer Details */}
            <Typography variant='h5'>Customer Details</Typography>
            <Paper elevation={3} className={classes.customerDetails}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.new_customer}
                        name="new_customer"
                        color="primary"
                        disabled
                      />
                    }
                    label="New Customer"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Order Stage"
                    variant="outlined"
                    value={this.state.stage_name}
                    fullWidth
                    disabled
                  />
                  <TextField
                    label="order_item_id"
                    variant="outlined"
                    value={this.state.order_item_id}
                    fullWidth
                    disabled
                  />

                </Grid>
              </Grid>
            </Paper>
          </div>
        </Modal>
      </>
    );
  }
}

const useStyles = theme => ({
  modalContent: {
    padding: theme.spacing(2),
  },
  orderStage: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  stagesList: {
    display: 'flex',
    overflowX: 'auto',
  },
  stageItem: {
    minWidth: 150,
    textAlign: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',
    border: '1px solid transparent',
    '&.Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  productDetails: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  customerDetails: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

export default withSnackbar(withStyles(useStyles)(ProcessBusinessOrder));
