import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import DatePicker from "../shared/date_picker";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SendSMS = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formValue, setFormValue] = React.useState<any>({
    allCustomer: "",
    oneCustomer: "",
  });
  const [formSMSValue, setFormSMSValue] = React.useState<any>({
    arabic_sms_text: "",
    english_sms_text: "",
    kurdish_sms_text: "",
  });
  const [phoneNo, setPhoneNo] = React.useState<any>("");
  const [arTextEr, setArTextEr] = React.useState<any>("");
  const [enTextEr, setEnTextEr] = React.useState<any>("");
  const [kdTextEr, setKdTextEr] = React.useState<any>("");
  const [selectGroup, setSelectGroup] = React.useState<any>("");
  const [phoneEr, setPhoneEr] = React.useState<any>("");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const clearData = () => {
    setFormSMSValue({
      arabic_sms_text: "",
      english_sms_text: "",
      kurdish_sms_text: "",
    });
    setFormValue({
      allCustomer: "",
      oneCustomer: "",
    });
  };

  const handleChange = (event) => {
    // setValue(event.target.value);
    setFormValue({ [event.target.name]: event.target.checked });
    setPhoneNo("");
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const onchangeFormValue = (event) => {
    setFormSMSValue({
      ...formSMSValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };
  const validate = () => {
    if (formSMSValue.arabic_sms_text) {
      setArTextEr("");
    } else {
      setArTextEr("Required arabic sms");
    }
    if (formSMSValue.english_sms_text) {
      setEnTextEr("");
    } else {
      setEnTextEr("Required english sms");
    }
    if (formSMSValue.kurdish_sms_text) {
      setKdTextEr("");
    } else {
      setKdTextEr("Required kurdish sms");
    }
    if (!phoneNo) {
      setPhoneEr("Please fill the mobile number");
    } else {
      setPhoneEr("");
    }
    if (formValue.allCustomer || formValue.oneCustomer) {
      setSelectGroup("");
    } else {
      setSelectGroup("select group");
    }
  };

  const onSubmitForm = () => {
    validate();
    if (
      formSMSValue.arabic_sms_text &&
      formSMSValue.english_sms_text &&
      formSMSValue.kurdish_sms_text &&
      (formValue.allCustomer || formValue.oneCustomer)
    ) {
      let formData = new FormData();
      formData.append("arabic_sms_text", formSMSValue.arabic_sms_text);
      formData.append("english_sms_text", formSMSValue.english_sms_text);
      formData.append("kurdish_sms_text", formSMSValue.kurdish_sms_text);
      formData.append("recipient_type", formValue?.oneCustomer ? "2" : "1");
      formData.append("phone_no", phoneNo ? phoneNo : "");

      let url = `/customer_profiles/send_sms_now.json`;
      setSubmitting(true);
      fetch(url, { method: "POST", body: formData })
        .then((response) => response.json())
        .then((result) => {
          setSubmitting(false);

          if (result.success) {
            enqueueSnackbar(result.msg, {
              variant: "success",
            });
            clearData();
          }
          if (!result.success) {
            enqueueSnackbar(result.msg, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          setSubmitting(false);
          enqueueSnackbar(error.msg, {
            variant: "error",
          });
        });

      // $.ajax({
      //   url: url,
      //   type: "POST",
      //   data: formData,
      //   dataType: "json",
      //   success: function (response) {
      //     enqueueSnackbar("successfully send!", {
      //       variant: "success",
      //     });
      //     setSubmitting(false);
      //   },
      //   error: function (response) {
      //     {
      //       enqueueSnackbar(response.responseJSON.error, {
      //         variant: "error",
      //       });
      //       setSubmitting(false);
      //     }
      //   },
      // });
    }
  };
  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="notification-header">Send SMS to Customer(s)</div>
        <div className="ar-text">
          <div>
            <span>SMS Text (ar) : </span>
            <TextField
              name="arabic_sms_text"
              variant="outlined"
              size="small"
              placeholder="Add Arabic SMS"
              onChange={onchangeFormValue}
              helperText={helperText(arTextEr)}
              value={formSMSValue.arabic_sms_text}
            />
          </div>
        </div>
        <div className="ar-text">
          <div>
            <span>SMS Text (en) : </span>
            <TextField
              name="english_sms_text"
              variant="outlined"
              size="small"
              placeholder="Add English SMS"
              onChange={onchangeFormValue}
              helperText={helperText(enTextEr)}
              value={formSMSValue.english_sms_text}
            />
          </div>
        </div>
        <div className="ar-text">
          <div>
            <span>SMS Text (ku) : </span>
            <TextField
              value={formSMSValue.kurdish_sms_text}
              name="kurdish_sms_text"
              variant="outlined"
              size="small"
              placeholder="Add Kurdish SMS"
              onChange={onchangeFormValue}
              helperText={helperText(kdTextEr)}
            />
          </div>
        </div>
        <div className="radio-section">
          <FormControl component="fieldset">
            <FormLabel
              style={{
                textAlign: "start",
                fontSize: "1.3rem",
                margin: formValue.oneCustomer
                  ? "1.3rem -1.5rem "
                  : "1.3rem -4rem",
              }}
              component="legend"
            >
              Select Group
            </FormLabel>
            <RadioGroup
              aria-label="Group"
              name="group"
              value={formValue}
              onChange={handleChange}
            >
              <FormControlLabel
                name="allCustomer"
                control={<Radio />}
                label="All customers"
                checked={formValue.allCustomer}
              />
              <FormControlLabel
                name="oneCustomer"
                control={<Radio />}
                label="Only one customer"
                checked={formValue.oneCustomer}
              />
              {formValue.oneCustomer ? (
                <div>
                  <span>Phone No. : </span>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    placeholder="Phone No"
                    name="phone_no"
                    onChange={(e) => {
                      if (e.target.value) {
                        setPhoneNo(e.target.value);
                      } else {
                        setPhoneNo("");
                        setPhoneEr("Please fill the mobile number");
                      }
                    }}
                    helperText={helperText(phoneEr)}
                  />
                </div>
              ) : null}
            </RadioGroup>
          </FormControl>
          <div>{selectGroup ? helperText(selectGroup) : null}</div>
        </div>
        <div className="sbt-btn">
          <Button
            style={{ background: "#f6b67f", color: "white" }}
            onClick={onSubmitForm}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(SendSMS);
