import React from "react";
import { Badge, Link } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import ShowItem from "../../order_items/show";
import Table from "../../shared/tables/material_table";
import { FiRefreshCw } from "react-icons/fi";
import Barcode from "../../order_items/_barcode";
import { ImInfo } from "react-icons/im";
import UpdateStageQty from "../../order_items/_update_stage_qty";

class PackingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      working: false,
      products: [],
      group_id: "",
    };
    this._tableRef = React.createRef();
    this._ShowItemRef = React.createRef();
  }

  handleOpen = (group_id) => {
    this.setState({ open: true, group_id: group_id });
    this.reloadData(group_id);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleQtyUpdateCallback = () => {
    this.reloadData(this.state.group_id);
  };

  reloadData = (group_id = null) => {
    this.setState({ working: true });
    const url = `/order_item_stages/packing_list.json?sea_shipping=${!this.props
      ?.orderState}&group_id=${group_id || this.state.group_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          products: data.products,
          working: false,
        });
      });
  };

  render() {
    const columns = [
      {
        title: "",
        field: "image",
        sorting: false,
        render: (row) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
            }}
          >
            <Badge
              badgeContent="DG"
              invisible={!row.dg}
              overlap="circular"
              color="error"
            >
              <img
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
                alt={row.name}
                src={row.image}
              ></img>
            </Badge>
          </div>
        ),
      },
      {
        title: "Product Name",
        field: "name",
        sorting: false,
        cellStyle: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          width: "30%",
        },
        render: (rowData) => (
          <Link href={rowData.url} target="_blank">
            {rowData.name_short}
          </Link>
        ),
      },
      {
        title: "QTY",
        field: "qty",
        sorting: false,
        cellStyle: { width: "2%" },
      },
      { title: "Order Number", field: "order_number" },
      { title: "Shipping", field: "shipping", sorting: false },
      { title: "Customs", field: "customs", sorting: false },
      { title: "Docking fee", field: "docking_fee", sorting: false },
      { title: "Code", field: "redeem_code", sorting: false },
      { title: "Date", field: "created_at" },
    ];

    const body = (
      <Table
        title={this.state.group_id}
        options={{
          toolbar: true,
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          paging: false,
          exportButton: true
        }}
        columns={columns}
        actions={[
          {
            icon: () => <FiRefreshCw />,
            onClick: () => {
              this.reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          (rowData) => ({
            icon: () => <Barcode code={rowData.redeem_code} />,
            tooltip: <span>Barcode</span>,
            position: "row",
          }),
          {
            icon: () => <ImInfo />,
            tooltip: <span>Show</span>,
            onClick: (event, rowData) =>
              this._ShowItemRef.current.handleOpen(rowData.order_item_id),
            position: "row",
          },
          (rowData) => ({
            icon: () => (
              <UpdateStageQty
                product={rowData}
                table={true}
                callBackStageQty={this.handleQtyUpdateCallback}
                qty={rowData.qty}
                stage={"Ready to Ship"}
              />
            ),
            tooltip: <span>Return to previous state</span>,
            position: "row",
          }),
        ]}
        data={this.state.products}
      />
    );

    return (
      <div>
        <Backdrop open={this.state.working} />
        <ShowItem ref={this._ShowItemRef} reloadData={this.reloadData} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title=""
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="85%"
          maxWidth="lg"
        >
          {body}
        </Modal>
      </div>
    );
  }
}

export default withSnackbar(PackingList);
