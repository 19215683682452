import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const SimpleSelect = ({name, selectValues, id, className, label, fullWidth, helperText, error, onChange}) => {
	const classes = useStyles();
	const [value, setValue] = React.useState('');
	const handleChange = (event) => {
		setValue(event.target.value);
		onChange(event);
	};
	return (
		<FormControl className={classes.formControl} error={error}>
			<InputLabel id={`${id}-select-label`}>{label}</InputLabel>
			<Select
				labelId={`${id}-select-label`}
				id={id}
				name={name}
				className={className}
				value={value}
				onChange={handleChange}
				fullWidth={fullWidth}
			>
				{selectValues.map(v =>
					<MenuItem key={v[0]} value={v[0]}>{v[1]}</MenuItem>
				)}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}
export default SimpleSelect;
