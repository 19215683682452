import React from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, TextField } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";
import { withSnackbar } from "notistack";

class VendorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      open: false,
      errors: {},
      serverError: "",
      values: this.initialValues(),
      type: "",
    };
  }

  initialValues = () => {
    return {
      id: "",
      region: "",
      country: "",
      url: "",
      name: "",
      active: true,
      vendor_total_bill: "0.0",
      header:
        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/83.0.4103.61 Safari/537.36",
      last_time_checked: "",
      check_alibility: true,
      max_shipping_fee: "0.0",
      min_shipping_fee: "0.0",
      landing_url: "",
      default_image_url: "",
      available_version: "1.0.0",
      hub_delivery_time: "7",
    };
  };

  handleOpen = (vendor = null) => {
    if (vendor) {
      this.setState({ open: true, values: vendor, type: "edit" });
    } else {
      this.setState({
        open: true,
        values: this.initialValues(),
        errors: {},
        type: "add",
      });
    }
  };

  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  };

  handleClose = () => {
    this.setState({ open: false, type: "" });
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload;
    if (this.state.values.id)
      payload = {
        url: values.url,
        default_image_url: values.default_image_url,
        landing_url: values.landing_url,
        name: values.name,
        active: values.active,
        vendor_total_bill: values.vendor_total_bill,
        max_shipping_fee: values.max_shipping_fee,
        min_shipping_fee: values.min_shipping_fee,
        region: values.region,
        country: values.country,
        check_alibility: values.check_alibility,
        header: values.header,
        available_version: values.available_version,
        hub_delivery_time: values.hub_delivery_time,
      };
    else
      payload = {
        vendors: {
          url: values.url,
          default_image_url: values.default_image_url,
          landing_url: values.landing_url,
          name: values.name,
          active: values.active,
          vendor_total_bill: values.vendor_total_bill,
          max_shipping_fee: values.max_shipping_fee,
          min_shipping_fee: values.min_shipping_fee,
          region: values.region,
          country: values.country,
          check_alibility: values.check_alibility,
          header: values.header,
          available_version: values.available_version,
          hub_delivery_time: values.hub_delivery_time,
        },
      };
    let url = this.state.values.id
      ? `/marketplace_vendor/${this.state.values.id}`
      : "/marketplace_vendor";
    let method = this.state.values.id ? "PUT" : "POST";
    let types = this.state.type == "add";
    $.ajax({
      url: url,
      type: method,
      data: payload,
      dataType: "json",
      success: function (response) {
        self.props.enqueueSnackbar(
          `Successfully ${types ? "Created" : "Updated"}`,
          {
            variant: "success",
          }
        );
        self.props.reloadData();
        self.handleClose();
      },
      error: function (response) {
        self.setState({
          working: false,
          errors: { serverErrors: response.responseJSON.error },
        });
      },
    });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } });
    this.validate({ [name]: value });
  };
  handleCheckBoxChange = (e) => {
    this.setState({
      values: {
        ...this.state.values,
        active: e.target.checked,
        check_alibility: e.target.checked,
      },
    });
  };
  handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  };
  handleInputNumberChangeForVersion = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?(\.\d{0,2})?$/)) {
      this.setState({ values: { ...this.state.values, [name]: value } });
    }
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Required";
    }
    if ("url" in fieldValues) {
      temp.url = fieldValues.url ? "" : "Required";
    }
    if ("landing_url" in fieldValues) {
      temp.landing_url = fieldValues.landing_url ? "" : "Required";
    }
    if ("default_image_url" in fieldValues) {
      temp.default_image_url = fieldValues.default_image_url ? "" : "Required";
    }
    if ("region" in fieldValues) {
      temp.region = fieldValues.region ? "" : "Required";
    }
    if ("country" in fieldValues) {
      temp.country = fieldValues.country ? "" : "Required";
    }
    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state.values)
      return Object.values(temp).every((x) => x === "");
  };

  render() {
    const { classes } = this.props;
    const body = (
      <>
        {this.state.errors.serverErrors && (
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        )}
        <Card className={classes.root} style={{ minHeight: "90vh" }}>
          <Backdrop open={this.state.working} />
          <div className="container pb-5">
            <div className="col">
              <TextField
                fullWidth
                margin="normal"
                name="name"
                value={this.state.values.name}
                label="Vendor Name"
                {...(this.state.errors.name && {
                  error: true,
                  helperText: this.state.errors.name,
                })}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <TextField
                name="region"
                select
                fullWidth
                margin="normal"
                label="Region"
                value={this.state.values.region}
                onChange={this.handleInputChange}
                {...(this.state.errors.region && {
                  error: true,
                  helperText: this.state.errors.region,
                })}
                SelectProps={{ native: true }}
              >
                {["", "US", "EU", "TR", "GC", "CN"].map((option) => (
                  <option key={option} value={option}>
                    {option.toUpperCase()}
                  </option>
                ))}
              </TextField>
            </div>
            <div className="col">
              <TextField
                name="country"
                select
                fullWidth
                margin="normal"
                label="Country"
                value={this.state.values.country}
                onChange={this.handleInputChange}
                {...(this.state.errors.country && {
                  error: true,
                  helperText: this.state.errors.country,
                })}
                SelectProps={{ native: true }}
              >
                {["", "US", "EU", "TR", "AE", "CN", "SA", "IQ", "QA"].map(
                  (option) => (
                    <option key={option} value={option}>
                      {option.toUpperCase()}
                    </option>
                  )
                )}
              </TextField>
            </div>
            <div className="col">
              <TextField
                fullWidth
                margin="normal"
                name="url"
                value={this.state.values.url}
                label="Vendor URL"
                {...(this.state.errors.url && {
                  error: true,
                  helperText: this.state.errors.url,
                })}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <TextField
                fullWidth
                margin="normal"
                name="landing_url"
                value={this.state.values.landing_url}
                label="Vendor Landing URL"
                {...(this.state.errors.landing_url && {
                  error: true,
                  helperText: this.state.errors.landing_url,
                })}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <TextField
                fullWidth
                margin="normal"
                name="default_image_url"
                value={this.state.values.default_image_url}
                label="Vendor Image URL"
                {...(this.state.errors.default_image_url && {
                  error: true,
                  helperText: this.state.errors.default_image_url,
                })}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  fullWidth
                  margin="normal"
                  name="min_shipping_fee"
                  value={this.state.values.min_shipping_fee}
                  label="Vendor Min Shipping Fee"
                  onChange={this.handleInputNumberChange}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  margin="normal"
                  name="max_shipping_fee"
                  value={this.state.values.max_shipping_fee}
                  label="Vendor Max Shipping Fee"
                  onChange={this.handleInputNumberChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  fullWidth
                  margin="normal"
                  name="vendor_total_bill"
                  value={this.state.values.vendor_total_bill}
                  label="Vendor Total Bill"
                  onChange={this.handleInputNumberChange}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  margin="normal"
                  name="hub_delivery_time"
                  value={this.state.values.hub_delivery_time}
                  label="Vendor Delivery Time (Days)"
                  onChange={this.handleInputNumberChange}
                />
              </div>
            </div>
            <div className="col">
              <TextField
                fullWidth
                margin="normal"
                name="header"
                value={this.state.values.header}
                label="Vendor Header"
                onChange={this.handleInputChange}
              />
            </div>
            <TextField
              fullWidth
              margin="normal"
              name="available_version"
              value={this.state.values.available_version}
              label="Vendor Availabe Version"
              onChange={this.handleInputNumberChangeForVersion}
            />
            <div className="pt-2 form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={this.state.values.active}
                name="active"
                defaultChecked={this.state.values.active}
                onChange={this.handleCheckBoxChange}
              />
              <label className="form-check-label" for="flexCheckChecked">
                Active
              </label>
            </div>
            <div className="pt-2 form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={this.state.values.check_alibility}
                value={this.state.values.check_alibility}
                name="check_alibility"
                onChange={this.handleCheckBoxChange}
              />
              <label className="form-check-label" for="flexCheckChecked">
                Check Alibility
              </label>
            </div>
          </div>
        </Card>
      </>
    );

    const actions = (
      <Button
        variant="contained"
        type="submit"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        disabled={this.state.working}
        hidden={this.state.page === 2}
        onClick={this.handleSubmit}
      >
        {this.state.working
          ? "Working..."
          : this.state.type == "add"
          ? "Submit"
          : "Update"}
      </Button>
    );
    console.log(this.state.type);
    return (
      <Modal
        isOpen={this.state.open}
        title={this.state.type == "add" ? "Add New Vendor" : "Update Vender"}
        handleClose={this.handleClose}
        minHeight="70vh"
        maxHeight="70%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(useStyles)(VendorForm));
