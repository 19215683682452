import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Button,
  Card,
  IconButton,
  CardContent,
  InputAdornment,
  Input, Typography,
} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack';
import Backdrop from "../shared/backdrop";
import PureIcons from "../shared/pure_icons";
import $ from "jquery";
import NumericInput from "react-numeric-input";
import Select from "../shared/simple_select";

class AddExternalItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      errors: {},
      open: false,
      maxSelect: 0,
      qty: 0,
      values: this.initialValues()
    }
  };

  initialValues = () => {
    return {order_item_stage_id: '', new_stage: ''}
  }

  handleOpen = () => {
    this.setState({open: true, values: this.initialValues()});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    let values = this.state.values;
    let payload = {stage_id: values.order_item_stage_id, qty: this.state.qty, new_stage: values.new_stage}
    this.setState({working: true});
    $.ajax({
      url: `/order_item_stages/time_machine`,
      type: 'POST',
      data: payload,
      dataType: 'json',
      success: function(response) {
        self.setState({working: false, open: false});
        self.props.enqueueSnackbar('successfully processed!',{ variant: 'success' })
      },
      error: function (response) {
        self.setState({working: false, errors: {serverErrors: response.responseJSON.error}});
      },
    });
  }

  handleInputChange = e => {
    const {name, value} = e.target;
    this.setState({values: {...this.state.values, [name]: value}})
    this.validate({[name]: value});
  }

  handleSelectChange = e => {
    const {name, value} = e.target;
    this.setState({values: {...this.state.values, [name]: value}})
    this.validate({[name]: value});
  }

  validate = (fieldValues = this.state.values) => {
    let temp = {...this.state.errors};
    temp.serverErrors = '';
    if ('order_item_stage_id' in fieldValues) {
      temp.order_item_stage_id = fieldValues.order_item_stage_id ? '' : 'Required';
    }
    if ('new_stage' in fieldValues) {
      temp.new_stage = fieldValues.new_stage ? '' : 'Required';
    }


    this.setState({errors: {...temp}})
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  };

  getStageData = () => {
    this.setState({working: true});
    const url = `/order_item_stages/${this.state.values.order_item_stage_id}.json`
    fetch(url)
      .then(response => {if (!response.ok) {
        throw Error(response.statusText);
      }
        return response.json()
      })
      .then(result => {this.setState({values: {...this.state.values, qty: result.qty, processed: result.processed, pre_stages: result.pre_stages}, working: false, maxSelect: result.available, qty: result.available})})
      .catch(error => {
        this.setState({working: false, errors: {serverErrors: "Invalid order item stage ID"}});
      });
  }

  handleQtyChange = (value) => {
    if (value > this.state.maxSelect || value <= 0 || value === null) return
    this.setState({...this.state, qty: value});
  }
  render() {
    const {classes} = this.props;
    const body =
      <>
        <Backdrop open={this.state.working}/>
        {this.state.errors.serverErrors &&
          <div className="alert alert-danger p-1 text-center" role="alert">
            {this.state.errors.serverErrors}
          </div>
        }
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <div className='row'>
              <div className='col'>
                <Input
                  name='order_item_stage_id'
                  value={this.state.values.order_item_stage_id}
                  label='Order Item Stage ID'
                  autoFocus
                  {...(this.state.errors.order_item_stage_id && {error: true, helperText: this.state.errors.order_item_stage_id})}
                  onChange={this.handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type="submit" aria-label="search" onClick={this.getStageData} disabled={!this.state.values.order_item_stage_id}>
                        <PureIcons.Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className='col'></div>
            </div>
            {this.state.values.qty &&
              <>
                <div className='row'>
                  <div className='col'>
                    <Typography variant='subtitle1' color='textSecondary' className='py-2'>
                      Stage Qty: <b>{this.state.values.qty}</b>
                    </Typography>
                  </div>
                  <div className='col'>
                    <Typography variant='subtitle1' color='textSecondary' className='py-2'>
                      Processed: <b>{this.state.values.processed}</b>
                    </Typography>
                  </div>
                </div>
                <Typography variant='subtitle1' color='textSecondary' className='py-2'>
                  Qty to move back: <NumericInput min={1} max={this.state.maxSelect} value={this.state.qty} mobile size={4} onChange={this.handleQtyChange} name='qty'/>
                </Typography>
                <Select
                  className={classes.textField}
                  id='new_stage'
                  name='new_stage'
                  label='Move to stage'
                  fullWidth
                  value={this.state.values.new_stage}
                  onChange={this.handleSelectChange}
                  selectValues={this.state.values.pre_stages}
                  {...(this.state.errors.new_stage && {error: true, helperText: this.state.errors.new_stage})}
                />
              </>
            }
          </CardContent>
        </Card>
      </>

    const actions = (
        <>
          <Button
            type='button'
            variant='contained'
            onClick={()=> this.setState({values: this.initialValues(), errors: {}})}
          >
            Clear
          </Button>
          {this.state.values.pre_stages && <Button
            variant='contained'
            type='submit'
            style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
            disabled={this.state.working}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>}
        </>
    );

    return (
      <Modal
        isOpen={this.state.open}
        title="Return item to previous stage"
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='80%'
        maxWidth='sm'
        actions={actions}>
        {body}
      </Modal>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    padding: 20,
    margin: 20,
    wordBreak: 'break-all'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 250,
    height: 250,
    objectFit: 'contain'
  },
});

export default withSnackbar(withStyles(useStyles)(AddExternalItem))
