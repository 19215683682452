import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import $ from "jquery";
import { useSnackbar } from "notistack";
import { MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import pureIcons from "../shared/pure_icons";

const Index = () => {
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const _tableRef = useRef<any>(null);
  const [stage, setStage] = useState<string>("received");
  const [value, setValue] = React.useState(0);
  // const [userList, setUserList] = React.useState([]);

  // const datas = [
  //   {
  //     order_number: "123",
  //     order_date: "28-03-2023",
  //     business_name: "ddc",
  //     status: "inactive",
  //   },
  // ];

  const newOrderColumns = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Name",
      field: "business_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Phone Number",
      field: "business_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "ETA",
      field: "eta",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    // {
    //   title: "Status",
    //   field: "status",
    //   sorting: false,
    //   cellStyle: {
    //     textOverflow: "ellipsis",
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     // maxWidth: 100,
    //   },
    //   render: (rowData) => <div>{toTitleCase(rowData.status)}</div>,
    // },
  ];

  const acceptedColumns = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Name",
      field: "business_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Phone Number",
      field: "business_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Last Updated At",
      field: "last_update",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const readyForDeliveryColumns = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Name",
      field: "business_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Phone Number",
      field: "business_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Address",
      field: "business_address",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Last Updated At",
      field: "last_update",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Customer Phone Number",
      field: "customer_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Customer Address",
      field: "customer_address",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const onTheWayColumns = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Name",
      field: "business_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Phone Number",
      field: "business_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Address",
      field: "business_address",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Customer Phone Number",
      field: "customer_phone",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Customer Address",
      field: "customer_address",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const deliveryStage = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Business Name",
      field: "business_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Original ETA",
      field: "original_eta",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Real ETA",
      field: "real_eta",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const cancelledStage = [
    {
      title: "Order No.",
      field: "order_number",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Ordered Date",
      field: "order_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Product Name",
      field: "product_name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Product Quantity",
      field: "quantity",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Cancelled By",
      field: "canceled_by",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Cancellation Reason",
      field: "cancel_reason",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Cancellation Date",
      field: "cancel_date",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const [storeColumns, setStoreColumns] = useState<any>(newOrderColumns);

  useEffect(() => {
    if (stage === "received") {
      setStoreColumns(newOrderColumns);
    } else if (stage === "purchased") {
      setStoreColumns(acceptedColumns);
    } else if (stage === "vendor_shipped") {
      setStoreColumns(readyForDeliveryColumns);
      // } else if (stage === "on_the_way") {
      //   setStoreColumns(onTheWayColumns);
    } else if (stage === "delivered") {
      setStoreColumns(deliveryStage);
    } else if (stage === "canceled") {
      setStoreColumns(cancelledStage);
    }
  }, [stage]);

  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
      >
        {value === index && children}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if (newValue === 0) {
    //   setStage("received");
    // } else if (newValue === 1) {
    //   setStage("purchased");
    // } else if (newValue === 2) {
    //   setStage("vendor_shipped");
    // } else if (newValue === 3) {
    //   setStage("on_the_way");
    // } else if (newValue === 4) {
    //   setStage("delivered");
    // } else if (newValue === 5) {
    //   setStage("canceled");
    // }
    if (newValue === 0) {
      setStage("received");
    } else if (newValue === 1) {
      setStage("purchased");
    } else if (newValue === 2) {
      setStage("vendor_shipped");
    } else if (newValue === 3) {
      setStage("delivered");
    } else if (newValue === 4) {
      setStage("canceled");
    }
    _tableRef.current.onQueryChange();
  };
  const { enqueueSnackbar } = useSnackbar();

  const reload = () => {
    _tableRef.current.onQueryChange();
  };

  const getData = (stage: string) => {
    // setWorking(true);
    const url = `/orders/pure_plus.json?stage=${stage}&per_page=25&page=1`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
        }
      });
  };

  //   useEffect(() => {
  //     getData();
  //   }, []);

  const toTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const markAsDeliveredItem = (orderId) => {
    let url = "";

    fetch(url, {
      method: "post",
      body: JSON.stringify({
        order_id: orderId,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.error) {
          enqueueSnackbar(result.error, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const cancelItem = (orderId) => {
    let url = "";

    // fetch(url, {
    //   method: "post",
    //   body: JSON.stringify({
    //     order_id: orderId,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result?.error) {
    //       enqueueSnackbar(result.error, {
    //         variant: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     enqueueSnackbar(error, {
    //       variant: "error",
    //     });
    //   });
  };

  const actionColumnOnTheWayStage = [
    {
      icon: () => <pureIcons.FaShippingFast />,
      tooltip: <span>Mark as deliver</span>,
      onClick: (event, data) => {
        markAsDeliveredItem(data.order_id);
      },
      position: "row",
    },
  ];

  const actionColumnAcceptedStage = [
    {
      icon: () => <pureIcons.CancelIcon />,
      tooltip: <span>Coming soon</span>,
      onClick: (event, data) => {
        console.log(data);
        cancelItem(data.order_id);
      },
      position: "row",
    },
  ];

  return (
    <div className="bg-white rounded">
      {/* <Backdrop open={working} /> */}
      <div id="pure-scrollable-tab">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* <Tab label="received" {...a11yProps(0)} />
          <Tab label="purchased" {...a11yProps(1)} />
          <Tab label="shipped" {...a11yProps(2)} />
          <Tab label="delivered" {...a11yProps(3)} />
          <Tab label="cancelled" {...a11yProps(4)} /> */}

          <Tab label="New Order" {...a11yProps(0)} />
          <Tab label="Accepted" {...a11yProps(1)} />
          <Tab label="Ready for Delivery" {...a11yProps(2)} />
          {/* <Tab label="On the Way" {...a11yProps(3)} /> */}
          <Tab label="Delivered" {...a11yProps(4)} />
          <Tab label="Cancelled" {...a11yProps(5)} />
        </Tabs>
        {/* <TabPanel value={value} index={0}></TabPanel>
        <TabPanel value={value} index={1}></TabPanel> */}
      </div>
      <Table
        title={"Pure Plus"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          emptyRowsWhenPaging: false,
          maxBodyHeight: "69vh",
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search order" },
        }}
        columns={storeColumns}
        tableRef={_tableRef}
        actions={
          stage === "on_the_way"
            ? actionColumnOnTheWayStage
            : stage === "purchased"
            ? actionColumnAcceptedStage
            : null
        }
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/orders/pure_plus.json?stage=${stage}&`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                if (result?.records) {
                  // setUserList(result?.user_list);
                  resolve({
                    data: result.records,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                } else {
                  resolve({
                    data: [],
                  });
                }

                if (result?.error) {
                  enqueueSnackbar(result.error, {
                    variant: "error",
                  });
                }
              });
          })
        }
      />
    </div>
  );
};

export default Index;
