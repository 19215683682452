import React from 'react';
import Table from '../../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from "../../shared/pure_icons";
import $ from "jquery";
import ReactToPrint from "react-to-print";
import Label from "./_order_label";
import RangePicker from "react-range-picker";
import {Button} from '@material-ui/core';
import Modal from "../../shared/modal/material_dialog";

class OutForDelivery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			working: false,
			date: null,
			date2: null,
			muiTableKey: 0,
		};
		this._tableRef = React.createRef();
	}

	reloadData = () => {
		this._tableRef.current.onQueryChange();
	}

	handleMakeAsDelivered = (rowData) => {
		let self = this;
		this.setState({working: true});
		$.ajax({
			url: `/order_item_stages/make_as_delivered`,
			type: 'POST',
			data: {group_id: rowData.group_id},
			dataType: 'json',
			success: function (response) {
				self.reloadData();
				self.setState({working: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
			},
			error: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	render() {
		const onDateChanges = (date, date2) => {
			if (!date2) return;
			this.setState({date: date, date2: date2})
			this._tableRef.current.onQueryChange();
		};

		const clear = () => {
			if (this.state.date && this.state.date2){
				this.setState({date: null, date2: null})
				this._tableRef.current.onQueryChange();
			}
		};
		const placeholder = ({ startDate, endDate }) => {
			if (!this.state.date) {
				return <button type="button" className="btn btn-link p-0 m-0">Select date range</button>
			}
			return (
				<div>
					<div>From - {this.state.date.toLocaleDateString("es-GT")}</div>
					{this.state.date2 && (
						<div>To - {this.state.date2.toLocaleDateString("es-GT")}</div>
					)}
				</div>
			);
		};

		const footer = ({ startDate, endDate, today, close }) => {
			return <div className='row pt-2'><button type="button" className="btn btn btn-light btn-sm" onClick={clear}>Clear</button></div>
		}

		const columns = [
			{title: 'Orders', field: 'orders', sorting: false, filtering: false},
			{title: 'Customer Phone',	field: 'phone', sorting: false, cellStyle: {width: '5%'}, filtering: false},
			{title: 'Customer Name', field: 'name', sorting: false, filtering: false},
			{title: 'Address', field: 'address', sorting: false, filtering: false},
			{title: 'Items', field: 'items_count', sorting: false, cellStyle: {width: '5%'}, filtering: false},
			{title: 'Date', field: 'created_at', filterComponent: () => <RangePicker onDateSelected={onDateChanges}  rangeTillEndOfDay={true} placeholderText='Select date' dateFormat='DDDD-MMMM-YYYY' placeholder={placeholder} footer={footer}/>},
		]

		return (
			<div className='bg-white rounded'>
				<Table
					title={'Out For Delivery'}
					key={this.state.muiTableKey}
					options={{debounceInterval: 1000, actionsColumnIndex: -1}}
					columns={columns}
					tableRef={this._tableRef}
					actions={
						[
							{
								icon: () => <pureIcons.FiRefreshCw/>,
								onClick: () => {this.reloadData()},
								isFreeAction: true,
								tooltip: 'Refresh'
							},
							rowData => ({
								icon: () => <PrintOrderLabel shopper_id={rowData.shopper_id} group_id={rowData.group_id}/>,
								tooltip: <span>Print Label</span>,
								position: 'row'
							}),
							{
								icon: () => <pureIcons.ThumbUpIcon/>,
								tooltip: <span>Mark as delivered</span>,
								onClick: (event , rowData) => { window.confirm(`Are you sure you want to mark ${rowData.group_id} as delivered?`) && this.handleMakeAsDelivered(rowData) },
								position: 'row'
							},
						]
					}
					data={query =>
						new Promise((resolve, reject) => {
							var filters
							if (query.filters.length > 0) {
								filters = query.filters.map((col) => ({field: col.column.field, value: col.value}))
							}
							let url = `/order_item_stages/out_for_delivery.json?date_start=${this.state.date}&date_end=${this.state.date2}&`
							url += 'per_page=' + query.pageSize
							url += '&page=' + (query.page + 1)
							if (query.filters.length > 0) {
								url += '&filters=' + JSON.stringify(filters)
							}
							if (query.orderBy) {
								url += '&orderBy=' + query.orderBy.field
								url += '&orderDirection=' + (query.orderDirection)
							}
							if (query.search) {
								url += '&search=' + (query.search)
							}
							fetch(url)
							.then(response => response.json())
							.then((result) => {
								resolve({
									data: result.records,
									page: result.page - 1,
									totalCount: result.total,
								});
								window.breadcrumb.addBreadcrumb(result.breadcrumb);
							})
						})
					}
				/>
			</div>
		);
	}
}
export default withSnackbar(OutForDelivery)

class PrintLabel extends React.Component {

	render() {
		return (
			<div>
				<style type="text/css" media="print">{"@page {size: landscape; }"}</style>
				<ReactToPrint
					trigger={() => <Button type='submit'	variant="outlined" color="primary"> print</Button>}
					content={() => this.componentRef}
				/>
				<div className='d-none d-print-block'>
					<Label ref={el => (this.componentRef = el)} data={this.props.shopper}/>
				</div>
			</div>
		);
	}
}

function PrintOrderLabel(props) {
	const [open, setOpen] = React.useState(false);
	const [shopper, setShopper] = React.useState({});

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		fetchShopperData();
		setOpen(true);
	};

	const fetchShopperData = () => {
		const url = `/order_items/shopper_items_in_sorting_facility.json?shopper_id=${props.shopper_id}&stage=out_for_delivery&group_id=${props.group_id}&locale=ar`
		fetch(url)
			.then(response => {return response.json()})
			.then(result => {setShopper(result.shopper)}
		);
	}

	return (
		<>
			<pureIcons.PrintIcon onClick={handleOpen}/>
			<Modal
				isOpen={open}
				title=''
				handleClose={handleClose}
				maxHeight='60%'
				maxWidth='md'
				actions={<PrintLabel shopper={shopper}/>}>
				{<Label data={shopper}/>}
			</Modal>
		</>
	);
}
