import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../shared/modal/material_dialog";
import Table from "../shared/tables/material_table";
import $ from "jquery";
import { useSnackbar } from "notistack";
import { Backdrop, makeStyles, CircularProgress } from "@material-ui/core";
import ReactDOM from "react-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

const Unshipped = forwardRef((props, ref: any) => {
  const [open, setOpen] = useState<any>(false);
  const [working, setWorking] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const columns = [
    { title: "ID", field: "order_id" },
    { title: "Order number", field: "order_number" },
    { title: "Order placed at:", field: "created_at" },
    { title: "Phone number", field: "phone_number" },
    { title: "Shopper type", field: "shopper_type" },
    { title: "Shopper Address", field: "shopper_address" },
    { title: "Shopper Name", field: "shopper_name" },
    { title: "Shipping?", field: "freight_type" },
    { title: "Payment type", field: "payment_type" },
    { title: "Order total", field: "order_total" },
    {
      title: "Credit card(country, last4, exp_month, exp_year, first6):",
      field: "card_info",
      cellStyle: {
        maxWidth: 10,
      },
    },
    { title: "3D secure authentication", field: "card_authentication" },
    { title: "Payment gateway", field: "payment_gateway" },
  ];

  const body = (
    <>
      <Table
        title={`Active unshippable orders`}
        options={{
          toolbar: true,
          sorting: false,
          search: true,
          exportButton: false,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          pageSize: 12,
          pageSizeOptions: [12],
        }}
        columns={columns}
        // data={tableData}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/orders/unshippable_items.json?`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.orders,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    </>
  );

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const fetchData = () => {
    setWorking(true);
    $.ajax({
      url: `/orders/unshippable_items.json`,
      type: "GET",
      dataType: "json",
      success: (response) => {
        setWorking(false);
        console.log(response);
        setTableData(response.orders);
      },
      error: (response) => {
        {
          enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
        }
        setWorking(false);
      },
    });
  };
  return (
    <>
      <Modal
        isOpen={open}
        handleClose={handleClose}
        minHeight="70vh"
        maxHeight="90%"
        maxWidth="xl"
      >
        <Backdrop className={classes.backdrop} open={working}>
          {" "}
          <CircularProgress color="inherit" />
        </Backdrop>
        {body}
      </Modal>
    </>
  );
});

export default Unshipped;
