import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Backdrop from "../shared/backdrop";
import Modal from "../shared/modal/material_dialog";
import { convertSnakeCaseToTitleCase } from "../settings/coupon_discount_code/data/data";

const SharingShow = forwardRef(({ callback }: any, ref) => {
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [LinkData, setLinkData] = useState([]);

  useImperativeHandle(ref, () => ({
    handleOpen(id, name) {
      setOpen(true);
      fetchLinkDetails(id);
    },
  }));

  const fetchLinkDetails = async (id) => {
    try {
      const response1 = await fetch(`/short_links/${id}.json`);
      const data1 = await response1.json();
      setLinkData(data1.link_details);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLinkData([]);
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="Link details"></CardHeader>
          <CardContent>
            <Box
              sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
            >
              <Table>
                <TableBody>
                  {LinkData && LinkData.length > 0 && LinkData[0] ? (
                    Object.entries(LinkData[0]).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>
                          {convertSnakeCaseToTitleCase(key)}
                        </TableCell>
                        <TableCell>
                          {typeof value === "object"
                            ? JSON.stringify(value)
                            : value}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default SharingShow;
