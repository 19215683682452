import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import Select from "../../shared/autocomplete";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";

class AddToListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      shipping_method: "",
      errors: [],
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = (products) => {
    this.setState({
      products: products,
      open: true,
      shipping_methods: this.props.shipping_methods,
      shipping_method: "",
    });
    if (this.props.shipping_methods.length === 1) {
      this.setState({ shipping_method: this.props.shipping_methods[0] });
    }
  };

  handleSubmit = (event) => {
    let self = this;
    event.preventDefault();
    if (!this.validate()) return;
    this.setState({ working: true });
    const payload = {
      group_id: this.state.packing_list,
      products: this.state.products,
      shipping_method: this.state.shipping_method,
    };
    $.ajax({
      url: `/order_item_stages/create_packing_list`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false, open: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.props.callBackAddToList();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  handleSelectCallBack = (data) => {
    this.setState({ packing_list: data.value });
    this.validate({ data });
  };

  validate = (fieldValues = this.state.packing_list) => {
    let temp = { ...this.state.errors };
    temp.packing_list = fieldValues ? "" : "Required";
    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state.packing_list)
      return Object.values(temp).every((x) => x === "");
  };

  render() {
    const { classes } = this.props;
    const actions = (
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        className={classes.button}
        onClick={this.handleSubmit}
      >
        Submit
      </Button>
    );

    const body = (
      <Card className={classes.root} style={{ minHeight: "30vh" }}>
        <div className="container pb-5">
          <p>
            You have selected {this.state.products?.length} item
            {this.state.products?.length > 1 ? "s" : ""} to add to an existing
            packing list
          </p>
          <div className="row pt-2">
            <Select
              name="packing_list"
              label="Packing List"
              url={`/order_item_stages/ready_to_ship.json?sea_shipping=${!this
                .props.orderState}`}
              error={this.state.errors.packing_list}
              callBack={this.handleSelectCallBack}
            />
          </div>
          {this.props.shipping_methods?.length > 1 && (
            <div className="row pt-3">
              <div className="col">
                <TextField
                  label="Shipping Method"
                  select
                  fullWidth
                  value={this.state.shipping_method || ""}
                  {...(this.state.errors.shipping_method && {
                    error: true,
                    helperText: this.state.errors.shipping_method,
                  })}
                  onChange={(event) =>
                    this.setState({ shipping_method: event.target.value })
                  }
                >
                  {this.state.shipping_methods?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}
        </div>
      </Card>
    );

    return (
      <>
        <Backdrop open={this.state.working} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title="Add to Packing List"
          handleClose={this.handleClose}
          maxHeight="400%"
          maxWidth="sm"
          actions={actions}
        >
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(AddToListForm));
