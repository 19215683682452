import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import Modal from "../../shared/modal/material_dialog";

const EditPhases = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [ids, setId] = useState<any>();
  const [ordersData, setOrdersData] = useState<any>([]);
  const [ordersNewData, setOrdersNewData] = useState<any>();
  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setId(id);
      getData(id);
    },
  }));

  const getData = (id) => {
    let url = `/shipping_carriers/${id}.json?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setOrdersData(result.phases_eta);
      });
  };

  const onChangeNewData = (e) => {
    setOrdersData({ ...ordersData, [e.target.name]: e.target.value });
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onUpdateSave = () => {
    const formData = new FormData();

    for (const key in ordersData) {
      formData.append(key, ordersData[key]);
    }
    let url = `/shipping_carriers/${ids}.json?locale=en`;
    fetch(url, {
      method: "PATCH",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          onCloseModal();
          enqueueSnackbar(result.msg, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.responseJSON.error, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Modal
        title={`Phases ETA`}
        handleClose={() => onCloseModal()}
        disableBackdropClick={false}
        isOpen={open}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={""}
      >
        <Card>
          <CardContent>
            {Object.entries(ordersData).map(([key, value]) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <Typography>{key}</Typography>

                    <TextField
                      style={{
                        minWidth: "30%",
                        marginRight: "42%",
                        marginLeft: "auto",
                      }}
                      name={key}
                      size="small"
                      variant="outlined"
                      value={value}
                      onChange={(ev) => onChangeNewData(ev)}
                      // placeholder="Name en"
                      // helperText={error.name ? "Please enter the name" : ""}
                      // error={error.name}
                      FormHelperTextProps={{ style: { color: "red" } }}
                    />
                  </Box>
                </>
              );
            })}

            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => onUpdateSave()}
                // onClick={() => {
                //   if (operation.current === "Add") {
                //     addClick();
                //   } else if (operation.current === "Edit") {
                //     editClick();
                //   }
                // }}
              >
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
});

export default EditPhases;
