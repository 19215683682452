import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 10000,
		color: '#fff',
	},
}));
const handleClose = () => {}
export default function CoreBackdrop({open}) {
	const classes = useStyles();
	const handleClose = () => {}
	return (
		<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
