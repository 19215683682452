import React, { useEffect, useState } from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import { Badge, Button } from "@material-ui/core";
import ShowDigitalProduct from "./show_digital_product";
import PhoneEdit from "./_edit";
import $ from "jquery";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Backdrop from "../../shared/backdrop";
import PhoneCardSlider from "./phone_card_sidebar";
import { Drawer } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const Index = (props) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [submitting, setSubmitting] = useState<any>(false);

  const _tableRef = React.createRef<any>();
  const _showProductRef = React.createRef<any>();
  const _EditProductRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [showBalanceMessage, setShowBalanceMessage] = useState(false);
  const [balanceData, setBalanceData] = useState<any>();

  const toggleShowPhoneCardSlider = () => {
    setShowBalanceMessage(!showBalanceMessage);
  };

  const getData = () => {
    setSubmitting(true);
    let url = `/products/phone_cards.json`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setSubmitting(false);
        setOrdersData(result.digital_brands.flat()),
          window.breadcrumb.addBreadcrumb(result.breadcrumb);
        setBalanceData(result?.balance_message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      title: "",
      field: "image_tag",
      sorting: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "70px", width: "80px", objectFit: "contain" }}
              src={row.image_tag}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "ID",
      field: "digital_brand_id",
      filtering: false,
      sorting: true,
      editable: "never",
    },
    {
      title: "Rmt brand id",
      field: "brand_id",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Brand name",
      field: "brand_name",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "10%" },
      sorting: false,
    },
    {
      title: "Provider",
      field: "provider",
      filtering: false,
      editable: "never",
      sorting: false,
    },

    {
      title: "Available to user?",
      field: "availability",
      editable: "never",
      filtering: false,
      sorting: false,
      render: (row) => (
        <>{capitalizeFirstLetter(row.availability.toString())}</>
      ),
    },
  ];

  const onChangeAvalability = (available, id) => {
    $.ajax({
      url: `/products/update_availability?available=${!available}&digital_brand_id=${id}`,
      type: "GET",
      dataType: "json",
      success: function (response) {
        if (response?.success) {
          enqueueSnackbar(response.response, {
            variant: "success",
          });
          getData();
        } else {
          enqueueSnackbar(response.response, {
            variant: "error",
          });
        }
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      <ShowDigitalProduct ref={_showProductRef} />
      <PhoneEdit ref={_EditProductRef} />
      <Table
        title={`Digital brands`}
        options={{
          toolbar: true,
          sorting: true,
          search: true,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          //   {
          //     icon: () => <pureIcons.AiOutlinePlus />,
          //     onClick: () => {
          //       _addNewKidusRef.current.handleOpen();
          //     },
          //     isFreeAction: true,
          //     tooltip: "Add New Kidus Serial",
          //   },
          //   {
          //     icon: () => <pureIcons.FiRefreshCw />,
          //     onClick: () => {
          //       reloadData();
          //     },
          //     isFreeAction: true,
          //     tooltip: "Refresh",
          //   },
          (rowData) => ({
            icon: () => (
              <>
                {rowData.availability ? (
                  <NotInterestedIcon style={{ color: "red" }} />
                ) : (
                  <AssignmentTurnedInIcon style={{ color: "green" }} />
                )}
              </>
            ),
            tooltip: (
              <span> {rowData.availability ? "Unavailable" : "Available"}</span>
            ),
            onClick: () => {
              onChangeAvalability(
                rowData.availability,
                rowData.digital_brand_id
              );
            },
            position: "row",
          }),
          (rowData) => ({
            icon: () => <pureIcons.BiShow />,
            tooltip: <span>Show Products</span>,
            onClick: () => {
              _showProductRef.current.handleOpen(
                rowData.digital_brand_id,
                rowData.brand_name
              );
            },
            position: "row",
          }),
          (rowData) => ({
            icon: () => <pureIcons.Edit />,
            tooltip: <span>Edit</span>,
            onClick: () => {
              _EditProductRef.current.handleOpen(rowData);
            },
            position: "row",
          }),
          {
            icon: () => <InfoIcon />,
            onClick: toggleShowPhoneCardSlider,
            isFreeAction: true,
            tooltip: "Balance Message",
          },
        ]}
        data={ordersData}
      />
      <Drawer
        anchor="right"
        open={showBalanceMessage}
        onClose={toggleShowPhoneCardSlider}
      >
        <PhoneCardSlider
          toggleShowPhoneCardSlider={toggleShowPhoneCardSlider}
          balanceData={balanceData}
        />
      </Drawer>
    </div>
  );
};

export default withSnackbar(Index);
