import React from 'react'
import { MenuItem, Badge } from '@material-ui/core'
import Table from '../shared/tables/material_table'
import Modal from '../shared/modal/material_dialog'
import ShowItem from "../order_items/show";
import pureIcons from '../shared/pure_icons';

export default class FindItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this._ShowItemRef = React.createRef();
    this._tableRef = React.createRef();
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const filter = {received: "Received", purchased: "Placed with the vendor", vendor_shipped: "Shipped from the vendor", hub_packing_list: "Received in the hub",
      hub_stock: "stocked", ready_to_ship: "Ready to ship", shipped_to_final_destination: "Shipped to final destination", sorting_facility: "Sorting facility", ready_for_pickup: "Ready for pickup",
      out_for_delivery: "Out for delivery", delivered: "Delivered", picked_up: "Picked up", canceled: "Canceled", ready_for_delivery: "Ready for delivery"}

    const columns = [
      {
        title: '', field: 'image', sorting: false, filtering: false,
        render: (row) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <Badge badgeContent='DG' invisible={!row.dg} overlap="circular" color="error">
              <img style={{ height: '60px', width: '60px', objectFit: 'contain' }} alt={row.name} src={row.image}></img>
            </Badge>
          </div>
        )
      },
      { title: 'Product Name', field: 'name_short', sorting: false, cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '15%' } },
      {title: 'Stage', field: 'stage', sorting: false, cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word', width: '10%'}, lookup: filter, render: rowDate => rowDate.stage_humanize},
      { title: 'QTY', field: 'qty', sorting: false, cellStyle: { width: '2%' }, filtering: false },
      { title: 'Tracking/Invoice', field: 'tracking', sorting: false, cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 } },
      { title: 'Vendor', field: 'brand', sorting: false },
      { title: 'Order Number', field: 'order_number', sorting: false },
      { title: 'Code', field: 'redeem_code', sorting: false },
      { title: 'Order Date', field: 'order_date', sorting: false, filtering: false },
    ]
    const body = (
      <div className='bg-white rounded'>
        <ShowItem ref={this._ShowItemRef} reloadData={this.reloadData} />
        <Table
          options={{
            toolbar: false,
            filtering: true,
            pageSize: 5,
            pageSizeOptions: [5, 25, 100],
            debounceInterval: 1000,
          }
          }
          columns={columns}
          tableRef={this._tableRef}
          actions={
            [{
              icon: pureIcons.ImInfo,
              tooltip: 'Show',
              onClick: (event, rowData) => this._ShowItemRef.current.handleOpen(rowData.order_item_id),
              position: 'row'
            }
            ]
          }
          data={query =>
            new Promise((resolve, reject) => {
              var filters
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
              }
              let url = '/marketplace_products.json?'
              url += 'per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.filters.length > 0) {
                url += '&filters=' + encodeURIComponent(JSON.stringify(filters));
              }
              if (query.orderBy) {
                url += '&orderBy=' + (query.orderBy)
                url += '&orderDirection=' + (query.orderDirection)
              }
              if (query.search) {
                url += '&search=' + (query.search)
              }
              fetch(url)
                .then(response => response.json())
                .then((result) => {
                  resolve({
                    data: result.products,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
            })
          }
        />
      </div>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title='Find Item'
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='90%'
        maxWidth='xl'
      >
        {body}
      </Modal>
    )
  }
}
