import React from "react";
export default class Invoice extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {invoice_data, total, total_weight} = this.props
		return (
			<div className='d-none d-print-block'>
				<div className="container-fluid p-3">
					<div className="pure-invoice--header">
						<table>
							<tr className='p-0 m-0'>
								<td className="text-start" style={{width:'30%'}}><small>This invoice must be completed in English.</small></td>
								<td className="text-center" style={{width:'30%'}}><h5 className='p-0 m-0'>CUSTOMS INVOICE</h5></td>
								<td className="text-end" style={{width:'30%'}}></td>
							</tr>
						</table>
						<table className="pdf-table">
							<tr>
								<td className="border border-bottom-0 border-3 p-1 align-top">
									<ul className="list-unstyled">
										<li><b>SENT BY:</b></li>
										<li><b>Contact Name:</b> {invoice_data.user}</li>
										<li><b>Telephone No.:</b> {invoice_data.phone_number}</li>
										<li><b>E-Mail:</b> office@pureplatform.com</li>
										<li><b>Name/Address:</b></li>
										<li>{invoice_data.user}</li>
										<li>{invoice_data.from_address}</li>
										<li><b>Country/Territory:</b>{invoice_data.from_country}</li>
									</ul>
								</td>
								<td className="border border-bottom-0 border-3 p-1 align-top">
									<ul className="list-unstyled">
										<li><b>CONSIGNEE:</b></li>
										<li><b>Tax ID#:</b></li>
										<li><b>Contact Name:</b> PURE PLATFORM</li>
										<li><b>Name/Address:</b></li>
										<li>Moe Asad</li>
										<li>{invoice_data.to_address}</li>
										<li><b>Country/Territory:</b>{invoice_data.to_country}</li>
									</ul>
								</td>
							</tr>
						</table>
						<table className="pdf-table m-0">
							<tr>
								<td className="border border-3 p-1 align-top">
									<ul className="list-unstyled">
										<li><b>Ship Date: </b>{invoice_data.today}</li>
										<li><b>Purpose of Shipment: </b>SOLD</li>
										<li><b>Parties to Transaction: </b>Non-Related</li>
										<li></li>
										<li></li>
									</ul>
								</td>
								<td className="border border-3 p-1 align-top">
									<ul className="list-unstyled">
										<li><b>SOLD TO / IMPORTER (if different from Consignee):</b></li>
										<li>Same as CONSIGNEE</li>
										<li><b>Tax ID#:</b></li>
										<li><b>Name/Address:</b></li>
										<li><b>Country/Territory:</b>{invoice_data.to_country}</li>
									</ul>
								</td>
							</tr>
						</table>
					</div>
					<div className="pure-invoice--body">
						<table className="pdf-table border border-top-0 border-3 p-2" style={{width: "100%"}}>
							<tbody>
								<tr>
									<td className="text-start" style={{width:'5%'}}><strong>No. of Packages</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>HS Code</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>No. of Units</strong></td>
									<td className="text-center" style={{width:'8%'}}><strong>Net Weight (LBS/KGS)</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>Unit of Measure</strong></td>
									<td className="text-center" style={{width:'42%'}}><strong>Description of Goods</strong></td>
									<td className="text-center" style={{width:'10%'}}><strong>COO</strong></td>
									<td className="text-center" style={{width:'10%'}}><strong>Unit Value</strong></td>
									<td className="text-center" style={{width:'10%'}}><strong>Total Value</strong></td>
								</tr>
								<tr>
									<td className="text-center">{invoice_data.number_of_units}</td>
									<td>40159000</td>
									<td>{invoice_data.number_of_units}</td>
									<td>{total_weight}</td>
									<td>BOX</td>
									<td className='text-start'>
										<p>{invoice_data.descriptions}</p>
										<div className='row'>
											{invoice_data.boxes.map((box, index) => <div className='col-6'>{box}</div>)}
										</div>
									</td>
									<td>US</td>
									<td>{(total/invoice_data.number_of_units).toFixed(2)}</td>
									<td>{total}</td>
								</tr>
							</tbody>
						</table>
						<table className="pdf-table border border-top-0 border-3 p-2" style={{width: "100%"}}>
							<tbody>
								<tr>
									<td className="text-center" style={{width:'5%'}}><strong>Total Pkgs</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>Total Units</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>Total Net Weight</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>(Indicate LBS/KGS)</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>Total Gross Weight</strong></td>
									<td className="text-center" style={{width:'5%'}}><strong>(Indicate LBS/KGS)</strong></td>
									<td className="text-start border-bottom-0" style={{width:'30%'}}><strong>Terms of Sale:</strong></td>
									<td className="text-center" style={{width:'10%'}}><strong>Subtotal:</strong></td>
									<td className="text-center" style={{width:'10%'}}>{total}</td>
								</tr>
								<tr>
									<td className="text-center">{invoice_data.number_of_units}</td>
									<td>{invoice_data.number_of_units}</td>
									<td colSpan="2">{total_weight} {invoice_data.weight_unit}</td>
									<td colSpan="2">{total_weight} {invoice_data.weight_unit}</td>
									<td></td>
									<td><strong>Insurance:</strong></td>
									<td>0.00</td>
								</tr>
								<tr>
									<td colSpan="7"><strong>Special Instructions:</strong></td>
									<td><strong>Freight:</strong></td>
									<td>0.00</td>
								</tr>
								<tr>
									<td colSpan="7" rowSpan={2}>
										<p><strong>Declaration Statement(s):</strong></p>
										<small>These items are controlled by the U.S. Government and authorized for export only to the country of ultimate destination for use by the ultimate consignee or end-user(s) herein
											identified. They may not be resold, transferred, or otherwise disposed of, to any other country or to any person other than the authorized ultimate consignee or end-user(s), either in
											their original form or after being incorporated into other items, without first obtaining approval from the U.S. government or as otherwise authorized by U.S. law and regulations.</small>
									</td>
									<td><strong>Handling:</strong></td>
									<td>0.00</td>
								</tr>
								<tr>
									<td className="text-center"><strong>Other:</strong></td>
									<td>0.00</td>
								</tr>
								<tr>
									<td colSpan="7"><strong>I declare that all the information contained in this invoice to be true and correct.</strong></td>
									<td><strong>Invoice Total:</strong></td>
									<td>{total}</td>
								</tr>
								<tr>
									<td colSpan="7">
										<strong>Originator or Name of Company Representative if the invoice is being completed on behalf of a company or individual:</strong>
										{invoice_data.user}
									</td>
									<td><strong>Currency Codd:</strong></td>
									<td>USD</td>
								</tr>
								<tr>
									<th colSpan="8">
										<strong>Signature / Date:</strong>
										<img src={this.props.signature} className='signature' alt='signature'/>
									</th>
									<th>
										{invoice_data.today}
									</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
