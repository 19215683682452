import React from 'react';
import {IconButton, TableCell, TableBody, TableRow, Paper, Table} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import InfoIcon from '@material-ui/icons/Info';

export default function OrderInfo({orderSummary}) {
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const body = <Paper>
		<Table>
			<TableBody>
				{orderSummary.map(row => (
					<TableRow key={row.name}>
						<TableCell component='th' scope='row' size='small' >
							{row.name}
						</TableCell>
						<TableCell align='left'>{row.value}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</Paper>

	return (
		<>
			<IconButton aria-label='order-info' onClick={handleOpen}>
				<InfoIcon />
			</IconButton>
			<Modal
				disableBackdropClick={false}
				isOpen={open}
				title='Order summary'
				handleClose={handleClose}
				minHeight='40vh'
				maxHeight='60%'
				maxWidth='sm'
				actions={<></>}>
				{body}
			</Modal>
		</>
	)
}
