import { withSnackbar } from "notistack";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Backdrop from "../../shared/backdrop";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import Modal from "../../shared/modal/material_dialog";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

const ProductStatistic = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState<any>(moment());
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [openNested, setOpenNested] = useState(false);
  const [newVal, setNewVal] = useState("");
  const [ordersData, setOrdersData] = useState<any>(null);
  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      getData();
    },
  }));

  const getData = () => {
    let url = `/prohibited_products/products_statistic.json?locale=en`;
    setLoader(true);
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setOrdersData(result);
        } else {
          setOrdersData(null);
        }
        setLoader(false);
      })
      .catch((err) => {
        setOrdersData(null);
        setLoader(false);
        console.log(err);
      });
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const updateData = () => {
    let url = `/prohibited_products/update_mismatch_prohibited?locale=en&new_value=${newVal}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setNewVal("");
        getData();
        console.log(result);
      });
  };

  const onCloseNestedModal = () => {
    setOpenNested(false);
  };

  const printData = () => {
    const formatEndDate = moment(endDate).format("DD-MM-YYYY");
    const formatStartDate = moment(startDate).format("DD-MM-YYYY");
    window.open(
      `/prohibited_products/prohibited_canceled_items_details.json?locale=en&start_date=${formatStartDate}&end_date=${formatEndDate}`,
      "_self"
    );
    onCloseNestedModal();
  };

  return (
    <div>
      <Modal
        title={`Product Statistics`}
        handleClose={() => onCloseModal()}
        disableBackdropClick={false}
        isOpen={open}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={""}
      >
        {ordersData?.id ? (
          <Card>
            <CardContent>
              <>
                <Box
                  sx={{
                    margin: "20px 0px",
                  }}
                >
                  <div>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Prohibited Products</TableCell>
                          <TableCell>
                            {ordersData?.prohibited_products}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Marketplace Products</TableCell>
                          <TableCell>
                            {ordersData?.marketplace_products}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Canceled Prohibited</TableCell>
                          <TableCell>
                            {ordersData?.canceled_prohibited}
                          </TableCell>
                          <TableCell>
                            <Button
                              type="button"
                              style={{
                                backgroundColor: "#ec9a53",
                                color: "#ebeef0",
                                textTransform: "none",
                              }}
                              onClick={() => setOpenNested(true)}
                            >
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Mismatch Prohibited</TableCell>
                          <TableCell>
                            {ordersData?.mismatch_prohibited}
                          </TableCell>
                          <TableCell>
                            <span style={{ verticalAlign: "sub" }}>
                              Edit (if needed) :
                            </span>
                            <TextField
                              type="number"
                              style={{ margin: "0rem 3rem" }}
                              size="small"
                              variant="outlined"
                              value={newVal}
                              onChange={(ev) => setNewVal(ev.target.value)}
                            />
                            <Button
                              type="button"
                              style={{
                                backgroundColor: "#ec9a53",
                                color: "#ebeef0",
                                textTransform: "none",
                              }}
                              onClick={() => updateData()}
                            >
                              Update
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </>

              {/* <CardActions style={{ justifyContent: "end" }}>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "#ec9a53",
                    color: "#ebeef0",
                    textTransform: "none",
                  }}
                  onClick={() => onCloseModal()}
                  // onClick={() => {
                  //   if (operation.current === "Add") {
                  //     addClick();
                  //   } else if (operation.current === "Edit") {
                  //     editClick();
                  //   }
                  // }}
                >
                  Back
                </Button>
              </CardActions> */}
            </CardContent>
          </Card>
        ) : (
          <Backdrop open={loader} />
        )}
      </Modal>
      <Modal
        title={`Prohibited canceled items details`}
        handleClose={() => onCloseNestedModal()}
        disableBackdropClick={false}
        isOpen={openNested}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={""}
      >
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader></CardHeader>

          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>From date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "15%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="start_date"
                    //   label="From date"
                    onChange={(ev) => setStartDate(ev)}
                    format="dd/MM/yyyy"
                    value={startDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              {/* placeholder={placeholder} footer={footer}  */}
            </Box>

            <Divider style={{ margin: "20px 0px" }} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>To date</Typography>

              <Box style={{ minWidth: "40%", marginLeft: "17%" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    name="end_date"
                    //   label="To date"
                    onChange={(ev) => setEndDate(ev)}
                    format="dd/MM/yyyy"
                    value={endDate}
                    // {...(this.state.errors.eta && {
                    //   error: true,
                    //   helperText: this.state.errors.eta,
                    // })}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>

            <Divider style={{ margin: "20px 0px" }} />

            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => printData()}
              >
                Print Sheet
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
});

export default withSnackbar(ProductStatistic);
