import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";

const EditBusinessCat = forwardRef((props: any, ref) => {
  const { business_categories, callback } = props;
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [businessProfileId, setBusinessProfileId] = useState<any>();
  const [businessProfile, setBusinessProfile] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [discountRate, setDiscount] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  const _IdRef = useRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    async handleOpen(data) {
      console.log(data);
      await fetchData(data.category);
      setOpen(true);
      setDiscount(data.discount_rate);
      setBusinessProfileId(data.id);
    },
  }));

  const fetchData = async (search) => {
    setSubmitting(true);
    const url = `/business_category_discounts/business_profile_select?locale=en`;
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setData(result.options);
        setCatData(result.part_categories);
        const findId = result.part_categories.find((obj) => {
          if (obj.category_name === search) {
            return obj.category_id;
          }
        });
        _IdRef.current = findId?.category_id;
        setCategory(findId?.category_id);
        setSubmitting(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.responseJSON.error, {
          variant: "error",
        });
      });

    // try {
    //   const response1 = await fetch(
    //     "/business_category_discounts/business_profile_select?locale=en"
    //   );
    //   const data1 = await response1.json();
    //   setData(data1.options);
    //   setCatData(data1.part_categories);
    //   const findId = data1.part_categories.find((obj) => {
    //     if (obj.category_name === search) {
    //       return obj.category_id;
    //     }
    //   });

    //   console.log(findId.category_id);
    //   await setCategory(findId?.category_id);
    //   // data1.part_categories.filter((o) => {
    //   //   if (o.category_name.includes(search)) {
    //   //     setCategory(o.category_id);
    //   //   }
    //   // });
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveData = () => {
    let formData = new FormData();
    // formData.append(
    //   "business_category_discount[business_profile_id]",
    //   businessProfileId
    // );
    formData.append("business_category_discount[parts_category_id]", category);
    formData.append("business_category_discount[discount_rate]", discountRate);
    formData.append("id", businessProfileId);

    const url = `/business_category_discounts/${businessProfileId}.json/?locale=en`;
    setSubmitting(true);

    fetch(url, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          enqueueSnackbar("successfully updated!", {
            variant: "success",
          });
          handleClose();
          setSubmitting(false);
          callback();
        } else {
          enqueueSnackbar("failed to added!", {
            variant: "error",
          });
          handleClose();
        }
      });
  };

  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };

  const businessCategoryNames = business_categories.current
    ?.map((val) => val.name)
    .join();

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="Edit Supplier Category and Discount Rate"></CardHeader>
          <CardContent>
            {/* <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Select Supplier"
                select
                fullWidth
                margin="normal"
                label="Discount Type"
                style={{ width: "20%" }}
                value={businessProfileId}
                onChange={(e: any) => {
                  setBusinessProfileId(e.target.value);
                }}
              >
                {data.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.phone_number}
                  </option>
                ))}
              </TextField>
            </div> */}

            {businessCategoryNames && (
              <div
                className="col mt-2"
                style={{ display: "flex", justifyContent: "start" }}
              >
                <Typography variant="h6">Supplier categories: </Typography>
                <Typography variant="h6">{businessCategoryNames}</Typography>
              </div>
            )}
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Select
                // select
                // fullWidth
                // margin="normal"
                id="supplier_category"
                label="Supplier Category"
                style={{ width: "20%" }}
                value={category || _IdRef.current}
                onChange={(e: any) => {
                  setCategory(e.target.value);
                }}
              >
                {catData.map((option: any) => (
                  <MenuItem key={option.category_id} value={option.category_id}>
                    {option.category_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Discount rate"
                type="number"
                margin="normal"
                label="Discount rate"
                style={{ width: "20%" }}
                value={discountRate}
                onChange={(e: any) => {
                  setDiscount(e.target.value);
                }}
              ></TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(EditBusinessCat);
