import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Card, TextField} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import Table from "../../shared/tables/material_table";
import $ from "jquery";
import NumericInput from 'react-numeric-input';
import ReactToPrint from "react-to-print";
import Invoice2 from './_invoice2'

class PackingListForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      products: [],
      page: 1,
      errors: {},
      boxes: [],
      values: {},
      total_weight: 0.0,
      factor: 0.4,
      working: false
    };
    this._ShowOrder = React.createRef();
  };

  handleOpen = (data) => {
    this.setState({working: true, factor: 0.4});
    let self = this
    $.ajax({
      url: `/order_item_stages/prepare_detail_invoice.json?group_ids=${data}`,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        self.setState({
          products: response.products,
          working: false,
          file_path: null,
          boxes: response.boxes,
          invoice_data: response.invoice_data,
          open: true,
          logo: response.logo,
          signature: response.signature,
          category_counts: response.category_counts
        });
        var valuesList = {};
        response.boxes.map(box => valuesList = {...valuesList, [`w_${box.name}`]: box.weight, [`d_${box.name}`]: box.dimensions});
        self.setState({values: valuesList, total_weight: response.total_weight});
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
        self.setState({working: false});
      },
    });

  };

  handleClose = () => {
    this.setState({open: false, errors: {}, page: 1, products: [], boxes: [], values: {}});
  };

  handleInputChange = value => {
    this.setState({factor: value})
  }

  handleBoxInputChange = e => {
    const {name, value} = e.target;
    this.setState({values: {...this.state.values, [name]: value}})
    this.validate({[name]: value});
  }

  validate = (fieldValues = this.state.values) => {
    let temp = {...this.state.errors};
    temp.serverErrors = '';
    this.state.boxes.map(box => {
      if (`w_${box.name}` in fieldValues) {
        temp[`w_${box.name}`] = fieldValues[`w_${box.name}`] ? '' : 'Required';
      }
      if (`d_${box.name}` in fieldValues) {
        temp[`d_${box.name}`] = fieldValues[`d_${box.name}`] ? '' : 'Required';
      }
    })
    this.setState({errors: {...temp}})

    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  };

  render() {
    console.log(this.state.boxes)
    const {classes} = this.props;
    const columns = [
      {title: 'Name',	field: 'name', sorting: false, cellStyle: {textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 700}},
      {title: 'Box No.',	field: 'group_id', sorting: false, cellStyle: {textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200}},
      {title: 'QTY', field: 'qty', sorting: false},
      {title: `Unit Price($)`, field: 'price'},
      {title: `Total ($)`, field: '', render: rowData => (rowData.qty * rowData.price * this.state.factor).toFixed(2), editable: 'never'}
    ]

    const body = this.state.page === 1 ?
      <Table
        title=''
        options={{
          toolbar: true,
          paging: false,
          filtering: false,
          sorting: false,
          debounceInterval: 1000,
          addRowPosition: 'first'
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                this.setState({products: [...this.state.products, newData]});
                resolve();
              }, 100)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...this.state.products];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                this.setState({products: [...dataUpdate]});

                resolve();
              }, 100)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...this.state.products];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                this.setState({products: [...dataDelete]});
                resolve();
              }, 100)
            }),
        }}
        columns={columns}
        data={this.state.products}
      />
      :
      <Card className={classes.root} style={{minHeight: '30vh'}}>
        <div className="container pb-5">
          {this.state.boxes.map(box=>
            <div className="row pt-2">
              <div className="col"/>
              <div className="col">
                <TextField
                  className={classes.textField}
                  fullWidth
                  name={`w_${box.name}`}
                  value={this.state.values[`w_${box.name}`]}
                  label={box.name + ' Weight'}
                  {...(this.state.errors[`w_${box.name}`] && {error: true, helperText: this.state.errors[`w_${box.name}`]})}
                  onChange={this.handleBoxInputChange}
                />
              </div>
              <div className="col">
                <TextField
                  className={classes.textField}
                  name={`d_${box.name}`}
                  fullWidth
                  value={this.state.values[`d_${box.name}`]}
                  label={box.name + ' Dimensions'}
                  {...(this.state.errors[`d_${box.name}`] && {error: true, helperText: this.state.errors[`d_${box.name}`]})}
                  onChange={this.handleBoxInputChange}
                />
              </div>
              <div className="col"/>
            </div>
          )}
        </div>
      </Card>

    const summary = (
      <div className="container">
        <div className="row ms-4 pt-4">
          <div className="col-2">
            <NumericInput min={0} max={1} step={0.01} value={this.state.factor} mobile size={6} onChange={this.handleInputChange} name='factor' disabled={this.state.page === 2}/>
          </div>
          <div className="col-2">
            <h6>Value: $ {this.state.products.reduce((a, v) => a = a + (v.qty * v.price * this.state.factor), 0).toFixed(2)}</h6>
          </div>
          <div className="col-8">
            <h6>Weight: {this.state.total_weight}</h6>
          </div>
        </div>
      </div>
    )

    const actions = (
      this.state.page === 1 ?
        <Button
          variant='contained'
          type='submit'
          className={classes.button}
          disabled={this.state.working}
          onClick={() => this.setState({page: 2})}
        >
          Next
        </Button> :
        <PrintInvoice products={this.state.products} values={this.state.values} factor={this.state.factor} invoice_data={this.state.invoice_data} logo={this.state.logo} signature={this.state.signature}/>
    );

    return (
      <>
        <Backdrop open={this.state.working}/>
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={'Prepare Invoice'}
          handleClose={this.handleClose}
          minHeight='40vh'
          maxHeight='85%'
          maxWidth='lg'
          summary={summary}
          actions={actions}>
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});
export default (withSnackbar)(withStyles(useStyles)(PackingListForm2))

class PrintInvoice extends React.Component {
  render() {
    document.title= this.props.invoice_data.file_name
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button type='submit'	variant="outlined" color="primary">
            print invoice
          </Button>}
          content={() => this.componentRef}
        />
        <Invoice2 ref={el => (this.componentRef = el)} invoice_data={this.props.invoice_data} products={this.props.products} factor={this.props.factor} logo={this.props.logo} signature={this.props.signature}/>
      </div>
    );
  }
}
