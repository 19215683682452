import React from 'react';
import {CircularProgress, IconButton} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import {MdChangeHistory} from 'react-icons/md';
import CardTimeline from '../notes/_note_time_line'

import { blue } from '@material-ui/core/colors';

export default class ShowIssues extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			issues: [],
			working: false
		}
	};

	handleOpen = () => {
		this.setState({open: true});
		this.getIssues(this.props.order_item_stage_id)
	};

	handleClose = () => {
		this.setState({open: false});
	};

	getIssues = (order_item_stage_id) => {
		this.setState({working: true});
		fetch(`/order_item_stages/${order_item_stage_id}/issues.json`)
		.then(response => response.json())
		.then(result => {
			this.setState({issues: result.issues, working: false});
		})
	};


	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('note' in fieldValues) {
			temp.note = fieldValues.note ? '' : 'Required';
		}
		this.setState({errors: {...temp}})

		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleInputChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value})
		this.validate({[name]: value});
	}

	render() {
		const body = <CardTimeline notes={this.state.issues}/>;
		const table = this.props.table

		return (
			!this.state.open ? 
				<IconButton onClick={this.handleOpen} style={{position: table ? '' : 'absolute',	margin: table ? '' : '1.5rem -2.5rem 0rem 0rem', top: table ? '' : '0px', right: table ? '' : '0px'}} hidden={this.props.hidden}>
					<MdChangeHistory color='secondary'/>
				</IconButton>
				:				
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title='Notes'
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='60%'
					maxWidth='md'
					>
					{this.state.working ? <CircularProgress/> : body}
				</Modal>
		);
	}
}
