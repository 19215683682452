import React from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  TextField,
  Typography,
  Input,
  InputLabel,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ProductCard from "./_product_card";
import DatePicker from "../../shared/date_picker";
import { withSnackbar } from "notistack";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import OrderInfo from "./_order_info";
import ShowOrder from "../_show";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";
import purchased from "../purchased";
import CopyTextComponent from "../../shared/copy_clipboard";

class PurchaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      products: [],
      orderSummary: [],
      order_number: "",
      vendor: "",
      order_id: "",
      currency: "",
      currencies: [],
      working: false,
      purchased: [],
      invoice: "",
      inTheCart: false,
      not_supported: true,
      invoice_total: "",
      eta: null,
      multiple_brand: false,
      invoice_warning: 0,
    };
    this._ShowOrder = React.createRef();
  }

  handleOpen = (order_id, vendor, order_number, in_the_cart, not_supported) => {
    this.setState({
      open: true,
      order_number: order_number,
      order_id: order_id,
      vendor: vendor,
      inTheCart: in_the_cart,
      not_supported: not_supported,
      invoice: "",
      invoice_total: "",
      duplicate: false,
      multiple_brand: false,
      invoice_warning: 0,
      errors: {},
      purchased: [],
      eta: null,
      invoice_native: "",
    });
    this.getProducts(order_id, vendor);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAfterSubmit = () => {
    if (
      this.state.purchased.length === this.state.products.length &&
      this.state.purchased.reduce((a, v) => (a = a + v.qty), 0) ===
        this.state.products.reduce((a, v) => (a = a + v.qty), 0)
    ) {
      this.handleClose();
    } else {
      this.setState({
        invoice: "",
        invoice_total: "",
        eta: null,
        purchased: [],
        invoice_native: "",
      });
      this.getProducts(this.state.order_id, this.state.vendor);
    }
  };

  getProducts = (order_id, vendor) => {
    this.setState({ working: true });
    const url = `/orders/${order_id}/items_to_purchase.json?brand=${vendor}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        if (result.auto_cart_errors) {
          this.props.enqueueSnackbar(result.auto_cart_errors, {
            variant: "error",
          });
        }
        this.setState({
          products: result.order_items,
          working: false,
          orderSummary: result.order_summary,
          multiple_brand: result.multiple_brand,
          currency: result.default_currency,
          currencies: result.currencies,
          currency_value: result.currency_value,
        });
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validate({ [name]: value });
  };

  handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ [name]: value, invoice_warning: 0 });
    }
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("invoice" in fieldValues) {
      temp.invoice = fieldValues.invoice ? "" : "Required";
    }

    if ("invoice_native" in fieldValues) {
      temp.invoice_native = fieldValues.invoice_native ? "" : "Required";
    }
    if ("eta" in fieldValues) {
      temp.eta =
        fieldValues.eta === null
          ? "Required"
          : isNaN(fieldValues.eta.getTime())
          ? "Invalid Date"
          : "";
    }
    if ("purchased" in fieldValues) {
      if (fieldValues.purchased.length > 0) temp.purchased = "";
      else {
        temp.purchased = "One or more items must be selected!";
        this.props.enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state)
      return Object.values(temp).every((x) => x === "");
  };

  handleSubmit = (event) => {
    let self = this;
    const invoice_total =
      this.state.currency === "$"
        ? this.state.invoice_native
        : (this.state.invoice_native * this.state.currency_value).toFixed(2);
    event.preventDefault();
    if (!this.validate()) return;
    let subtotal = this.state.purchased.reduce(
      (a, v) => (a = a + v.qty * v.price),
      0
    );
    if (
      this.state.invoice_warning !== 3 &&
      subtotal + subtotal * 0.025 < invoice_total
    ) {
      this.setState({ invoice_warning: 1 });
      return;
    }
    if (
      this.state.invoice_warning !== 3 &&
      subtotal - subtotal * 0.1 > invoice_total
    ) {
      this.setState({ invoice_warning: 2 });
      return;
    }
    this.setState({ working: true });
    const {
      invoice,
      eta,
      purchased,
      vendor,
      currency,
      duplicate,
      invoice_native,
    } = this.state;
    const data = {
      purchased: purchased,
      invoice: invoice,
      invoice_native: invoice_native,
      eta: eta,
      total: invoice_total,
      vendor: vendor,
      currency: currency,
      duplicate: duplicate,
    };
    $.ajax({
      url: `/orders/${this.state.order_id}/purchase`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false, duplicate: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.handleAfterSubmit();
        self.props.reloadData();
      },
      error: function (response) {
        if (response.responseJSON.error === "duplicate") {
          self.setState({ working: false, duplicate: true });
        } else {
          self.props.enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          self.setState({ working: false });
        }
      },
    });
  };

  setProductData = (data) => {
    if (data.selected) {
      let obj = this.state.purchased.find(
        (o) => o.order_item_id === data.order_item_id
      );
      if (obj) {
        let replace = this.state.purchased.filter(
          (o) => o.order_item_id !== data.order_item_id
        );
        replace.push(data);
        this.setState({ purchased: replace });
      } else {
        this.setState((prevState) => ({
          purchased: [...prevState.purchased, data],
        }));
      }
    } else {
      if (data.deleted) {
        let replace = this.state.products.filter(
          (o) => o.order_item_id !== data.order_item_id
        );
        if (this.state.products.length === 1) {
          this.handleClose();
          this.props.reloadData();
        } else {
          this.setState({ products: replace });
        }
      }
      let replace = this.state.purchased.filter(
        (o) => o.order_item_id !== data.order_item_id
      );
      this.setState({ purchased: replace });
    }
  };

  handleAddToAutoCart = () => {
    let self = this;
    this.setState({ working: true });
    $.ajax({
      url: `/orders/${this.state.order_id}/add_to_auto`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.message, { variant: "success" });
        self.props.reloadData();
        self.handleClose();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  handleClearAutoCart = () => {
    let self = this;
    this.setState({ working: true });
    $.ajax({
      url: `/orders/process_next_auto?vendor=${self.state.vendor}`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar("successfully processed next in queue!", {
          variant: "success",
        });
        self.props.reloadData();
        self.handleClose();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };

  render() {
    const { classes } = this.props;
    const body = this.state.products.map((p) => (
      <ProductCard
        product={p}
        callBack={this.setProductData}
        key={p.order_item_id}
        purchased={this.state.purchased.find(
          (o) => o.order_item_stage_id === p.order_item_stage_id
        )}
      />
    ));

    const summary = (
      <div className="container">
        <ShowOrder ref={this._ShowOrder} />
        <div className="row ms-4">
          <div className="col">
            <Typography variant="button" color="textSecondary">
              Selected: {this.state.purchased.length}/
              {this.state.products.length}
            </Typography>
          </div>
          <div className="col">
            <Typography variant="button" color="textSecondary">
              Items: {this.state.purchased.reduce((a, v) => (a = a + v.qty), 0)}
            </Typography>
          </div>
          <div className="col">
            <Typography variant="button" color="textSecondary">
              Subtotal: $
              {this.state.purchased
                .reduce((a, v) => (a = a + v.qty * v.price), 0)
                .toFixed(2)}
            </Typography>
          </div>
          <div className="col">
            <Typography variant="button" color="textSecondary">
              US shipping: $
              {this.state.purchased
                .reduce((a, v) => (a = a + v.shipping_to_hub), 0)
                .toFixed(2)}
            </Typography>
          </div>
        </div>
        <div className="row mt-2 ms-4">
          <div className="col">
            <TextField
              name="invoice"
              fullWidth
              value={this.state.invoice}
              label="Invoice #"
              {...(this.state.errors.invoice && {
                error: true,
                helperText: this.state.errors.invoice,
              })}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col">
            <div className="row">
              <div className="col-3">
                <TextField
                  fullWidth
                  label="Currency"
                  select
                  value={this.state.currency}
                  onChange={(event) =>
                    this.setState({ currency: event.target.value })
                  }
                >
                  {this.state.currencies.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-8">
                <TextField
                  name="invoice_native"
                  fullWidth
                  value={this.state.invoice_native}
                  label="Invoice Native Total"
                  {...(this.state.errors.invoice_native && {
                    error: true,
                    helperText: this.state.errors.invoice_native,
                  })}
                  onChange={this.handleInputNumberChange}
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <TextField
              name="invoice_total"
              fullWidth
              disabled={true}
              value={
                this.state.currency === "$"
                  ? this.state.invoice_native
                  : (
                      this.state.invoice_native * this.state.currency_value
                    ).toFixed(2)
              }
              label="Invoice Total"
              onChange={this.handleInputNumberChange}
            />
            {(this.state.invoice_warning === 1 ||
              this.state.invoice_warning === 2) && (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => this.setState({ invoice_warning: 3 })}
                  >
                    OK
                  </Button>
                }
              >
                {`Invoice is ${
                  this.state.invoice_warning === 1 ? "greater" : "less"
                } than subtotal`}
              </Alert>
            )}
          </div>
          <div className="col">
            <DatePicker
              disablePast={true}
              disableFuture={false}
              name="eta"
              label="ETA"
              selectedDateValue={this.selectedDateValue}
              value={this.state.eta}
              {...(this.state.errors.eta && {
                error: true,
                helperText: this.state.errors.eta,
              })}
            />
          </div>
        </div>
      </div>
    );
    const actions = (
      <>
        {this.state.duplicate && (
          <Alert severity="error">Invoice already exist in the system!</Alert>
        )}
        <Backdrop open={this.state.working} />
        {/*<Button*/}
        {/*	variant='contained'*/}
        {/*	className={classes.button}*/}
        {/*	hidden={this.state.inTheCart || this.state.not_supported}*/}
        {/*	onClick={this.handleAddToAutoCart}*/}
        {/*>*/}
        {/*	Add Auto*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*	variant='contained'*/}
        {/*	className={classes.button}*/}
        {/*	hidden={this.state.not_supported}*/}
        {/*	onClick={this.handleClearAutoCart}*/}
        {/*>*/}
        {/*	Clear Auto*/}
        {/*</Button>*/}
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          disabled={this.state.invoice_warning === 1}
          className={classes.button}
          onClick={this.handleSubmit}
        >
          {this.state.duplicate ? "Submit duplicate" : "Submit"}
        </Button>
      </>
    );
    const orderTitle = (
      <>
        <Button
          style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
          onClick={() =>
            this._ShowOrder.current.handleOpen(
              this.state.order_id,
              this.state.order_number
            )
          }
        >
          {this.state.order_number}
        </Button>
        {`(${this.state.vendor}) `}
        <IconButton
          onClick={() => navigator.clipboard.writeText(this.state.order_number)}
          aria-label="copy"
        >
          <CopyTextComponent textValue={this.state.order_number} />
        </IconButton>
        <OrderInfo orderSummary={this.state.orderSummary} />
        {this.state.multiple_brand && (
          <div className="badge bg-secondary">Multiple brand</div>
        )}
      </>
    );
    return (
      <div>
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={orderTitle}
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="85%"
          maxWidth="lg"
          summary={summary}
          actions={actions}
        >
          {this.state.working ? <ProductCardSkeleton /> : body}
        </Modal>
      </div>
    );
  }

  selectedDateValue = (date) => {
    this.setState({ eta: date });
    this.validate({ eta: date });
  };
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});
export default withSnackbar(withStyles(useStyles)(PurchaseForm));
