import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";

const AddNonStandard = forwardRef((props: any, ref) => {
  const { callback } = props;
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [businessProfileId, setBusinessProfileOrdersId] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [discountRate, setDiscount] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [catNameEn, setCatNameEn] = useState<any>("");
  const [subCatNameEn, setSubCatNameEn] = useState<any>();
  const [subCatOneNameEn, setSubCatOneNameEn] = useState<any>();
  const [venderName, setVenderName] = useState<any>();
  const [standardCategory, setStandardCategory] = useState<any>();
  const [standardSubCategory, setStandardSubCategory] = useState<any>();
  const [standardSubCategoryOne, setStandardSubCategoryOne] = useState<any>();
  const [venderNameValue, setVenderNameValue] = useState<any>([]);
  const [standardCategoryValue, setStandardCategoryValue] = useState<any>([]);
  const [standardSubCategoryValue, setStandardSubCategoryValue] = useState<any>(
    []
  );
  const [standardSubCategoryOneValue, setStandardSubCategoryOneValue] =
    useState<any>([]);
  const [prohibited, setProhibited] = useState<any>(false);

  const [error, setError] = useState<any>({
    standardCategory: "",
    nameEn: "",
    vendorName: "",
    // standardSubCategory: "",
    // categoryOne: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const clearData = () => {
    setCatNameEn("");
    setSubCatNameEn("");
    setSubCatOneNameEn("");
    setVenderNameValue([]);
    setStandardCategoryValue([]);
    setStandardSubCategoryValue([]);
    setStandardSubCategoryOneValue([]);
    setProhibited(false);

    setError({
      standardCategory: "",
      nameEn: "",
      vendorName: "",
      // standardSubCategory: "",
      // categoryOne: "",
    });
  };

  useImperativeHandle(ref, () => ({
    handleOpen(data) {
      setOpen(true);
      setVenderName(data.venderName);
      setStandardCategory(data.standardCategory);
      setStandardSubCategory(data.standardSubCategory);
      setStandardSubCategoryOne(data.standardSubCategoryOne);
    },
  }));

  const onSetTextValue = (e) => {
    setCatNameEn(e.target.value);
  };
  const onSetTextValue1 = (e) => {
    setSubCatNameEn(e.target.value);
  };
  const onSetTextValue2 = (e) => {
    setSubCatOneNameEn(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const validate = () => {
    let showError = false,
      name = "",
      vendor = "",
      cat = "";
    // subCat = "",
    // catOne = "";
    if (catNameEn === "") {
      showError = true;
      name = "Please add cat name";
    }
    if (venderNameValue.length === 0) {
      showError = true;
      vendor = "Please select vendor";
    }
    if (standardCategoryValue.length === 0) {
      showError = true;
      cat = "Please select standard category";
    }
    // if (standardSubCategoryValue.length === 0) {
    //   showError = true;
    //   subCat = "Please select standard sub category";
    // }
    // if (standardSubCategoryOneValue.length === 0) {
    //   showError = true;
    //   catOne = "Please select standard sub category one";
    // }

    setError({
      nameEn: name,
      venderName: vendor,
      standardCategory: cat,
      // standardSubCategory: subCat,
      // categoryOne: catOne,
    });

    return showError;
  };

  const saveData = () => {
    const showError = validate();
    if (showError) return;

    let formData = new FormData();
    formData.append("vendor_category[cat_name_en]", catNameEn);
    formData.append("vendor_category[sub_cat_name_en]", subCatNameEn);
    formData.append("vendor_category[sub_cat_one_name_en]", subCatOneNameEn);
    formData.append("vendor_category[vendor_name]", venderNameValue);
    formData.append(
      "vendor_category[parts_category_id]",
      standardCategoryValue
    );
    formData.append(
      "vendor_category[parts_sub_category_id]",
      standardSubCategoryValue
    );
    formData.append(
      "vendor_category[parts_sub_category_one_id]",
      standardSubCategoryOneValue
    );
    formData.append("vendor_category[prohibited]", prohibited ? "1" : "0");
    formData.append("locale", "en");

    const url = `/vendor_categories.json?locale=en`;
    fetch(url, { method: "POST", body: formData })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(result.response, {
            variant: "success",
          });
          callback();
          handleClose();
        } else {
          enqueueSnackbar(result.response, {
            variant: "error",
          });
          handleClose();
        }
      });
  };

  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };
  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="Add Non Standard Category"></CardHeader>
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Discount rate"
                type="text"
                margin="normal"
                label="Cat Name en"
                style={{ width: "30%" }}
                value={catNameEn}
                onChange={(e) => {
                  onSetTextValue(e);
                  setError({
                    ...error,
                    nameEn: "",
                  });
                }}
                helperText={error.nameEn === "" ? "" : helperText(error.nameEn)}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Discount rate"
                type="text"
                margin="normal"
                label="Sub Cat Name en"
                style={{ width: "30%" }}
                value={subCatNameEn}
                onChange={(e) => onSetTextValue1(e)}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Discount rate"
                type="text"
                margin="normal"
                label="Sub Cat one name en"
                style={{ width: "30%" }}
                value={subCatOneNameEn}
                onChange={(e) => onSetTextValue2(e)}
              ></TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="   Select Supplier"
                select
                fullWidth
                margin="normal"
                label="Vendor Name"
                style={{ width: "30%" }}
                value={venderNameValue}
                onChange={(e: any) => {
                  setVenderNameValue(e.target.value);
                  setError({
                    ...error,
                    venderName: "",
                  });
                }}
                helperText={
                  error.venderName === "" ? "" : helperText(error.venderName)
                }
              >
                {venderName?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Category"
                style={{ width: "30%" }}
                value={standardCategoryValue}
                onChange={(e: any) => {
                  setStandardCategoryValue(e.target.value);
                  setError({
                    ...error,
                    standardCategory: "",
                  });
                }}
                helperText={
                  error.standardCategory === ""
                    ? ""
                    : helperText(error.standardCategory)
                }
              >
                {standardCategory?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Sub Category"
                style={{ width: "30%" }}
                value={standardSubCategoryValue}
                onChange={(e: any) => {
                  setStandardSubCategoryValue(e.target.value);
                  // setError({
                  //   ...error,
                  //   standardSubCategory: "",
                  // });
                }}
                // helperText={
                //   error.standardSubCategory === ""
                //     ? ""
                //     : helperText(error.standardSubCategory)
                // }
              >
                {standardSubCategory?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Sub Category One"
                style={{ width: "30%" }}
                value={standardSubCategoryOneValue}
                onChange={(e: any) => {
                  setStandardSubCategoryOneValue(e.target.value);
                  // setError({
                  //   ...error,
                  //   categoryOne: "",
                  // });
                }}
                // helperText={
                //   error.categoryOne === "" ? "" : helperText(error.categoryOne)
                // }
              >
                {standardSubCategoryOne?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Checkbox
                className="form-check-input"
                name="isCouponUsedMultipleTimes"
                id="isCouponUsedMultipleTimes"
                checked={prohibited}
                style={{ marginRight: "15px" }}
                onChange={(e: any) => {
                  setProhibited(e.target.checked);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="isCouponUsedMultipleTimes"
              >
                Prohibited?
              </label>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(AddNonStandard);
