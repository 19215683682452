import React from 'react';
import {Breadcrumbs, Button} from '@material-ui/core';

export default class Breadcrumb extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb_data: this.props.breadcrumb_data || []
		};
		window.breadcrumb = this;
	}

	addBreadcrumb = (breadcrumb_data) => {
		if (breadcrumb_data)
			this.setState({ breadcrumb_data: breadcrumb_data });
		else
			this.setState({ breadcrumb_data: [] });
	}

	render () {
		const buildList = (
			this.state.breadcrumb_data.map(l => 
				l.url === '#' ? 
				<Button size="small" key={l.name}>{l.name}</Button>
				: l.url === '@' ? 
				<Button size="small" style={{color: '#FFFFFF'}} key={l.name}>{l.name}</Button>
				:
				<Button onClick={()=> window.location.href= l.url} key={l.name}>{l.name}</Button>
			)
		)
		return (
			<Breadcrumbs aria-label='breadcrumb' className='st-code-breadcrumb'>
				{this.state.breadcrumb_data.length > 0 &&
					<Button size="small" onClick={()=> window.location.href='/'}>Home</Button>
				}
				{buildList}
			</Breadcrumbs>
		);
	}
}
