import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DatePicker({name, label, selectedDateValue, value, error, className,helperText, InputProps={}, disableFuture=false, disablePast=false, hidden}) {
	const handleDateChange = (date) => {
		selectedDateValue(date)
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				disablePast={disablePast}
				disableFuture={disableFuture}
				// disableToolbar
				variant='inline'
				format='dd/MM/yyyy'
				id={name}
				name={name}
				hidden={hidden}
				className={className}
				autoOk={true}
				label={label}
				value={value}
				InputProps={{ readOnly: true }}
				error={error}
				helperText={helperText}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}
