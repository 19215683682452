import React, { useEffect, useState } from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import { Backdrop, Button } from "@material-ui/core";
import $ from "jquery";

const Index = (props) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<any>(false);
  const [muiTableKey, setMuiTableKey] = useState<any>(0);

  const _tableRef = React.createRef<any>();
  const _createcardRef = React.createRef<any>();
  const _showpaymentsRef = React.createRef<any>();

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const handleUpdates = (data, oldValue, newValue, columnDef) => {
    const payload = {
      province_delivery: {
        local_currency_delivery_fee: newValue,
        locale: "en",
        id: data?.id,
      },
    };

    $.ajax({
      url: `/province_deliveries/${data?.id}.json?locale=en`,
      type: "PATCH",
      data: payload,
      dataType: "json",
      success: function (response) {
        props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        getData();
      },
      error: function (response) {
        props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Provinces",
      field: "provinces",
      filtering: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 300,
      },
    },
    {
      title: "Delivery Fee",
      field: "delivery_fee",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "2%" },
    },
    {
      title: "Local Vurrency Felivery Fee",
      field: "local_currency_delivery_fee",
      filtering: false,
      // cellStyle: { width: "10%" },
    },
  ];

  const getData = () => {
    let url = `/province_deliveries.json?locale=en&`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setOrdersData(result.province_deliveries.flat());
        window.breadcrumb.addBreadcrumb(result.breadcrumb);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      {/* <CreateCard ref={this._createcardRef} reloadData={this.reloadData} />
      <ShowPayments ref={this._showpaymentsRef} reloadData={this.reloadData} /> */}
      <Table
        title={`Province Delivery`}
        options={{
          toolbar: true,
          sorting: true,
          search: true,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          // pageSize: 10,
          // pageSizeOptions: [10],
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                handleUpdates(rowData, oldValue, newValue, columnDef),
                  resolve();
              }, 500);
            });
          },
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          // {
          //   icon: () => <pureIcons.AiOutlinePlus />,
          //   onClick: () => {
          //     _createcardRef.current.handleOpen();
          //   },
          //   isFreeAction: true,
          //   tooltip: "Add New Credit",
          // },
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              getData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          // (rowData) => ({
          //   icon: () => (
          //     <Button style={{ background: "#f6b67f", color: "white" }}>
          //       Edit
          //     </Button>
          //   ),
          //   tooltip: <span>Edit</span>,
          //   onClick: () => {
          //     _showpaymentsRef.current.handleOpen(rowData.id, rowData.digits);
          //   },
          //   position: "row",
          // }),
        ]}
        data={ordersData}
        // data={(query) =>
        //   new Promise((resolve, reject) => {
        //     var filters;
        //     if (query.filters.length > 0) {
        //       filters = query.filters.map((col) => ({
        //         field: col.column.field,
        //         value: col.value,
        //       }));
        //     }
        //     let url = `/province_deliveries?locale=en&`;
        //     url += "per_page=" + query.pageSize;
        //     url += "&page=" + (query.page + 1);
        //     if (query.filters.length > 0) {
        //       url += "&filters=" + JSON.stringify(filters);
        //     }
        //     if (query.search) {
        //       url += "&search=" + query.search;
        //     }
        //     fetch(url)
        //       .then((response) => response.json())
        //       .then((result) => {
        //         resolve({
        //           data: result.province_deliveries.flat(),
        //           page: result.page - 1,
        //           totalCount: result.total,
        //         });
        //         window.breadcrumb.addBreadcrumb(result.breadcrumb);
        //       });
        //   })
        // }
      />
    </div>
  );
};

export default withSnackbar(Index);
