import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import { Badge, MenuItem, Select } from "@material-ui/core";
import $ from "jquery";
import { useSnackbar, withSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { productMetadataIndex } from "../standard_categories/dummyData";
import AddNewProduct from "./_add_new_product";
import EditProduct from "./_edit_metadata";

const Index = () => {
  const [working, setWorking] = useState<boolean>(false);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [data, setData] = useState<any>([]);

  const _tableRef = useRef<any>();
  const _addNewProductRef = useRef<any>();
  const _editProductRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      title: "Name",
      field: "name_en",
      sorting: false,
      // cellStyle: { textAlign: "center" },
      // headerStyle: { textAlign: "center" },
    },
  ];

  const reload = () => {
    getData();
  };

  const getData = () => {
    const url = `/metadata_products.json?locale=en`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setData(result.metadata_products);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      <AddNewProduct ref={_addNewProductRef} callback={reload} />
      <EditProduct ref={_editProductRef} callback={reload} />
      <Table
        title={"Product Metadata"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category" },
        }}
        columns={columns}
        tableRef={_tableRef}
        data={data}
        actions={[
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.ProductMetadata />
                </Badge>
              </>
            ),
            onClick: () => {
              _addNewProductRef.current.handleOpen();
            },
            tooltip: "Add new product metadata",
            isFreeAction: true,
          },
          (rowData) => ({
            icon: () => <pureIcons.Edit />,
            onClick: () => {
              _editProductRef.current.handleOpen(rowData);
            },
            tooltip: "Edit",
            // position: "center",
          }),
        ]}
      />
    </div>
  );
};

export default withSnackbar(Index);
