import React from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import { FaFileInvoice, FaShippingFast } from "react-icons/fa";
import $ from "jquery";
import Backdrop from "../../shared/backdrop";
import { FiRefreshCw } from "react-icons/fi";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import AddTrackingFrom from "./_shipping_form";
import PackingListForm from "./_packing_list_form";
import PackingListForm2 from "./_packing_list_form2";
import AddToShipment from "./_add_to_shipment";
import PackingList from "./_packing_list";
import pureIcons from "../../shared/pure_icons";
import { BsPlusLg } from "react-icons/bs";
import { styled, Switch } from "@material-ui/core";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      total: null,
      orderState: true,
    };
    this._tableRef = React.createRef();
    this._shippingFormRef = React.createRef();
    this._PackingListForm = React.createRef();
    this._PackingListForm2 = React.createRef();
    this._PackingList = React.createRef();
    this._AddToShipment = React.createRef();
  }

  reloadData = () => {
    this.setState({ submitting: true });
    const url = `/order_item_stages/ready_to_ship.json?sea_shipping=${!this
      .state.orderState}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          shipments: data.shipments,
          submitting: false,
        });
        window.breadcrumb.addBreadcrumb(data.breadcrumb);
      });
  };

  switchAction = () => {
    this.setState(
      (prevState) => ({
        orderState: !prevState.orderState,
      }),
      () => {
        this.reloadData();
      }
    );
  };

  componentDidMount() {
    this.reloadData();
  }

  handleUpdate = (newValue, data, columnDef) => {
    let self = this;
    if (newValue === "" || newValue === null) return;
    let payload, url;
    switch (columnDef.field) {
      case "weight":
        url = `/order_item_stages/update_stage_tracking`;
        payload = {
          tracking: data.tracking,
          new_tracking: `${data.group_id}*${newValue}*${data.dimensions}`,
          stage: "ready_to_ship",
        };
        break;
      case "dimensions":
        url = `/order_item_stages/update_stage_tracking`;
        payload = {
          tracking: data.tracking,
          new_tracking: `${data.group_id}*${data.weight}*${newValue}`,
          stage: "ready_to_ship",
        };
        break;
      case "status":
        url = `/order_item_stages/update_stage`;
        payload = {
          status: newValue,
          group_id: data.group_id,
          stage: "ready_to_ship",
        };
        break;
      default:
    }

    this.setState({ working: true });
    $.ajax({
      url: url,
      type: "PATCH",
      data: payload,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.reloadData();
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  handlePrepareSummary = (data) => {};
  render() {
    const columns = [
      {
        title: "Box Number",
        field: "group_id",
        sorting: false,
        editable: "never",
        filtering: false,
      },
      {
        title: "Items QTY",
        field: "items_qty",
        sorting: false,
        editable: "never",
        filtering: false,
      },
      { title: "Weight", field: "weight", sorting: false, filtering: false },
      {
        title: "Dimensions",
        field: "dimensions",
        sorting: false,
        filtering: false,
      },
      {
        title: "Status",
        field: "status",
        sorting: false,
        lookup: {
          Ready: "Ready",
          Ticket: "Ticket",
          Approved: "Approved",
          Return: "Return",
          Other: "Other",
        },
      },
      {
        title: "Date Created",
        field: "created_at",
        editable: "never",
        filtering: false,
      },
    ];
    const IOSSwitch = styled((props) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    ))(({ theme }) => ({
      width: 80,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(53.5px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#f6b67f" : "#d9d8d8",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#fff", // Changed from "#33cf4d" to white
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        color: "#fff", // Explicitly set thumb color to white
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#40C071" : "green",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    }));
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.submitting} />
        <PackingListForm ref={this._PackingListForm} />
        <PackingListForm2 ref={this._PackingListForm2} />
        <PackingList
          ref={this._PackingList}
          orderState={this.state.orderState}
        />
        <AddToShipment ref={this._AddToShipment} reloadData={this.reloadData} />
        <AddTrackingFrom
          ref={this._shippingFormRef}
          reloadData={this.reloadData}
        />
        <Table
          title={
            <>
              <span
                style={{
                  marginRight: "2rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Ready to Ship
              </span>
              <span onClick={() => this.switchAction()}>
                <span
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    color: this.state.orderState ? "#315e5a" : "white",
                    fontSize: "1rem",
                    marginLeft: this.state.orderState ? "1rem" : "2rem",
                  }}
                >
                  {this.state.orderState ? "Sea" : "Sea"}
                </span>
                <IOSSwitch sx={{ m: 1 }} checked={this.state.orderState} />
              </span>
            </>
          }
          options={{
            toolbar: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            selection: true,
          }}
          columns={columns}
          data={this.state.shipments}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.handleUpdate(newValue, rowData, columnDef);
                  resolve();
                }, 500);
              });
            },
          }}
          actions={[
            {
              icon: () => <FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              tooltip: "Create Packing List",
              icon: () => <FaFileInvoice />,
              onClick: (event, data) =>
                this._PackingListForm.current.handleOpen(
                  data.map((p) => p.group_id)
                ),
            },
            {
              icon: () => <FaShippingFast />,
              tooltip: "Add Carrier Tracking",
              onClick: (event, data) => {
                this._shippingFormRef.current.handleOpen(data);
              },
            },
            {
              icon: () => <pureIcons.Edit />,
              tooltip: "Show packing list details",
              onClick: (event, rowData) =>
                this._PackingList.current.handleOpen(rowData.group_id),
              position: "row",
            },
            {
              icon: () => <BsPlusLg />,
              tooltip: "Add to Shipment",
              onClick: (event, data) => {
                this._AddToShipment.current.handleOpen(
                  data.map((p) => p.group_id)
                );
              },
            },
            {
              tooltip: "Detailed Packing List",
              icon: () => <FaFileInvoiceDollar />,
              onClick: (event, data) =>
                this._PackingListForm2.current.handleOpen(
                  data.map((p) => p.group_id)
                ),
            },
            {
              tooltip: "Detailed Packing List",
              icon: () => <pureIcons.Export />,
              onClick: (event, data) =>
                window.open(
                  `/reports/shipping_list_summary?group_ids=${data.map(
                    (p) => p.group_id
                  )}`,
                  "_self"
                ),
            },
          ]}
        />
      </div>
    );
  }
}

export default withSnackbar(Index);
