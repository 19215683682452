import React, { useEffect, useState } from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import AddNewKidus from "./add_new_kidus";
import $ from "jquery";
import { Button } from "@material-ui/core";
import Backdrop from "../../shared/backdrop";

const Index = (props) => {
  const [ordersData, setOrdersData] = useState<any>([]);
  const [submitting, setSubmitting] = useState<any>(false);
  const [numbersDropDown, setNumbersDropDown] = useState<any>([]);
  const [sellersDropDown, setSellersDropDown] = useState<any>([]);
  const [creatorId, setCreatorId] = useState<any>("");
  const [actionId, setActionId] = useState<any>("");

  const _tableRef = React.createRef<any>();
  const _addNewKidusRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    setSubmitting(true);
    $.ajax({
      url: `/kidu_serial_numbers.json`,
      type: "GET",
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
        setOrdersData(response.kidus_serial_numbers.flat()),
          setNumbersDropDown(response.numbers_dropdown);
        setSellersDropDown(response.sellers_dropdown);
        setCreatorId(response.creator_id);
        window.breadcrumb.addBreadcrumb(response.breadcrumb);
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Serial Number",
      field: "number",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Kidus Amount",
      field: "amount",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "IQD Amount",
      field: "iqd_amount",
      editable: "never",
      filtering: false,
      // cellStyle: { width: "2%" },
      sorting: false,
    },
    {
      title: "Seller",
      field: "seller",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Created By",
      field: "created_by",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Expiration Date",
      field: "expire_date",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Created At",
      field: "created_at",
      editable: "never",
      filtering: false,
      sorting: false,
    },
    {
      title: "Redeemed?",
      field: "redeemed",
      editable: "never",
      // cellStyle: { width: "10%" },
      filtering: false,
      sorting: false,
    },
  ];

  const onChangeAction = (id) => {
    setSubmitting(true);
    $.ajax({
      url: `/kidu_serial_numbers/${id}/deactivate_code.json`,
      type: "PUT",
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        getData();
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  return (
    <div className="bg-white rounded">
      <Backdrop open={submitting} />
      <AddNewKidus
        ref={_addNewKidusRef}
        numbersDropDown={numbersDropDown}
        sellersDropDown={sellersDropDown}
        creatorId={creatorId}
      />
      <Table
        title={`Kidus serial numbers`}
        options={{
          toolbar: true,
          sorting: true,
          search: true,
          filtering: false,
          debounceInterval: 500,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: () => <pureIcons.AiOutlinePlus />,
            onClick: () => {
              _addNewKidusRef.current.handleOpen();
            },
            isFreeAction: true,
            tooltip: "Add New Kidus Serial",
          },
          (rowData) => ({
            icon: () =>
              rowData.deactivate == true ? (
                <Button style={{ background: "#f6b67f", color: "white" }}>
                  Deactivate
                </Button>
              ) : (
                <span style={{ fontSize: "1rem", marginRight: "3rem" }}>
                  {rowData.deactivate}
                </span>
              ),
            tooltip: <span>Deactivate</span>,
            onClick: () => {
              rowData.deactivate == true ? onChangeAction(rowData.id) : null;
            },
            position: "row",
          }),
        ]}
        data={ordersData}
      />
    </div>
  );
};

export default Index;
