import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DatePicker from "../shared/date_picker";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import $ from "jquery";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";

const NotificationComp = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formValue, setFormValue] = React.useState<any>({
    allCustomer: "",
    oneCustomer: "",
    regSpecificDate: "",
    createdOrderSpecificDate: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const [titles, setTitles] = useState<any>({
    title_ar: "",
    text_ar: "",
    title_en: "",
    text_en: "",
    title_ku: "",
    text_ku: "",
  });

  const [titlesEr, setTitlesEr] = useState<any>({
    title_ar: "",
    text_ar: "",
    title_en: "",
    text_en: "",
    title_ku: "",
    text_ku: "",
  });

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [eta, setEta] = useState<any>("");

  const handleChange = (event) => {
    // setValue(event.target.value);

    setFormValue({ [event.target.value]: event.target.checked });

    setPhoneNumber("");
    setEta("");
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const clearValues = () => {
    setTitles({
      title_ar: "",
      text_ar: "",
      title_en: "",
      text_en: "",
      title_ku: "",
      text_ku: "",
    });
    setPhoneNumber("");
    setEta("");
    setFormValue({
      allCustomer: "",
      oneCustomer: "",
      regSpecificDate: "",
      createdOrderSpecificDate: "",
    });
    setTitlesEr({
      title_ar: "",
      text_ar: "",
      title_en: "",
      text_en: "",
      title_ku: "",
      text_ku: "",
    });
  };

  const handleClose = () => {
    setOpen(false);

    clearValues();
  };

  const validate = () => {
    let errorObject = {
        title_ar: "",
        text_ar: "",
        title_en: "",
        text_en: "",
        title_ku: "",
        text_ku: "",
      },
      error = false;

    if (titles.title_ar === "") {
      errorObject.title_ar = "Required";
      error = true;
    }
    if (titles.text_ar === "") {
      errorObject.text_ar = "Required";
      error = true;
    }
    if (titles.title_en === "") {
      errorObject.title_en = "Required";
      error = true;
    }
    if (titles.text_en === "") {
      errorObject.text_en = "Required";
      error = true;
    }
    if (titles.title_ku === "") {
      errorObject.title_ku = "Required";
      error = true;
    }
    if (titles.text_ku === "") {
      errorObject.text_ku = "Required";
      error = true;
    }

    setTitlesEr({ ...errorObject });

    return error;
  };

  const onSubmit = () => {
    if (validate()) return;

    let selectedRadioButtonNumber;

    function findIndex() {
      if (formValue.allCustomer) {
        selectedRadioButtonNumber = "1";
      } else if (formValue.oneCustomer) {
        selectedRadioButtonNumber = "2";
      } else if (formValue.regSpecificDate) {
        selectedRadioButtonNumber = "3";
      } else if (formValue.createdOrderSpecificDate) {
        selectedRadioButtonNumber = "4";
      }

      return selectedRadioButtonNumber;
    }

    const payload = {
      title_ar: titles.title_ar,
      arabic_notification_text: titles.text_ar,
      title_en: titles.title_en,
      english_notification_text: titles.text_en,
      title_ku: titles.title_ku,
      kurdish_notification_text: titles.text_ku,
      recipient_type: findIndex(),
      phone_no: phoneNumber,
      register_date: formValue.regSpecificDate
        ? eta !== ""
          ? eta.toLocaleDateString()
          : ""
        : "",
      order_date: formValue.createdOrderSpecificDate
        ? eta !== ""
          ? eta.toLocaleDateString()
          : ""
        : "",
    };
    setSubmitting(true);
    $.ajax("/customer_profiles/send_notifications", {
      method: "get",
      data: payload,
      success: (res: any) => {
        setSubmitting(false);
        if (res.success) {
          enqueueSnackbar(res.msg, {
            variant: "success",
          });
          handleClose();
        } else {
          enqueueSnackbar(res.msg, {
            variant: "error",
          });
        }
        setSubmitting(false);
      },
      error: (error: any) => {
        enqueueSnackbar(error.responseJSONText.error, {
          variant: "error",
        });
        setSubmitting(false);
      },
    });
  };

  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <div className="notification-header">
          Send Notification to Customer(s)
        </div>
        <div className="ar-text">
          <div>
            <span>Title (ar) : </span>
            <TextField
              value={titles.title_ar}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  title_ar: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  title_ar: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add Arabic title"
              helperText={titlesEr.title_ar}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
          <div>
            <span style={{ marginLeft: "2rem" }}>Text (ar) : </span>
            <TextField
              value={titles.text_ar}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  text_ar: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  text_ar: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add Arabic notification"
              helperText={titlesEr.text_ar}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
        </div>
        <div className="ar-text">
          <div>
            <span>Title (en) : </span>
            <TextField
              value={titles.title_en}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  title_en: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  title_en: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add English title"
              helperText={titlesEr.title_en}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
          <div>
            <span style={{ marginLeft: "2rem" }}>Text (en) : </span>
            <TextField
              value={titles.text_en}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  text_en: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  text_en: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add English notification"
              helperText={titlesEr.text_en}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
        </div>
        <div className="ar-text">
          <div>
            <span>Title (ku) : </span>
            <TextField
              value={titles.title_ku}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  title_ku: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  title_ku: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add Kurdish title"
              helperText={titlesEr.title_ku}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
          <div>
            <span style={{ marginLeft: "2rem" }}>Text (ku) : </span>
            <TextField
              value={titles.text_ku}
              onChange={(ev) => {
                setTitles({
                  ...titles,
                  text_ku: ev.target.value,
                });

                setTitlesEr({
                  ...titlesEr,
                  text_ku: "",
                });
              }}
              variant="outlined"
              size="small"
              placeholder="Add Kurdish notification"
              helperText={titlesEr.text_ku}
              FormHelperTextProps={{ style: { color: "red" } }}
            />
          </div>
        </div>
        <div className="radio-section">
          <FormControl component="fieldset">
            <FormLabel
              style={{
                textAlign: "start",
                fontSize: "1.3rem",
                margin: "1.3rem 0rem 1.3rem 0rem",
              }}
              component="legend"
            >
              Select Group
            </FormLabel>
            <RadioGroup
              aria-label="Group"
              name="group"
              // value={formValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="allCustomer"
                control={<Radio />}
                label="All customers"
                // checked={formValue.allCustomer}
              />
              <FormControlLabel
                value="oneCustomer"
                control={<Radio />}
                label="Only one customer"
                // checked={formValue.oneCustomer}
              />
              {formValue.oneCustomer === true ? (
                <div>
                  <span>Phone No. : </span>
                  <TextField
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    variant="outlined"
                    size="small"
                    placeholder="Phone No"
                  />
                </div>
              ) : null}
              <FormControlLabel
                value="regSpecificDate"
                control={<Radio />}
                label="Customer registered on a specific date"
                // checked={formValue.regSpecificDate}
              />
              {formValue.regSpecificDate === true ? (
                // <DatePicker
                //   disablePast={true}
                //   disableFuture={false}
                //   name="eta"
                //   label="ETA"
                //   value={eta}
                //   selectedDateValue={(e) => setEta(e.target.value)}
                // />
                <DatePicker
                  disablePast={false}
                  disableFuture={true}
                  name="eta"
                  // label="ETA"
                  selectedDateValue={(e) => setEta(e)}
                  value={eta}
                  error={false}
                  helperText=""
                />
              ) : null}
              <FormControlLabel
                value="createdOrderSpecificDate"
                control={<Radio />}
                label="Customer created orders on specific date"
                // checked={formValue.createdOrderSpecificDate}
              />
              {formValue.createdOrderSpecificDate === true ? (
                <DatePicker
                  disablePast={false}
                  disableFuture={true}
                  name="eta"
                  // label="ETA"
                  selectedDateValue={(e) => setEta(e)}
                  value={eta}
                  error={false}
                  helperText=""
                />
              ) : null}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="sbt-btn">
          <Button
            style={{ background: "#f6b67f", color: "white" }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default NotificationComp;
