import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Sidebar from "../components/shared/sidebar";
import ReceivedOrders from "../components/orders/received/index";
import PurchasedOrders from "../components/orders/purchased/index";
import VendorShipped from "../components/orders/vendor_shipped/index";
import Warehouse from "../components/orders/warehouse/index";
import stocked from "../components/orders/warehouse/stocked";
import ReadyToShip from "../components/orders/ready_to_ship/index";
import Reports from "../components/reports/reports";
import InTransit from "../components/orders/sorting_facility/in_transit";
import SortingFacility from "../components/orders/sorting_facility/sorting_facility";
import SystemUsers from "../components/users/index";
import WaybillsIndex from "../components/waybills/index";
import InvoicesIndex from "../components/invoices/index";
import TransitItems from "../components/waybills/box_items";
import ReadyForDelivery from "../components/orders/sorting_facility/ready_for_delivery";
import OutForDelivery from "../components/orders/sorting_facility/out_for_delivery";
import Delivered from "../components/orders/sorting_facility/delivered";
import ReadyForPickup from "../components/orders/sorting_facility/ready_for_pickup";
import MarketplaceVendors from "../components/marketplace_vendor/index";
import Operations from "../components/operations/index";
import CanceledItems from "../components/orders/canceled_items/_canceled_items";
import StagingBusinessProfiles from "../components/staging_business_profiles/index";
import BusinessProfiles from "../components/business_profiles/index";
import ShowBusiness from "../components/business_profiles/_show_business";
import StandardCategoriesIndex from "../components/standard_categories/index";
import StandardSubCategories from "../components/standard_categories/subCategories";
import StandardSubCategoriesOne from "../components/standard_categories/subCategoriesOne";
import NonStandardCategoriesIndex from "../components/non_standard_categories/index";
import ProhibitedIndex from "../components/prohibiteds/index";
import ShortLinksIndex from "../components/short_links/index";
import CreateShortLinks from "../components/pp_url";
import ProductMetadataIndex from "../components/metadata_products/index";
import BusinessCategoryDiscountIndex from "../components/business_category_discounts/index";
import DiscountCodesIndex from "../components/shopper_coupons_index/index";
import ReferralsIndex from "../components/Referrals/index";
import ShippinCarriers from "../components/settings/shipping_carrires/index";
import ProhibitedProducts from "../components/settings/prohibited_products/index";
import provinceDelivery from "../components/settings/province_delivery/index";
import couponDiscountCode from "../components/settings/coupon_discount_code/index";
import ProductMetadata from "../components/metadata_products/index";
import BusinessCategoryDiscount from "../components/business_category_discounts/index";
import NonStandardCategory from "../components/non_standard_categories/index";
import ProhibitedCategory from "../components/prohibiteds/index";
import ExpenseIncurred from "../components/ExpenseIncurred/index";
import LocalOrders from "../components/local_orders/index";
import KidusSerialNumber from "../components/settings/kidus_serial_numbers/index";
import PhoneCards from "../components/settings/phone_cards";
import KidusTransferLocation from "../components/settings/kidus_transfer_location/index";

export const getSubCategories = (id) => {
  return `/parts_categories/${id}`;
};

export const getSubCategoriesOne = (id) => {
  return `/parts_sub_categories/${id}`;
};

export default (
  <Router>
    <div className="d-flex">
      <Route
        path="/"
        render={(props) => {
          switch (props.match.params.id) {
            default:
              return (
                <Sidebar
                  collapsed={localStorage.getItem("collapsed") || true}
                  page_id={props.match.params.id}
                />
              );
          }
        }}
      />
      <div id="content" collapsed={localStorage.getItem("collapsed") || true}>
        <Switch>
          <Route
            path="/business_profiles/_show_business"
            exact
            component={ShowBusiness}
          />
          <Route
            path="/staging_business_profiles"
            exact
            component={StagingBusinessProfiles}
          />
          <Route path="/business_profiles" exact component={BusinessProfiles} />
          <Route
            path="/order_item_stages/canceled_items"
            exact
            component={CanceledItems}
          />
          <Route path="/orders/received" exact component={ReceivedOrders} />
          <Route
            path="/order_item_stages/purchased"
            exact
            component={PurchasedOrders}
          />
          <Route
            path="/order_item_stages/vendor_shipped"
            exact
            component={VendorShipped}
          />
          <Route
            path="/order_item_stages/warehouse"
            exact
            component={Warehouse}
          />
          <Route path="/order_item_stages/stocked" exact component={stocked} />
          <Route
            path="/order_item_stages/ready_to_ship"
            exact
            component={ReadyToShip}
          />
          <Route
            path="/order_item_stages/in_transit"
            exact
            component={InTransit}
          />
          <Route
            path="/orders/sorting_facility"
            exact
            component={SortingFacility}
          />
          <Route path="/users" exact component={SystemUsers} />
          <Route
            path="/parts_categories"
            exact
            component={StandardCategoriesIndex}
          />
          <Route
            path="/parts_categories/:id"
            // exact
            component={StandardSubCategories}
          />
          <Route
            path="/parts_sub_categories/:id"
            // exact
            component={StandardSubCategoriesOne}
          />
          <Route
            path="/vendor_categories"
            exact
            component={NonStandardCategoriesIndex}
          />
          <Route path="/prohibiteds" exact component={ProhibitedIndex} />
          <Route path="/short_links/new" exact component={CreateShortLinks} />
          <Route path="/short_links" exact component={ShortLinksIndex} />
          <Route
            path="/metadata_products"
            exact
            component={ProductMetadataIndex}
          />
          <Route
            path="/business_category_discounts"
            exact
            component={BusinessCategoryDiscountIndex}
          />
          <Route path="/select_referrals" exact component={ReferralsIndex} />
          <Route
            path="/shopper_coupons_index"
            exact
            component={DiscountCodesIndex}
          />
          <Route path="/reports" exact component={Reports} />
          <Route path="/waybills" exact component={WaybillsIndex} />
          <Route path="/invoices" exact component={InvoicesIndex} />
          <Route
            path="/marketplace_vendor"
            exact
            component={MarketplaceVendors}
          />
          <Route path="/waybills/box_items" exact component={TransitItems} />
          <Route
            path="/order_item_stages/ready_for_delivery"
            exact
            component={ReadyForDelivery}
          />
          <Route
            path="/order_item_stages/ready_for_pickup"
            exact
            component={ReadyForPickup}
          />
          <Route
            path="/order_item_stages/out_for_delivery"
            exact
            component={OutForDelivery}
          />
          <Route
            path="/order_item_stages/delivered"
            exact
            component={Delivered}
          />
          <Route path="/orders/operations" exact component={Operations} />
          <Route path="/shipping_carriers" exact component={ShippinCarriers} />
          <Route
            path="/prohibited_products"
            exact
            component={ProhibitedProducts}
          />
          <Route
            path="/province_deliveries"
            exact
            component={provinceDelivery}
          />
          <Route path="/metadata_products" exact component={ProductMetadata} />
          <Route
            path="/business_category_discounts"
            exact
            component={BusinessCategoryDiscount}
          />
          <Route
            path="/vendor_categories"
            exact
            component={NonStandardCategory}
          />
          <Route path="/prohibiteds" exact component={ProhibitedCategory} />
          <Route path="/expense" exact component={ExpenseIncurred} />
          <Route
            path="/kidu_serial_numbers"
            exact
            component={KidusSerialNumber}
          />
          <Route
            path="/users/kidu_transferor_province"
            exact
            component={KidusTransferLocation}
          />
          <Route path="/products/phone_cards" exact component={PhoneCards} />

          <Route path="/coupons" exact component={couponDiscountCode} />
          <Route path="/orders/pure_plus" exact component={LocalOrders} />
        </Switch>
      </div>
    </div>
  </Router>
);
