import React, { useState } from "react";
import SharedTable from "../../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../../shared/pure_icons";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { columns } from "./data/data";
import Modal from "../../shared/modal/material_dialog";
import InfoIcon from "@material-ui/icons/Info";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import EditCouponForm from "./EditCouponForm";
import ShowDetails from "./ShowDetails";
import AddCouponForm from "./AddCouponForm";
import $ from "jquery";

const Index = (props: any) => {
  const [showDetails, setShowDetails] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [addCoupon, setAddCoupon] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeletId] = useState(null);
  const [callUpdateApi, setCallUpdateApi] = useState(false);
  const [dropdownData, setDropdownData] = useState<any>({});

  const _tableRef = React.createRef<any>();
  const _createcardRef = React.createRef<any>();
  const _showpaymentsRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const handleShowDetailsClose = () => {
    setShowDetails(false);
  };

  const handleEditDetailsClose = () => {
    setEditDetails(false);
  };

  const handleAddClose = () => {
    setAddCoupon(false);
  };

  const handleDeleteClick = (id: any) => {
    setDeletId(id);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    $.ajax({
      url: `/coupons/${deleteId}`,
      type: "DELETE",
      dataType: "json",
      success: function (response: any) {
        enqueueSnackbar(response.msg, {
          variant: "success",
        });
      },
      error: function (response) {
        enqueueSnackbar(response?.responseJSON?.error, {
          variant: "error",
        });
      },
    });
    setOpen(false);
    reloadData();
  };

  const handleActivate = (id: any) => {
    $.ajax({
      url: `/coupons/${id}/change_active`,
      type: "GET",
      dataType: "json",
      success: function (response: any) {
        enqueueSnackbar(response.msg, {
          variant: "success",
        });
        reloadData();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.msg, {
          variant: "error",
        });
      },
    });
    reloadData();
  };

  const actions = (
    <Button
      variant="contained"
      type="submit"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      onClick={() => {
        setCallUpdateApi(true);
      }}
    >
      Submit
    </Button>
  );

  return (
    <div className="bg-white rounded">
      <SharedTable
        title="Coupons (Discount codes)"
        options={{
          toolbar: true,
          filtering: false,
          sorting: true,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: () => <pureIcons.AiOutlinePlus />,
            onClick: () => {
              setAddCoupon(true);
            },
            isFreeAction: true,
            tooltip: "Add New Coupon",
          },
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          (rowData: any) => ({
            icon: () => <InfoIcon />,
            onClick: () => {
              setSelectedId(rowData.id);
              setShowDetails(true);
            },
            position: "row",
            tooltip: "Show Details",
          }),
          (rowData: any) => ({
            icon: () => <EditIcon />,

            onClick: () => {
              if (rowData.editable) {
                setEditId(rowData.id);
                setEditDetails(true);
              } else {
                enqueueSnackbar(rowData?.tooltip, {
                  variant: "error",
                });
              }
            },

            position: "row",
            tooltip: rowData.editable ? "Edit" : "Non-editable",
          }),
          (rowData: any) => ({
            icon: () => rowData.active ? <DeleteOutlineIcon style={{ color: "red" }} /> : <DeleteForeverIcon />,
            onClick: () => rowData.active && handleDeleteClick(rowData.id),
            position: "row",
            tooltip: rowData.active ? "Remove" : "Non-removable",
          }),
          (rowData: any) => ({
            icon: () =>
              rowData.active ? (
                <NotInterestedIcon style={{ color: "red" }} />
              ) : (
                <AssignmentTurnedInIcon style={{ color: "green" }} />
              ),
            onClick: () => handleActivate(rowData.id),
            position: "row",
            tooltip: !rowData.active ? "Activate" : "Deactivate",
          }),
        ]}
        data={(query: any) =>
          new Promise((resolve: any) => {
            var filters: any;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
            }
            let url = `/coupons.json?locale=en&type=all`;
            url += '&per_page=' + query.pageSize
            url += '&page=' + (query.page + 1)
            if (query.filters.length > 0) {
              url += '&filters=' + JSON.stringify(filters)
            }
            if (query.orderBy) {
              url += '&orderBy=' + query.orderBy.field
              url += '&orderDirection=' + (query.orderDirection)
            }
            if (query.search) {
              url += '&search=' + (query.search)
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                setDropdownData(result);
                resolve({
                  data: result.coupons,
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              });
          })
        }
      />
      <Modal
        isOpen={showDetails}
        title="Show Details"
        handleClose={handleShowDetailsClose}
        minHeight="80vh"
        maxHeight="80%"
        maxWidth="sm"
        actions={""}
      >
        <ShowDetails selectedId={selectedId} />
      </Modal>
      <Modal
        isOpen={addCoupon}
        title="Add New Coupon"
        handleClose={handleAddClose}
        minHeight="80vh"
        maxHeight="80%"
        maxWidth="sm"
        actions={actions}
      >
        <AddCouponForm
          callUpdateApi={callUpdateApi}
          setCallUpdateApi={setCallUpdateApi}
          setAddCoupon={setAddCoupon}
          reloadData={reloadData}
          dropdownData={dropdownData}
        />
      </Modal>
      <Modal
        isOpen={editDetails}
        title="Edit Details"
        handleClose={handleEditDetailsClose}
        minHeight="80vh"
        maxHeight="80%"
        maxWidth="sm"
        actions={actions}
      >
        <EditCouponForm
          editId={editId}
          callUpdateApi={callUpdateApi}
          setCallUpdateApi={setCallUpdateApi}
          setEditDetails={setEditDetails}
          reloadData={reloadData}
          dropdownData={dropdownData}
        />
      </Modal>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}>
          Delete Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withSnackbar(Index);
