import React, { forwardRef, useImperativeHandle, useState } from "react";
import Backdrop from "../../shared/backdrop";
import Modal from "../../shared/modal/material_dialog";
import Table from "../../shared/tables/material_table";

const KidusTransferTable = forwardRef((props: any, ref: any) => {
  const [working, setWorking] = useState(false);
  const [open, setOpen] = useState(false);
  const [kidusTransferUsers, setKidusTransferUsers] = useState<any>([]);
  const [cityName, setCityName] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const handleClose = () => {
    setOpen(false);
    props.callback();
  };

  useImperativeHandle(ref, () => ({
    handleOpen(id, amount) {
      setOpen(true);
      fetchData(id, amount);
    },
  }));

  const fetchData = async (id, amount) => {
    setWorking(true);
    try {
      const response = await fetch(
        `/users/kidu_transferor_index?city_id=${id}&kidus_amount=${amount}`
      );
      const data = await response.json();
      if (data) {
        setWorking(false);
        setKidusTransferUsers(data.kidu_transferor_index);
        setCityName(data.city_name);
        setDescription(data.description);
      } else {
        setWorking(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      title: "User Id",
      field: "id",
      sorting: false,
    },
    {
      title: "Name",
      field: "full_name",
      sorting: false,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "20%",
      },
    },
    {
      title: "Phone Number",
      field: "phone_number",
      sorting: false,
    },
    {
      title: "Kidus Balance",
      field: "kidus_balance",
      sorting: false,
    },
    { title: "Address", field: "address" },
    {
      title: "Supplier transferor?",
      field: "supplier_transferor",
      sorting: false,
    },
  ];

  const body = (
    <Table
      title={
        <>
          <div style={{ fontSize: "13px" }}>{description}</div>
        </>
      }
      options={{
        toolbar: true,
        filtering: false,
        debounceInterval: 1000,
        actionsColumnIndex: -1,
      }}
      columns={columns}
      data={kidusTransferUsers}
    />
  );

  return (
    <div>
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={
          <span style={{ marginLeft: "1.4rem" }}>
            Kidu transferors available in {cityName}
          </span>
        }
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
      >
        {body}
      </Modal>
    </div>
  );
});

export default KidusTransferTable;
