import React from 'react';
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, Badge, IconButton, TextField} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'
import {BsFillChatQuoteFill} from 'react-icons/bs';
import CardTimeline from './_note_time_line'
import { withSnackbar } from 'notistack';
import { blue } from '@material-ui/core/colors';

class NoteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			notes: [],
			order_item_stage_id: null,
			note: '',
			errors: [],
			global: false,
			loading: false,
			submitting: false
		}
	};

	handleOpen = (order_item_stage_id) => {
		this.setState({
				open: true,
				order_item_stage_id: this.props.order_item_stage_id ? this.props.order_item_stage_id : order_item_stage_id,
				order_id: this.props.order_id,
				errors: []
			}
		);
		this.getNotes(order_item_stage_id)
	};

	handleClose = () => {
		this.setState({open: false});
	};

	getNotes = (order_item_stage_id) => {
		this.setState({loading: true});
		const url = this.props.order_id ? `/orders/${this.props.order_id}/notes.json` : this.props.order_item_stage_id ? `/order_item_stages/${this.props.order_item_stage_id}/notes.json` : `/order_item_stages/${order_item_stage_id}/notes.json`
		fetch(url)
		.then(response => response.json())
		.then(result => {
			this.setState({notes: result.notes, loading: false});
		})
	};


	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('note' in fieldValues) {
			temp.note = fieldValues.note ? '' : 'Required';
		}
		this.setState({errors: {...temp}})

		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({submitting: true});
		const {note, order_item_stage_id, global} = this.state;
		const data = {note: note, global: global}
		$.ajax({
			url: this.props.order_id ? `/orders/${this.state.order_id}/create_note` : `/order_item_stages/${order_item_stage_id}/create_note`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function(response) {
				self.setState({submitting: false, note: ''});
				self.props.newNoteChild(note)
				self.handleClose()
			},
			error: function (response) {
				self.setState({errors: {...{serverErrors: response.responseJSON.error}}, submitting: false});
				self.props.enqueueSnackbar(response.responseJSON.error,{ variant: 'error' })
			},
		});
	}

	handleInputChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value})
		this.validate({[name]: value});
	}

	handleCheckBoxChange = (e) => {
		this.setState({[event.target.name]: event.target.checked})
	}
	render() {
		const {classes} = this.props;
		const body = this.state.loading ? <CircularProgress/> :
		<CardTimeline notes={this.state.notes}/>;
	
		const actions = (
			<>
				<TextField
					name='note'
					label='Note'
					style={{ margin: 8 }}
					value={this.state.note}
					fullWidth
					multiline
					disabled={this.state.submitting}
					onChange={this.handleInputChange}
					{...(this.state.errors.note && {error: true, helperText: this.state.errors.note})}
					margin='normal'
				/>
				<div className="form-check form-switch mt-4">
					<label className="form-check-label" htmlFor="global">Global</label>
					<input className="form-check-input" type="checkbox" id="global" name='global' checked={this.state.global} onClick={this.handleCheckBoxChange}/>
				</div>
				{this.state.submitting ?
					<CircularProgress className='mt-3'/> :
					<Button
						variant='contained'
						style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
						type='submit'
						className='mt-3'
						disabled={this.state.submitting}
						onClick={this.handleSubmit}
					>
						Submit
					</Button>}
			</>
		);

		return (
			<>
				{(this.props.order_item_stage_id || this.props.order_id) &&
					<IconButton onClick={this.handleOpen}>
						<Badge badgeContent={this.props.notes_count} color='secondary'><BsFillChatQuoteFill style={{fontSize: 25, color: blue[500] }}/></Badge>
					</IconButton>
				}
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title='Notes'
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='60%'
					maxWidth='sm'
					actions={actions}>
					{this.state.loading ? <CircularProgress/> : body}
				</Modal>
			</>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default (withSnackbar)(withStyles(useStyles)(NoteForm))
