import React from "react";
import Table from "../../shared/tables/material_table";
import pureIcons from "../../shared/pure_icons";
import { withSnackbar } from "notistack";
import $ from "jquery";
import Backdrop from "../../shared/backdrop";
import StockForm from "./_stock_form";
import NoteForm from "../../notes/_note_form";
import { Link, Badge, styled, Switch } from "@material-ui/core";
import ShowItem from "../../order_items/show";
import Barcode from "../../order_items/_barcode";

class stocked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      muiTableKey: 0,
      total: null,
      orderState: true,
    };
    this._tableRef = React.createRef();
    this._stockFormRef = React.createRef();
    this._noteFormRef = React.createRef();
    this._ShowItemRef = React.createRef();
  }

  handleUpdate = (stage_id, newValue) => {
    let self = this;
    const url = `/order_item_stages/${stage_id}/change_group`;
    $.ajax({
      url: url,
      type: "PATCH",
      data: { group_id: newValue },
      dataType: "json",
      success: function (response) {
        self.reloadData();
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  reloadData = () => {
    // this.setState({muiTableKey: this.state.muiTableKey + 1})
    this._tableRef.current.onQueryChange();
  };

  handleLockChange = (rowData) => {
    let self = this;
    $.ajax({
      url: `/order_item_stages/${rowData.order_item_stage_id}/switch_lock`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        self.reloadData();
        self.props.enqueueSnackbar(`successfully ${response.state}!`, {
          variant: "success",
        });
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  switchAction = () => {
    this.setState((prevState) => ({
      orderState: !prevState.orderState,
    }));
    this.reloadData();
  };

  render() {
    const columns = [
      {
        title: "",
        field: "image",
        sorting: false,
        filtering: false,
        editable: "never",
        render: (row) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
            }}
          >
            <Badge
              badgeContent="DG"
              invisible={!row.dg}
              overlap="circular"
              color="error"
            >
              <img
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
                alt={row.name}
                src={row.image}
              ></img>
            </Badge>
          </div>
        ),
      },
      {
        title: "Product Name",
        field: "name_short",
        sorting: false,
        filtering: false,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 400,
        },
        editable: "never",
      },
      {
        title: "QTY",
        field: "qty",
        sorting: false,
        filtering: false,
        editable: "never",
      },
      {
        title: "Note",
        field: "note",
        filtering: false,
        sorting: false,
        editable: "never",
        cellStyle: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          width: "15%",
        },
      },
      {
        title: "Code",
        field: "redeem_code",
        sorting: false,
        filtering: false,
        editable: "never",
      },
      {
        title: "Tracking",
        field: "tracking",
        sorting: false,
        filtering: false,
        editable: "never",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 300,
        },
      },
      {
        title: "Purchased",
        field: "purchased_at",
        filtering: false,
        editable: "never",
        sorting: false,
      },
      {
        title: "Received",
        field: "created_at",
        filtering: false,
        editable: "never",
      },
    ];

    const IOSSwitch = styled((props) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    ))(({ theme }) => ({
      width: 80,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(53.5px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#f6b67f" : "#d9d8d8",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#fff", // Changed from "#33cf4d" to white
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        color: "#fff", // Explicitly set thumb color to white
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#40C071" : "green",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    }));
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.submitting} />
        <ShowItem ref={this._ShowItemRef} reloadData={this.reloadData} />
        <NoteForm ref={this._noteFormRef} newNoteChild={this.reloadData} />
        <StockForm
          callBackStageQty={this.reloadData}
          stock={false}
          ref={this._stockFormRef}
        />
        <Table
          title={
            <>
              <span
                style={{
                  marginRight: "2rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Items in Stock
              </span>
              <span onClick={() => this.switchAction()}>
                <span
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    color: this.state.orderState ? "#315e5a" : "white",
                    fontSize: "1rem",
                    marginLeft: this.state.orderState ? "1rem" : "2rem",
                  }}
                >
                  {this.state.orderState ? "Sea" : "Sea"}
                </span>
                <IOSSwitch sx={{ m: 1 }} checked={this.state.orderState} />
              </span>
            </>
          }
          key={this.state.muiTableKey}
          options={{
            toolbar: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            focus: true,
          }}
          columns={columns}
          tableRef={this._tableRef}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.handleUpdate(rowData.order_item_stage_id, newValue);
                  resolve();
                }, 500);
              });
            },
          }}
          actions={[
            {
              icon: pureIcons.FiRefreshCw,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            (rowData) => ({
              icon: () => <Barcode code={rowData.redeem_code} />,
              tooltip: <span>Barcode</span>,
              position: "row",
            }),
            (rowData) => ({
              tooltip: "Move to Shipment",
              icon: pureIcons.FiPackage,
              disabled: rowData.locked,
              onClick: (event, rowData) => {
                this._stockFormRef.current.handleOpen(rowData);
              },
              position: "row",
            }),
            {
              icon: pureIcons.ImInfo,
              tooltip: <span>Show</span>,
              onClick: (event, rowData) =>
                this._ShowItemRef.current.handleOpen(rowData.order_item_id),
              position: "row",
            },
            (rowData) => ({
              icon: rowData.locked
                ? pureIcons.LockOpenIcon
                : pureIcons.LockIcon,
              tooltip: rowData.locked ? <span>Unlock</span> : <span>Lock</span>,
              onClick: (event, rowData) => {
                window.confirm(
                  `Are you sure you want to ${
                    rowData.locked ? "unlock" : "lock"
                  } ${rowData.name} ?`
                ) && this.handleLockChange(rowData);
              },
              position: "row",
            }),
            {
              icon: pureIcons.BsChatDots,
              tooltip: <span>Notes</span>,
              onClick: (event, rowData) =>
                this._noteFormRef.current.handleOpen(
                  rowData.order_item_stage_id
                ),
              position: "row",
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/order_item_stages/stocked.json?sea_shipping=${!this
                .state.orderState}&`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.products,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                });
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(stocked);
