import React, { useEffect, useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../shared/backdrop";
import pureIcons from "../shared/pure_icons";
import AddProhibitedCat from "./_add_prohibited_cat";
import EditProhibitedCat from "./_edit_prohibited_cat";

const Index = () => {
  const [orders, setOrders] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [total, setTotal] = useState<any>(null);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [order_number, setOrderNumber] = useState<any>("");
  const [tableData, setTableData] = useState<any>();
  const [regionData, setRegionData] = useState<any>();
  const [standardCategory, setStandardCategory] = useState<any>();
  const [standardSubCategory, setStandardSubCategory] = useState<any>();
  const [standardSubCategoryOne, setStandardSubCategoryOne] = useState<any>();

  const _tableRef = useRef<any>();
  const _ShippingForm = useRef<any>();
  const _ShowOrderRef = useRef<any>();
  const _AutoControlPanelRef = useRef<any>();
  const deleteItem = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const regionOptions = [
    {
      key: "us",
      name: "US",
    },
    {
      key: "eu",
      name: "EU",
    },
    {
      key: "tr",
      name: "TR",
    },
    {
      key: "ae",
      name: "AE",
    },
    {
      key: "cn",
      name: "CN",
    },
    {
      key: "sa",
      name: "SA",
    },
  ];

  const [selectedRegions, setSelectedRegions] = useState<any>("us");

  const handleChange = (value: any) => {
    setSelectedRegions(value);
  };

  const getData = () => {
    setWorking(true);
    const url = `/prohibiteds.json?locale=en&region=${selectedRegions}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setWorking(false);
        setTableData(result.prohibiteds);
        setRegionData(result.regions);
        setStandardCategory(result.standard_categories);
        setStandardSubCategory(result.standard_sub_categories);
        setStandardSubCategoryOne(result.standard_sub_category_ones);
      });
  };

  useEffect(() => {
    getData();
  }, [selectedRegions]);

  const reload = () => {
    getData();
  };

  const handleConfirmDelete = () => {
    $.ajax({
      url: `/prohibiteds/${deleteItem.current}?locale=en`,
      type: "DELETE",
      dataType: "json",
      success: function (response: any) {
        enqueueSnackbar(response.response, {
          variant: "success",
        });
        getData();
        handleCancel();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.msg, {
          variant: "error",
        });
        handleCancel();
      },
    });
  };

  const columns = [
    {
      title: "Standard Category",
      field: "parts_category",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Standard Sub Category",
      field: "parts_sub_category",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Standard Sub Category One",
      field: "parts_sub_category_one",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
    {
      title: "Region",
      field: "region",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth: 100,
      },
    },
  ];

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      <AddProhibitedCat ref={_AutoControlPanelRef} callback={reload} />
      <EditProhibitedCat ref={_ShowOrderRef} callback={reload} />
      <Table
        title={"Prohibited Categories"}
        key={muiTableKey}
        options={{
          filtering: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          focus: true,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search category" },
        }}
        columns={columns}
        tableRef={_tableRef}
        data={tableData}
        actions={[
          {
            icon: () => (
              <Select
                value={selectedRegions}
                onChange={(ev) => handleChange(ev.target.value)}
                renderValue={(selected: any) => {
                  if (selected.length === 0) {
                    return <em>Select region</em>;
                  }

                  const value = regionData
                    ?.filter((value) => value === selected)
                    .map((value) => value);

                  return selected || value;
                }}
              >
                {regionData?.map((value) => (
                  <MenuItem value={value}> {value}</MenuItem>
                ))}
              </Select>
            ),
            isFreeAction: true,
          },
          {
            icon: () => (
              <>
                <Badge badgeContent="+">
                  <pureIcons.ProhibitedIcon />
                </Badge>
              </>
            ),
            onClick: () => {
              _AutoControlPanelRef.current.handleOpen({
                regionData,
                standardCategory,
                standardSubCategory,
                standardSubCategoryOne,
              });
            },
            tooltip: "Add new prohibited",
            isFreeAction: true,
          },
          (rowData) => ({
            icon: () => <pureIcons.Edit />,
            onClick: () => {
              _ShowOrderRef.current.handleOpen({
                rowData,
                regionData,
                standardCategory,
                standardSubCategory,
                standardSubCategoryOne,
              });
            },
            tooltip: "Edit",
          }),
          (rowData) => ({
            icon: () => <pureIcons.Delete />,
            onClick: () => {
              setOpen(true);
              deleteItem.current = rowData.id;
            },
            tooltip: "Delete",
          }),
        ]}
      />

      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Delete Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
