import React from "react";
import Table from "../../shared/tables/material_table";
import { withSnackbar } from "notistack";
import { FiRefreshCw, FiPackage } from "react-icons/fi";
import Backdrop from "../../shared/backdrop";
import { ImInfo } from "react-icons/im";
import StockForm from "./_stock_form";
import { BsBookshelf, BsChatDots, BsPlusLg } from "react-icons/bs";
import NoteForm from "../../notes/_note_form";
import { Link, Badge, IconButton, styled, Switch } from "@material-ui/core";
import Barcode from "../../order_items/_barcode";
import AddToList from "./_add_to_packing_list_form";
import ShowItem from "../../order_items/show";
import CreateShipment from "./_create_shipment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import pureIcons from "../../shared/pure_icons";
import TextField from "@material-ui/core/TextField";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      total: null,
      hub_operators: [],
      orderState: true,
    };
    this._tableRef = React.createRef();
    this._stockFormRef = React.createRef();
    this._noteFormRef = React.createRef();
    this._ShowItemRef = React.createRef();
    this._AddToListRef = React.createRef();
    this._CreateShipmentRef = React.createRef();
  }

  switchAction = () => {
    this.setState((prevState) => ({
      orderState: !prevState.orderState,
    }));
    this.reloadData();
  };
  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  render() {
    const columns = [
      {
        title: "",
        field: "image",
        sorting: false,
        filtering: false,
        cellStyle: { width: "5%" },
        render: (row) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
            }}
          >
            <Badge
              badgeContent="DG"
              invisible={!row.dg}
              overlap="circular"
              color="error"
            >
              <img
                style={{ height: "60px", width: "60px", objectFit: "contain" }}
                alt={row.name}
                src={row.image}
              ></img>
            </Badge>
          </div>
        ),
      },
      {
        title: "Product Name",
        field: "name",
        sorting: false,
        cellStyle: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          width: "30%",
        },
        filtering: false,
        render: (rowData) => (
          <Link href={rowData.url} target="_blank">
            {rowData.name_short}
          </Link>
        ),
      },
      {
        title: "QTY",
        field: "qty",
        sorting: false,
        cellStyle: { width: "2%" },
        filtering: false,
      },
      {
        title: "Tracking",
        field: "tracking",
        sorting: false,
        filtering: false,
        editable: "never",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 300,
        },
      },
      { title: "Vendor", field: "brand", filtering: false, sorting: false },
      {
        title: "Code",
        field: "redeem_code",
        sorting: false,
        filtering: false,
        editable: "never",
      },
      { title: "Time moved", field: "updated_at", filtering: false },
      {
        title: "Who",
        field: "who",
        sorting: false,
        editable: "never",
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={this.state.hub_operators}
            disableCloseOnSelect
            limitTags={1}
            onChange={(e, newValue) => {
              onFilterChanged(columnDef.tableData.id, newValue);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        ),
      },
      {
        title: "Code",
        field: "redeem_code",
        sorting: false,
        filtering: false,
        cellStyle: { width: "2%" },
        render: (rowData) => (
          <IconButton aria-label="Barcode">
            <Barcode code={rowData.redeem_code} />
          </IconButton>
        ),
      },
    ];
    const IOSSwitch = styled((props) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    ))(({ theme }) => ({
      width: 80,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(53.5px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#f6b67f" : "#d9d8d8",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#fff", // Changed from "#33cf4d" to white
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        color: "#fff", // Explicitly set thumb color to white
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#40C071" : "green",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    }));
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.submitting} />
        <AddToList
          ref={this._AddToListRef}
          callBackAddToList={this.reloadData}
          shipping_methods={this.state.shipping_methods}
          orderState={this.state.orderState}
        />
        <CreateShipment
          ref={this._CreateShipmentRef}
          callBackCreateShipment={this.reloadData}
          units={this.state.units}
          shipping_methods={this.state.shipping_methods}
        />
        <NoteForm ref={this._noteFormRef} newNoteChild={this.reloadData} />
        <ShowItem ref={this._ShowItemRef} reloadData={this.reloadData} />
        <StockForm
          callBackStageQty={this.reloadData}
          stock={true}
          ref={this._stockFormRef}
        />
        <Table
          title={
            <>
              <span
                style={{
                  marginRight: "2rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Current Packing List
              </span>
              <span onClick={() => this.switchAction()}>
                <span
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    color: this.state.orderState ? "#315e5a" : "white",
                    fontSize: "1rem",
                    marginLeft: this.state.orderState ? "1rem" : "2rem",
                  }}
                >
                  {this.state.orderState ? "Sea" : "Sea"}
                </span>
                <IOSSwitch sx={{ m: 1 }} checked={this.state.orderState} />
              </span>
            </>
          }
          options={{
            toolbar: true,
            paging: false,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            selection: true,
            focus: true,
          }}
          columns={columns}
          tableRef={this._tableRef}
          actions={[
            {
              icon: () => <FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              tooltip: "Create Shipment",
              icon: () => <FiPackage />,
              onClick: (event, rowData) => {
                this._CreateShipmentRef.current.handleOpen(rowData);
              },
            },
            {
              icon: () => <BsBookshelf />,
              onClick: (event, rowData) => {
                this._stockFormRef.current.handleOpen(rowData);
              },
              tooltip: <span>Move it to stock</span>,
              position: "row",
            },
            {
              icon: () => <BsPlusLg />,
              tooltip: "Add to Shipment",
              onClick: (event, rowData) => {
                this._AddToListRef.current.handleOpen(rowData);
              },
            },
            {
              icon: () => <ImInfo />,
              tooltip: <span>Show</span>,
              onClick: (event, rowData) =>
                this._ShowItemRef.current.handleOpen(rowData.order_item_id),
              position: "row",
            },
            {
              icon: () => <BsChatDots />,
              tooltip: "Notes",
              onClick: (event, rowData) =>
                this._noteFormRef.current.handleOpen(
                  rowData.order_item_stage_id
                ),
              position: "row",
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/order_item_stages/warehouse.json?sea_shipping=${!this
                .state.orderState}`;
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.products,
                  });
                  this.setState({
                    shipping_methods: result.shipping_methods,
                  });
                  if (this.state.hub_operators.length === 0) {
                    this.setState({
                      units: result.units,
                      hub_operators: result.hub_operators,
                    });
                    window.breadcrumb.addBreadcrumb(result.breadcrumb);
                  }
                });
            })
          }
        />
      </div>
    );
  }
}

export default withSnackbar(Index);
