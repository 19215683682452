import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
	Button,
	Card,
	CardContent,
	CardMedia,
	Typography,
	Select, TextField
} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import $ from 'jquery';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import NumericInput from 'react-numeric-input';
class StockForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			note: '',
			qty: 0,
			maxSelect: 0,
			product: {},
		}
	}

	handleQtyChange = (value) => {
		if (value > this.state.maxSelect || value <= 0 || value === null) return
		this.setState({qty: value});
	};

	handleClose = () => {
		this.setState({open: false});
		const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
	};

	handleOpen = (product) => {
		this.setState({product: product, open: true, qty: product.qty, maxSelect: product.qty})
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		this.setState({submitting: true})
		$.ajax({
			url: `/order_item_stages/${this.state.product.order_item_stage_id}/stock_movement`,
			type: 'POST',
			data: {product: self.state.product, qty: self.state.qty, note: self.state.note},
			dataType: 'json',
			success: function (response) {
				self.setState({submitting: false})
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
				self.props.callBackStageQty(self.state.product.qty - self.state.qty);
				self.handleClose();
			},
			error: function (response) {
				self.setState({submitting: false})
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}

	handleInputChange = e => {
		const {name, value} = e.target;
		this.setState({[name]: value})
	}

	render () {
		const {classes} = this.props;
		const  actions = (
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='submit'
				className={classes.button}
				onClick={this.handleSubmit}
			>
				Submit
			</Button>)

		const body = <Card className={classes.root}>
				<CardMedia	className={classes.cover}>
					<img src={this.state.product.image} alt={this.state.product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
				</CardMedia>
				<div className={classes.details}>
					<CardContent className={classes.content}>
						<Typography component='h5' variant='h5' pb={5}>
							{this.state.product.name}
						</Typography>
						<br/>
						<Typography variant='subtitle1' color='textSecondary' >
							QTY: <NumericInput min={1} max={this.state.maxSelect} value={this.state.qty} mobile size={4} onChange={this.handleQtyChange} name='qty'/> 
						</Typography>
						{this.props.stock &&
							<TextField
								name='note'
								label='Comment'
								style={{ margin: 8 }}
								multiline
								fullWidth
								onChange={this.handleInputChange}
								margin='normal'
							/>
						}
					</CardContent>
				</div>
			</Card>

		return (
			<>
				<Backdrop open={this.state.submitting}/>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title={this.props.stock ? 'Move item to stock' : 'Move item to shipment'}
					handleClose={this.handleClose}
					maxHeight='60%'
					maxWidth='md'
					actions={actions}>
					{body}
				</Modal>
			</>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 5
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		objectFit: 'contain'
	},
})

export default withSnackbar(withStyles(useStyles)(StockForm))
