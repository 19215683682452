import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import pureIcons from '../shared/pure_icons';
import { Backdrop } from '@material-ui/core';
import Modal from '../shared/modal/material_dialog'



class ShowPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      errors: {},
      id:'',
      digits: ''
    };
    this._tableRef = React.createRef();
  }
  reloadData = () => {
    this._tableRef.current.onQueryChange();
  }

  handleOpen = (id,digits) => {
    this.setState({ open: true ,id: id,digits: digits});
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  reloadData = (e) => {
    this._tableRef.current.onQueryChange();
  }
  render() {
    const columns = [
      { title: 'User Name', field: 'user_name' },
      { title: 'Phone Number', field: 'phone_number'},
      { title: 'Amount', field: 'amount'},
      { title: 'Created at', field: 'created_at' },
      { title: 'Refunded at', field: 'updated_at'},
      { title: 'refunded?', field: 'refunded' },
      { title: 'rejected?', field: 'rejected'}
    ]
    const body =
      <>
        <div className='bg-white rounded'>
          <Backdrop open={this.state.submitting} />
          <Table
            title={`${this.state.digits}'s Payments`}
            options={{
              toolbar: true,
              sorting: true,
              search: false,
              filtering: false,
              debounceInterval: 500,
              actionsColumnIndex: -1,
            }
            }
            columns={columns}
            tableRef={this._tableRef}
            actions={
              [

                {
                  icon: () => <pureIcons.FiRefreshCw />,
                  onClick: () => { this.reloadData() },
                  isFreeAction: true,
                  tooltip: 'Refresh'
                },
              ]}
            data={query =>
              new Promise((resolve, reject) => {
                var filters
                if (query.filters.length > 0) {
                  filters = query.filters.map((col) => ({ field: col.column.field, value: col.value }))
                }
                let url = `/prepaid_card_accesses/${this.state.id}/show_payments.json?`
                url += 'per_page=' + query.pageSize
                url += '&page=' + (query.page + 1)
                if (query.filters.length > 0) {
                  url += '&filters=' + JSON.stringify(filters)
                }
                if (query.search) {
                  url += '&search=' + (query.search)
                }
                if (query.orderBy) {
                  url += '&orderBy=' + query.orderBy.field
                  url += '&orderDirection=' + (query.orderDirection)
                }
                fetch(url)
                  .then(response => response.json())
                  .then((result) => {
                    resolve({
                      data: result.payments,
                      page: result.page - 1,
                      totalCount: result.total,
                    });
                    window.breadcrumb.addBreadcrumb(result.breadcrumb);
                  })
              })
            }
          />
        </div>
      </>
    return (
      <Modal
        isOpen={this.state.open}
        handleClose={this.handleClose}
        minHeight='70vh'
        maxHeight='90%'
        maxWidth='lg'>
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(ShowPayments)
