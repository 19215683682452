import React, { useEffect, useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import { balanceData } from "./data/data";
import { useSnackbar } from "notistack";

const ShowBalance = ({ toggleBalances, expensUsers }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div
      style={{
        width: 300,
        padding: "16px",
        overflowY: "auto",
        backgroundColor: "#f0f2f8",
        height: 2000,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" gutterBottom>
          Balances
        </Typography>
        <Button onClick={toggleBalances}>
          <CloseIcon />
        </Button>
      </div>
      <List>
        {expensUsers.map((item, index) => (
          <React.Fragment key={index}>
            <Card style={{ marginBottom: "8px", color: "#476f6c" }}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: "#476f6c" }}
                      >
                        Dollar balance: {item.dollar_balance}
                      </Typography>
                      <br />
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: "#476f6c" }}
                      >
                        Dinar balance: {item.dinar_balance}
                      </Typography>
                      <br />
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: "#476f6c" }}
                      >
                        Cash refund: {item.cash_refunds}
                      </Typography>
                      <br />
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: "#476f6c" }}
                      >
                        Visa card refund: {item.visa_cards_refunds}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </Card>
            {index !== balanceData.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default ShowBalance;
