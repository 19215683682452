import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,

} from "@material-ui/core";
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import $ from "jquery";
import { useSnackbar } from "notistack";
import { shortLinkPageData } from "../standard_categories/dummyData";
import Modal from "../shared/modal/material_dialog";
import { useLocation, useHistory } from "react-router-dom";

const Index = forwardRef((props, ref) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState<boolean>(false);
  const [pageList, setPageList] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>({
    key: "refer_friend",
    name: "Refer friend",
  });
  const [tabValue, setTabValue] = useState<string>("");
  const [dataValue, setDataValue] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const getPageData = () => {
    // setWorking(true);
    setPageList(shortLinkPageData);
    $.ajax({
      url: `short_links/new`,
      type: "GET",
      success: function (response: any) {
        setPageList(response);
        setWorking(false);
      },
      error: function (response: any) {
        // setWorking(false);
      },
    });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleChange = (values: any) => {
    const name = pageList.filter((value: any) => value.key === values);
    setSelectedPage(name[0]);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  console.log(tabValue, dataValue);

  const submitClick = () => {
    setWorking(true);
    $.ajax({
      url: `/short_links.json`,
      type: "POST",
      data: { short_link: {tab: tabValue, data: dataValue, page: selectedPage.key, creator_type: 'Navigational'}},
      dataType: "json",
      success: function (response: any) {
        if(response.success){
          enqueueSnackbar(response.msg, {
            variant: "success",
          });
          setWorking(false);
          history.push("/short_links");
        } else {
          enqueueSnackbar(response.msg, {
            variant: "error",
          });
          setWorking(false);
        }
      },
      error: function (response: any) {
        enqueueSnackbar(response.error, {
          variant: "error",
        });
        setWorking(false);
      },
    });
  };

  const body = (
    <Card style={{ margin: "20px", height: "95%" }}>
      <CardHeader title="Create Short Link"></CardHeader>

      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Typography style={{ position: "absolute" }}>Page</Typography>
          <FormControl style={{ minWidth: "40%", marginLeft: "20%" }}>
            <Select
              value={selectedPage}
              onChange={(ev) => handleChange(ev.target.value)}
              renderValue={() => {
                return selectedPage.name;
              }}
            >
              {pageList?.map((value: any) => {
                return <MenuItem value={value.key}>{value.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>

        <Divider style={{ margin: "20px 0px" }} />

        <Box sx={{ display: "flex" }}>
          <Typography style={{ position: "absolute" }}>Tab</Typography>
          <FormControl style={{ minWidth: "40%", marginLeft: "20%" }}>
            <TextField
              value={tabValue}
              onChange={(ev) => setTabValue(ev.target.value)}
            />
          </FormControl>
        </Box>

        <Divider style={{ margin: "20px 0px" }} />

        <Box sx={{ display: "flex" }}>
          <Typography style={{ position: "absolute" }}>Data</Typography>
          <FormControl style={{ minWidth: "40%", marginLeft: "20%" }}>
            <TextField
              value={dataValue}
              onChange={(ev) => setDataValue(ev.target.value)}
            />
          </FormControl>
        </Box>

        <Divider style={{ margin: "20px 0px" }} />

        <CardActions style={{ justifyContent: "end" }}>
          <Button
            type="button"
            style={{
              backgroundColor: "#ec9a53",
              color: "#ebeef0",
              textTransform: "none",
            }}
            onClick={submitClick}
          >
            Submit
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )

  return (
    <>
      <Modal
        isOpen={open}
        handleClose={handleClose}
        minHeight="70vh"
        maxHeight="90%"
        maxWidth="lg"
      >
        {body}
      </Modal>

      <Backdrop open={working} style={{ zIndex: 0 }} />
    </>
  );

});

export default Index;
