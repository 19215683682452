import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, CardContent, Card, TextField } from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack'
import $ from 'jquery'
import Typography from '@material-ui/core/Typography'

class UpdateUserBalance extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      working: false,
      errors: {},
      open: false,
      val: ''
    }
  }
  handleOpen = (id, name, kidus) => {
    this.setState({
      id: id,
      note: '',
      kidus_amount: '',
      open: true,
      name: name,
      kidus: kidus
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleAdd = event => {
    let self = this
    const data = {
      note: this.state.note,
      kidus_amount: this.state.kidus_amount,
      val: 'add'
    }
    $.ajax({
      url: '/users/' + this.props.id + '/update_balance',
      type: 'PUT',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.setState({ working: false })
        self.props.enqueueSnackbar('successfully processed!', {
          variant: 'success'
        })
        self.handleClose()
        self.props.callback()
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }
  handleWithdraw = event => {
    let self = this
    const data = {
      note: this.state.note,
      kidus_amount: this.state.kidus_amount,
      val: 'withdraw'
    }
    $.ajax({
      url: '/users/' + this.props.id + '/update_balance',
      type: 'PUT',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.setState({ working: false })
        self.props.enqueueSnackbar('successfully processed!', {
          variant: 'success'
        })
        self.handleClose()
        self.props.callback()
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({ working: false })
      }
    })
  }
  handleInputChange = e => {
    const { name, value } = e.target
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      this.setState({ [name]: value })
    }
    this.validate({ [name]: value })
  }
  handleInputnoteChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
    this.validate({ [name]: value })
  }
  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors }
    temp.serverErrors = ''
    if ('note' in fieldValues) {
      temp.note = fieldValues.note ? '' : 'Required'
    }
    if ('kidus_amount' in fieldValues) {
      temp.kidus_amount = fieldValues.kidus_amount ? '' : 'Required'
    }
    this.setState({ errors: { ...temp } })
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }
  render () {
    const { classes } = this.props

    const body = (
      <>
        {' '}
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <div className='row'>
                <div className='col'>
                  <Typography component='h7' variant='h7'>
                    Balance:<b> {this.state.kidus} kidus</b>
                  </Typography>
                  <TextField
                    name='kidus_amount'
                    fullWidth
                    value={this.state.kidus_amount}
                    label={'add the amount'}
                    {...(this.state.errors.kidus_amount && {
                      error: true,
                      helperText: this.state.errors.kidus_amount
                    })}
                    onChange={this.handleInputChange}
                  />
                  <TextField
                    name='note'
                    fullWidth
                    value={this.state.note}
                    label={'add note'}
                    {...(this.state.errors.kidus_amount && {
                      error: true,
                      helperText: this.state.errors.note
                    })}
                    onChange={this.handleInputnoteChange}
                  />
                </div>
              </div>
              <div className='row pt-3'>
                {' '}
                <div className='col-6'>
                  <Button
                    variant='contained'
                    style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
                    type='submit'
                    className={classes.button}
                    onClick={this.handleAdd}
                  >
                    Add Kidus
                  </Button>
                </div>{' '}
                <div className='col-6'>
                  <Button
                    variant='contained'
                    style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
                    type='submit'
                    className={classes.button}
                    onClick={this.handleWithdraw}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
            </CardContent>
          </div>
        </Card>
      </>
    )

    return (
      <>
        <Modal
          isOpen={this.state.open}
          title={`Update ${this.state.name}'s balance`}
          handleClose={this.handleClose}
          minHeight='30vh'
          maxHeight='55%'
          maxWidth='sm'
        >
          {body}
        </Modal>
      </>
    )
  }
}

const useStyles = theme => ({
  content: {
    flex: '1 0 auto'
  },
  root: {
    display: 'flex',
    padding: 20,
    margin: 20,
    wordBreak: 'break-all'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
})

export default withSnackbar(withStyles(useStyles)(UpdateUserBalance))
