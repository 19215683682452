export const standardCategoryIndexData = [
  {
    id: 64,
    name: "Food",
    weight: 0.5,
    discount_rate: 0.05,
    priority: 9999,
    prohibited: "false",
    age_sensitivity: "false",
    tax_rate: 5.0,
  },
  {
    id: 52,
    name: "Books & movies & music",
    weight: 1,
    discount_rate: 0.05,
    priority: 9999,
    prohibited: "false",
    age_sensitivity: "false",
    tax_rate: 5.0,
  },
  {
    id: 53,
    name: "Toys & Hobbies",
    weight: 2,
    discount_rate: 0.05,
    priority: 9999,
    prohibited: "false",
    age_sensitivity: "false",
    tax_rate: 5.0,
  },
  {
    id: 45,
    name: "Fashion",
    weight: 0.75,
    discount_rate: 0.05,
    priority: 9999,
    prohibited: "false",
    age_sensitivity: "false",
    tax_rate: 5.0,
  },
  {
    id: 63,
    name: "Medical supplies",
    weight: 20.0,
    discount_rate: 0.05,
    priority: 9999,
    prohibited: "false",
    age_sensitivity: "false",
    tax_rate: 5.0,
  },
];

export const nonStandardCategoryIndexData = [
  {
    id: 64,
    non_standard_category: "Computers & Tablets",
    non_standard_sub_category: "Computer Accessories",
    non_standard_sub_category_one: "",
    standard_category: "Electronics",
    standard_sub_category: "Accessories",
    standard_sub_category_one: "",
    vendor_name: "Best Buy",
    prohibited: false,
  },
  {
    id: 52,
    non_standard_category: "Electronics",
    non_standard_sub_category: "Mac",
    non_standard_sub_category_one: "",
    standard_category: "Electronics",
    standard_sub_category: "Laptops",
    standard_sub_category_one: "",
    vendor_name: "Apple AE",
    prohibited: false,
  },
  {
    id: 53,
    non_standard_category: "Electronics",
    non_standard_sub_category: "iPad",
    non_standard_sub_category_one: "",
    standard_category: "Electronics",
    standard_sub_category: "Tablets & notebooks",
    standard_sub_category_one: "",
    vendor_name: "Apple AE",
    prohibited: false,
  },
  {
    id: 45,
    non_standard_category: "Electronics",
    non_standard_sub_category: "iPhone",
    non_standard_sub_category_one: "",
    standard_category: "Electronics",
    standard_sub_category: "Cell phones",
    standard_sub_category_one: "",
    vendor_name: "Apple AE",
    prohibited: false,
  },
  {
    id: 63,
    non_standard_category: "Electronics",
    non_standard_sub_category: "Watch",
    non_standard_sub_category_one: "",
    standard_category: "Electronics",
    standard_sub_category: "Smart watches",
    standard_sub_category_one: "",
    vendor_name: "Apple AE",
    prohibited: false,
  },
];

export const prohibitedCategoryIndexData = [
  {
    id: 64,
    standard_category: "Electronics",
    standard_sub_category: "Accessories",
    standard_sub_category_one: "",
    region: "US",
  },
  {
    id: 52,
    standard_category: "Electronics",
    standard_sub_category: "Laptops",
    standard_sub_category_one: "",
    region: "US",
  },
  {
    id: 53,
    standard_category: "Electronics",
    standard_sub_category: "Tablets & notebooks",
    standard_sub_category_one: "",
    region: "US",
  },
  {
    id: 45,
    standard_category: "Electronics",
    standard_sub_category: "Cell phones",
    standard_sub_category_one: "",
    region: "US",
  },
  {
    id: 63,
    standard_category: "Electronics",
    standard_sub_category: "Smart watches",
    standard_sub_category_one: "",
    region: "US",
  },
];

export const shortLinkPageData = [
  {
    key: "refer_friend",
    name: "Refer friend",
  },
  {
    key: "transfer_kidus",
    name: "Transfer kidus",
  },
  {
    key: "orders",
    name: "Orders",
  },
  {
    key: "cart",
    name: "Cart",
  },
  {
    key: "feedback",
    name: "Feedback",
  },
  {
    key: "transactions",
    name: "Transactions",
  },
  {
    key: "profile",
    name: "Profile",
  },
  {
    key: "market_vendors",
    name: "Market vendors",
  },
  {
    key: "businesses",
    name: "Businesses",
  },
];

export const productMetadataIndex = [
  {
    name: "style",
  },
  {
    name: "color",
  },
  {
    name: "length",
  },
  {
    name: "width",
  },
  {
    name: "height",
  },
];

export const businessCategoryDiscounts = [
  {
    supplier: "S.M.S",
    category: "Cards",
    discount_rate: "0.05",
  },
  {
    supplier: "Science Based Group",
    category: "Sports & outdoor",
    discount_rate: "0.1",
  },
  {
    supplier: "Daraj Bookstore",
    category: "Books & movies & music",
    discount_rate: "0.1",
  },
  {
    supplier: "Modern Visions",
    category: "Electronics",
    discount_rate: "0.02",
  },
  {
    supplier: "Pluto_lighting",
    category: "Electronics",
    discount_rate: "0.1",
  },
];

export const discountCodesIndex = [
  {
    discount_code: "DISC0001",
    discount_value: "203",
    discounts: "10%",
    order_number: "ORD#12",
    order_group_number: "AA23",
    cart_review_coupon: "false",
    created_at: "2024-01-01 02:32 PM",
    updated_at: "2024-01-01 02:34 PM",
  },
  {
    discount_code: "DISC0001",
    discount_value: "203",
    discounts: "10%",
    order_number: "ORD#12",
    order_group_number: "AA23",
    cart_review_coupon: "false",
    created_at: "2024-01-01 02:32 PM",
    updated_at: "2024-01-01 02:34 PM",
  },
  {
    discount_code: "DISC0001",
    discount_value: "203",
    discounts: "10%",
    order_number: "ORD#12",
    order_group_number: "AA23",
    cart_review_coupon: "false",
    created_at: "2024-01-01 02:32 PM",
    updated_at: "2024-01-01 02:34 PM",
  },
  {
    discount_code: "DISC0001",
    discount_value: "203",
    discounts: "10%",
    order_number: "ORD#12",
    order_group_number: "AA23",
    cart_review_coupon: "false",
    created_at: "2024-01-01 02:32 PM",
    updated_at: "2024-01-01 02:34 PM",
  },
  {
    discount_code: "DISC0001",
    discount_value: "203",
    discounts: "10%",
    order_number: "ORD#12",
    order_group_number: "AA23",
    cart_review_coupon: "false",
    created_at: "2024-01-01 02:32 PM",
    updated_at: "2024-01-01 02:34 PM",
  },
];

export const linksInformationIndexData = [
  {
    id: 64,
    brand: "",
    created_at: "16/04/2024, 4:27 PM",
    created_by: "Pure Proxy User",
    phone_number: "00000",
    product_price: "0",
    profit_per_purchase: "1",
  },
  {
    id: 52,
    brand: "",
    created_at: "16/04/2024, 4:22 PM",
    created_by: "Pure Proxy User",
    phone_number: "00000",
    product_price: "0",
    profit_per_purchase: "1",
  },
  {
    id: 53,
    brand: "",
    created_at: "16/04/2024, 4:25 PM",
    created_by: "Pure Proxy User",
    phone_number: "00000",
    product_price: "0",
    profit_per_purchase: "1",
  },
  {
    id: 45,
    brand: "",
    created_at: "16/04/2024, 4:29 PM",
    created_by: "Pure Proxy User",
    phone_number: "00000",
    product_price: "0",
    profit_per_purchase: "1",
  },
  {
    id: 63,
    brand: "",
    created_at: "16/04/2024, 4:21 PM",
    created_by: "Pure Proxy User",
    phone_number: "00000",
    product_price: "0",
    profit_per_purchase: "1",
  },
];

export const linksTransactionsIndexData = [
  {
    id: 64,
    type: "Credit",
    unit: "Kidus",
    amount: "0.21",
    owner: "Noor noor noor",
    owner_phone_number: "07460785760",
    receiver_phone_number: "07473536081",
    category: "Link sharing profit",
    date: "05/04/2024, 6:34 PM",
  },
  {
    id: 52,
    type: "Credit",
    unit: "Kidus",
    amount: "0.21",
    owner: "Noor noor noor",
    owner_phone_number: "07460785760",
    receiver_phone_number: "07473536081",
    category: "Link sharing profit",
    date: "05/04/2024, 6:34 PM",
  },
  {
    id: 53,
    type: "Credit",
    unit: "Kidus",
    amount: "0.21",
    owner: "Noor noor noor",
    owner_phone_number: "07460785760",
    receiver_phone_number: "07473536081",
    category: "Link sharing profit",
    date: "05/04/2024, 6:34 PM",
  },
  {
    id: 45,
    type: "Credit",
    unit: "Kidus",
    amount: "0.21",
    owner: "Noor noor noor",
    owner_phone_number: "07460785760",
    receiver_phone_number: "07473536081",
    category: "Link sharing profit",
    date: "05/04/2024, 6:34 PM",
  },
  {
    id: 63,
    type: "Credit",
    unit: "Kidus",
    amount: "0.21",
    owner: "Noor noor noor",
    owner_phone_number: "07460785760",
    receiver_phone_number: "07473536081",
    category: "Link sharing profit",
    date: "05/04/2024, 6:34 PM",
  },
];
