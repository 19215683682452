import React from 'react';
import {Button, Card, TextField, Select, MenuItem} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import {withSnackbar} from 'notistack';
import {withStyles} from '@material-ui/core/styles';
import $ from "jquery";
import Backdrop from '../../shared/backdrop'
import DatePicker from "../../shared/date_picker";

class ShippingForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: [],
			values: {},
			errors: {},
			submitting: false
		}
	};

	handleOpen = (data) => {
		var valuesList = {waybill: ''};
		data.map(box => valuesList = {...valuesList, [box.group_id]: ''});
		let eta = new Date();
		eta.setDate(eta.getDate() + 3 * 7);
		this.setState({
				open: true,
				data: data,
				values: valuesList,
				errors: [],
				carrier: '',
				eta: eta
			}
		);
	};

	handleClose = () => {
		this.setState({open: false});
	};

	handleInputChange = e => {
		const {name, value} = e.target;
		this.setState({values: {...this.state.values, [name]: value}})
		this.validate({[name]: value});
	}

	validate = (fieldValues = this.state.values) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('waybill' in fieldValues) {
			temp.waybill = fieldValues.waybill ? '' : 'Required';
		}
		if ('carrier' in fieldValues) {
			temp.carrier = fieldValues.carrier ? '' : 'Required';
		}
		this.state.data.map(box => {
				if (box.group_id in fieldValues) {
					if (['Aramex','LAND', 'MISC', 'TNT'].includes(this.state.carrier)) {
						this.setState({values: {...this.state.values, [box.group_id]: box.group_id}})
						fieldValues[box.group_id] = box.group_id
					}
					temp[box.group_id] = fieldValues[box.group_id] ? '' : 'Required';
				}
			}
		)
		// if ('eta' in fieldValues) {
		// 	temp.eta = fieldValues.eta === null ? '' : isNaN(fieldValues.eta.getTime()) ? 'Invalid Date' : '';
		// }

		this.setState({errors: {...temp}})
		if (fieldValues === this.state.values)
			return Object.values(temp).every(x => x === '')
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({submitting: true});
		$.ajax({
			url: `/order_item_stages/add_shipper_tracking_number`,
			type: 'POST',
			data: {values: this.state.values, data: this.state.data, carrier: this.state.carrier},
			dataType: 'json',
			success: function (response) {
				self.setState({submitting: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
				self.props.reloadData();
				self.handleClose();
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
				self.setState({submitting: false});
			},
		});
	}

	render() {
		const {classes} = this.props;
		const {data} = this.state
		const body = <Card className={classes.root} style={{minHeight: '30vh'}}>
			<div className="container pb-5">
				<div className="row pt-2 ps-1">
					<Select
						name="simple-select"
						className={classes.textField2}
						displayEmpty
						value={this.state.carrier}
						{...(this.state.errors.carrier && {error: true, helperText: this.state.errors.carrier})}
						onChange={(event)=> this.setState({carrier: event.target.value})}
					>
						<MenuItem value="" disabled>
							Select carrier
						</MenuItem>
						{['LAND'].map(m => <MenuItem value={m}>{m}</MenuItem>)}
					</Select>
				</div>
				<div className="row pt-2">
					<TextField
						fullWidth
						className={classes.textField2}
						name='waybill'
						hidden={!this.state.carrier}
						value={this.state.values.waybill}
						label='Carrier Waybill/Tracking'
						{...(this.state.errors.waybill && {error: true, helperText: this.state.errors.waybill})}
						onChange={this.handleInputChange}
					/>
				</div>
				{data.map(box =>
					<div className="row pt-2">
						<TextField
							fullWidth
							key={box.group_id}
							className={classes.textField}
							name={box.group_id}
							hidden={!this.state.carrier || ['Aramex', 'LAND', 'MISC', 'TNT'].includes(this.state.carrier)}
							value={['Aramex','LAND', 'MISC', 'TNT'].includes(this.state.carrier) ? box.group_id : this.state.values[box.group_id]}
							label={box.group_id}
							{...(this.state.errors[box.group_id] && {error: true, helperText: this.state.errors[box.group_id]})}
							onChange={this.handleInputChange}
						/>
					</div>
				)}
				{/*<div className="row pt-2">*/}
				{/*	<DatePicker*/}
				{/*		disablePast={true}*/}
				{/*		disableFuture={false}*/}
				{/*		className={classes.textField}*/}
				{/*		name='eta'*/}
				{/*		label='ETA'*/}
				{/*		hidden={!this.state.carrier}*/}
				{/*		selectedDateValue={this.selectedDateValue}*/}
				{/*		value={this.state.eta}*/}
				{/*		{...(this.state.errors.eta && {error: true, helperText: this.state.errors.eta})}*/}
				{/*	/>*/}
				{/*</div>*/}
			</div>
		</Card>

		const actions =
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='submit'
				className={classes.button}
				onClick={this.handleSubmit}>
				{this.state.submitting ? 'Submitting' : 'Submit'}
			</Button>

		return (
			<div>
				<Backdrop open={this.state.submitting}/>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title={'Carrier Tracking'}
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='85%'
					maxWidth='sm'
					actions={actions}>
					{body}
				</Modal>
			</div>
		);
	}

	selectedDateValue = (date) => {
		this.setState({eta: date})
		this.validate({eta: date});
	};
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		margin: theme.spacing(1),
		width: '50ch',
	},
	textField2: {
		margin: theme.spacing(1),
		width: '22ch',
	},
});

export default (withSnackbar)(withStyles(useStyles)(ShippingForm))
