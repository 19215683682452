import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import Backdrop from "../shared/backdrop";
import Modal from "../shared/modal/material_dialog";
import $ from "jquery";

const ProductShow = forwardRef((props: any, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [working, setWorking] = useState<boolean>(false);
  const [ordersData, setOrdersData] = useState<any>([]);
  const [ordersDataValue, setOrdersDataValue] = useState<any>({});
  const [dataValue, setDataValue] = useState({ price: "", image: "" });
  const [DataId, setDataId] = useState<any>();
  const [productId, setProductId] = useState<any>();
  const [requestParamData, setRequestParamData] = useState<any>({});
  const [countryData, setCountryData] = useState<any>([]);
  const [regionData, setRegionData] = useState<any>([]);
  const [weightData, setWeightData] = useState<any>([]);
  const [enableEdit, setEnableEdit] = useState<any>(null);
  useImperativeHandle(ref, () => ({
    handleOpen(data, weight, region, country) {
      setWeightData(weight);
      setRegionData(region);
      setCountryData(country);
      setDataId(data.id);
      setProductId(data.product_id);
      setEnableEdit(data.enable_edit);
      setOpen(true);
      setOrdersData(data);
      setDataValue({ price: data?.price, image: data?.image });
      setRequestParamData(data.request_params);
      const modifiedDataUnit = Object.entries(data.request_params).reduce(
        (acc, [key, value]) => {
          acc[key] = "";
          return acc;
        },
        {}
      );
      setOrdersDataValue(modifiedDataUnit);
    },
  }));

  const clearData = () => {
    setOrdersData([]);
  };

  const onCloseModal = () => {
    setOpen(false);
    clearData();
  };

  const extractUnit = (input) => {
    let parts = input?.split(" ");
    if (parts?.length > 1) {
      return parts.slice(1).join(" ");
    } else {
      return "";
    }
  };

  const extractValue = (input) => {
    if (input) {
      let parts = input.split(" ");
      let value = parts[0];
      return value;
    }
  };

  const onSetFormValue = (e) => {
    setRequestParamData({
      ...requestParamData,
      [e.target.name]: e.target.value,
    });
  };

  const onSetFormUnit = (e) => {
    setOrdersDataValue({
      ...ordersDataValue,
      [e.target.name]: e.target.value,
    });
  };

  const onSetValue = (e) => {
    setDataValue({ ...dataValue, [e.target.name]: e.target.value });
  };

  const params = ordersData?.request_params;
  const convertString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const onUpdate = () => {
    if (Number(dataValue.price) > 0) {
      const modifiedData = Object.entries(requestParamData).reduce(
        (acc, [key, value]) => {
          acc[`request_params_${key}`] = value;
          return acc;
        },
        {}
      );
      const modifiedDataUnit = Object.entries(ordersDataValue).reduce(
        (acc, [key, value]) => {
          acc[`weight_unit_for_${key}`] = value;
          return acc;
        },
        {}
      );
      const filteredObject = Object.entries(modifiedDataUnit)
        .filter(([key, value]) => {
          if (typeof value === "object" && Object.keys(value).length === 0) {
            return false; // Remove entries with empty objects
          }
          return true; // Keep other entries
        })
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});

      const payload = {
        marketplace_product: {
          ...dataValue,
        },
        ...modifiedData,
        ...filteredObject,
        commit: "Update",
        locale: "en",
        id: productId,
      };

      $.ajax({
        url: `/marketplace_products/${productId}?locale=en`,
        type: "PUT",
        data: payload,
        dataType: "json",
        success: function (response) {
          if (response?.success) {
            enqueueSnackbar(response?.msg, {
              variant: "success",
            });

            setOpenEdit(false);
            onCloseModal();
            props.callback();
          } else {
            enqueueSnackbar(response.msg, {
              variant: "error",
            });
          }
        },
        error: function (response) {
          enqueueSnackbar(response.msg, {
            variant: "error",
          });
        },
      });
    } else {
      enqueueSnackbar("Please enter price value", {
        variant: "error",
      });
    }
  };
  const action = (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        onClick={() => setOpenEdit(true)}
      >
        Edit
      </Button>
    </>
  );
  const actionButton = (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        onClick={() => onUpdate()}
      >
        Update
      </Button>
    </>
  );
  const renderCellContent = (key, value) => {
    if (Array.isArray(value)) {
      if (key === "product_screenshots") {
        return value.map((val, i) => (
          <React.Fragment key={i}>
            <Link href={val}>{val}</Link>
            {i !== value.length - 1 && <br />}
          </React.Fragment>
        ));
      }
      return value.join(", ");
    }

    if (typeof value === "object" && value !== null) {
      return Object.entries(value)
        .map(([subKey, subValue]) => `${subKey}: ${subValue}`)
        .join(", ");
    }

    return value?.toString();
  };

  return (
    <div>
      <Modal
        title={`Product Statistics`}
        handleClose={() => onCloseModal()}
        disableBackdropClick={false}
        isOpen={open}
        minHeight="30%"
        minWidth="60%"
        maxHeight="85%"
        fullWidth={false}
        actions={enableEdit ? action : ""}
      >
        <>
          <Backdrop open={working} />
          {ordersData?.id && (
            <Card>
              <CardContent>
                <>
                  <Box
                    sx={{
                      display: "flex",
                      margin: "20px 0px",
                    }}
                  >
                    <div>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ minWidth: "150px" }}>
                              Name
                            </TableCell>
                            <TableCell>{ordersData?.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>{ordersData?.description}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>
                              <Link href={ordersData?.url}>
                                {ordersData?.url}
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cost</TableCell>
                            <TableCell>{ordersData?.price}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Brand</TableCell>
                            <TableCell>{ordersData?.brand}</TableCell>
                          </TableRow>
                          {Object.entries(params).map(([key, value]) => (
                            <TableRow key={key}>
                              <TableCell>{convertString(key)}</TableCell>
                              <TableCell>
                                {renderCellContent(key, value)}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell>Shopper type</TableCell>
                            <TableCell>{ordersData?.shopper_type}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard category</TableCell>
                            <TableCell>{ordersData?.category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard sub category</TableCell>
                            <TableCell>{ordersData?.sub_category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Standard sub category one</TableCell>
                            <TableCell>
                              {ordersData?.sub_category_one}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Predict</TableCell>
                            <TableCell>
                              {ordersData?.predict?.toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Created at</TableCell>
                            <TableCell>{ordersData?.created_at}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                    <div style={{ width: "30%", textAlign: "center" }}>
                      <img src={ordersData?.image} width="100%" />
                    </div>
                  </Box>
                </>
              </CardContent>
            </Card>
          )}
        </>
      </Modal>
      <Modal
        title={`Update Product`}
        handleClose={() => setOpenEdit(false)}
        disableBackdropClick={false}
        isOpen={openEdit}
        minHeight="30%"
        minWidth="40%"
        maxHeight="85%"
        fullWidth={false}
        actions={actionButton}
      >
        <>
          <Backdrop open={working} />
          {ordersData?.id && (
            <Card>
              <CardContent>
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <Typography>Price</Typography>

                    <TextField
                      name="price"
                      style={{
                        minWidth: "50%",
                        marginRight: "20%",
                        marginLeft: "auto",
                      }}
                      value={dataValue?.price}
                      onChange={onSetValue}
                      size="small"
                      variant="outlined"
                      placeholder="Price"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <Typography>Image</Typography>

                    <TextField
                      name="image"
                      style={{
                        minWidth: "50%",
                        marginRight: "20%",
                        marginLeft: "auto",
                      }}
                      value={dataValue?.image}
                      onChange={onSetValue}
                      size="small"
                      variant="outlined"
                      placeholder="Image"
                    />
                  </Box>
                  {Object.keys(requestParamData).map((key) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "20px 0px",
                        }}
                      >
                        <Typography>{convertString(key)}</Typography>
                        {key == "region" || key == "country" ? null : (
                          <TextField
                            name={key}
                            style={{
                              minWidth: "50%",
                              marginRight:
                                key == "country" ||
                                key == "region" ||
                                key.includes("weight") ||
                                key == "weight_liquid"
                                  ? "0%"
                                  : "20%",
                              marginLeft: "auto",
                            }}
                            value={requestParamData[key]}
                            onChange={onSetFormValue}
                            size="small"
                            variant="outlined"
                            placeholder={convertString(key)}
                          />
                        )}
                        {key.includes("weight_liquid") ? (
                          <span style={{ width: "20%" }}>
                            <Select
                              style={{
                                minWidth: "80%",
                                marginRight: "20%",
                                marginLeft: "10%",
                              }}
                              name={key}
                              value={ordersDataValue[key]}
                              onChange={onSetFormUnit}
                              variant="outlined"
                              // select
                              // fullWidth
                              // margin="normal"
                              id="supplier_category"
                              label="Supplier Category"
                            >
                              {weightData?.slice(-3)?.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </span>
                        ) : key.includes("weight") ? (
                          <span style={{ width: "20%" }}>
                            <Select
                              style={{
                                minWidth: "80%",
                                marginRight: "20%",
                                marginLeft: "10%",
                              }}
                              name={key}
                              value={ordersDataValue[key]}
                              onChange={onSetFormUnit}
                              variant="outlined"
                              // select
                              // fullWidth
                              // margin="normal"
                              id="supplier_category"
                              label="Supplier Category"
                            >
                              {weightData?.slice(0, 6).map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </span>
                        ) : key == "country" ? (
                          <span style={{ width: "50%" }}>
                            <Select
                              style={{
                                minWidth: "100%",
                                marginRight: "0%",
                                marginLeft: "43%",
                              }}
                              name={key}
                              value={requestParamData[key]}
                              onChange={onSetFormValue}
                              variant="outlined"
                              // select
                              // fullWidth
                              // margin="normal"
                              id="supplier_category"
                              label="Supplier Category"
                            >
                              {countryData?.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </span>
                        ) : key == "region" ? (
                          <span style={{ width: "50%" }}>
                            <Select
                              style={{
                                minWidth: "100%",
                                marginRight: "0%",
                                marginLeft: "44%",
                              }}
                              name={key}
                              value={requestParamData[key]}
                              onChange={onSetFormValue}
                              variant="outlined"
                              // select
                              // fullWidth
                              // margin="normal"
                              id="supplier_category"
                              label="Supplier Category"
                            >
                              {regionData?.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </span>
                        ) : null}
                      </Box>
                    );
                  })}
                </>
              </CardContent>
            </Card>
          )}
        </>
      </Modal>
    </div>
  );
});

export default ProductShow;
