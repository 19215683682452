import React, { forwardRef, useImperativeHandle, useState } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import pureIcons from "../shared/pure_icons";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";

const AddProhibitedCat = forwardRef((props: any, ref) => {
  const { callback } = props;
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [businessProfileId, setBusinessProfileOrdersId] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [discountRate, setDiscount] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [region, setRegion] = useState<any>();
  const [regionData, setRegionData] = useState<any>();
  const [standardCategory, setStandardCategory] = useState<any>();
  const [standardSubCategory, setStandardSubCategory] = useState<any>();
  const [standardSubCategoryOne, setStandardSubCategoryOne] = useState<any>();
  const [venderNameValue, setVenderNameValue] = useState<any>();
  const [standardCategoryValue, setStandardCategoryValue] = useState<any>();
  const [standardSubCategoryValue, setStandardSubCategoryValue] =
    useState<any>();
  const [standardSubCategoryOneValue, setStandardSubCategoryOneValue] =
    useState<any>();
  const [prohibited, setProhibited] = useState<any>(false);

  const _tableRef = React.createRef<any>();
  const { enqueueSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    handleOpen(data) {
      setOpen(true);
      console.log(data);
      setRegionData(data.regionData);
      setStandardCategory(data.standardCategory);
      setStandardSubCategory(data.standardSubCategory);
      setStandardSubCategoryOne(data.standardSubCategoryOne);
    },
    callParent() {
      props.callback();
    },
  }));

  const clearData = () => {
    setRegion("");
    setStandardCategoryValue("");
    setStandardSubCategoryValue("");
    setStandardSubCategoryOneValue("");
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const saveData = () => {
    let formData = new FormData();

    formData.append("prohibited[region]", region);
    formData.append("prohibited[parts_category_id]", standardCategoryValue);
    formData.append(
      "prohibited[parts_sub_category_id]",
      standardSubCategoryValue
    );
    formData.append(
      "prohibited[parts_sub_category_one_id]",
      standardSubCategoryOneValue
    );
    formData.append("locale", "en");

    const url = `/prohibiteds.json?locale=en`;
    fetch(url, { method: "POST", body: formData })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          enqueueSnackbar(result.response || result.msg, {
            variant: "success",
          });
          handleClose();
          callback();
        } else {
          enqueueSnackbar(result.response || result.msg, {
            variant: "error",
          });
        }
      });
  };

  const helperText = (msg) => {
    return <span style={{ color: "red" }}>{msg}</span>;
  };
  const body = (
    <>
      <div className="bg-white rounded">
        <Backdrop open={submitting} />
        <Card style={{ margin: "20px", height: "95%" }}>
          <CardHeader title="Add Prohibited Category"></CardHeader>
          <CardContent>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                name="Region"
                select
                fullWidth
                margin="normal"
                label="Region"
                style={{ width: "30%" }}
                value={region}
                onChange={(e: any) => {
                  setRegion(e.target.value);
                }}
              >
                {regionData?.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Category"
                style={{ width: "30%" }}
                value={standardCategoryValue}
                onChange={(e: any) => {
                  setStandardCategoryValue(e.target.value);
                }}
              >
                {standardCategory?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Sub Category"
                style={{ width: "30%" }}
                value={standardSubCategoryValue}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setStandardSubCategoryValue(e.target.value);
                }}
              >
                {standardSubCategory?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="col mt-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                select
                fullWidth
                margin="normal"
                label="Standard Sub Category One"
                style={{ width: "30%" }}
                value={standardSubCategoryOneValue}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setStandardSubCategoryOneValue(e.target.value);
                }}
              >
                {standardSubCategoryOne?.map((option: any) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <CardActions style={{ justifyContent: "end" }}>
              <Button
                type="button"
                style={{
                  backgroundColor: "#ec9a53",
                  color: "#ebeef0",
                  textTransform: "none",
                }}
                onClick={() => saveData()}
              >
                Save
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      minHeight="70vh"
      maxHeight="90%"
      maxWidth="lg"
    >
      {body}
    </Modal>
  );
});

export default withSnackbar(AddProhibitedCat);
