import React from 'react';
import {Badge,IconButton} from '@material-ui/core';
import PureIcons from '../../shared/pure_icons'
import ShowItem from "../../order_items/show";

export default class RecipeReviewCard extends React.Component {
	constructor(props) {
		super(props);
		this._ShowItemRef = React.createRef();
	}

	render () {
		const product = this.props.product;

		return (
			<div className="card p-1">
				<ShowItem ref={this._ShowItemRef} reloadData={this.reloadData}/>
				<div className="row g-0 m-1">
					<div className="col-md-2 mt-2">
						<div style={{ display: 'flex', justifyContent: 'center',	alignItems: 'center',	height: '200px'}}>
							<a href={product.url} target='_blank'>
								<Badge badgeContent='DG' invisible={!product.dg} overlap="circular" color="error">
									<img style={{height: '200px', width: '200px', objectFit: 'contain'}} alt={product.name} src={product.image} title={product.name}/>
								</Badge>
							</a>
						</div>
					</div>
					<div className="col-md-10">
						<div className="card-body">
							<h5 className="card-title">{product.name}</h5>
							<div className='row'>
								<div className='col-4'>
									<p className='p-0 m-0'>Price: <b>{product.price}</b></p>
									<p className='p-0 m-0'>QTY: <b>{product.qty}</b></p>
									<p className='p-0 m-0'>Item ID: <b>{product.order_item_id}</b></p>
									<IconButton onClick={()=> this._ShowItemRef.current.handleOpen(product.order_item_id)}>
										<PureIcons.ImInfo/>
									</IconButton>
								</div>
								<div className='col-6'>
									{product.customizations.map(c => c.value && <p className='p-0 m-0'>{c.name}: <b>{c.value}</b></p>)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
